/**
 *
 * Button
 * @author: Alex Matthews
 *
 */
import * as React from "react";
import styled, {
  css,
  DefaultTheme,
  ThemedCssFunction,
  ThemeProvider,
} from "styled-components";
import { BORDER_RADIUS, CUBIC_BEZIER } from "../../web/constants";
import {
  themeTextColor,
  themeBorderColor,
  themeGrayLight,
  themeBackgroundColor,
} from "../Theme";
export { default as DullButton } from "./DullButton";
export { default as SecondaryButton } from "./SecondaryButton";
export { default as SuccessButton } from "./SuccessButton";
export { default as DestructiveButton } from "./DestructiveButton";
export { default as ButtonWithIcon } from "./ButtonWithIcon";
export { default as DoneButton } from "./DoneButton";

enum SHAPES {
  default = "default",
  round = "round",
}

export enum VARIANT {
  outline = "outline",
  default = "default",
  round = "round",
}
const shapes: {
  [key in VARIANT]: any;
} = {
  [VARIANT.default]: css`
    padding: ${({ size }: { size: string }) =>
      size === "small" ? "10px 18px" : "15px 25px"};
    border-radius: ${BORDER_RADIUS};
  `,
  [VARIANT.round]: css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 4em;
    height: 4em;
    padding: 0;
    border-radius: 9999px;
    line-height: 4em;
  `,
};
const symbol = css`
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  width: 0.7em;
  height: 0.7em;
  border-left: 1px solid;
  border-bottom: 1px solid;
`;
export const BaseButton = styled.button`
  position: relative;
  display: inline-block;
  color: ${themeTextColor};
  background: ${themeBackgroundColor};
  border: 1px solid ${themeBorderColor};
  font-family: inherit;
  font-size: ${({
    size,
  }: {
    shape?: SHAPES;
    size?: string;
    prev?: boolean;
    next?: boolean;
    variant?: VARIANT;
  }) => (size === "small" ? "13px" : "inherit")};
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 0.04em;
  line-height: 1;

  ${({ prev }) =>
    prev &&
    css`
      &::before {
        ${symbol} left: 15px;
        transform: translateY(-50%) rotate(45deg);
      }
    `} ${({ next }) =>
    next &&
    css`
      &::after {
        ${symbol} right: 15px;
        transform: translateY(-50%) rotate(-135deg);
      }
    `}

  &::selection {
    background: transparent;
    color: inherit;
  }

  &:active,
  &:focus {
    outline: none;
    box-shadow: none;
  }

  &:not([disabled]) {
    cursor: pointer;
    box-shadow: ${({ theme }) => theme.elevation0};
    transition: 0.25s box-shadow ${CUBIC_BEZIER};

    &:hover {
      transform: scale(1.01);
      box-shadow: ${({ theme }) => theme.elevation200};
    }

    &:active {
      transform: scale(1.02);
      box-shadow: ${({ theme }) => theme.elevation200};
    }
  }

  ${({ variant }) =>
    variant === VARIANT.outline
      ? css`
          &[disabled] {
            opacity: 0.4;
          }
        `
      : css`
          &[disabled] {
            background: ${themeGrayLight};
            border-color: ${themeGrayLight};
          }
        `};
  ${({ shape }) => shapes[shape!]};
`;
BaseButton.defaultProps = {
  shape: SHAPES.default,
  variant: VARIANT.default,
  size: "default",
  next: false,
  prev: false,
};
export const UnthemedButton = React.forwardRef(
  (
    props: {
      variant?: VARIANT;
      inverted?: boolean;
      children?: React.ReactNode;
    },
    ref
  ) => (
    <ThemeProvider
      theme={(currentTheme: any) => ({
        ...currentTheme,
        textColor:
          props.variant === "outline" || props.inverted
            ? currentTheme.color
            : currentTheme.opposedColor,
        backgroundColor:
          props.variant === "outline" || props.inverted
            ? currentTheme.trueWhite
            : currentTheme.color,
        borderColor: props.inverted
          ? currentTheme.opposedColor
          : currentTheme.color,
      })}
    >
      <BaseButton {...props} ref={ref as any}>
        {props.children}
      </BaseButton>
    </ThemeProvider>
  )
);

const Button = ({
  variant,
  inverted,
  size,
  next,
  prev,
  ...children
}: {
  variant?: VARIANT;
  inverted?: boolean;
  size?: "default" | "small";
  next?: boolean;
  prev?: boolean;
} & React.ButtonHTMLAttributes<HTMLButtonElement>) => (
  <ThemeProvider
    theme={(currentTheme) => ({
      ...currentTheme,
      color: currentTheme.secondary,
      opposedColor: currentTheme.trueWhite,
    })}
  >
    <UnthemedButton variant={variant} inverted={inverted} {...children} />
  </ThemeProvider>
);

export default Button;
