import { concat, range } from "ramda";
import {
  KeyfobButtonType,
  KeyfobOutputAction,
  KeyfobPressTime,
  PanelHardwareModel,
} from "securecom-graphql/client";
import { PanelHardwareModel as PanelContextHardwareModel } from "../__generated__/PanelContextUseHardwareModel_panel.graphql";

/**
 * Takes in the keyfob button number and button type; returns the default values for the button
 * @param buttonType the keyfob button type
 * @param buttonNumber the button number of the keyfob
 * @returns default values for a keyfob buttton number and type
 */

export const getKeyfobButtonDefaults = (
  buttonType: KeyfobButtonType,
  buttonNumber: KeyfobButtonNumberType
) => {
  let data = {} as any;
  switch (buttonType) {
    case KeyfobButtonType.ARMING:
      data[`button${buttonNumber}Action`] = KeyfobButtonType.ARMING;
      data[`button${buttonNumber}PressTime`] = KeyfobPressTime.SHORT;
      data[`button${buttonNumber}Output`] = "0";
      data[`button${buttonNumber}OutputAction`] = KeyfobOutputAction.OFF;
      data[`button${buttonNumber}Areas`] = "1,2,3,4,5,6";
      break;
    case KeyfobButtonType.DISARMING:
      data[`button${buttonNumber}Action`] = KeyfobButtonType.DISARMING;
      data[`button${buttonNumber}PressTime`] = KeyfobPressTime.SHORT;
      data[`button${buttonNumber}Output`] = "0";
      data[`button${buttonNumber}OutputAction`] = KeyfobOutputAction.OFF;
      data[`button${buttonNumber}Areas`] = "1,2,3,4,5,6";
      break;
    case KeyfobButtonType.TOGGLE_ARM_DISARM: {
      data[`button${buttonNumber}Action`] = KeyfobButtonType.TOGGLE_ARM_DISARM;
      data[`button${buttonNumber}PressTime`] = KeyfobPressTime.SHORT;
      data[`button${buttonNumber}Output`] = "0";
      data[`button${buttonNumber}OutputAction`] = KeyfobOutputAction.OFF;
      data[`button${buttonNumber}Areas`] = "1,2,3,4,5,6";
      break;
    }
    case KeyfobButtonType.PANIC:
      data[`button${buttonNumber}Action`] = KeyfobButtonType.PANIC;
      data[`button${buttonNumber}PressTime`] = KeyfobPressTime.LONG;
      data[`button${buttonNumber}Output`] = "0";
      data[`button${buttonNumber}OutputAction`] = KeyfobOutputAction.STEADY;
      data[`button${buttonNumber}Areas`] = "";
      break;
    case KeyfobButtonType.PANIC_TWO_BUTTON:
      data[`button${buttonNumber}Action`] = KeyfobButtonType.PANIC_TWO_BUTTON;
      data[`button${buttonNumber}PressTime`] = KeyfobPressTime.LONG;
      data[`button${buttonNumber}Output`] = "0";
      data[`button${buttonNumber}OutputAction`] = KeyfobOutputAction.STEADY;
      data[`button${buttonNumber}Areas`] = "";
      break;
    case KeyfobButtonType.EMERGENCY:
      data[`button${buttonNumber}Action`] = KeyfobButtonType.EMERGENCY;
      data[`button${buttonNumber}PressTime`] = KeyfobPressTime.LONG;
      data[`button${buttonNumber}Output`] = "0";
      data[`button${buttonNumber}OutputAction`] = KeyfobOutputAction.STEADY;
      data[`button${buttonNumber}Areas`] = "";
      break;
    case KeyfobButtonType.EMERGENCY_TWO_BUTTON: {
      data[`button${buttonNumber}Action`] =
        KeyfobButtonType.EMERGENCY_TWO_BUTTON;
      data[`button${buttonNumber}PressTime`] = KeyfobPressTime.LONG;
      data[`button${buttonNumber}Output`] = "0";
      data[`button${buttonNumber}OutputAction`] = KeyfobOutputAction.STEADY;
      data[`button${buttonNumber}Areas`] = "";
      break;
    }
    case KeyfobButtonType.OUTPUT: {
      data[`button${buttonNumber}Action`] = KeyfobButtonType.OUTPUT;
      data[`button${buttonNumber}PressTime`] = KeyfobPressTime.SHORT;
      data[`button${buttonNumber}Output`] = "0";
      data[`button${buttonNumber}OutputAction`] = KeyfobOutputAction.STEADY;
      data[`button${buttonNumber}Areas`] = "";
      break;
    }
    case KeyfobButtonType.STATUS:
      data[`button${buttonNumber}Action`] = KeyfobButtonType.STATUS;
      data[`button${buttonNumber}PressTime`] = KeyfobPressTime.SHORT;
      data[`button${buttonNumber}Output`] = "0";
      data[`button${buttonNumber}OutputAction`] = KeyfobOutputAction.OFF;
      data[`button${buttonNumber}Areas`] = "";
      break;
    case KeyfobButtonType.SENSOR_RESET:
      data[`button${buttonNumber}Action`] = KeyfobButtonType.SENSOR_RESET;
      data[`button${buttonNumber}PressTime`] = KeyfobPressTime.SHORT;
      data[`button${buttonNumber}Output`] = "0";
      data[`button${buttonNumber}OutputAction`] = KeyfobOutputAction.OFF;
      data[`button${buttonNumber}Areas`] = "";
      break;
    case KeyfobButtonType.UNUSED:
    default: {
      data[`button${buttonNumber}Action`] = KeyfobButtonType.UNUSED;
      data[`button${buttonNumber}PressTime`] = KeyfobPressTime.SHORT;
      data[`button${buttonNumber}Output`] = "0";
      data[`button${buttonNumber}OutputAction`] = KeyfobOutputAction.OFF;
      data[`button${buttonNumber}Areas`] = "";
      break;
    }
  }
  return data;
};

/**
 * Returns an array of all possible keyfob numbers for a particular hardwareModel and softwareVersion
 *@param hardwareModel ScapiHardwarModel
 *@param softwareVersion number of the softwareVersion
 *@param hasOnboardWirelessEnabled does xt50 panel have use onboard 1100 wireless set to yes
 *@returns array of all possible keyfob number
 */

export const getKeyfobNumberRange = (
  hardwareModel: PanelContextHardwareModel
): readonly number[] => {
  switch (hardwareModel) {
    case PanelHardwareModel.XR150:
    case PanelHardwareModel.XR350:
    case PanelHardwareModel.XR550: {
      return range(400, 450);
    }
    case PanelHardwareModel.XTLN:
    case PanelHardwareModel.XTLW:
    case PanelHardwareModel.XTL:
    case PanelHardwareModel.XTLP: {
      return concat(range(51, 55), range(61, 65));
    }

    case PanelHardwareModel.XT50:
    case PanelHardwareModel.XT50L:
    case PanelHardwareModel.XT30:
    case PanelHardwareModel.XT30L: {
      return concat(range(31, 35), range(41, 45));
    }
    case PanelHardwareModel.DUALCOM:
    case PanelHardwareModel.ICOM:
    case PanelHardwareModel.ICOM_SL:
    case PanelHardwareModel.ICOM_E:
    case PanelHardwareModel.ICOM_NRL:
    case PanelHardwareModel.CELLCOM_SL:
    case PanelHardwareModel.CELLCOM_EX:
    case PanelHardwareModel.MINI_CELLCOM:
    case PanelHardwareModel.ICOM_LNC:
    default: {
      return [];
    }
  }
};

export type KeyfobButtonNumberType = "One" | "Two" | "Three" | "Four";

export const getButtonNumber = (buttonNumber: KeyfobButtonNumberType): string =>
  buttonNumber === "One"
    ? "1"
    : buttonNumber === "Two"
    ? "2"
    : buttonNumber === "Three"
    ? "3"
    : "4";
