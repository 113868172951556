import { App } from "app-module";
import React from "react";
import { react2angular } from "react2angular";
import { joinSpaced } from "utils/string";

type Sizes = "xs" | "sm" | "md" | "lg" | "xl";
type ButtonType = "button" | "submit" | "reset" | undefined;

export default function Button({
  design = "default",
  size = "sm",
  type = "button",
  ...props
}: {
  design?: "primary" | "default" | "warning" | "error";
  size?: Sizes;
  // the types of HTMLButtonElement are not specific enough and cause
  // an error when trying to use (string VS string literals)
  type?: "button" | "submit" | "reset" | undefined;
} & Omit<React.HTMLProps<HTMLButtonElement>, "size" | "type">) {
  return (
    <button
      {...props}
      type={type}
      className={joinSpaced(
        "btn",
        `btn-${size}`,
        `btn-${design === "primary" ? "dmp" : design}`,
        props.className ?? ""
      )}
    />
  );
}

export function ButtonLink({
  design = "default",
  size = "sm",
  ...props
}: { design: "primary" | "default" | "warning" | "error"; size: Sizes } & Omit<
  React.HTMLProps<HTMLAnchorElement>,
  "size"
>) {
  return (
    <a
      {...props}
      className={joinSpaced(
        "btn",
        `btn-${size}`,
        `btn-${design === "primary" ? "dmp" : design}`
      )}
    >
      {props.children ? props.children : ""}
    </a>
  );
}

/**
 * @depreciated This button was an example that was able to be used in angular as a directive.
 * Ideally buttons (onClick javascript actions) should NOT be Links as it is not great for accessiblity
 * It can work, but it needs a lot of aria setup to work seamlessly.
 */
export function SmallButton({
  text,
  onClick = () => {},
  type = "primary",
  ...props
}: {
  onClick: (...args: any[]) => void;
  text: React.ReactNode;
  type?: string;
} & React.HTMLProps<HTMLAnchorElement>) {
  return (
    <a
      className={`btn btn-sm ${type === "primary" ? "btn-dmp" : "btn-default"}`}
      onClick={onClick}
      {...props}
    >
      {text}
    </a>
  );
}

export function dangerouslyAddToApp() {
  App.component(
    "smallButton",
    react2angular(SmallButton, ["text", "onClick", "disabled", "type"])
  );
}
