/**
 * @generated SignedSource<<4a8dc5f52c76feecbf2895d9278a99ef>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type DeviceInformationType = "CAMERA" | "DOOR" | "FIRE" | "KEYPAD" | "NONE" | "VPLEX" | "XR550" | "ZONE_EXPANDER" | "_1100T";
import { FragmentRefs } from "relay-runtime";
export type PanelContextUseHasVplexDevices_panel$data = {
  readonly deviceInformations: ReadonlyArray<{
    readonly deviceType?: DeviceInformationType;
    readonly lxNumber?: string;
    readonly number?: number;
  }>;
  readonly supportsVplex: boolean;
  readonly " $fragmentType": "PanelContextUseHasVplexDevices_panel";
};
export type PanelContextUseHasVplexDevices_panel$key = {
  readonly " $data"?: PanelContextUseHasVplexDevices_panel$data;
  readonly " $fragmentSpreads": FragmentRefs<"PanelContextUseHasVplexDevices_panel">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PanelContextUseHasVplexDevices_panel",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "supportsVplex",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "deviceInformations",
      "plural": true,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "number",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "lxNumber",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "deviceType",
              "storageKey": null
            }
          ],
          "type": "XrDeviceInformation",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Panel",
  "abstractKey": null
};

(node as any).hash = "1e8a1d3e3aa2b3ed3efa0adf75c661ab";

export default node;
