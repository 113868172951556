import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import { useRelayEnvironment } from "react-relay";
import {
  Device734,
  Device734CardOptions,
  DeviceInformation,
  DeviceInformationType,
} from "securecom-graphql/client";
import NumericInput from "../../NumericInput";
import ProgrammingConceptForm from "../../ProgrammingConceptForm";
import { useDeviceInformationFragment } from "../DeviceInformationContext";
import { DeviceInformationWiegandCodeLengthField_deviceInformation$key } from "./__generated__/DeviceInformationWiegandCodeLengthField_deviceInformation.graphql";

export const deviceInformationWiegandCodeLengthFieldId = (number: string) =>
  `device-information-wiegand-code-length-${number}`;

function DeviceInformationWiegandCodeLengthField() {
  const [{ id, number, device734, deviceType }] =
    useDeviceInformationFragment<DeviceInformationWiegandCodeLengthField_deviceInformation$key>(
      graphql`
        fragment DeviceInformationWiegandCodeLengthField_deviceInformation on DeviceInformation {
          id
          number
          ... on XrDeviceInformation {
            deviceType
            device734 {
              ... on Device734 {
                wiegandBitLength
                cardFormatOptions
              }
            }
          }
        }
      `
    );

  const fieldId = deviceInformationWiegandCodeLengthFieldId(String(number));
  const disabled =
    device734?.cardFormatOptions !== Device734CardOptions.CUSTOM ||
    deviceType !== DeviceInformationType.DOOR;
  const relayEnv = useRelayEnvironment();

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Wiegand Code Length"
      tooltip="Length of the code, including parity bits."
      disabled={disabled}
    >
      <NumericInput
        id={fieldId}
        disabled={disabled}
        pattern="((?!000)[0-1]?[0-9]{1,2}|2[0-4][0-9]|25[0-5])"
        value={device734?.wiegandBitLength ?? 26}
        validationMessage="Valid values are 1-255."
        inlineHelp="1-255"
        min={1}
        max={255}
        required
        onChange={({ target }) => {
          relayEnv.commitUpdate((store) => {
            const device734Options = store
              .get<DeviceInformation>(id)
              ?.getLinkedRecord<Device734>("device734");

            if (device734Options) {
              device734Options.setValue(
                Number(target.value),
                "wiegandBitLength"
              );
            }
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default DeviceInformationWiegandCodeLengthField;
