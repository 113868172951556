import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import { resolveOutputNumbers } from "../../OutputInformationFields/utils";
import {
  getClosestOutputValue,
  resolveOutputOptionsFieldNumbers,
} from "../../OutputOptionsFields/utils";
import { useHardwareModel } from "../../PanelContext";
import ProgrammingConceptForm from "../../ProgrammingConceptForm";
import { useHouseCode } from "../../SystemOptionsFields/SystemOptionsContext";
import TextInput from "../../TextInput";
import { useAreaFragment } from "./AreaContext";
import { AreaLateOutputNumberField_xrArea$key } from "./__generated__/AreaLateOutputNumberField_xrArea.graphql";

export const areaLateOutputNumberFieldId = (number: string) =>
  `area-late-output-number-field-${number}`;

function AreaLateOutputNumberField() {
  const [{ number, lateOutputNumber }, updateArea] =
    useAreaFragment<AreaLateOutputNumberField_xrArea$key>(
      graphql`
        fragment AreaLateOutputNumberField_xrArea on XrArea {
          number
          lateOutputNumber
        }
      `
    );

  const fieldId = areaLateOutputNumberFieldId(number);
  const hardwareModel = useHardwareModel();
  const houseCode = useHouseCode();
  const { pattern, validationMessage, inlineHelp } =
    resolveOutputOptionsFieldNumbers(hardwareModel, Number(houseCode || "0"));
  const validNumericalRange = resolveOutputNumbers(hardwareModel, true).concat(
    0
  ); // 0 disables outputs
  const { current: originalValue } = React.useRef(lateOutputNumber);

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Late Output Number"
      tooltip="Turn the output on when the Area has not been armed by the scheduled time. Turn the output off when the Area is armed or the buzzer is silenced."
    >
      <TextInput
        id={fieldId}
        name={fieldId}
        value={lateOutputNumber}
        required
        maxLength={3}
        pattern={pattern}
        inlineHelp={inlineHelp}
        validationMessage={validationMessage}
        onChange={({ target }) => {
          updateArea((recordProxy) => {
            recordProxy.setValue(target.value, "lateOutputNumber");
          });
        }}
        onBlur={({ target }) => {
          const newValue = target.value;
          updateArea((recordProxy) => {
            recordProxy.setValue(
              getClosestOutputValue(
                newValue,
                originalValue ? originalValue.toString() : "",
                hardwareModel,
                validNumericalRange
              ),
              "lateOutputNumber"
            );
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default AreaLateOutputNumberField;
