import graphql from "babel-plugin-relay/macro";

export const xrTemplateSaveMutation = graphql`
  mutation XRTemplateSaveMutation(
    $dealerId: ID!
    $templateData: XrProgrammingTemplateInput!
  ) {
    saveXrProgrammingTemplate(
      dealerId: $dealerId
      templateData: $templateData
    ) {
      ... on SaveProgrammingTemplateErrorResult {
        error
        messages
      }
      ... on SaveProgrammingTemplateSuccessResult {
        programmingTemplate {
          concepts {
            ...XRTemplateDataInline_xrProgrammingTemplateConcepts
          }
        }
      }
      __typename
    }
  }
`;
