import graphql from "babel-plugin-relay/macro";
import Select from "components/FullProgramming/common/Select";
import { range } from "ramda";
import * as React from "react";
import { RecordProxy } from "relay-runtime";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useSystemOptionsFragment } from "./SystemOptionsContext";
import {
  SystemOptionsWirelessEncryption1100Field_systemOptions$data,
  SystemOptionsWirelessEncryption1100Field_systemOptions$key,
  WirelessEncryption1100,
} from "./__generated__/SystemOptionsWirelessEncryption1100Field_systemOptions.graphql";
import { SystemOptionsWirelessHouseCodeField_systemOptions$data } from "./__generated__/SystemOptionsWirelessHouseCodeField_systemOptions.graphql";

export const systemOptionsWirelessEncryption1100FieldId = () =>
  `system-options-wireless-encryption-1100`;

export const wirelessEncryption1100Updater =
  (value: WirelessEncryption1100 | undefined) =>
  (
    recordProxy: RecordProxy<{
      houseCode?: SystemOptionsWirelessHouseCodeField_systemOptions$data["houseCode"];
      wirelessEncryption1100?: WirelessEncryption1100;
      wirelessEncryption1100Passphrase?: SystemOptionsWirelessEncryption1100Field_systemOptions$data["wirelessEncryption1100Passphrase"];
    }>
  ) => {
    recordProxy.setValue(value, "wirelessEncryption1100");

    if (!value || value === "NONE") {
      recordProxy.setValue("", "wirelessEncryption1100Passphrase");
    }
  };

function WirelessEncryption1100Field() {
  const [{ wirelessEncryption1100, houseCode }, updateSystemOptions] =
    useSystemOptionsFragment<SystemOptionsWirelessEncryption1100Field_systemOptions$key>(
      graphql`
        fragment SystemOptionsWirelessEncryption1100Field_systemOptions on SystemOptions {
          ... on XrSystemOptions {
            wirelessEncryption1100
            wirelessEncryption1100Passphrase
            houseCode
          }
          ... on XtSystemOptions {
            wirelessEncryption1100
            wirelessEncryption1100Passphrase
            houseCode
          }
          ... on XfSystemOptions {
            wirelessEncryption1100
            wirelessEncryption1100Passphrase
            houseCode
          }
        }
      `
    );

  const fieldId = systemOptionsWirelessEncryption1100FieldId();
  const houseCodeRange = range(1, 51);
  const disabled = !houseCodeRange.includes(Number(houseCode));

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="1100 Wireless Encryption"
      disabled={disabled}
      tooltip="Allows usage of only Standard 1100 series wireless (None), only Encrypted 1100 series wireless (All), or both."
    >
      <Select
        id={fieldId}
        name={fieldId}
        value={wirelessEncryption1100}
        disabled={disabled}
        required
        onChange={({ target }) => {
          updateSystemOptions(
            wirelessEncryption1100Updater(
              target.value as WirelessEncryption1100
            )
          );
        }}
      >
        <Select.Option value="NONE">None</Select.Option>
        <Select.Option value="BOTH">Both</Select.Option>
        <Select.Option value="ALL">All</Select.Option>
      </Select>
    </ProgrammingConceptForm.Field>
  );
}

export default WirelessEncryption1100Field;
