import graphql from "babel-plugin-relay/macro";
import Switch from "components/FullProgramming/common/Switch";
import { range } from "ramda";
import * as React from "react";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useSystemOptionsFragment } from "./SystemOptionsContext";
import { SystemOptionsUseBuiltIn1100WirelessField_systemOptions$key } from "./__generated__/SystemOptionsUseBuiltIn1100WirelessField_systemOptions.graphql";

export const systemOptionsUseBuiltIn1100WirelessFieldId = () =>
  "system-options-use-built-in-1100-wireless";

function UseBuiltIn1100WirelessFieldField() {
  const [{ useBuiltIn1100Wireless, houseCode }, updateSystemOptions] =
    useSystemOptionsFragment<SystemOptionsUseBuiltIn1100WirelessField_systemOptions$key>(
      graphql`
        fragment SystemOptionsUseBuiltIn1100WirelessField_systemOptions on SystemOptions {
          ... on XrSystemOptions {
            useBuiltIn1100Wireless
            houseCode
          }
          ... on XtSystemOptions {
            useBuiltIn1100Wireless
            houseCode
          }
        }
      `
    );

  const fieldId = systemOptionsUseBuiltIn1100WirelessFieldId();
  const label = "Use Built-In 1100 Wireless";
  const houseCodeRange = range(1, 51);
  const disabled = !houseCodeRange.includes(Number(houseCode));
  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      disabled={disabled}
      label={label}
      tooltip="When enabled, the onboard 1100 wireless is used and Zones 80 and 85 through 99 are enabled."
    >
      <Switch
        label={label}
        id={fieldId}
        name={fieldId}
        checked={disabled ? false : useBuiltIn1100Wireless}
        disabled={disabled}
        onChange={() => {
          updateSystemOptions((recordProxy) => {
            recordProxy.setValue(
              !useBuiltIn1100Wireless,
              "useBuiltIn1100Wireless"
            );
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default UseBuiltIn1100WirelessFieldField;
