/**
 * @generated SignedSource<<95541920e50c3e08fa53cb543bcf8f22>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type DeviceInformationType = "CAMERA" | "DOOR" | "FIRE" | "KEYPAD" | "NONE" | "VPLEX" | "XR550" | "ZONE_EXPANDER" | "_1100T";
import { FragmentRefs } from "relay-runtime";
export type DeviceInformationPublicDoorField_deviceInformation$data = {
  readonly deviceType?: DeviceInformationType;
  readonly number: number;
  readonly publicDoor?: boolean;
  readonly " $fragmentType": "DeviceInformationPublicDoorField_deviceInformation";
};
export type DeviceInformationPublicDoorField_deviceInformation$key = {
  readonly " $data"?: DeviceInformationPublicDoorField_deviceInformation$data;
  readonly " $fragmentSpreads": FragmentRefs<"DeviceInformationPublicDoorField_deviceInformation">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DeviceInformationPublicDoorField_deviceInformation",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "number",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "publicDoor",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "deviceType",
          "storageKey": null
        }
      ],
      "type": "XrDeviceInformation",
      "abstractKey": null
    }
  ],
  "type": "DeviceInformation",
  "abstractKey": "__isDeviceInformation"
};

(node as any).hash = "84647835550c796b53f6e706fe73f999";

export default node;
