import graphql from "babel-plugin-relay/macro";
import Select from "components/FullProgramming/common/Select";
import { panelVersionGTOE } from "components/FullProgramming/utils/panel";
import * as React from "react";
import {
  DeviceInformationCommType,
  DeviceInformationType,
} from "securecom-graphql/client";
import { useSoftwareVersion } from "../PanelContext";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useDeviceInformationFragment } from "./DeviceInformationContext";
import { isAXBusRange } from "./utils";
import { DeviceInformationDeviceCommunicationTypeField_deviceInformation$key } from "./__generated__/DeviceInformationDeviceCommunicationTypeField_deviceInformation.graphql";

export const deviceInformationDeviceCommunicationTypeFieldId = (
  number: string
) => `device-information-device-communication-type-${number}`;

function DeviceInformationDeviceCommunicationTypeField() {
  const [
    { deviceCommunicationMethod, deviceType, lxNumber, number },
    updateDeviceInformation,
  ] = useDeviceInformationFragment<DeviceInformationDeviceCommunicationTypeField_deviceInformation$key>(
    graphql`
      fragment DeviceInformationDeviceCommunicationTypeField_deviceInformation on DeviceInformation {
        id
        number
        lxNumber
        serialNumber
        ... on XrDeviceInformation {
          deviceType
          deviceCommunicationMethod
        }
      }
    `
  );

  const fieldId = deviceInformationDeviceCommunicationTypeFieldId(
    String(number)
  );
  const isAXDevice = isAXBusRange(Number(lxNumber));
  const softwareVersion = useSoftwareVersion();

  // Rules to disable input need to be different from rules to disable entire field.
  const disabled =
    deviceType === DeviceInformationType.FIRE ||
    deviceType === DeviceInformationType.VPLEX ||
    deviceType === DeviceInformationType._1100T ||
    deviceType === DeviceInformationType.CAMERA ||
    deviceType === DeviceInformationType.XR550 ||
    (deviceType === DeviceInformationType.ZONE_EXPANDER && isAXDevice) ||
    Number(number) === 1;

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Device Communication Type"
    >
      <Select
        id={fieldId}
        disabled={disabled}
        value={deviceCommunicationMethod}
        required
        pattern=""
        onChange={({ target }) => {
          updateDeviceInformation((recordProxy) => {
            recordProxy.setValue(
              target.value as DeviceInformationCommType,
              "deviceCommunicationMethod"
            );
            switch (target.value) {
              case DeviceInformationCommType.WIRELESS: {
                if (
                  deviceType === DeviceInformationType.DOOR ||
                  deviceType === DeviceInformationType.KEYPAD
                ) {
                  recordProxy.setValue("14", "serialNumber");
                }
                break;
              }
              default: {
                recordProxy.setValue("", "serialNumber");
                break;
              }
            }
          });
        }}
      >
        <Select.Option selected default disabled={true} value="">
          {deviceType === DeviceInformationType.VPLEX ? `` : `Select a Type`}
        </Select.Option>
        {isAXDevice && deviceType === DeviceInformationType.DOOR && (
          <Select.Option value={DeviceInformationCommType.KEYPAD_BUS}>
            AX Bus
          </Select.Option>
        )}
        {!isAXDevice && (
          <Select.Option value={DeviceInformationCommType.KEYPAD_BUS}>
            Keypad Bus
          </Select.Option>
        )}
        {(deviceType === DeviceInformationType.DOOR ||
          deviceType === DeviceInformationType.ZONE_EXPANDER ||
          deviceType === DeviceInformationType.XR550 ||
          deviceType === DeviceInformationType.CAMERA ||
          (panelVersionGTOE(201, softwareVersion) &&
            deviceType === DeviceInformationType.KEYPAD)) && (
          <Select.Option value={DeviceInformationCommType.NETWORK}>
            Network
          </Select.Option>
        )}
        {(deviceType === DeviceInformationType.DOOR ||
          deviceType === DeviceInformationType.KEYPAD ||
          deviceType === DeviceInformationType._1100T) &&
          !isAXDevice && (
            <Select.Option value={DeviceInformationCommType.WIRELESS}>
              Wireless
            </Select.Option>
          )}
      </Select>
    </ProgrammingConceptForm.Field>
  );
}

export default DeviceInformationDeviceCommunicationTypeField;
