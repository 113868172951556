import graphql from "babel-plugin-relay/macro";
import { closest } from "common/utils";
import { isNotNullOrUndefined } from "common/utils/universal/function";
import { useControlSystem } from "components/FullProgramming/common/ControlSystemContext";
import { deviceInformationActivateOnboardSpeakerFieldId } from "components/FullProgramming/common/DeviceInformationFields/734OptionsFields/DeviceInformationActivateOnboardSpeakerField";
import { deviceInformationActivateZone2BypassFieldId } from "components/FullProgramming/common/DeviceInformationFields/734OptionsFields/DeviceInformationActivateZone2BypassField";
import { deviceInformationActivateZone3RequestToExitFieldId } from "components/FullProgramming/common/DeviceInformationFields/734OptionsFields/DeviceInformationActivateZone3RequestToExitField";
import { deviceInformationCardOptionsFieldId } from "components/FullProgramming/common/DeviceInformationFields/734OptionsFields/DeviceInformationCardOptionsField";
import { deviceInformationNoCommWithPanelFieldId } from "components/FullProgramming/common/DeviceInformationFields/734OptionsFields/DeviceInformationNoCommWithPanelField";
import { deviceInformationRelockOnZone2ChangeFieldId } from "components/FullProgramming/common/DeviceInformationFields/734OptionsFields/DeviceInformationRelockOnZone2ChangeField";
import { deviceInformationRequireSiteCodeFieldId } from "components/FullProgramming/common/DeviceInformationFields/734OptionsFields/DeviceInformationRequireSiteCodeField";
import { deviceInformationSiteCode1FieldId } from "components/FullProgramming/common/DeviceInformationFields/734OptionsFields/DeviceInformationSiteCode1Field";
import { deviceInformationSiteCode2FieldId } from "components/FullProgramming/common/DeviceInformationFields/734OptionsFields/DeviceInformationSiteCode2Field";
import { deviceInformationSiteCode3FieldId } from "components/FullProgramming/common/DeviceInformationFields/734OptionsFields/DeviceInformationSiteCode3Field";
import { deviceInformationSiteCode4FieldId } from "components/FullProgramming/common/DeviceInformationFields/734OptionsFields/DeviceInformationSiteCode4Field";
import { deviceInformationSiteCode5FieldId } from "components/FullProgramming/common/DeviceInformationFields/734OptionsFields/DeviceInformationSiteCode5Field";
import { deviceInformationSiteCode6FieldId } from "components/FullProgramming/common/DeviceInformationFields/734OptionsFields/DeviceInformationSiteCode6Field";
import { deviceInformationSiteCode7FieldId } from "components/FullProgramming/common/DeviceInformationFields/734OptionsFields/DeviceInformationSiteCode7Field";
import { deviceInformationSiteCode8FieldId } from "components/FullProgramming/common/DeviceInformationFields/734OptionsFields/DeviceInformationSiteCode8Field";
import { deviceInformationSiteCodeLengthFieldId } from "components/FullProgramming/common/DeviceInformationFields/734OptionsFields/DeviceInformationSiteCodeLengthField";
import { deviceInformationSiteCodePositionFieldId } from "components/FullProgramming/common/DeviceInformationFields/734OptionsFields/DeviceInformationSiteCodePositionField";
import { deviceInformationUserCodeDigitsFieldId } from "components/FullProgramming/common/DeviceInformationFields/734OptionsFields/DeviceInformationUserCodeDigitsField";
import { deviceInformationUserCodeLengthFieldId } from "components/FullProgramming/common/DeviceInformationFields/734OptionsFields/DeviceInformationUserCodeLengthField";
import { deviceInformationUserCodePositionFieldId } from "components/FullProgramming/common/DeviceInformationFields/734OptionsFields/DeviceInformationUserCodePositionField";
import { deviceInformationWiegandCodeLengthFieldId } from "components/FullProgramming/common/DeviceInformationFields/734OptionsFields/DeviceInformationWiegandCodeLengthField";
import { deviceInformationZone2BypassTimeFieldId } from "components/FullProgramming/common/DeviceInformationFields/734OptionsFields/DeviceInformationZone2BypassTimeField";
import { deviceInformationZone3RequestToExitTimeFieldId } from "components/FullProgramming/common/DeviceInformationFields/734OptionsFields/DeviceInformationZone3RequestToExitTimeField";
import { deviceInformation1100TFieldId } from "components/FullProgramming/common/DeviceInformationFields/DeviceInformation1100TField";
import { deviceInformation1100TWirelessFrequencyFieldId } from "components/FullProgramming/common/DeviceInformationFields/DeviceInformation1100TWirelessFrequencyField";
import { deviceInformationAccessAreasFieldId } from "components/FullProgramming/common/DeviceInformationFields/DeviceInformationAccessAreasField";
import { deviceInformationAutoForceArmFieldId } from "components/FullProgramming/common/DeviceInformationFields/DeviceInformationAutoForceArmField";
import { useDeviceInformationFragment } from "components/FullProgramming/common/DeviceInformationFields/DeviceInformationContext";
import { deviceInformationDeviceCommunicationTypeFieldId } from "components/FullProgramming/common/DeviceInformationFields/DeviceInformationDeviceCommunicationTypeField";
import { deviceInformationDeviceTypeFieldId } from "components/FullProgramming/common/DeviceInformationFields/DeviceInformationDeviceTypeField";
import { deviceInformationDisplayAreasFieldId } from "components/FullProgramming/common/DeviceInformationFields/DeviceInformationDisplayAreasField";
import { deviceInformationDoorRealTimeStatusFieldId } from "components/FullProgramming/common/DeviceInformationFields/DeviceInformationDoorRealTimeStatusField";
import { deviceInformationEgressAreasFieldId } from "components/FullProgramming/common/DeviceInformationFields/DeviceInformationEgressAreasField";
import { deviceInformationFireExitReleaseFieldId } from "components/FullProgramming/common/DeviceInformationFields/DeviceInformationFireExitReleaseField";
import { deviceInformationNameFieldId } from "components/FullProgramming/common/DeviceInformationFields/DeviceInformationNameField";
import { deviceInformationOutputGroupFieldId } from "components/FullProgramming/common/DeviceInformationFields/DeviceInformationOutputGroupField";
import { deviceInformationPinDisarmFieldId } from "components/FullProgramming/common/DeviceInformationFields/DeviceInformationPinDisarmField";
import { deviceInformationPrivateDoorFieldId } from "components/FullProgramming/common/DeviceInformationFields/DeviceInformationPrivateDoorField";
import { deviceInformationProgram734OptionsFieldId } from "components/FullProgramming/common/DeviceInformationFields/DeviceInformationProgram734OptionsField";
import { deviceInformationPublicDoorFieldId } from "components/FullProgramming/common/DeviceInformationFields/DeviceInformationPublicDoorField";
import { deviceInformationScheduleOverrideFieldId } from "components/FullProgramming/common/DeviceInformationFields/DeviceInformationScheduleOverrideField";
import { deviceInformationSendDoorForcedMessageFieldId } from "components/FullProgramming/common/DeviceInformationFields/DeviceInformationSendDoorForcedMessageField";
import { deviceInformationStrikeDelayFieldId } from "components/FullProgramming/common/DeviceInformationFields/DeviceInformationStrikeDelayField";
import { deviceInformationStrikeTimeFieldId } from "components/FullProgramming/common/DeviceInformationFields/DeviceInformationStrikeTimeField";
import { deviceInformationSupervisionTimeFieldId } from "components/FullProgramming/common/DeviceInformationFields/DeviceInformationSupervisionTimeField";
import { isAXBusRange } from "components/FullProgramming/common/DeviceInformationFields/utils";
import NumericInput from "components/FullProgramming/common/NumericInput";
import {
  useDeviceNumberRange,
  useHardwareModel,
  usePanelFragment,
  useSupportsAxBus,
  useSupportsVplex,
} from "components/FullProgramming/common/PanelContext";
import ProgrammingConceptForm, {
  AxDoorDevicesAmountAvailableContext,
} from "components/FullProgramming/common/ProgrammingConceptForm";
import { useUpdateListItemOnNumberChange } from "components/FullProgramming/Templates/utils";
import { emptyStringOrNumber } from "components/FullProgramming/utils/format";
import * as React from "react";
import { useRelayEnvironment } from "react-relay";
import {
  DeviceInformationCommType,
  DeviceInformationType,
  PanelHardwareModel,
} from "securecom-graphql/client";
import {
  formatToAxDeviceNumber,
  formatToLxDeviceNumber,
  resolveXrDeviceSetupNumberRules,
} from "./utils";
import { XRDeviceInformationNumberField_deviceInformation$key } from "./__generated__/XRDeviceInformationNumberField_deviceInformation.graphql";
import { XRDeviceInformationNumberField_panel$key } from "./__generated__/XRDeviceInformationNumberField_panel.graphql";

export const xrDeviceListItemTemplateId = (number: string) =>
  `device-${number}`;
export const XR_DEVICE_INFORMATION_NUMBER =
  "xr-device-information-number-field-";
export const xrDeviceInformationNumberFieldId = (number: string) =>
  XR_DEVICE_INFORMATION_NUMBER + number;
export const XR_DEVICE_IDS = [
  xrDeviceListItemTemplateId,
  xrDeviceInformationNumberFieldId,
  deviceInformationNameFieldId,
  deviceInformationSupervisionTimeFieldId,
  deviceInformation1100TWirelessFrequencyFieldId,
  deviceInformationDeviceTypeFieldId,
  deviceInformationDeviceCommunicationTypeFieldId,
  deviceInformationAccessAreasFieldId,
  deviceInformationEgressAreasFieldId,
  deviceInformationDisplayAreasFieldId,
  deviceInformationStrikeTimeFieldId,
  deviceInformationStrikeDelayFieldId,
  deviceInformationFireExitReleaseFieldId,
  deviceInformationPublicDoorFieldId,
  deviceInformationOutputGroupFieldId,
  deviceInformationScheduleOverrideFieldId,
  deviceInformationAutoForceArmFieldId,
  deviceInformationDoorRealTimeStatusFieldId,
  deviceInformationSendDoorForcedMessageFieldId,
  deviceInformationPinDisarmFieldId,
  deviceInformationProgram734OptionsFieldId,
  deviceInformationPrivateDoorFieldId,
  deviceInformation1100TFieldId,
  deviceInformationActivateZone2BypassFieldId,
  deviceInformationZone2BypassTimeFieldId,
  deviceInformationRelockOnZone2ChangeFieldId,
  deviceInformationActivateZone3RequestToExitFieldId,
  deviceInformationZone3RequestToExitTimeFieldId,
  deviceInformationActivateOnboardSpeakerFieldId,
  deviceInformationCardOptionsFieldId,
  deviceInformationWiegandCodeLengthFieldId,
  deviceInformationSiteCodePositionFieldId,
  deviceInformationSiteCodeLengthFieldId,
  deviceInformationUserCodePositionFieldId,
  deviceInformationUserCodeLengthFieldId,
  deviceInformationRequireSiteCodeFieldId,
  deviceInformationSiteCode1FieldId,
  deviceInformationSiteCode2FieldId,
  deviceInformationSiteCode3FieldId,
  deviceInformationSiteCode4FieldId,
  deviceInformationSiteCode5FieldId,
  deviceInformationSiteCode6FieldId,
  deviceInformationSiteCode7FieldId,
  deviceInformationSiteCode8FieldId,
  deviceInformationUserCodeDigitsFieldId,
  deviceInformationNoCommWithPanelFieldId,
];

function XRDeviceInformationNumberField() {
  const [deviceInformation, updateDeviceInformation] =
    useDeviceInformationFragment<XRDeviceInformationNumberField_deviceInformation$key>(
      graphql`
        fragment XRDeviceInformationNumberField_deviceInformation on DeviceInformation {
          id
          ... on XrDeviceInformation {
            number
            lxNumber
            axNumber
            isNew
            deviceType
          }
        }
      `
    );
  const [{ deviceInformations }] =
    usePanelFragment<XRDeviceInformationNumberField_panel$key>(graphql`
      fragment XRDeviceInformationNumberField_panel on Panel {
        deviceInformations {
          number
        }
      }
    `);

  const duplicateDevicesExist =
    deviceInformations.filter(
      ({ number: deviceNumber }) => deviceNumber === deviceInformation.number
    ).length > 1;

  const [onChangeNumberUpdate, onBlurNumberUpdate] =
    useUpdateListItemOnNumberChange(
      XR_DEVICE_IDS,
      duplicateDevicesExist,
      String(deviceInformation.number),
      xrDeviceListItemTemplateId
    );

  const hardwareModel = useHardwareModel();
  const { isNew, number, deviceType } = deviceInformation;
  const relayEnv = useRelayEnvironment();
  const controlSystem = useControlSystem();
  const deviceNumberRange = useDeviceNumberRange();
  const { pattern, inlineHelp, validationMessage } =
    resolveXrDeviceSetupNumberRules(
      hardwareModel,
      useSupportsAxBus(),
      useSupportsVplex()
    );

  const fieldId = xrDeviceInformationNumberFieldId(
    String(deviceInformation.number)
  );
  const label = "Device Number";
  const disabled = !isNew;

  const axDoorDevicesAvailable = React.useContext(
    AxDoorDevicesAmountAvailableContext
  );

  return (
    <ProgrammingConceptForm.Field fieldId={fieldId} label={label}>
      <NumericInput
        id={fieldId}
        value={isNew ? number : formatToLxDeviceNumber(number)}
        required
        disabled={disabled}
        inlineHelp={inlineHelp}
        validationMessage={validationMessage}
        pattern={pattern}
        onChange={({ target }) => {
          onChangeNumberUpdate(target.value);
          updateDeviceInformation((recordProxy) => {
            recordProxy.setValue(emptyStringOrNumber(target.value), "number");
            if (
              isAXBusRange(Number(target.value)) &&
              hardwareModel === PanelHardwareModel.XR150
            ) {
              recordProxy.setValue(DeviceInformationType.VPLEX, "deviceType");
            }
          });
        }}
        onBlur={({ target }) => {
          /*This is to make sure you cannot duplicate device numbers.  
          If you try to use a taken number or invalid number you are directed 
          back to the closest next man up value.*/
          let blurredNumber = number;
          relayEnv.commitUpdate((store) => {
            const controlSystemRecord = store.get(controlSystem.id);
            const currentDevice = store.get(deviceInformation.id);
            if (currentDevice) {
              const panelRecord = controlSystemRecord?.getLinkedRecord("panel");
              const deviceInformationRecords =
                panelRecord?.getLinkedRecords("deviceInformations");

              if (deviceInformationRecords) {
                const takenNumbers = new Set(
                  deviceInformationRecords.map((device) =>
                    device.getValue("id") !== deviceInformation.id
                      ? formatToLxDeviceNumber(
                          device.getValue("number") as number
                        )?.toString()
                      : "0"
                  )
                );

                const valueAsNumber = Number(target.value);
                const value = closest(
                  !target.value || isNaN(valueAsNumber)
                    ? 1
                    : formatToLxDeviceNumber(valueAsNumber) ?? 1,
                  deviceNumberRange.filter(
                    (num) => !takenNumbers.has(num.toString())
                  )
                );
                currentDevice.setValue(value, "number");
                blurredNumber = value;

                if (value && !isAXBusRange(value)) {
                  currentDevice.setValue("", "lxNumber");
                  currentDevice.setValue(
                    formatToAxDeviceNumber(value)?.toString(),
                    "axNumber"
                  );
                  if (
                    value === 1 &&
                    deviceType === DeviceInformationType.XR550
                  ) {
                    // If the device is the first device and is an XR550 we have to default the device to something else
                    currentDevice.setValue(
                      DeviceInformationType.KEYPAD,
                      "deviceType"
                    );
                    currentDevice.setValue("", "serialNumber");
                    currentDevice.setValue(
                      DeviceInformationCommType.KEYPAD_BUS,
                      "deviceCommunicationMethod"
                    );
                  }
                } else if (value && isAXBusRange(value)) {
                  currentDevice.setValue(value.toString(), "lxNumber");
                  currentDevice.setValue(
                    formatToAxDeviceNumber(value)?.toString(),
                    "axNumber"
                  );
                  currentDevice.setValue(false, "autoForceArm"); // If the device is an AX device, we have to default the autoForceArm to false
                  currentDevice.setValue("", "displayAreas"); // If the device is an AX device, we have to default the displayAreas to empty string

                  if (
                    deviceType !== DeviceInformationType.ZONE_EXPANDER &&
                    deviceType !== DeviceInformationType.VPLEX &&
                    hardwareModel !== PanelHardwareModel.XR150
                  ) {
                    currentDevice.setValue(
                      isNotNullOrUndefined(axDoorDevicesAvailable) &&
                        axDoorDevicesAvailable <= 0 // if this value exists and is at or below 0, we know that there are no more ax doors available and should set the device type to zone expander
                        ? DeviceInformationType.ZONE_EXPANDER
                        : DeviceInformationType.DOOR,
                      "deviceType"
                    );
                  } else if (hardwareModel !== PanelHardwareModel.XR150) {
                    currentDevice.setValue(
                      DeviceInformationType.VPLEX,
                      "deviceType"
                    );
                  }
                }
              }
            }
          });
          onBlurNumberUpdate(String(blurredNumber));
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default XRDeviceInformationNumberField;
