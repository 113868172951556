App.controller("SchedulesXREditCtrl", [
  "$rootScope",
  "$scope",
  "$state",
  "$filter",
  "UserService",
  "timeScheduleModel",
  "scheduleUtilService",
  "SCHEDULES",
  "MultiPanelService",
  "PanelCapabilitiesService",
  "PanelProgArchiveService",
  "ScheduleUseService",
  function (
    $rootScope,
    $scope,
    $state,
    $filter,
    UserService,
    timeScheduleModel,
    scheduleUtilService,
    SCHEDULES,
    MultiPanelService,
    PanelCapabilitiesService,
    PanelProgArchiveService,
    ScheduleUseService
  ) {
    //Determine if we're editing a schedule or creating a new one
    $scope.actionType = "";
    if ($state.is("app.panel_programming.schedule_edit_xr"))
      $scope.actionType = "Edit";
    if ($state.is("app.panel_programming.schedule_new_xr"))
      $scope.actionType = "New";

    $scope.isBusy = false; // Used for showing the spinner overlay
    $scope.viewLoading = true; //Used to delay rendering the Area, Door, Output, and Favorite concepts until after all data has loaded from the panel
    $scope.panels = []; // Stores a list of all control systems the user has access to
    $scope.selectedPanels = []; // Stores a list of all selected systems from the multi-panel list
    $scope.copy_times = false;
    $scope.copy_areas = false;
    $scope.copy_doors = false;
    $scope.copy_outputs = false;
    $scope.copy_favorites = false;
    $scope.door_count = 0; // Used for showing/hiding the door section
    $scope.UserService = UserService;
    $scope.PanelProgArchiveService = PanelProgArchiveService;
    var controlSystem = UserService.controlSystem; // The current control system

    var editSched = this; // ControllerAs

    $scope.multiPanelFilters = [];
    $scope.multiPanelFilters.push({
      field: "ITEM.software_version",
      operator: ">=",
      value: "100", // This is set dynamically on load and when twilight values change
      action: "disable",
      displayTemplate:
        "Panel system version is less than {{ multiPanelFilterData.value }}.",
    });

    $scope.multiPanelFilters.push({
      field: "ITEM.hardware_family",
      operator: "==",
      value: "XR550",
      action: "hide",
    });

    $scope.twilightData = scheduleUtilService.twilightData;
    $scope.setPeriod = function (beginTime, selection) {
      scheduleUtilService.setPeriod(beginTime, selection, $scope.schedule);
    };
    $scope.setOffset = function (beginTime, offset) {
      scheduleUtilService.setOffset(beginTime, offset, $scope.schedule);
    };
    $scope.setAnyChecked = function () {
      scheduleUtilService.setAnyChecked($scope.schedule);
      $scope.updateMultiPanelFilters();
    };

    /**
     * @desc Update our multi-panel-list filters
     */
    $scope.updateMultiPanelFilters = function () {
      // 171 contains sunrise/sunset
      $scope.twilightData.anyBeginCheck || $scope.twilightData.anyEndCheck
        ? ($scope.multiPanelFilters[0].value = "171")
        : ($scope.multiPanelFilters[0].value = "100");

      // 172 contains Temporary Schedule
      if ($scope.schedule.temp_sched === "Y")
        $scope.multiPanelFilters[0].value = "172";
    };

    // Set the local variables to the new control system values
    var hardwareFamily =
      controlSystem.panels[controlSystem.panel_index].hardware_family; // Get the hardware family
    //$scope.tracked_outputs = UserService.controlSystem.panels[UserService.controlSystem.panel_index].tracked_outputs;
    $scope.family =
      UserService.controlSystem.panels[
        UserService.controlSystem.panel_index
      ].hardware_family;
    $scope.panel_id = $state.params.panel_id;
    $scope.maxScheduleNumber = SCHEDULES[$scope.family + "_MAX_SCHEDULES"]; // Get the max schedule number for the current panel family
    $scope.areaSchedules = "";

    //Get a reference for each day of the week
    $scope.dayPrefixes = [];
    angular.forEach(scheduleUtilService.days, function (day) {
      $scope.dayPrefixes.push(day.prefix);
    });

    //Collapse all of the sections
    editSched.isCollapsedAreas = true;
    editSched.isCollapsedDoors = true;
    editSched.isCollapsedFavorites = true;
    editSched.isCollapsedDoors = true;
    editSched.isCollapsedOutputs = true;

    /**
     * @ngdoc object
     * @name toggleCheckBox
     * @methodOf SchedulesEditCtrl
     *
     * @description
     * Allows you to toggle a checkbox and have it update a foreign array with a given item
     *
     */
    $scope.toggleCheckBox = function (item, array) {
      var idx = array.indexOf(item);
      // If the item is currently selected, remove it, else add it
      idx > -1 ? array.splice(idx, 1) : array.push(item);
    };

    /**
     * @ngdoc object
     * @name setAutoDisarm
     * @methodOf SchedulesEditCtrl
     *
     * @description
     * Forces certain auto_disrm rules.
     * if you are disarming PERIMETER, then also disarm INTERIOR and BEDROOMS
     * if you are disarming INTERIOR, then also disarm BEDROOMS
     *
     */
    $scope.setAutoDisarm = function (area) {
      if (
        $scope.Panel.system_options.arm_mode == "A" ||
        $scope.Panel.system_options.arm_mode == "H"
      ) {
        switch (parseInt(area.number, 10)) {
          case 1: // PERIMETER
            if (getAreaWithNumber(1).auto_disrm === "Y") {
              getAreaWithNumber(2).auto_disrm = area.auto_disrm; // INTERIOR
              getAreaWithNumber(3).auto_disrm = area.auto_disrm; // BEDROOMS
            }
            break;
          case 2: // INTERIOR
            if (getAreaWithNumber(2).auto_disrm === "Y") {
              getAreaWithNumber(3).auto_disrm = area.auto_disrm; // BEDROOMS
            } else {
              getAreaWithNumber(1).auto_disrm = area.auto_disrm; // BEDROOMS
            }
            break;
          case 3: // BEDROOMS
            if (getAreaWithNumber(3).auto_disrm === "N") {
              getAreaWithNumber(1).auto_disrm = area.auto_disrm; // PERIMETER
              getAreaWithNumber(2).auto_disrm = area.auto_disrm; // INTERIOR
            }
            break;
          default:
            break;
        }
      }
    };

    /**
     * @ngdoc object
     * @name setAutoArm
     * @methodOf SchedulesEditCtrl
     *
     * @description
     * Forces certain auto_disrm rules.
     * if you are arming INTERIOR, then also arm PERIMETER
     * if you are arming BEDROOMS, then also arm PERIMETER and INTERIOR
     *
     */
    $scope.setAutoArm = function (area) {
      if (
        $scope.Panel.system_options.arm_mode == "A" ||
        $scope.Panel.system_options.arm_mode == "H"
      ) {
        switch (parseInt(area.number, 10)) {
          case 1: // PERIMETER
            if (getAreaWithNumber(1).auto_arm === "N") {
              getAreaWithNumber(2).auto_arm = area.auto_arm;
              getAreaWithNumber(3).auto_arm = area.auto_arm;
            }
            break;
          case 2: // INTERIOR
            if (getAreaWithNumber(2).auto_arm === "N") {
              getAreaWithNumber(3).auto_arm = area.auto_arm;
            } else {
              getAreaWithNumber(1).auto_arm = area.auto_arm; // PERIMETER
            }
            break;
          case 3: // BEDROOMS
            if (getAreaWithNumber(3).auto_arm === "Y") {
              getAreaWithNumber(1).auto_arm = area.auto_arm; // PERIMETER
              getAreaWithNumber(2).auto_arm = area.auto_arm; // INTERIOR
            }
            break;
          default:
            break;
        }
      }
    };

    /**
     * @ngdoc
     * @name SchedulesEditCtrl:getAreaWithNumber
     *
     * @param {int} number The area number to search for
     *
     * @description
     * Finds the area with the given number
     *
     * @returns {area_information} The area with a matching number
     *
     */
    function getAreaWithNumber(number) {
      return $filter("filter")($scope.Panel.area_informations, {
        number: number,
      })[0];
    }

    /**
     * @ngdoc
     * @name method:getNextScheduleNumber
     * @methodOf SchedulesEditCtrl:XRTimeSchedulesService
     *
     * @description
     * Get the next available schedule number
     *
     * @returns {Integer} The next available number slot
     */
    function getNextScheduleNumber() {
      var scheduleNumbers = [];
      angular.forEach($scope.Panel.time_schedules, function (key, value) {
        scheduleNumbers.push(parseInt(key.number, 10));
      });

      $scope.nextScheduleNumber = new nextManUp(
        scheduleNumbers,
        $scope.maxScheduleNumber
      ).getNext();
      return $scope.nextScheduleNumber;
    }

    /**
     * @ngdoc object
     * @name method:disableCheckBox
     * @methodOf XRTimeSchedulesService
     *
     * @description
     * Determines whether a checkbox should be disabled for a concept (Favorite, Output or Door) based on whether you've
     * reached the limit of schedules for that type, and whether the concept is on the currently viewed schedule.
     *
     * @param {Integer} number The number of the concept, such as favorite.number
     * @param {String} type The concept type; area, favorite, door, output
     * @param {timeScheduleModel} thisSchedule The schedule that is currently being edited
     * @returns {boolean} indicates whether a concept should be disabled on the edit schedule view
     */
    $scope.disableCheckBox = function (number, type, thisSchedule) {
      if (
        number == null ||
        type == null ||
        thisSchedule == null ||
        angular.isUndefined(number) ||
        angular.isUndefined(type) ||
        angular.isUndefined(thisSchedule) ||
        angular.isUndefined(thisSchedule[type])
      )
        return false;

      // Get an array of all schedules found for this type
      var found = $filter("filter")(
        $scope.Panel.time_schedules,
        function (timeScheduleModel) {
          return timeScheduleModel[type].indexOf(parseInt(number, 10)) > -1;
        },
        true
      );
      // Does the passed in schedule contain the passed in type ?
      var isInThisSchedule =
        thisSchedule[type].indexOf(parseInt(number, 10)) > -1;
      // Have we reached the limit (8), or 16 for 191

      var limitReached = false;
      if (
        PanelCapabilitiesService.supports16DoorSchedules(
          $scope.controlSystem.panels[$scope.controlSystem.panel_index]
        ) &&
        type == "door_ids"
      ) {
        limitReached = found.length >= 16;
      } else {
        limitReached = found.length >= 8;
      }

      // If the limit is reached AND this type isn't set for this schedule, then disable it
      var disabled = angular.isUndefined(found)
        ? false
        : limitReached && !isInThisSchedule;

      return disabled;
    };

    // Convenience methods for disableCheckBox...
    $scope.disableDoorCheckBox = function (number, thisSchedule) {
      return this.disableCheckBox(number, "door_ids", thisSchedule);
    };

    $scope.disableOutputCheckBox = function (number, thisSchedule) {
      return this.disableCheckBox(number, "output_ids", thisSchedule);
    };

    $scope.disableFavoriteCheckBox = function (number, thisSchedule) {
      return this.disableCheckBox(number, "favorite_ids", thisSchedule);
    };

    $scope.disableAreaCheckBox = function (number, thisSchedule) {
      return this.disableCheckBox(number, "area_ids", thisSchedule);
    };

    $scope.dateTimeForceUTC = function (inDateStr) {
      var inDate = new Date(inDateStr);
      return dateTimeForceUTC(inDate);
    };

    /**
     * @ngdoc
     * @name SchedulesEditCtrl:goToSchedules
     *
     * @description
     * Loads the schedules view
     */
    function goToSchedules() {
      $state.go("^.schedules_xr", {
        customer_id: UserService.customer_id,
        control_system_id: UserService.control_system_id,
        panel_id: UserService.panel_id,
      });
    }

    /**
     * @ngdoc
     * @name SchedulesEditCtrl:deleteSchedule
     *
     * @description
     * Removes the schedule from the panel then calls goToSchedules
     */
    $scope.deleteSchedule = function () {
      var selectedSystemsCount = $filter("filter")(
        $scope.panels,
        { selected: true },
        true
      ).length;
      selectedSystemsCount > 1
        ? deleteMultiPanel($scope.schedule)
        : deleteSinglePanel($scope.schedule);
    };

    function deleteSinglePanel(scheduleToDelete) {
      $scope.isBusy = true;
      $scope.Panel.deleteItem("time_schedules", scheduleToDelete)
        .then(function () {
          $scope.isBusy = false;
          goToSchedules();
        })
        .catch(function (error) {
          console.error(error);
        });
    }

    function deleteMultiPanel(scheduleToDelete) {
      var queuedJobs = [];
      var jobType = "DELETE_XR_SCHEDULE_V2";

      // Create our SchedulerJobs and JobGroup and schedule the group to run
      MultiPanelService.createMultiPanelJob(
        jobType,
        $scope.selectedPanels,
        null,
        $scope.schedule.number
      )
        .then(
          function () {
            $scope.isBusy = false;
            $rootScope.alerts.push({
              type: "success",
              text: "XR Schedule changes have been queued up.",
            });
            $scope.Panel.cancelEdit("time_schedules"); // Since we don't want to show changes until the scheduled job finishes, revert back to the pristine concept
            goToSchedules();
          },
          function (error) {
            $scope.isBusy = false;
            $rootScope.alerts.push({
              type: "error",
              text: "Error occurred when attempting to schedule the multi-panel action.",
              json: error,
            });
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    }

    /**
     * @ngdoc
     * @name SchedulesEditCtrl:scheduleUseArray
     * @methodOf SchedulesEditCtrl
     * @description
     * Checks to see if the schedule is in use and returns an array of strings to display in the warning message
     * @param {time_schedule} scheduleToDelete The schedule to remove from the panel
     * @returns {[string]} an array of strings to display in the warning message
     * */
    $scope.scheduleUseArray = async function (scheduleToDelete) {
      let useArray = "";
      if (scheduleToDelete && $scope.Panel.panel_id) {
        const scheduleUseData = {
          ...(await ScheduleUseService.getScheduleUse(
            $scope.Panel.panel_id,
            scheduleToDelete.number
          )),
        };
        useArray = scheduleUtilService.formatScheduleUseArray(scheduleUseData);
      }
      return useArray;
    };

    $scope.saveNewSchedule = function () {
      $scope.isBusy = true;
      // Schedules are assumed to be "in sync" with the panel so set the refreshPristine flag to false

      $scope.Panel.saveProgramming()
        .then(
          function () {
            $scope.isBusy = false;
            goToSchedules();
          },
          function (error) {
            $scope.isBusy = false;
            console.error(
              "Error occurred when attempting to save programming to Dealer Admin: " +
                angular.toJson(error)
            );
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    };

    /**
     * @ngdoc
     * @name SchedulesEditCtrl:sendScheduleChanges
     *
     * @description
     * Save all changes that the user has made to panel programming then calls goToSchedules on success
     */
    $scope.sendScheduleChanges = function () {
      scheduleUtilService.finalizeSchedule($scope.schedule);
      var selectedSystemsCount = $filter("filter")(
        $scope.panels,
        { selected: true },
        true
      ).length;
      selectedSystemsCount > 1 ? saveMultiPanel() : saveSinglePanel();
    };

    /**
     * @ngdoc
     * @name SchedulesEditCtrl:saveAreaSettings
     *
     * @description
     * Save changes to area_informations for the current panel.
     */
    $scope.saveAreaSettings = function () {
      $scope.isBusy = true;
      $scope.Panel.sendProgramming([
        "area_informations",
        "system_area_information",
      ])
        .then(
          function () {
            $rootScope.alerts.push({
              type: "success",
              text: "Area settings saved.",
            });
            $scope.isBusy = false;
          },
          function (error) {
            console.error(
              "XR Edit -> saveAreaSettings() - area_informations, system_area_information save changes error: " +
                angular.toJson(error)
            );
            $rootScope.alerts.push({
              type: "error",
              text: "Error saving area settings.",
              json: error,
            });
            $scope.isBusy = false;
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    };

    function saveSinglePanel() {
      $scope.isBusy = true;
      $scope.Panel.sendProgramming(
        "time_schedules",
        false,
        false,
        $scope.schedule.number
      )
        .then(
          function () {
            $rootScope.alerts.push({
              type: "success",
              text: "Schedule saved.",
            });
            goToSchedules();
          },
          function (error) {
            $rootScope.alerts.push({
              type: "error",
              text: "Error saving data to the system.",
              json: error,
            });
          }
        )
        .catch(function (error) {
          console.error(error);
        })
        .finally(function () {
          $scope.isBusy = false;
        });
    }

    function saveMultiPanel() {
      $scope.isBusy = true;
      var jobData = [];
      var jobType = "";
      $scope.actionType == "Edit"
        ? (jobType = "UPDATE_XR_SCHEDULE_V2")
        : (jobType = "ADD_XR_SCHEDULE_V2");

      // Loop through all panels in the multi-panel list and build our jobData objects to send to the multi-panel service
      for (var i = 0; i < $scope.selectedPanels.length; i++) {
        var pnl = $scope.selectedPanels[i]; // Get a reference to the current panel in our selectedPanels array

        if (pnl.id == UserService.control_system_id) {
          // Build the scheduler job for the current panel
          var schedule = angular.copy($scope.schedule);
        } else {
          // Build the scheduler job for all other panels
          var schedule = $scope.schedule.prepareToSendMultiPanel(
            $scope.copy_times,
            $scope.copy_areas,
            $scope.copy_doors,
            $scope.copy_outputs,
            $scope.copy_favorites
          );
        }

        jobData.push({ time_schedule: schedule });
      }

      // Create our SchedulerJobs and JobGroup and schedule the group to run
      MultiPanelService.createMultiPanelJob(
        jobType,
        $scope.selectedPanels,
        jobData,
        $scope.schedule.number
      )
        .then(
          function () {
            $scope.isBusy = false;
            $rootScope.alerts.push({
              type: "success",
              text: "XR Schedule changes have been queued up.",
            });
            $scope.Panel.cancelEdit("time_schedules"); // Since we don't want to show changes until the scheduled job finishes, revert back to the pristine concept
            goToSchedules();
          },
          function (error) {
            $scope.isBusy = false;
            $rootScope.alerts.push({
              type: "error",
              text: "Error occurred when attempting to schedule the multi-panel action.",
              json: error,
            });
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    }

    /**
     * @ngdoc
     * @name SchedulesEditCtrl:setSelected
     *
     * @description
     * Sets the selected state of the panels to the selectPanel variable
     */
    $scope.setSelected = function (selectedState) {
      // Check or uncheck each system in the multi-panel list except for the template system which will always be checked
      angular.forEach($scope.panels, function (system) {
        system.templateSystem
          ? (system.selected = true)
          : (system.selected = selectedState);
      });
    };

    /**
     * Callback for multi-panel-list directive selectionChanged event
     * @param selectedPanels {array} An array of currently selected panels in the multi-panel list
     */
    $scope.selectionChanged = function (selectedPanels) {
      $scope.selectedPanels = selectedPanels;
    };

    /**
     * @ngdoc
     * @name SchedulesEditCtrl:cancelEdit
     *
     * @description
     * Cancels any changes that have been made by restoring the concept's pristine state then calls goToSchedules
     */
    $scope.cancelEdit = function () {
      $scope.Panel.cancelEdit("time_schedules");
      goToSchedules();
    };

    /**
     * @ngdoc
     * @name SchedulesEditCtrl:initialize
     *
     * @description
     * Initialize the control system
     */
    function initialize() {
      if (angular.isUndefined($scope.Panel["time_schedules"])) {
        return;
      }

      $scope.isBusy = true;

      // Get the number of doors for this panel
      $scope.door_count = $filter("filter")($scope.Panel.device_informations, {
        tracked: true,
        tipe: "1",
      }).length;

      // Make sure that all of our concept numbers are uniformly formatted (ex. 001)
      angular.forEach($scope.Panel.area_informations, function (area) {
        area.number = $filter("zpad")(area.number, 3);
      });
      angular.forEach($scope.Panel.output_informations, function (output) {
        output.number = $filter("zpad")(output.number, 3);
      });
      /*angular.forEach($scope.Panel.device_informations, function(device) {
        device.number = $filter('zpad')(device.number,3);
      });*/
      angular.forEach($scope.Panel.favorites, function (favorite) {
        favorite.number = $filter("zpad")(favorite.number, 3);
      });

      $scope.areaSchedules = $scope.Panel.system_area_information.area_sch; // Used to show/hide area checkboxes

      if ($scope.actionType == "New") {
        // Create a new output schedule, and update the Schedule Service's currentSchedule property
        $scope.Panel.newItem("time_schedules")
          .then(function (scheduleData) {
            buildSchedule(scheduleData);
          })
          .catch(function (error) {
            console.error(error);
          });
        $scope.viewLoading = false;
      } else if ($scope.actionType == "Edit") {
        //Get the first schedule that has a matching schedule number
        var scheduleData = $filter("filter")(
          $scope.Panel.time_schedules,
          { number: $state.params.schedule_number },
          true
        )[0];

        buildSchedule(scheduleData);
        $scope.viewLoading = false;
      }

      getMultiPanelInfo();

      $scope.isBusy = false;
    }

    /**
     * Function to initialize data when/if a schedule is loaded
     */
    $scope.$watch("schedule", function (newValue) {
      if (angular.isDefined(newValue)) {
        // Check to see if the "Apply Times To All Areas" checkbox needs to be initialized
        if (
          angular.isDefined($scope.schedule) &&
          angular.isDefined($scope.areaSchedules) &&
          $scope.areaSchedules === "N"
        ) {
          $scope["selectAllAreas"] = $scope.schedule.area_ids.indexOf(1) !== -1;
        }
      }
    });

    /**
     * Get a list of all supported panels for the multi-panel list. Also get the appropriate concepts for each panel
     */
    function getMultiPanelInfo() {
      // Retrieve control systems for the current customer
      $scope.multiBusy = true;

      MultiPanelService.getPanels(
        $state.params.customer_id,
        $scope.Panel.panel_id
      )
        .then(
          function (data) {
            $scope.panels = data;
          },
          function (error) {
            $scope.multiBusy = false;
            $rootScope.alerts.push({
              type: "error",
              text: "An error occurred while retrieving the multi-panel list.",
              json: error,
            });
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    }

    async function buildSchedule(scheduleData) {
      var newTimeSchedule = new timeScheduleModel();
      $scope.schedule = angular.extend(scheduleData, newTimeSchedule);
      $scope.schedule.initSchedule();
      scheduleUtilService.initTwilightData($scope.schedule);
      $scope.updateMultiPanelFilters();
      $scope.schedule.warningArray = await $scope.scheduleUseArray(
        $scope.schedule
      );
    }

    initialize();

    /**
     * Listen for the all_concepts_retrieved event, which means the progrouter controller has successfully retrieved the concepts
     */
    $scope.$on("all_concepts_retrieved", function () {
      initialize();
    });

    /*datepicker*/
    $scope.dateFilters = {};
    $scope.datePickerOpen = {};
    $scope.currentDate = new Date();
    $scope.today = function () {
      $scope.dt = new Date();
    };
    $scope.today();

    $scope.clear = function () {
      $scope.dt = null;
    };

    $scope.date_low_open = function ($event) {
      $event.preventDefault();
      $event.stopPropagation();

      $scope.datePickerOpen.dateLowOpened = true;
    };

    $scope.date_high_open = function ($event) {
      $event.preventDefault();
      $event.stopPropagation();

      $scope.datePickerOpen.dateHighOpened = true;
    };

    $scope.dateOptions = {
      formatYear: "yy",
      startingDay: 1,
    };

    $scope.formats = [
      "dd-MMMM-yyyy",
      "yyyy/MM/dd",
      "dd.MM.yyyy",
      "MM/dd/yy",
      "MM/dd/yyyy",
      "MM/d/yy",
      "shortDate",
    ];
    $scope.format = $scope.formats[4];

    var tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    var afterTomorrow = new Date();
    afterTomorrow.setDate(tomorrow.getDate() + 2);
    $scope.events = [
      {
        date: tomorrow,
        status: "full",
      },
      {
        date: afterTomorrow,
        status: "partially",
      },
    ];

    $scope.getDayClass = function (date, mode) {
      if (mode === "day") {
        var dayToCheck = new Date(date).setHours(0, 0, 0, 0);

        for (var i = 0; i < $scope.events.length; i++) {
          var currentDay = new Date($scope.events[i].date).setHours(0, 0, 0, 0);

          if (dayToCheck === currentDay) {
            return $scope.events[i].status;
          }
        }
      }

      return "";
    };

    /**
     * Cancel any pending changes when the controller is destroyed.
     * This prevents the list page from displaying incorrect data if the user presses the back button
     */
    $scope.$on("$destroy", function () {
      $scope.Panel.cancelEdit("time_schedules");
    });
  },
]);
