import graphql from "babel-plugin-relay/macro";
import TextInput from "components/FullProgramming/common/TextInput";
import BarCodeScanner from "components/MobileCamera/MobileCameraCheck";
import * as React from "react";
import {
  DeviceInformationCommType,
  DeviceInformationType,
} from "securecom-graphql/client";
import { usePanelFragment } from "../PanelContext";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useDeviceInformationFragment } from "./DeviceInformationContext";
import { DeviceInformationSerialNumberField_deviceInformation$key } from "./__generated__/DeviceInformationSerialNumberField_deviceInformation.graphql";
import { DeviceInformationSerialNumberField_deviceSerialNumberList_panel$key } from "./__generated__/DeviceInformationSerialNumberField_deviceSerialNumberList_panel.graphql";

export const DEVICE_INFORMATION_SERIAL_NUMBER =
  "device-information-serial-number-";

export const deviceInformationSerialNumberFieldId = (number: string) =>
  DEVICE_INFORMATION_SERIAL_NUMBER + number;

function DeviceInformationSerialNumberField() {
  const [
    {
      id,
      number,
      serialNumber,
      wirelessTranslator1100t,
      deviceCommunicationMethod,
      wireless,
      deviceType,
    },
    updateDeviceInformation,
  ] = useDeviceInformationFragment<DeviceInformationSerialNumberField_deviceInformation$key>(
    graphql`
      fragment DeviceInformationSerialNumberField_deviceInformation on DeviceInformation {
        id
        number
        serialNumber
        ... on XtDeviceInformation {
          wirelessTranslator1100t
          wireless
        }
        ... on XrDeviceInformation {
          wirelessTranslator1100t
          deviceCommunicationMethod
          deviceType
        }
      }
    `
  );

  const [panel] =
    usePanelFragment<DeviceInformationSerialNumberField_deviceSerialNumberList_panel$key>(
      graphql`
        fragment DeviceInformationSerialNumberField_deviceSerialNumberList_panel on Panel {
          deviceInformations {
            id
            serialNumber
          }
        }
      `
    );
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);
  const fieldId = deviceInformationSerialNumberFieldId(String(number));
  const is1100t =
    wirelessTranslator1100t || deviceType === DeviceInformationType._1100T;
  const XR550 = deviceType === DeviceInformationType.XR550;
  const CAMERA = deviceType === DeviceInformationType.CAMERA;
  const isWireless =
    wireless ||
    deviceCommunicationMethod === DeviceInformationCommType.WIRELESS;
  const disabled = !(isWireless || is1100t || XR550);

  const otherDeviceInformationSerialNumberList = panel.deviceInformations
    .filter((device) => device.id !== id && device.serialNumber.length === 8)
    .map((device) => device.serialNumber);

  const duplicateSerialNumberPattern =
    otherDeviceInformationSerialNumberList.join(`|`);

  const keypadSerialNumberPattern = duplicateSerialNumberPattern
    ? `(?!${duplicateSerialNumberPattern})^14[0-9][0-9][0-9][0-9][0-9][0-9]$`
    : `^14[0-9][0-9][0-9][0-9][0-9][0-9]$`;

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Serial Number"
      disabled={disabled}
    >
      <TextInput
        id={fieldId}
        name={fieldId}
        required
        value={serialNumber}
        disabled={disabled}
        className={`setup-${number}-textbox`}
        pattern={
          disabled
            ? ""
            : is1100t
            ? "13[0-9]{6}"
            : XR550
            ? "^[0-9A-F]{8}$"
            : keypadSerialNumberPattern
        }
        inlineHelp={
          is1100t
            ? "13000000-13999999"
            : XR550
            ? "XR Panel serial number"
            : "14000000-14999999"
        }
        maxLength={8}
        onChange={({ target }) => {
          updateDeviceInformation((recordProxy) => {
            recordProxy.setValue(
              target.value.toLocaleUpperCase(),
              "serialNumber"
            );
          });
        }}
        onBlur={({ target }) => {
          updateDeviceInformation((recordProxy) => {
            recordProxy.setValue(
              target.value.toLocaleUpperCase(),
              "serialNumber"
            );
          });
        }}
        getValidationMessage={(input) => {
          if (
            input.validity.patternMismatch &&
            duplicateSerialNumberPattern !== ""
          ) {
            if (serialNumber?.match(duplicateSerialNumberPattern)) {
              return "Serial number already in use";
            }
          } else if (input.validity.patternMismatch) {
            return "Invalid serial number";
          }
          return "";
        }}
      />
      {windowWidth <= 800 || window.navigator.userAgent.includes("iPad") ? (
        <BarCodeScanner updateDeviceInformation={updateDeviceInformation} />
      ) : null}
      <span hidden={!XR550} className="user-feedback__neutral">
        Ensure XR/X1 Communication (Network Options) is enabled and the XR/X1
        Passphrase (Network Options) matches this XR's passphrase for proper
        communication.
      </span>
    </ProgrammingConceptForm.Field>
  );
}

export default DeviceInformationSerialNumberField;
