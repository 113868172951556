import graphql from "babel-plugin-relay/macro";
import NumericInput from "components/FullProgramming/common/NumericInput";
import { emptyStringOrNumber } from "components/FullProgramming/utils/format";
import { clamp } from "ramda";
import * as React from "react";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useSystemOptionsFragment } from "./SystemOptionsContext";
import { SystemOptionsEntryDelay1Field_systemOptions$key } from "./__generated__/SystemOptionsEntryDelay1Field_systemOptions.graphql";

export const systemOptionsEntryDelay1FieldId = () =>
  "system-options-entry-delay-1";

function EntryDelay1Field() {
  const [{ entryDelay1, entryDelayMin, entryDelayMax }, updateSystemOptions] =
    useSystemOptionsFragment<SystemOptionsEntryDelay1Field_systemOptions$key>(
      graphql`
        fragment SystemOptionsEntryDelay1Field_systemOptions on SystemOptions {
          ... on XrSystemOptions {
            entryDelay1
            entryDelayMin
            entryDelayMax
          }
          ... on XtSystemOptions {
            entryDelay1
            entryDelayMin
            entryDelayMax
          }
          ... on TMSentrySystemOptions {
            entryDelay1
            entryDelayMin
            entryDelayMax
          }
        }
      `
    );

  const fieldId = systemOptionsEntryDelay1FieldId();
  const originalValue = React.useRef(entryDelay1).current;

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Entry Delay 1"
      tooltip="Set the duration for Entry Delay 1 in seconds. Used in Zone Information Programming for Exit Zones."
    >
      <NumericInput
        id={fieldId}
        name={fieldId}
        min={entryDelayMin}
        max={entryDelayMax}
        value={entryDelay1?.toString()}
        required
        // validationMessage="Valid values are 30-250."
        inlineHelp={`${entryDelayMin}–${entryDelayMax}`}
        onChange={({ target }) => {
          updateSystemOptions((recordProxy) => {
            recordProxy.setValue(
              emptyStringOrNumber(target.value),
              "entryDelay1"
            );
          });
        }}
        onBlur={({ target }) => {
          updateSystemOptions((recordProxy) => {
            const valueAsNumber = Number(target.value);
            const value =
              !target.value || isNaN(valueAsNumber)
                ? originalValue
                : clamp(entryDelayMin, entryDelayMax, valueAsNumber);
            recordProxy.setValue(Number(value), "entryDelay1");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default EntryDelay1Field;
