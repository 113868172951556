import graphql from "babel-plugin-relay/macro";
import { isNotNullOrUndefined } from "common/utils/universal/function";
import { range } from "ramda";
import * as React from "react";
import {
  ConnectionHandler,
  useFragment,
  useRelayEnvironment,
} from "react-relay";
import { RecordProxy } from "relay-runtime";
import {
  AreaConnection,
  ControlSystem,
  fromAreaId,
  ID,
  SystemType,
  XrArea,
} from "securecom-graphql/client";
import AreaAccountNumberField from "../../common/AreaInformationFields/AreaFields/AreaAccountNumberField";
import AreaArmedOutputNumberField from "../../common/AreaInformationFields/AreaFields/AreaArmedOutputNumberField";
import AreaArmFirstAreaField from "../../common/AreaInformationFields/AreaFields/AreaArmFirstAreaField";
import AreaAutoArmField from "../../common/AreaInformationFields/AreaFields/AreaAutoArmField";
import AreaAutoDisarmField from "../../common/AreaInformationFields/AreaFields/AreaAutoDisarmField";
import AreaBadZonesField from "../../common/AreaInformationFields/AreaFields/AreaBadZonesField";
import AreaBankSafeVaultField from "../../common/AreaInformationFields/AreaFields/AreaBankSafeVaultField";
import AreaBurglaryBellOutputNumberField from "../../common/AreaInformationFields/AreaFields/AreaBurglaryBellOutputNumberField";
import AreaCardPlusPinField from "../../common/AreaInformationFields/AreaFields/AreaCardPlusPinField";
import AreaCommonAreaField from "../../common/AreaInformationFields/AreaFields/AreaCommonAreaField";
import { AreaContextProvider } from "../../common/AreaInformationFields/AreaFields/AreaContext";
import AreaDualAuthorityField from "../../common/AreaInformationFields/AreaFields/AreaDualAuthorityField";
import AreaLateArmDelayField from "../../common/AreaInformationFields/AreaFields/AreaLateArmDelayField";
import AreaLateOutputNumberField from "../../common/AreaInformationFields/AreaFields/AreaLateOutputNumberField";
import AreaNameField from "../../common/AreaInformationFields/AreaFields/AreaNameField";
import AreaNumberField, {
  areaListItemTemplateId,
} from "../../common/AreaInformationFields/AreaFields/AreaNumberField";
import AreaOpenClosingReportsField from "../../common/AreaInformationFields/AreaFields/AreaOpenClosingReportsField";
import AreaTwoManRuleField from "../../common/AreaInformationFields/AreaFields/AreaTwoManRuleField";
import ProgrammingConceptForm from "../../common/ProgrammingConceptForm";
import { useSystemType } from "../../common/SystemOptionsFields/SystemOptionsContext";
import { XRAreaInformationFormAreaTitle_area$key } from "./__generated__/XRAreaInformationFormAreaTitle_area.graphql";
import { XRAreaInformationForm_area$key } from "./__generated__/XRAreaInformationForm_area.graphql";
import { XRAreaInformationForm_controlSystem$key } from "./__generated__/XRAreaInformationForm_controlSystem.graphql";

function AreaTitle(props: { area: XRAreaInformationFormAreaTitle_area$key }) {
  const area = useFragment(
    graphql`
      fragment XRAreaInformationFormAreaTitle_area on XrArea {
        id
        number
        name
      }
    `,
    props.area
  );
  return (
    <>
      # {Number(area.number)} {area.name}
    </>
  );
}

function XRAreaInformationForm(props: {
  conceptId: string;
  area: XRAreaInformationForm_area$key;
  controlSystem: XRAreaInformationForm_controlSystem$key;
  removeSelectedArea: () => void;
  connectionId: string;
  visible: boolean;
  availableAreasExist: boolean;
  setSelectedListItemId: React.Dispatch<React.SetStateAction<string | null>>;
  canRemove: boolean;
  newAreaId: ID;
}) {
  const area = useFragment(
    graphql`
      fragment XRAreaInformationForm_area on XrArea {
        id
        number
        isNew
        ...AreaAccountNumberField_xrArea
        ...AreaArmedOutputNumberField_xrArea
        ...AreaLateOutputNumberField_xrArea
        ...AreaLateArmDelayField_xrArea
        ...AreaBankSafeVaultField_xrArea
        ...AreaCommonAreaField_xrArea
        ...AreaArmFirstAreaField_xrArea
        ...AreaBurglaryBellOutputNumberField_xrArea
        ...AreaOpenClosingReportsField_xrArea
        ...AreaTwoManRuleField_xrArea
        ...AreaDualAuthorityField_xrArea
        ...XRAreaInformationFormAreaTitle_area
        ...AreaCardPlusPinField_xrArea
        ...AreaContext_area
        ...AreaNumberField_area
        ...AreaNameField_area
        ...AreaAutoArmField_area
        ...AreaAutoDisarmField_area
        ...AreaBadZonesField_area
        ...AreaListItem_area
      }
    `,
    props.area
  );
  const controlSystem = useFragment(
    graphql`
      fragment XRAreaInformationForm_controlSystem on ControlSystem {
        id
        supportsOpenClosingReports
        supportsBurglaryBellOutput
        supportsDualAuthority
        supportsTwoManRule
        supportsCardPlusPin
        copiedArea {
          id
        }
        panel {
          hardwareModel
          systemOptions {
            ... on XrSystemOptions {
              systemType
            }
          }
        }
      }
    `,
    props.controlSystem
  );

  const systemType = useSystemType();
  const relayEnv = useRelayEnvironment();

  return (
    <ProgrammingConceptForm.SelectedItem
      key={area.id}
      conceptId={props.conceptId}
      isnew={area.isNew}
      listItemId={area.id}
      templateListItemId={areaListItemTemplateId(String(area.number))}
      title={<AreaTitle area={area} />}
      visible={props.visible}
      onDuplicate={
        props.availableAreasExist &&
        (() => {
          relayEnv.commitUpdate((store) => {
            const areasConnection = store.get<AreaConnection>(
              props.connectionId
            );
            const areaToDuplicate = store.get<XrArea>(area.id);
            if (areasConnection && areaToDuplicate) {
              const id = `${props.newAreaId}`;
              const newAreaRecord = store.create(id, "XrArea");

              newAreaRecord.setValue(id, "id");
              newAreaRecord.copyFieldsFrom(areaToDuplicate);
              newAreaRecord.setValue(id, "id");
              newAreaRecord.setValue(true, "isNew");
              newAreaRecord.setValue(
                fromAreaId(props.newAreaId).number,
                "number"
              );

              const newEdge = ConnectionHandler.createEdge(
                store,
                areasConnection,
                newAreaRecord,
                "AreaEdge"
              );

              newEdge.setValue(id, "cursor");

              ConnectionHandler.insertEdgeAfter(areasConnection, newEdge);

              props.setSelectedListItemId(id);
            }
          });
        })
      }
      onCopy={() => {
        relayEnv.commitUpdate((store) => {
          const controlSystemRecord = store.get<ControlSystem>(
            controlSystem.id
          );
          const areaRecord = store.get<XrArea>(area.id);
          if (controlSystemRecord && areaRecord) {
            const tempRecord =
              store.get("copiedArea") ?? store.create("copiedArea", "XrArea");
            tempRecord.copyFieldsFrom(areaRecord);
            controlSystemRecord.setLinkedRecord(tempRecord, "copiedArea");
          }
        });
      }}
      onPaste={
        !!controlSystem.copiedArea &&
        (() => {
          relayEnv.commitUpdate((store) => {
            const areaRecord = store.get<XrArea>(area.id);
            const copiedAreaRecord = store.get<XrArea>("copiedArea");
            if (areaRecord && copiedAreaRecord) {
              applyAreaProgrammingToAreaInformation(
                copiedAreaRecord,
                areaRecord,
                systemType as SystemType
              );
            }
          });
        })
      }
      onRemove={() => {
        props.removeSelectedArea();
      }}
    >
      <AreaContextProvider area={area}>
        <ProgrammingConceptForm.Fields>
          <AreaNumberField />
          <AreaNameField />
          <AreaAccountNumberField />
          <AreaAutoArmField />
          <AreaBadZonesField />
          <AreaAutoDisarmField />
          <AreaArmedOutputNumberField />
          <AreaLateOutputNumberField />
          <AreaLateArmDelayField />
          {controlSystem.panel.hardwareModel === "XR550" && (
            <AreaBankSafeVaultField />
          )}
          <AreaCommonAreaField />
          <AreaArmFirstAreaField />
          {controlSystem.supportsTwoManRule && <AreaTwoManRuleField />}
          {controlSystem.supportsDualAuthority && <AreaDualAuthorityField />}
          {controlSystem.supportsOpenClosingReports && (
            <AreaOpenClosingReportsField />
          )}
          {controlSystem.supportsBurglaryBellOutput && (
            <AreaBurglaryBellOutputNumberField />
          )}
          {controlSystem.supportsCardPlusPin && <AreaCardPlusPinField />}
        </ProgrammingConceptForm.Fields>
      </AreaContextProvider>
    </ProgrammingConceptForm.SelectedItem>
  );
}

export default React.memo(XRAreaInformationForm);

const applyAreaProgrammingToAreaInformation = (
  source: RecordProxy<XrArea>,
  dest: RecordProxy<XrArea>,
  systemType: SystemType | undefined
) => {
  const id = dest.getValue("id");
  const number = dest.getValue("number");
  const isNew = dest.getValue("isNew");
  const name = dest.getValue("name");
  dest.copyFieldsFrom(source);
  dest.setValue(id, "id");
  dest.setValue(isNew, "isNew");
  dest.setValue(number, "number");
  if (systemType !== SystemType.AREA) {
    dest.setValue(name, "name");
  }

  return dest;
};

export const getNextAvailableNumber = (
  areaConnection: RecordProxy<AreaConnection>
) => {
  const edges = areaConnection.getLinkedRecords("edges") ?? [];
  const currentAreaNumbers = new Set(
    edges
      .map((edge) => edge.getLinkedRecord("node")?.getValue("number"))
      .filter(isNotNullOrUndefined)
      .map((number) => Number(number))
  );

  return Math.min(
    ...range(1, 33).filter((areaNumber) => !currentAreaNumbers.has(areaNumber))
  );
};
