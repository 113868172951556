import React from "react";
import { ThemeProvider } from "styled-components";
import { CheckmarkIcon } from "../Icons";
import ButtonWithIcon from "./ButtonWithIcon";

const DoneButton = (props) => (
  <ThemeProvider
    theme={(currentTheme) => ({
      ...currentTheme,
      color: currentTheme.grayMedium,
      opposingColor: currentTheme.trueWhite,
    })}
  >
    <ButtonWithIcon
      size="small"
      variant="outline"
      {...props}
      icon={<CheckmarkIcon />}
    >
      Done
    </ButtonWithIcon>
  </ThemeProvider>
);

export default DoneButton;
