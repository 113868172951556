import { App } from "./app-module";
import { angular } from "./globals";
import { DoesNestedPropertyExist } from "./utils";

// Start App
// -----------------------------------

export function renderApp() {
  angular.element(function () {
    angular.bootstrap(document, ["dealeradmin"]);
  });

  App.run([
    "$rootScope",
    "$state",
    "$stateParams",
    "$window",
    "$log",
    "UserService",
    "LoginService",
    "GoogleAnalyticsService",
    "$location",
    "PROPS",
    "toaster",
    "$filter",
    "DealerService",
    "CustomRolesService",
    "IpResolverService",
    "IpWhitelistService",
    "PulseService",
    "$route",
    "RelayService",
    "$injector",
    "$q",
    "RefreshSessionAPI",
    function (
      $rootScope,
      $state,
      $stateParams,
      $window,
      $log,
      UserService,
      LoginService,
      GoogleAnalyticsService,
      $location,
      PROPS,
      toaster,
      $filter,
      DealerService,
      CustomRolesService,
      IpResolverService,
      IpWhitelistService,
      PulseService,
      $route,
      RelayService,
      $injector,
      $q,
      RefreshSessionAPI
    ) {
      $rootScope.appProperties = {
        type: "dealerAdmin",
      };
      // Set reference to access them from any scope
      $rootScope.$state = $state;
      $rootScope.$stateParams = $stateParams;
      $rootScope.$storage = $window.localStorage;
      $rootScope.alertVisible = false;

      $rootScope.logout = function () {
        RelayService.logout();
        $state.go("page.login");
      };

      // Setup the global alerts array
      $rootScope.alerts = [];

      // Setup the IP whitelist Object
      $rootScope.localIP = {};

      // Setup Global Variables to track if the token is being refreshed
      $rootScope.refreshingToken = false;
      $rootScope.refreshingPromise = null;

      // Scope Globals
      // -----------------------------------
      $rootScope.app = {
        name: "Dealer Admin",
        description: "SecureCom Dealer Admin",
        year: new Date().getFullYear(),
        layout: {
          isFixed: true,
          isCollapsed: false,
          isBoxed: false,
          isRTL: false,
          isToggled: false,
        },
        viewAnimation: "ng-fadeInUp",
      };
      //$anchorScroll.yOffset = 90;

      // Register to watch route changes for non-authenticated page requests
      // This runs on every route (page) change
      $rootScope.$on(
        "$stateChangeStart",
        function (event, next, nextParams, current, currentParams) {
          $rootScope.stateChange = {
            next: next,
            nextParams: nextParams,
            current: current,
            currentParams: currentParams,
          };

          var pageIndex = next.name.indexOf("page.");
          var search = $location.search();

          if (
            UserService.isAuthenticated() &&
            UserService.enabledIpWhitelist()
          ) {
            if (
              !$rootScope.localIP.ip ||
              $rootScope.localIP.expirationTime < Date.now()
            ) {
              IpResolverService.getIP().then(
                function (ip) {
                  $rootScope.localIP.ip = ip;
                  $rootScope.localIP.expirationTime = Date.now() + 3600000;
                },
                function (error) {
                  $rootScope.localIP.ip = undefined;
                  $rootScope.localIP.expirationTime = Date.now();
                }
              );
            }

            if (
              !(
                next.name === "page.login" ||
                current.name === "page.404" ||
                current.name === "page.403"
              ) &&
              UserService.enabledIpWhitelist() &&
              !search.headless
            ) {
              IpWhitelistService.validateDealerWhitelistIp(
                UserService.dealer_id
              )
                .then(
                  function (data) {
                    $rootScope.localIP.valid = true;
                  },
                  function (error) {
                    $rootScope.localIP.valid = false;
                    console.error(`Unable to verify if IP is valid.`, error);
                  }
                )
                .catch(function (error) {
                  console.error(`Unable to verify if IP is valid.`, error);
                })
                .finally(function () {
                  if (!$rootScope.localIP.valid) {
                    let enforceIpWhitelist =
                      UserService.enforceIpWhitelist() &&
                      UserService.isAuthenticated();
                    if (enforceIpWhitelist) {
                      RelayService.logout();
                      $state.go("page.403");
                    }
                  }
                });
            }
          }

          if (
            !(
              next.name === "page.login" ||
              current.name === "page.404" ||
              current.name === "page.403" ||
              next.name === "app.dealer.dealer-invoices-download"
            ) &&
            UserService.enforceDistributionSubscriberValidPaymentMethod() &&
            !search.headless
          ) {
            if (UserService.enforceDistributionSubscriberValidPaymentMethod()) {
              let enforcePayment =
                UserService.enforceDistributionSubscriberValidPaymentMethod() &&
                !!UserService.isAuthenticated();
              console.log(
                "enforcePayment",
                enforcePayment,
                UserService.enforceDistributionSubscriberValidPaymentMethod(),
                UserService.isAuthenticated()
              );
              if (enforcePayment) {
                UserService.waitForUser().then(() =>
                  $state.go("app.dealer.dealer-invoices-download", {
                    dealer_id: UserService.user.accessible_id,
                  })
                );
              }
            }
          }

          if (
            !(
              current.url === "/ServerStatus" || next.url === "/ServerStatus"
            ) &&
            next.url !== "/login"
          ) {
            PulseService.getServerStatus().then(
              function (server) {
                if (server && server.outage) {
                  $state.go("page.serverStatus");
                }
              },
              function (error) {}
            );
          }

          // If the user is going to a protected "app" page
          if (pageIndex < 0) {
            // Access the website externally - This is either a completely fresh link from old VK or a call from Tech App,
            // we should honor these credentials over locally stored.
            if (search.auth_token && search.email) {
              // Store the email and auth_token on the UserService so that auth_token is sent with all requests
              UserService.login(search.email, search.auth_token);

              if (!search.vv) {
                LoginService.getMyInformation()
                  .then(
                    function (user) {
                      // Technician roles can access externally from Tech App for full programming or print programming only
                      // If it's a technician and they're not going to one of the allowed routes, immediately go to not found
                      if (
                        user.role === "technician" &&
                        !techAppTechnicianPages.some(function (route) {
                          return next.name.indexOf(route) > -1;
                        })
                      ) {
                        RelayService.logout();
                        $state.go("page.404");
                      } else if (UserService.isVideoVerifier(user)) {
                        $state.go("page.vvhold");
                      } else {
                        switch (user.accessible_type.toLowerCase()) {
                          case "supervisor":
                            if (UserService.setUser(user)) {
                              $state.go("app.dealer.search", {
                                dealer_id: UserService.dealer_id,
                                criteria: "Dealers",
                                parameter: "",
                              });

                              // Strip the credentials off the URL...we have what we need. This will cause the route to fire
                              // again without the email and auth_token so only do this after setting the user.
                              $location.search("email", null);
                              $location.search("auth_token", null);
                            } else {
                              console.error(
                                "Error setting user info, invalid user object:",
                                user
                              );
                              RelayService.logout();
                              $state.go("page.404");
                            }
                            break;
                          case "dealer":
                            LoginService.signInDealerUser(user)
                              .then(
                                function () {
                                  // Now that the UserService has picked up custom role information, check if the role allows
                                  // access to the requested route
                                  if (customRoleAllowsRoute(next.name)) {
                                    // If there is no previous route, and the next route is customer_list, then it's most likely a
                                    // Dealer entering the site after login from VK, so we show the dashboard by default.
                                    if (
                                      current.name === "" &&
                                      next.name === "app.dealer.customer_list"
                                    ) {
                                      if (UserService.isParentDealerLogin()) {
                                        $state.go("app.dealer.dealers", {
                                          dealer_id: nextParams.dealer_id,
                                        });
                                      } else {
                                        $state.go("app.dealer.dashboard", {
                                          dealer_id: nextParams.dealer_id,
                                        });
                                      }
                                    }

                                    // Strip the credentials off the URL...we have what we need. This will cause the route to fire
                                    // again without the email and auth_token so only do this after setting the user.
                                    $location.search("email", null);
                                    $location.search("auth_token", null);
                                  } else {
                                    RelayService.logout();
                                    $state.go("page.404");
                                  }
                                },
                                function (error) {
                                  console.error(
                                    "Error setting user info: " +
                                      angular.toJson(error)
                                  );
                                  $rootScope.alerts.push({
                                    type: "error",
                                    text: "unable to get list of dealers",
                                    json: error,
                                  });
                                  RelayService.logout();
                                  $state.go("page.404");
                                }
                              )
                              .catch(function (error) {
                                console.error(error);
                              });
                            break;
                          default:
                            $rootScope.alerts.push({
                              type: "error",
                              text: "invalid user",
                              json: user,
                            });
                            RelayService.logout();
                            $state.go("page.404");
                            break;
                        }
                      }
                    },
                    function (error) {
                      console.error(
                        "Error getting logged in user info: " +
                          angular.toJson(error)
                      );
                      RelayService.logout();
                      $state.go("page.404");
                    }
                  )
                  .catch(function (error) {
                    console.error(error);
                  });
              }

              $rootScope.$broadcast("event:auth-loginConfirmed");
            }
            // Not accessed externally - route change or reload
            else if (UserService.isAuthenticated()) {
              // If the UserService user is not defined, the page may have been reloaded. i.e. Only get the about_me info if
              // we don't already have it.
              if (!UserService.user && !search.vv) {
                LoginService.getMyInformation()
                  .then(
                    function (user) {
                      if (UserService.isVideoVerifier(user)) {
                        $state.go("page.vvhold");
                      } else {
                        switch (user.accessible_type.toLowerCase()) {
                          case "supervisor":
                            if (UserService.setUser(user)) {
                              // On a reload, ui-router will pick up a dealer id or customer id for most routes. If it finds
                              // one, it will set the dealerInfo on the UserService to that dealer. It will then retrieve
                              // the dealer which will set the dealerInfo.id property. It's a race to this point.
                              // If the dealer has been retrieved and the id is different than the user's accessible_id,
                              // it is the selected dealer's id
                              if (
                                UserService.dealer_id &&
                                +UserService.dealer_id !==
                                  +UserService.user.accessible_id
                              ) {
                                UserService.selectedDealer =
                                  UserService.dealerInfo.name;
                                UserService.setDisplayDealerOptions();
                                // Otherwise, if dealer or customer ids were picked up from the route, go ahead and retrieve the
                                // dealer again so you can update the selected dealer information when it resolves
                              } else if (
                                DoesNestedPropertyExist(
                                  UserService,
                                  "dealerInfo.initKeys"
                                )
                              ) {
                                UserService.setDealerInfo(
                                  UserService.dealerInfo
                                )
                                  .then(
                                    function () {
                                      if (
                                        +UserService.dealerInfo.id !==
                                        +UserService.user.accessible_id
                                      ) {
                                        UserService.selectedDealer =
                                          UserService.dealerInfo.name;
                                        UserService.setDisplayDealerOptions();
                                      }
                                    },
                                    function () {
                                      console.error(
                                        "Unable to set selected dealer. Unsetting."
                                      );
                                      UserService.unsetSelectedDealer(
                                        new DealerService({
                                          dealer_id:
                                            UserService.user.accessible_id,
                                        })
                                      )
                                        .then(function () {
                                          $state.go("app.dealer.search", {
                                            dealer_id: UserService.dealer_id,
                                            criteria: "Dealers",
                                            parameter: "",
                                          });
                                        })
                                        .catch(function (error) {
                                          console.error(error);
                                        });
                                    }
                                  )
                                  .catch(function (error) {
                                    console.error(error);
                                  });
                                // Otherwise, the route didn't have a dealer or customer id. Unset the selected dealer.
                              } else {
                                console.error(
                                  "Unable to set selected dealer. Unsetting."
                                );
                                UserService.unsetSelectedDealer(
                                  new DealerService({
                                    dealer_id: UserService.user.accessible_id,
                                  })
                                )
                                  .then(function () {
                                    $state.go("app.dealer.search", {
                                      dealer_id: UserService.dealer_id,
                                      criteria: "Dealers",
                                      parameter: "",
                                    });
                                  })
                                  .catch(function (error) {
                                    console.error(error);
                                  });
                              }
                            } else {
                              console.error(
                                "Error setting user info: " +
                                  angular.toJson(error)
                              );
                              $rootScope.alerts.push({
                                type: "error",
                                text: "unable to get list of dealers",
                                json: error,
                              });
                              RelayService.logout();
                              $state.go("page.404");
                            }
                            break;
                          case "dealer":
                            // As for the supervisor users (above), the route will most likely pick up a dealer id. If it has,
                            // store it so that it can be restored after logging in the dealer user
                            var routeDealerId = null;
                            if (UserService.hasOwnProperty("dealerInfo")) {
                              if (
                                angular.isDefined(UserService.dealerInfo.id)
                              ) {
                                routeDealerId = UserService.dealerInfo.id;
                              } else if (
                                DoesNestedPropertyExist(
                                  UserService.dealerInfo,
                                  "initKeys.dealer_id"
                                )
                              ) {
                                routeDealerId =
                                  UserService.dealerInfo.initKeys.dealer_id;
                              }
                            }
                            LoginService.signInDealerUser(user)
                              .then(
                                function () {
                                  if (customRoleAllowsRoute(next.name)) {
                                    // After setting up the UserService, if the route points to a different dealer for a parent
                                    // dealer login, restore the route dealer so that controls, buttons, etc. display correctly
                                    if (
                                      UserService.isParentDealerLogin() &&
                                      +routeDealerId > 0 &&
                                      routeDealerId !== UserService.dealer_id
                                    ) {
                                      UserService.setDealerInfo(
                                        new DealerService({
                                          dealer_id: routeDealerId,
                                        })
                                      );
                                    }
                                  } else {
                                    RelayService.logout();
                                    $state.go("page.login");
                                  }
                                },
                                function (error) {
                                  $rootScope.alerts.push({
                                    type: "error",
                                    text: "unable to set user properties",
                                    json: error,
                                  });
                                  RelayService.logout();
                                  $state.go("page.404");
                                }
                              )
                              .catch(function (error) {
                                console.error(error);
                              });
                            break;
                          default:
                            $rootScope.alerts.push({
                              type: "error",
                              text: "invalid user",
                              json: error,
                            });
                            RelayService.logout();
                            $state.go("page.404");
                            break;
                        }
                      }
                    },
                    function (error) {
                      console.error(
                        "Error getting logged in user info: " +
                          angular.toJson(error)
                      );
                      RelayService.logout();
                      $state.go("page.404");
                    }
                  )
                  .catch(function (error) {
                    console.error(error);
                  });
              } else if (UserService.user) {
                // If a page fails to load, a user with a role may be sent somewhere else. If
                // they're not going to one of the allowed routes, go to not found
                if (!customRoleAllowsRoute(next.name)) {
                  RelayService.logout();
                  $state.go("page.404");
                }
              }
            } else {
              event.preventDefault();
              $state.go("page.login");
            }
          }

          // This is a video verification page
          if (next.name === "page.video") {
            if (
              (!search.auth_token || !search.email) &&
              !UserService.isAuthenticated()
            ) {
              event.preventDefault();
              $state.go("page.login");
            } else if (
              (search.vv || next.name === "page.video") &&
              search.auth_token &&
              search.email
            ) {
              UserService.login(search.email, search.auth_token);
            }
          }

          // Google Analytics page tracking
          GoogleAnalyticsService.trackPage(next.name);
        }
      );

      /**
       * These are the pages that can be accessed by technicians from Tech App
       * @type {string[]}
       */
      var techAppTechnicianPages = [
        "programming_full",
        "programming_com",
        "programming_print",
        "programming",
      ];

      /**
       * Check that if a role is assigned to the user, the role allows access to the requested route
       * @param route
       * @returns {*}
       */
      function customRoleAllowsRoute(route) {
        if (
          CustomRolesService.mayHaveCustomRole(UserService.user) &&
          UserService.userHasCustomRole() &&
          UserService.user.role === "technician"
        ) {
          switch (route) {
            case "app.panel_programming.programming_print":
              return UserService.canProgramFullPanel();
            case "app.panel_programming.programming_com":
              return UserService.canFastProgramPanel();
            case "app.panel_programming.programming":
              // Programming does not (completely) disable fields so you must check for edit permission
              return (
                UserService.canProgramFullPanel() &&
                UserService.canEditProgramming()
              );
            case "app.panel_programming.programming_full":
              // Old Full programming does not (completely) disable fields so you must check for edit permission
              return (
                UserService.canProgramFullPanel() &&
                UserService.canEditProgramming()
              );
            default:
              return false;
          }
        }
        // If there's no chance the user has a role, the route is allowed
        else {
          return true;
        }
      }

      $rootScope.$on(
        "$stateChangeError",
        function (event, toState, toParams, fromState, fromParams, error) {
          console.error("State change error", error);
        }
      );

      /**
       *
       * @param alert
       * @returns {Object}
       */
      $rootScope.processAlert = function (alert) {
        var alertReturn = {};
        const suppressTitleCasing = alert.suppressTitleCasing;
        alertReturn.type = alert.type;
        alertReturn.title = angular.isDefined(alert.title)
          ? alert.title
          : $filter("translate")("alerts.DEFAULT_ALERT_TITLE");

        if (angular.isDefined(alert.text)) {
          if (
            typeof alert.text === "object" &&
            angular.isUndefined(alert.json)
          ) {
            alert.json = alert.text;
            delete alert.text;
          } else {
            alert.content = alert.text.toString();
          }
        }

        var json = "";
        // TODO: Handle OData/.NET errors
        if (angular.isDefined(alert.json)) {
          json = angular.copy(alert.json);
          // {“error”:”Error Message String”}
          if (angular.isDefined(json.error)) {
            json = json.error;
          }
          // {“status”:”error”,“error_message”:”Error message string”}
          else if (
            angular.isDefined(json.status) &&
            json.status === "error" &&
            angular.isDefined(json.error_message)
          ) {
            json = json.error_message;
          }
          // {"status":"error","error_code":1,"errors":{"base":["The number is invalid"]}} OR
          // {"status":"error","error_code":1,"errors":{"email":["address already in use"]}}
          else if (
            angular.isDefined(json.status) &&
            json.status === "error" &&
            angular.isDefined(json.errors)
          ) {
            var newJson = "";
            // loop through the errors
            for (var error in json.errors) {
              if (error === "base") {
                newJson += json.errors[error] + ". ";
              } else {
                newJson += error + " " + json.errors[error] + ". ";
              }
            }
            json = newJson;
          }
          //V2 Job Timeout Error
          else if (
            json.data &&
            json.data.job &&
            json.data.job.details &&
            json.data.job.details.message
          ) {
            json = json.data.job.details.message;
          } //V2 Job Stats
          else if (
            json.job &&
            json.job.details &&
            json.job.details.message &&
            json.job.status === "error"
          ) {
            json = json.job.details.message;
          } else if (json.data && json.data.error) {
            // Innererrors are thrown by .NET. If there is one, the only useful piece of information appears
            // to be the status code.
            if (json.data.error.hasOwnProperty("innererror")) {
              // If it's numeric, show the code. Otherwise, show nothing (to be safe; we don't want a giant red toast)
              json =
                json.hasOwnProperty("status") && +json.status > 0
                  ? json.status
                  : "";
            } else {
              json = json.data.error;
            }
          } else if (json.data && json.data.errors) {
            var newJson = "";
            // loop through the errors
            for (var error in json.data.errors) {
              newJson +=
                $filter("humanize")(error) +
                " " +
                json.data.errors[error] +
                ". ";
            }
            json = newJson;
          }
          //V2 Not Found/Failed (400, 404, or other)
          else if (angular.isDefined(json.statusText)) {
            json = json.statusText.trim();
          }
          // "Error message String"
          else {
          }
        }
        json = JSON.stringify(json);
        json = json.replace(/"/g, "");

        alert.content = angular.isUndefined(alert.content) ? "" : alert.content;
        if (!suppressTitleCasing) {
          alert.content = $filter("titlecase")(alert.content);
        }
        alert.content += alert.content !== "" && json !== "" ? " - " : "";
        alert.content += $filter("titlecase")(json);
        if (
          alert.content.toLowerCase().indexOf("promise:{}") >= 0 ||
          alert.content.toLowerCase() === "null" ||
          !alert.content.length
        ) {
          alert.content = $filter("translate")("alerts.ERROR_UNKNOWN");
        }

        alertReturn.text = alert.content;

        return alertReturn;
      };

      /**
       * This is the alert handler.  It watches for new alerts to be added to the array, then handles them appropriately.
       * You can set the alertType to any of the following values to display an alert in a toaster:
       * success, info, warning, error (see toaster docs).
       * If you set the alertType to critical, the alert will appear in a popup browser alert box.
       *
       * Examples:
       * $rootScope.alerts.push({"type": PROPS.alertError,"title": "Dynamic Title", "text": "This is an error!"});
       * $rootScope.alerts.push({"type": PROPS.alertCritical,"title": "Dynamic Title", "text": "This is a critical error!"});
       */
      $rootScope.$watch("alerts.length", function () {
        if ($rootScope.alerts.length > 0) {
          if (alertShouldBeProcessed($rootScope.alerts[0])) {
            var alertTimeout =
              $rootScope.alerts[0].type === PROPS.alertError
                ? PROPS.alertErrorTimeout
                : PROPS.alertSuccessTimeout;

            var alertResult = $rootScope.processAlert($rootScope.alerts[0]);

            // Determine what type of error message this is, and display appropriately
            if (alertResult.type === PROPS.alertCritical) {
              $window.alert(alertResult.title + "\n\n" + alertResult.text);
            } else {
              toaster.pop(
                alertResult.type,
                alertResult.title,
                alertResult.text,
                alertTimeout
              );
            }
          }
          // Delete the alert from the array
          $rootScope.alerts.splice(0, 1);
        }
      });

      // Returns true if the alert is formatted properly
      function alertShouldBeProcessed(alert) {
        // An alert must have a type
        return (
          alert.hasOwnProperty("type") &&
          // And either a text or json property
          (alert.hasOwnProperty("text") || alert.hasOwnProperty("json")) &&
          // And either not have a json property or have one that doesn't indicate the user cancelled an action
          (!alert.hasOwnProperty("json") ||
            (alert.hasOwnProperty("json") && alert.json !== "USER_CANCELLED"))
        );
      }

      // Initialize the Google Analytics service
      GoogleAnalyticsService.init("G-JDE9S91F10");

      // Refresh the token half way through the expiration time

      $rootScope.$watch(
        function () {
          return UserService.auth_token;
        },
        function (newVal, oldVal) {
          if (newVal !== oldVal && UserService.auth_token) {
            // Base64 decode the token to get the expiration time
            var token = UserService.auth_token.split(".");
            var tokenData = JSON.parse(atob(token[1]));
            var refreshTime = tokenData.exp * 1000;
            var currentTime = Date.now();
            var timeToRefresh = Math.floor((refreshTime - currentTime) / 2);

            if (UserService.auth_token) {
              if (timeToRefresh > 0) {
                // Clear the existing timeout
                if ($rootScope.refreshTimeout) {
                  $window.clearTimeout($rootScope.refreshTimeout);
                  $rootScope.refreshTimeout = null;
                }
                // Set the new timeout
                $rootScope.refreshTimeout = $window.setTimeout(
                  async function () {
                    $rootScope.refreshTimeout = null;
                    await refreshLogin();
                  },
                  timeToRefresh
                );
              }
            }
          }
        }
      );

      // Function to refresh the token

      async function refreshLogin() {
        try {
          const deferred = $q.defer();
          const refreshAPI = RefreshSessionAPI;
          $rootScope.refreshPromise = refreshAPI.refresh(
            {},
            angular.toJson({
              refresh_token: UserService.refresh_token,
            }),
            function (data) {
              try {
                UserService.login(data.email, data.jwt, data.refresh_token);
              } catch (e) {
                deferred.reject(e);
              }
              deferred.resolve(data);
            },
            function (error) {
              $rootScope.logout();
              $q.reject(error);
            }
          );
          return deferred.promise;
        } catch (e) {
          return $q.reject(e);
        }
      }
    },
  ]);

  // Turn off debug logging in PROD
  App.config(function ($logProvider) {
    if (["dev1", "dev2"].includes(process.env.SECURE_COM_ENVIRONMENT)) {
      $logProvider.debugEnabled(false);
    }
  });

  App.config(function ($rootScopeProvider) {
    $rootScopeProvider.digestTtl(20);
  });
}
