App.controller("ReportConfigController", [
  "$scope",
  "$rootScope",
  "$stateParams",
  "$http",
  "$filter",
  "$timeout",
  "$state",
  "$window",
  "UserService",
  "Customer",
  "DTOptionsBuilder",
  "DTColumnDefBuilder",
  "controlSystemModel",
  "ReportService",
  "PanelV2API",
  function (
    $scope,
    $rootScope,
    $stateParams,
    $http,
    $filter,
    $timeout,
    $state,
    $window,
    UserService,
    Customer,
    DTOptionsBuilder,
    DTColumnDefBuilder,
    controlSystemModel,
    ReportService,
    PanelV2API
  ) {
    var reportCtrl = this;

    $scope.UserService = UserService;
    UserService.customer_id = $stateParams.customer_id;
    UserService.control_system_id = $stateParams.control_system_id;
    UserService.panel_id = $stateParams.panel_id;
    $scope.profiles = [];
    $scope.areas = [];
    $scope.zones = [];
    $scope.devices = [];
    $scope.eventSubcategories = [];
    $scope.userRpt = false;
    $scope.eventRpt = false;
    $scope.profilesRpt = false;
    $scope.dateFilters = {};
    $scope.datePickerOpen = {};
    $scope.hardware_family =
      UserService.controlSystem.panels[0].hardware_family;

    // Scaffold the filter objects, to be used later
    $scope.usersReportFilter = {
      panel_id: parseInt(UserService.panel_id),
      profiles: [],
    };

    $scope.profilesReportFilter = {
      panel_id: parseInt(UserService.panel_id),
      areas: [],
    };

    $scope.eventsReportFilter = {
      panel_id: parseInt(UserService.panel_id),
      subcategory_ids: [],
      areas: [],
      zones: [],
      devices: [],
      users: [],
      event_date_low: null,
      event_date_high: null,
    };

    $scope.selectedProfiles = [];
    const panelModelsThatDoNotSupportDevices = new Set([
      "CellComEX",
      "CellComSL",
      "DualCom",
      "iComSL",
      "MiniCellCom",
      "TMS6",
    ]);
    //Get the list of devices

    function fetchDevices(page = 1) {
      if (
        panelModelsThatDoNotSupportDevices.has(
          UserService.controlSystem.panels[0].hardware_model
        )
      ) {
        return;
      }
      PanelV2API.index(
        {
          panel_id: UserService.panel_id,
          concept: "device_informations",
          page_size: 100,
          page: 1,
        },
        function (data) {
          //push the data into the devices array
          data.array_data
            .sort((a, b) =>
              a.device_information.name.localeCompare(
                b.device_information.name,
                undefined,
                { numeric: true, sensitivity: "base" }
              )
            )
            .forEach((data) => $scope.devices.push(data.device_information));

          // If there are more devices, fetch the next page
          //since the page size is 100, if the length of the array is 100,
          // there are more devices
          if (data.array_data.length === 100) {
            fetchDevices(page + 1);
          }
        },
        function (error) {
          //failure

          $rootScope.alerts.push({
            type: "error",
            text: $filter("translate")("api.UNABLE_TO_GET_DEVICES"),
          });
        }
      );
    }

    // Start fetching devices
    fetchDevices();

    // Get the list of profiles for the Users and Profiles Report
    ReportService.getProfiles(UserService.panel_id)
      .then(
        function (data) {
          $scope.profiles = data.value;
        },
        function (error) {
          $rootScope.alerts.push({
            type: "error",
            text: $filter("translate")("api.UNABLE_TO_GET_PROFILES"),
          });
        }
      )
      .catch(function (error) {
        console.error(error);
      });

    // Get the list of event_subcategories for the Events Report
    ReportService.getFilteredEventSubcategories(UserService.panel_id)
      .then(
        function (data) {
          $scope.eventSubcategories = data.value;
        },
        function (error) {
          $rootScope.alerts.push({
            type: "error",
            text: $filter("translate")("api.UNABLE_TO_GET_EVENT_SUBCATEGORIES"),
          });
        }
      )
      .catch(function (error) {
        console.error(error);
      });

    // Get the list of areas for the Events Report & Profiles Report
    ReportService.getAreas(UserService.panel_id)
      .then(
        function (data) {
          $scope.areas = data.value;
          ReportService.areas = data.value;
        },
        function (error) {
          $rootScope.alerts.push({
            type: "error",
            text: $filter("translate")("api.UNABLE_TO_GET_AREAS"),
          });
        }
      )
      .catch(function (error) {
        console.error(error);
      });

    // Get the list of zones for the Events Report
    ReportService.getZones(UserService.panel_id)
      .then(
        function (data) {
          $scope.zones = data.value;
        },
        function (error) {
          $rootScope.alerts.push({
            type: "error",
            text: $filter("translate")("api.UNABLE_TO_GET_ZONES"),
          });
        }
      )
      .catch(function (error) {
        console.error(error);
      });

    // Get the list of user_codes for the Events Report
    ReportService.getUserCodes(UserService.panel_id)
      .then(
        function (data) {
          $scope.userCodes = data.value.sort((a, b) =>
            a.name.localeCompare(b.name, undefined, {
              numeric: true,
              sensitivity: "base",
            })
          );
        },
        function (error) {
          $rootScope.alerts.push({
            type: "error",
            text: $filter("translate")("api.UNABLE_TO_GET_USER_CODES"),
          });
        }
      )
      .catch(function (error) {
        console.error(error);
      });

    $scope.addEventTypeTofilter = function (filterArray, value) {
      filterArray.pop();
      filterArray.push(Number(value));
    };
    $scope.addToFilter = function (filterArray, value) {
      const int = Number(value);
      var index = filterArray.indexOf(int);
      if (index < 0) {
        filterArray.push(int);
      }
    };
    $scope.removeFromFilter = function (filterArray, value) {
      const int = Number(value);
      var index = filterArray.indexOf(int);
      if (index > -1) {
        filterArray.splice(index, 1);
      }
    };

    reportCtrl.currentDate = new Date();

    $scope.today = function () {
      $scope.dt = new Date();
    };
    $scope.today();

    $scope.clear = function () {
      $scope.dt = null;
    };

    $scope.date_low_open = function ($event) {
      $event.preventDefault();
      $event.stopPropagation();

      $scope.datePickerOpen.dateLowOpened = true;
    };

    $scope.date_high_open = function ($event) {
      $event.preventDefault();
      $event.stopPropagation();

      $scope.datePickerOpen.dateHighOpened = true;
    };

    $scope.dateOptions = {
      formatYear: "yy",
      startingDay: 1,
    };

    $scope.formats = [
      "dd-MMMM-yyyy",
      "yyyy/MM/dd",
      "dd.MM.yyyy",
      "MM/dd/yy",
      "shortDate",
    ];
    $scope.format = $scope.formats[4];

    var tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    var afterTomorrow = new Date();
    afterTomorrow.setDate(tomorrow.getDate() + 2);
    $scope.events = [
      {
        date: tomorrow,
        status: "full",
      },
      {
        date: afterTomorrow,
        status: "partially",
      },
    ];

    $scope.getDayClass = function (date, mode) {
      if (mode === "day") {
        var dayToCheck = new Date(date).setHours(0, 0, 0, 0);

        for (var i = 0; i < $scope.events.length; i++) {
          var currentDay = new Date($scope.events[i].date).setHours(0, 0, 0, 0);

          if (dayToCheck === currentDay) {
            return $scope.events[i].status;
          }
        }
      }

      return "";
    };

    /**
     *  Functions for UI-Select
     *  **********************************/
    $scope.disabled = undefined;
    $scope.searchEnabled = undefined;

    $scope.enable = function () {
      $scope.disabled = false;
    };

    $scope.disable = function () {
      $scope.disabled = true;
    };

    $scope.enableSearch = function () {
      $scope.searchEnabled = true;
    };

    $scope.disableSearch = function () {
      $scope.searchEnabled = false;
    };

    $scope.clear = function () {
      $scope.person.selected = undefined;
      $scope.address.selected = undefined;
      $scope.country.selected = undefined;
    };

    $scope.someGroupFn = function (item) {
      if (item.name[0] >= "A" && item.name[0] <= "M") return "From A - M";

      if (item.name[0] >= "N" && item.name[0] <= "Z") return "From N - Z";
    };

    $scope.counter = 0;
    $scope.someFunction = function (item, model) {
      $scope.counter++;
      $scope.eventResult = { item: item, model: model };
    };

    $scope.removed = function (item, model) {
      $scope.lastRemoved = {
        item: item,
        model: model,
      };
    };

    $scope.goToReportPage = function (reportType) {
      switch (reportType) {
        case "UsersReport":
          ReportService.filters = $scope.usersReportFilter;
          goToDisplay(reportType);
          break;
        case "ProfilesReport":
          ReportService.filters = $scope.profilesReportFilter;
          goToDisplay(reportType);
          break;
        case "EventsReport":
          // Merge the date_low and time_low, date_high and time_high together into event_date_low and
          if ($scope.dateFilters.date_low) {
            var dateLowDate = $scope.dateFilters.date_low;
            // Default the low date to the first second of the day
            if ($scope.dateFilters.time_low) {
              var timeLowDate = $scope.dateFilters.time_low;
              var timeLow = $filter("date")(timeLowDate, "HH:mm:ss", "UTC");
            } else {
              var timeLow = "00:00:00";
            }
            var dateLow = $filter("date")(dateLowDate, "yyyy-MM-dd");
            var newLow = dateLow + "T" + timeLow + "Z";
            $scope.eventsReportFilter.event_date_low = newLow;
          } else {
            delete $scope.eventsReportFilter.event_date_low;
          }
          if ($scope.dateFilters.date_high) {
            var dateHighDate = $scope.dateFilters.date_high;
            // Default the high date to the last second of the day
            if ($scope.dateFilters.time_high) {
              var timeHighDate = $scope.dateFilters.time_high;
              var timeHigh = $filter("date")(timeHighDate, "HH:mm:ss", "UTC");
            } else {
              var timeHigh = "23:59:59";
            }
            var dateHigh = $filter("date")(dateHighDate, "yyyy-MM-dd");
            var newHigh = dateHigh + "T" + timeHigh + "Z";
            $scope.eventsReportFilter.event_date_high = newHigh;
          } else {
            delete $scope.eventsReportFilter.event_date_high;
          }
          ReportService.filters = $scope.eventsReportFilter;
          goToDisplay(reportType);
          break;
        default:
          break;
      }
    };

    var goToDisplay = function (reportType) {
      $state.go("app.report-display", {
        customer_id: UserService.customer_id,
        control_system_id: UserService.control_system_id,
        panel_id: UserService.panel_id,
        report_type: reportType,
      });
    };
  },
]);
