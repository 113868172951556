/**
 * @generated SignedSource<<e2c93f30507dcdd81807c5adcad6356c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type PanelHardwareModel = "CELLCOM" | "CELLCOM_EX" | "CELLCOM_SL" | "DUALCOM" | "EPAD" | "ICOM" | "ICOM_E" | "ICOM_LNC" | "ICOM_NRL" | "ICOM_SL" | "MINI_CELLCOM" | "NOTIFYI" | "SCS101" | "SCS150" | "TMS6" | "VIDEO_ONLY" | "X001" | "XF6_100" | "XF6_500" | "XR10" | "XR100" | "XR100N" | "XR150" | "XR20" | "XR200" | "XR2400F" | "XR350" | "XR40" | "XR5" | "XR500" | "XR500N" | "XR550" | "XR6" | "XRSuper6" | "XT30" | "XT30L" | "XT50" | "XT50L" | "XT75" | "XTL" | "XTLN" | "XTLP" | "XTLW";
export type SystemType = "ALL_PERIMETER" | "AREA" | "HOME_SLEEP_AWAY" | "HOME_SLEEP_AWAY_GUEST";
import { FragmentRefs } from "relay-runtime";
export type XRAreaInformationProgrammingConceptForm_controlSystem$data = {
  readonly copiedArea: {
    readonly id: string;
  } | null;
  readonly id: string;
  readonly panel: {
    readonly accountNumber: string;
    readonly areas: {
      readonly __id: string;
      readonly edges: ReadonlyArray<{
        readonly cursor: string | null;
        readonly node: {
          readonly id: string;
          readonly isNew: boolean;
          readonly name: string;
          readonly number: string;
          readonly " $fragmentSpreads": FragmentRefs<"AreaListItem_area" | "XRAreaInformationForm_area">;
        } | null;
      }>;
      readonly maxNumberOfAreas: number;
      readonly minNumberOfAreas: number;
      readonly totalCount: number;
    };
    readonly hardwareModel: PanelHardwareModel;
    readonly helpFiles: {
      readonly installGuideUrl: string;
      readonly programmingGuideUrl: string;
    };
    readonly id: string;
    readonly newArea: {
      readonly hasChanges: boolean;
      readonly id: string;
      readonly isNew: boolean;
      readonly name: string;
      readonly number: string;
      readonly " $fragmentSpreads": FragmentRefs<"AreaListItem_area" | "XRAreaInformationForm_area">;
    } | null;
    readonly online: boolean;
    readonly systemAreaInformation: {
      readonly supportsBurglaryBellOutput: boolean;
      readonly supportsOpenClosingReports: boolean;
      readonly " $fragmentSpreads": FragmentRefs<"SystemAreaInformationAnyBypassField_systemAreaInformation" | "SystemAreaInformationAreaSchedulesField_systemAreaInformation" | "SystemAreaInformationBurglaryBellOutputNumberField_systemAreaInformation" | "SystemAreaInformationClosingCheckField_systemAreaInformation" | "SystemAreaInformationClosingCodeField_systemAreaInformation" | "SystemAreaInformationContext_systemAreaInformation" | "SystemAreaInformationEarlyMorningAmbushField_systemAreaInformation" | "SystemAreaInformationExitDelayField_systemAreaInformation" | "SystemAreaInformationOpenClosingReportsField_systemAreaInformation">;
    } | null;
    readonly systemOptions: {
      readonly systemType?: SystemType;
      readonly " $fragmentSpreads": FragmentRefs<"SystemOptionsContextHouseCode_systemOptions" | "SystemOptionsContextIsAreaSystem_systemOptions" | "SystemOptionsContextSystemType_systemOptions" | "SystemOptionsContext_systemOptions">;
    };
    readonly " $fragmentSpreads": FragmentRefs<"AreaNumberField_panel" | "PanelContextUseHardwareModel_panel" | "PanelContext_panel">;
  };
  readonly " $fragmentSpreads": FragmentRefs<"XRAreaInformationForm_controlSystem">;
  readonly " $fragmentType": "XRAreaInformationProgrammingConceptForm_controlSystem";
};
export type XRAreaInformationProgrammingConceptForm_controlSystem$key = {
  readonly " $data"?: XRAreaInformationProgrammingConceptForm_controlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"XRAreaInformationProgrammingConceptForm_controlSystem">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isNew",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "number",
  "storageKey": null
},
v4 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "XRAreaInformationForm_area"
},
v5 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "AreaListItem_area"
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "XRAreaInformationProgrammingConceptForm_controlSystem",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Panel",
      "kind": "LinkedField",
      "name": "panel",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PanelContext_panel"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PanelContextUseHardwareModel_panel"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "AreaNumberField_panel"
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hardwareModel",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "accountNumber",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "online",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "HelpFileUrls",
          "kind": "LinkedField",
          "name": "helpFiles",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "programmingGuideUrl",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "installGuideUrl",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "systemOptions",
          "plural": false,
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "systemType",
                  "storageKey": null
                }
              ],
              "type": "XrSystemOptions",
              "abstractKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SystemOptionsContext_systemOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SystemOptionsContextSystemType_systemOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SystemOptionsContextHouseCode_systemOptions"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SystemOptionsContextIsAreaSystem_systemOptions"
            }
          ],
          "storageKey": null
        },
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "newArea",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasChanges",
              "storageKey": null
            },
            (v1/*: any*/),
            (v2/*: any*/),
            (v3/*: any*/),
            (v4/*: any*/),
            {
              "kind": "InlineFragment",
              "selections": [
                (v5/*: any*/)
              ],
              "type": "XrArea",
              "abstractKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "first",
              "value": 32
            },
            {
              "kind": "Literal",
              "name": "sort",
              "value": {
                "keys": [
                  "number"
                ],
                "order": "ASC"
              }
            }
          ],
          "concreteType": "AreaConnection",
          "kind": "LinkedField",
          "name": "areas",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "maxNumberOfAreas",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "minNumberOfAreas",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalCount",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "AreaEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "cursor",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v2/*: any*/),
                    (v3/*: any*/),
                    (v1/*: any*/),
                    (v4/*: any*/),
                    (v5/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "kind": "ClientExtension",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__id",
                  "storageKey": null
                }
              ]
            }
          ],
          "storageKey": "areas(first:32,sort:{\"keys\":[\"number\"],\"order\":\"ASC\"})"
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "SystemAreaInformation",
          "kind": "LinkedField",
          "name": "systemAreaInformation",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "supportsOpenClosingReports",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "supportsBurglaryBellOutput",
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SystemAreaInformationContext_systemAreaInformation"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SystemAreaInformationExitDelayField_systemAreaInformation"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SystemAreaInformationClosingCheckField_systemAreaInformation"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SystemAreaInformationClosingCodeField_systemAreaInformation"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SystemAreaInformationAnyBypassField_systemAreaInformation"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SystemAreaInformationAreaSchedulesField_systemAreaInformation"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SystemAreaInformationEarlyMorningAmbushField_systemAreaInformation"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SystemAreaInformationBurglaryBellOutputNumberField_systemAreaInformation"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SystemAreaInformationOpenClosingReportsField_systemAreaInformation"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "XRAreaInformationForm_controlSystem"
    },
    {
      "kind": "ClientExtension",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "copiedArea",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ]
    }
  ],
  "type": "ControlSystem",
  "abstractKey": null
};
})();

(node as any).hash = "1cb7837fb2752830040a7cc8345c7ee0";

export default node;
