/**
 * @generated SignedSource<<5a2e9cb9b6079f69cd845cbe9b2d1c84>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SystemOptionsDetectWirelessJammingField_systemOptions$data = {
  readonly detectJam?: boolean;
  readonly houseCode?: string;
  readonly " $fragmentType": "SystemOptionsDetectWirelessJammingField_systemOptions";
};
export type SystemOptionsDetectWirelessJammingField_systemOptions$key = {
  readonly " $data"?: SystemOptionsDetectWirelessJammingField_systemOptions$data;
  readonly " $fragmentSpreads": FragmentRefs<"SystemOptionsDetectWirelessJammingField_systemOptions">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "detectJam",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "houseCode",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SystemOptionsDetectWirelessJammingField_systemOptions",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "XrSystemOptions",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "XtSystemOptions",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "XfSystemOptions",
      "abstractKey": null
    }
  ],
  "type": "SystemOptions",
  "abstractKey": "__isSystemOptions"
};
})();

(node as any).hash = "e41e8c1da31a3c70d5fe4678bdbcd2e2";

export default node;
