import graphql from "babel-plugin-relay/macro";
import NumericInput from "components/FullProgramming/common/NumericInput";
import { emptyStringOrNumber } from "components/FullProgramming/utils/format";
import { resolvePanelType } from "components/FullProgramming/utils/panel";
import { clamp } from "ramda";
import * as React from "react";
import { useHardwareModel } from "../PanelContext";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useOutputOptionsFragment } from "./OutputOptionsContext";
import { OutputOptionsOutputCutoffTimeField_outputOptions$key } from "./__generated__/OutputOptionsOutputCutoffTimeField_outputOptions.graphql";

export const outputOptionsOutputCutoffTimeFieldId = () =>
  "output-options-output-cutoff-time";

function OutputOptionsOutputCutoffTimeField() {
  const [
    { outputCutoffTime, outputCutoffTimeMin, outputCutoffTimeMax },
    updateOutputOptions,
  ] = useOutputOptionsFragment<OutputOptionsOutputCutoffTimeField_outputOptions$key>(
    graphql`
      fragment OutputOptionsOutputCutoffTimeField_outputOptions on OutputOptions {
        outputCutoffTime
        outputCutoffTimeMin
        outputCutoffTimeMax
      }
    `
  );

  const fieldId = outputOptionsOutputCutoffTimeFieldId();
  const hardwareModel = useHardwareModel();
  const { isXr } = resolvePanelType(hardwareModel);

  const originalValue = React.useRef(outputCutoffTime).current;

  const pattern = isXr ? "([0-9]{0,2})" : "(0{0,1}[0-9]|1[0-5])";
  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Output Cutoff Time"
      tooltip="Number of minutes a programmed Cutoff Output will stay on before automatically turning off."
    >
      <NumericInput
        id={fieldId}
        min={outputCutoffTimeMin}
        max={outputCutoffTimeMax}
        value={outputCutoffTime}
        pattern={pattern}
        inlineHelp={`${outputCutoffTimeMin}-${outputCutoffTimeMax}`}
        onChange={({ target }) => {
          updateOutputOptions((recordProxy) => {
            recordProxy.setValue(
              emptyStringOrNumber(target.value),
              "outputCutoffTime"
            );
          });
        }}
        onBlur={({ target }) => {
          updateOutputOptions((recordProxy) => {
            const valueAsNumber = Number(target.value);
            const value =
              !target.value || isNaN(valueAsNumber)
                ? originalValue
                : valueAsNumber === 0
                ? valueAsNumber
                : clamp(
                    outputCutoffTimeMin,
                    outputCutoffTimeMax,
                    valueAsNumber
                  );
            recordProxy.setValue(value, "outputCutoffTime");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default OutputOptionsOutputCutoffTimeField;
