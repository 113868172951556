import graphql from "babel-plugin-relay/macro";
import TextInput from "components/FullProgramming/common/TextInput";
import {
  panelVersionGTOE,
  resolvePanelType,
} from "components/FullProgramming/utils/panel";
import * as React from "react";
import {
  useHardwareModel,
  useHasWifiCommType,
  useSoftwareVersion,
} from "../PanelContext";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useNetworkOptionsFragment } from "./NetworkOptionsContext";
import { NetworkOptionsDnsServerField_networkOptions$key } from "./__generated__/NetworkOptionsDnsServerField_networkOptions.graphql";

export const networkOptionsDnsServerFieldId = () =>
  `network-options-dns-server`;

export function useDnsIsEditableWithDhcpEnabled() {
  const hardwareModel = useHardwareModel();
  const softwareVersion = useSoftwareVersion();
  const { isXr, isXf } = resolvePanelType(hardwareModel);

  return (isXr && panelVersionGTOE(213, softwareVersion)) || isXf;
}

function NetworkOptionsDnsServerField() {
  const [{ dnsServer, dhcpEnabled }, updateNetworkOptions] =
    useNetworkOptionsFragment<NetworkOptionsDnsServerField_networkOptions$key>(
      graphql`
        fragment NetworkOptionsDnsServerField_networkOptions on NetworkOptions {
          dnsServer
          dhcpEnabled
        }
      `
    );

  const fieldId = networkOptionsDnsServerFieldId();
  const isEditableWithDhcpEnabled = useDnsIsEditableWithDhcpEnabled();
  const hasWifi = useHasWifiCommType();
  const disabled = dhcpEnabled && !(isEditableWithDhcpEnabled && !hasWifi); // is editable with DHCP only if WIFI is not in use

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="DNS Server"
      tooltip="Enter the IP address of the DNS (Domain Name System) used by the panel to resolve domain 
      names into IP addresses."
    >
      <TextInput
        id={fieldId}
        disabled={disabled}
        required={!dhcpEnabled || isEditableWithDhcpEnabled}
        value={dnsServer}
        pattern="((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)[.]){3}(25[0-5] *|2[0-4][0-9] *|[01]?[0-9][0-9]? *)"
        onChange={({ target }) => {
          updateNetworkOptions((recordProxy) => {
            recordProxy.setValue(target.value, "dnsServer");
          });
        }}
        onBlur={({ target }) => {
          updateNetworkOptions((recordProxy) => {
            recordProxy.setValue(
              target.value === "" ? "0.0.0.0" : target.value,
              "dnsServer"
            );
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default NetworkOptionsDnsServerField;
