import graphql from "babel-plugin-relay/macro";
import useEffectOnce from "common/hooks/useEffectOnce";
import Select from "components/FullProgramming/common/Select";
import {
  panelVersionGTOE,
  resolvePanelType,
} from "components/FullProgramming/utils/panel";
import * as React from "react";
import {
  CommunicationPathCommType,
  CommunicationPathType,
} from "securecom-graphql/client";
import CommunicationTypeOverrideModal from "../CommunicationTypeOverrideModal";
import { useVarEnabled } from "../ControlSystemContext";
import {
  useHardwareModel,
  useHasNetworkCommType,
  useHasWifiCommType,
  usePanelFragment,
  useSoftwareVersion,
} from "../PanelContext";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import VarWifiWarningModal from "../VarWifiWarningModal";
import { useCommPathFragment } from "./CommPathContext";
import { CommPathCommTypeField_communicationPath$key } from "./__generated__/CommPathCommTypeField_communicationPath.graphql";
import { CommPathCommTypeField_panel$key } from "./__generated__/CommPathCommTypeField_panel.graphql";

export const commPathCommTypeFieldId = (number: string) =>
  `comm-path-comm-type-${number}`;

function CommPathCommTypeField() {
  const [{ id, number, commType, type }, updateCommPath] =
    useCommPathFragment<CommPathCommTypeField_communicationPath$key>(
      graphql`
        fragment CommPathCommTypeField_communicationPath on CommunicationPath {
          id
          number
          commType
          type
        }
      `
    );
  const [{ communicationPaths }] =
    usePanelFragment<CommPathCommTypeField_panel$key>(graphql`
      fragment CommPathCommTypeField_panel on Panel {
        communicationPaths {
          number
          commType
        }
      }
    `);

  const varEnabled = useVarEnabled();

  const [openVarWifiWarningModal, setOpenVarWifiWarningModal] =
    React.useState(false);

  const fieldId = commPathCommTypeFieldId(String(number));
  const softwareVersion = useSoftwareVersion();
  const [openCommTypeOverrideModal, setOpenCommTypeOverrideModal] =
    React.useState(false);
  const [overrideCommType, setOverrideCommType] = React.useState<
    "WIFI" | "NETWORK"
  >("WIFI");
  const hasWifi = useHasWifiCommType();
  const hasNetwork = useHasNetworkCommType();
  const commPath1 = communicationPaths.find(
    (commPath) => commPath.number === 1
  );
  const hardwareModel = useHardwareModel();
  const { isXf } = resolvePanelType(hardwareModel);

  //Overrides blank default values for XF6, which results in DD being set
  useEffectOnce(() => {
    if (isXf && commType === CommunicationPathCommType.DD) {
      updateCommPath((recordProxy) => {
        recordProxy.setValue(CommunicationPathCommType.NETWORK, "commType");
      });
    }
  });

  return (
    <>
      <ProgrammingConceptForm.Field
        fieldId={fieldId}
        label="Communication Type"
        tooltip="Communication type that the System uses to connect to the Receiver"
      >
        <Select
          id={fieldId}
          value={commType}
          required
          onChange={({ target }) => {
            if (target.value === "WIFI" && varEnabled) {
              setOpenVarWifiWarningModal(true);
            } else if (
              (target.value === "WIFI" && hasNetwork) ||
              (target.value === "NETWORK" && hasWifi)
            ) {
              setOverrideCommType(target.value);
              setOpenCommTypeOverrideModal(true);
            }
            updateCommPath((recordProxy) => {
              recordProxy.setValue(target.value, "commType");
              switch (target.value) {
                case CommunicationPathCommType.NETWORK:
                case CommunicationPathCommType.WIFI: {
                  recordProxy.setValue("YES", "useCheckIn");
                  recordProxy.setValue(200, "checkInMinutes");
                  recordProxy.setValue(240, "failTime");
                  break;
                }
                case CommunicationPathCommType.DD:
                case CommunicationPathCommType.CID:
                case CommunicationPathCommType.CELL: {
                  recordProxy.setValue("NO", "useCheckIn");
                  recordProxy.setValue(0, "checkInMinutes");
                  recordProxy.setValue(0, "failTime");
                  break;
                }
                default: {
                  recordProxy.setValue("NO", "useCheckIn");
                  recordProxy.setValue(0, "checkInMinutes");
                  recordProxy.setValue(240, "failTime");
                  break;
                }
              }
            });
            if (["NETWORK", "WIFI"].includes(target.value)) {
              updateCommPath((recordProxy) => {
                recordProxy.setValue("YES", "useCheckIn");
                recordProxy.setValue(200, "checkInMinutes");
                recordProxy.setValue(240, "failTime");
              });
            } else if (["CELL"].includes(target.value)) {
              updateCommPath((recordProxy) => {
                recordProxy.setValue("NO", "useCheckIn");
              });
              updateCommPath((recordProxy) => {
                recordProxy.setValue("SECURECOM400", "apn");
              });
            }
          }}
          getValidationMessage={(input) => {
            if (
              number === 2 &&
              type === CommunicationPathType.BACKUP &&
              (commType === CommunicationPathCommType.DD ||
                commType === CommunicationPathCommType.CID)
            ) {
              return commPath1?.commType === CommunicationPathCommType.CELL
                ? "Cannot be the first backup path for a cellular primary path"
                : "";
            } else {
              return "";
            }
          }}
        >
          {/* Default selection is determined by enums in GQL Communication resolvers */}
          {!isXf ? (
            <Select.Option value={CommunicationPathCommType.CID}>
              CID
            </Select.Option>
          ) : null}
          {!isXf ? (
            <Select.Option value={CommunicationPathCommType.DD}>
              DD
            </Select.Option>
          ) : null}
          <Select.Option value={CommunicationPathCommType.NETWORK}>
            Network
          </Select.Option>
          <Select.Option value={CommunicationPathCommType.CELL}>
            Cell
          </Select.Option>
          {panelVersionGTOE(112, softwareVersion) && !isXf ? (
            <Select.Option value={CommunicationPathCommType.WIFI}>
              Wi-Fi
            </Select.Option>
          ) : null}
        </Select>
        {openVarWifiWarningModal ? (
          <VarWifiWarningModal
            onClose={() => setOpenVarWifiWarningModal(false)}
            overrideToType={CommunicationPathCommType.WIFI}
            commPathId={id}
          />
        ) : null}
        {openCommTypeOverrideModal ? (
          <CommunicationTypeOverrideModal
            onClose={() => setOpenCommTypeOverrideModal(false)}
            overrideToType={overrideCommType}
            commPathId={id}
          />
        ) : null}
      </ProgrammingConceptForm.Field>
    </>
  );
}

export default CommPathCommTypeField;
