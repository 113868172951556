import graphql from "babel-plugin-relay/macro";
import ConfirmingDeleteTooltip from "components/ConfirmingDeleteTooltip";
import { Clickable } from "components/DaStyledElements";
import Icon from "components/Icon";
import { useShowAlert } from "contexts/AlertsContext";
import React from "react";
import { useMutation } from "react-relay";
import { asID, fromTagId, toDealerId } from "securecom-graphql/client";
import styled from "styled-components";
import { TagsSettingsTableItemDeleteTagMutation } from "./__generated__/TagsSettingsTableItemDeleteTagMutation.graphql";

interface TagsSettingsTableItemProps {
  state: any;
  tag: {
    readonly description: string | null;
    readonly id: string;
    readonly name: string | null;
  };
  customersCount: number;
  systemsCount: number;
}
function TagsSettingsTableItem(props: TagsSettingsTableItemProps) {
  const { state, tag, customersCount, systemsCount } = props;
  const showAlert = useShowAlert();
  const [deleteTag] =
    useMutation<TagsSettingsTableItemDeleteTagMutation>(deleteTagMutation);
  const { dealerId } = fromTagId(asID(tag.id));
  return (
    <tr>
      <td>{tag.name}</td>
      <td>{systemsCount}</td>
      <td>{customersCount}</td>
      <td>{tag.description}</td>
      <td>
        <IconButtonsContainer>
          <Clickable.IconButton
            icon={
              <Icon
                name="settings"
                size="2.4rem"
                color="var(--color-primary)"
                style={{ marginRight: ".8rem" }}
              />
            }
            onClick={() => {
              state.go("app.dealer.tag-edit", {
                tag_id: tag.id,
              });
            }}
          />

          <ConfirmingDeleteTooltip
            iconSize="2.4rem"
            onConfirm={() =>
              deleteTag({
                variables: {
                  encodedTagId: tag.id,
                },
                onCompleted: ({ deleteTag }) => {
                  if (deleteTag.status === "SUCCESS") {
                    showAlert({
                      type: "success",
                      text: `Deleted tag: ${tag.name}`,
                    });
                  } else {
                    showAlert({
                      type: "error",
                      text: `Failed to delete tag: ${tag.name}`,
                    });
                  }
                },
                updater: (store) => {
                  const tagsRelationsCountsRecordProxy = store
                    .getRoot()
                    .getLinkedRecords("getDealerTagsRelationsCounts", {
                      id: toDealerId(dealerId),
                    });
                  const updatedTagsRelationsCountsRecordProxy =
                    tagsRelationsCountsRecordProxy?.filter(
                      (tagRecordProxy) =>
                        tagRecordProxy
                          .getLinkedRecord("dealerTag")
                          ?.getValue("id") !== tag.id
                    );

                  // Updating store with deleted tag removed
                  store
                    .getRoot()
                    .setLinkedRecords(
                      updatedTagsRelationsCountsRecordProxy,
                      "getDealerTagsRelationsCounts",
                      { id: toDealerId(dealerId) }
                    );
                },
              })
            }
            message={`Deleting ${tag.name} tag. Are you sure?`}
          />
        </IconButtonsContainer>
      </td>
    </tr>
  );
}

const IconButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const deleteTagMutation = graphql`
  mutation TagsSettingsTableItemDeleteTagMutation($encodedTagId: ID!) {
    deleteTag(encodedTagId: $encodedTagId) {
      ... on DeleteTagSuccessPayload {
        dealer {
          tags {
            id
          }
        }
        deletedTagId
        status
      }
      ... on UnknownError {
        type
      }
    }
  }
`;

export default TagsSettingsTableItem;
