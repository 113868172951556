/**
 * @generated SignedSource<<05c30b81c7dc7974393242ba387b8f42>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type SystemType = "ALL_PERIMETER" | "AREA" | "HOME_SLEEP_AWAY" | "HOME_SLEEP_AWAY_GUEST";
import { FragmentRefs } from "relay-runtime";
export type SystemOptionsSystemTypeField_systemOptions$data = {
  readonly systemType?: SystemType;
  readonly " $fragmentType": "SystemOptionsSystemTypeField_systemOptions";
};
export type SystemOptionsSystemTypeField_systemOptions$key = {
  readonly " $data"?: SystemOptionsSystemTypeField_systemOptions$data;
  readonly " $fragmentSpreads": FragmentRefs<"SystemOptionsSystemTypeField_systemOptions">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "systemType",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SystemOptionsSystemTypeField_systemOptions",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "XrSystemOptions",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "XtSystemOptions",
      "abstractKey": null
    }
  ],
  "type": "SystemOptions",
  "abstractKey": "__isSystemOptions"
};
})();

(node as any).hash = "f14ee9e8e701f916faa450050fe1fbc4";

export default node;
