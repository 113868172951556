/**
 * @generated SignedSource<<295d77ed0bc1fb03b3d6d6786ae484d2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SystemOptionsOccupiedPremisesField_systemOptions$data = {
  readonly occupiedPremises?: boolean;
  readonly " $fragmentType": "SystemOptionsOccupiedPremisesField_systemOptions";
};
export type SystemOptionsOccupiedPremisesField_systemOptions$key = {
  readonly " $data"?: SystemOptionsOccupiedPremisesField_systemOptions$data;
  readonly " $fragmentSpreads": FragmentRefs<"SystemOptionsOccupiedPremisesField_systemOptions">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "occupiedPremises",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SystemOptionsOccupiedPremisesField_systemOptions",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "XrSystemOptions",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "XtSystemOptions",
      "abstractKey": null
    }
  ],
  "type": "SystemOptions",
  "abstractKey": "__isSystemOptions"
};
})();

(node as any).hash = "d0382e1f6efa6cf281bf5fbeefad337c";

export default node;
