/**
 * @generated SignedSource<<515a60be226487f5b543728f8ff3cf48>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AreaLateOutputNumberField_xrArea$data = {
  readonly lateOutputNumber: string;
  readonly number: string;
  readonly " $fragmentType": "AreaLateOutputNumberField_xrArea";
};
export type AreaLateOutputNumberField_xrArea$key = {
  readonly " $data"?: AreaLateOutputNumberField_xrArea$data;
  readonly " $fragmentSpreads": FragmentRefs<"AreaLateOutputNumberField_xrArea">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AreaLateOutputNumberField_xrArea",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "number",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lateOutputNumber",
      "storageKey": null
    }
  ],
  "type": "XrArea",
  "abstractKey": null
};

(node as any).hash = "a89ffea0d89ca3d49fc4d05893c9573a";

export default node;
