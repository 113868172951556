import graphql from "babel-plugin-relay/macro";
import Switch from "components/FullProgramming/common/Switch";
import * as React from "react";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useSystemOptionsFragment } from "./SystemOptionsContext";
import { SystemOptionsInstantArmingField_systemOptions$key } from "./__generated__/SystemOptionsInstantArmingField_systemOptions.graphql";

export const systemOptionsInstantArmingFieldId = () =>
  "system-options-instant-arming";

function InstantArmingField() {
  const [{ instantArming }, updateSystemOptions] =
    useSystemOptionsFragment<SystemOptionsInstantArmingField_systemOptions$key>(
      graphql`
        fragment SystemOptionsInstantArmingField_systemOptions on SystemOptions {
          ... on XrSystemOptions {
            instantArming
          }
        }
      `
    );

  const fieldId = systemOptionsInstantArmingFieldId();
  const label = "Instant Arming";

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label={label}
      tooltip="Allow the INSTANT option to display during the Exit Delay Countdown when the System is arming less than all Areas."
    >
      <Switch
        label={label}
        id={fieldId}
        name={fieldId}
        checked={instantArming}
        onChange={() => {
          updateSystemOptions((recordProxy) => {
            recordProxy.setValue(!instantArming, "instantArming");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default InstantArmingField;
