import graphql from "babel-plugin-relay/macro";
import Switch from "components/FullProgramming/common/Switch";
import { resolvePanelType } from "components/FullProgramming/utils/panel";
import * as React from "react";
import { ZoneSupervisionTime } from "securecom-graphql/client";
import {
  useHardwareModel,
  useHas1100T,
  useWirelessZoneNumberRange,
  useZoneIsWirelessOnly,
} from "../PanelContext";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useZoneInformationFragment } from "./ZoneInformationContext";
import { ZoneInformationCompetitorWirelessField_zone$key } from "./__generated__/ZoneInformationCompetitorWirelessField_zone.graphql";

export const zoneInformationCompetitorWirelessFieldId = (number: string) =>
  `zone-information-competitor-wireless-${number}`;

function ZoneInformationCompetitorWirelessField(props: { houseCode?: string }) {
  const [{ number, competitorWireless, wireless }, updateZoneInformation] =
    useZoneInformationFragment<ZoneInformationCompetitorWirelessField_zone$key>(
      graphql`
        fragment ZoneInformationCompetitorWirelessField_zone on Zone {
          id
          number
          competitorWireless
          wireless
          supervisionTime
        }
      `
    );

  const fieldId = zoneInformationCompetitorWirelessFieldId(String(number));
  const hardwareModel = useHardwareModel();
  const wirelessZones = useWirelessZoneNumberRange();
  const has1100T = useHas1100T();
  const { isXtlPlus } = resolvePanelType(hardwareModel);
  const disabled = isXtlPlus
    ? !has1100T
    : !number ||
      !wirelessZones.includes(Number(number)) ||
      wireless ||
      Number(props.houseCode) === 0 ||
      !has1100T;
  const isWirelessOnly = useZoneIsWirelessOnly()(number);
  const label = "Competitor Wireless";

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label={label}
      disabled={disabled}
    >
      <Switch
        label={label}
        id={fieldId}
        name={fieldId}
        checked={!!competitorWireless}
        disabled={disabled}
        onChange={() => {
          updateZoneInformation((recordProxy) => {
            const toggledCompetitorWireless = !competitorWireless;
            // Force DMP Wireless to be true if the zone is wireless only
            // and competitor wireless is being toggled to false
            const forceDMPWireless =
              isWirelessOnly && !toggledCompetitorWireless && !wireless;

            recordProxy.setValue(forceDMPWireless, "wireless");
            recordProxy.setValue(
              toggledCompetitorWireless,
              "competitorWireless"
            );
            recordProxy.setValue("", "serialNumber");
            if (toggledCompetitorWireless) {
              recordProxy.setValue(
                ZoneSupervisionTime._1440_MIN,
                "supervisionTime"
              );
            } else if (forceDMPWireless) {
              recordProxy.setValue(
                ZoneSupervisionTime._240_MIN,
                "supervisionTime"
              );
            } else {
              recordProxy.setValue(ZoneSupervisionTime.NONE, "supervisionTime");
            }
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default ZoneInformationCompetitorWirelessField;
