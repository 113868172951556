import graphql from "babel-plugin-relay/macro";
import Switch from "components/FullProgramming/common/Switch";
import * as React from "react";
import ProgrammingConceptForm from "../../ProgrammingConceptForm";
import { useSystemAreaInformationFragment } from "./SystemAreaInformationContext";
import { SystemAreaInformationAreaSchedulesField_systemAreaInformation$key } from "./__generated__/SystemAreaInformationAreaSchedulesField_systemAreaInformation.graphql";

export const systemAreaInformationAreaSchedulesFieldId = () =>
  `system-area-information-area-schedules`;

function SystemAreaInformationAreaSchedulesField() {
  const [{ areaSchedules }, updateSystemAreaInformation] =
    useSystemAreaInformationFragment<SystemAreaInformationAreaSchedulesField_systemAreaInformation$key>(
      graphql`
        fragment SystemAreaInformationAreaSchedulesField_systemAreaInformation on SystemAreaInformation {
          areaSchedules
        }
      `
    );

  const fieldId = systemAreaInformationAreaSchedulesFieldId();

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Area Schedules"
      tooltip="If enabled, each Area can have its own set of Schedules. Otherwise, all Areas follow the Schedule assigned to Area 1."
    >
      <Switch
        label="Area Schedules"
        id={fieldId}
        checked={areaSchedules}
        onChange={() => {
          updateSystemAreaInformation((recordProxy) => {
            recordProxy.setValue(!areaSchedules, "areaSchedules");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default SystemAreaInformationAreaSchedulesField;
