import graphql from "babel-plugin-relay/macro";
import { closest } from "common/utils";
import NumericInput from "components/FullProgramming/common/NumericInput";
import { useUpdateListItemOnNumberChange } from "components/FullProgramming/Templates/utils";
import { emptyStringOrNumber } from "components/FullProgramming/utils/format";
import { xrDeviceInformationNumberFieldId } from "components/FullProgramming/XRFullProgramming/XRDeviceSetupProgrammingConceptForm/XRDeviceInformationNumberField";
import * as React from "react";
import { useRelayEnvironment } from "react-relay";
import { useControlSystem } from "../ControlSystemContext";
import {
  useDeviceNumberRange,
  useHardwareModel,
  usePanelFragment,
  useSoftwareVersion,
} from "../PanelContext";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { deviceInformationActivateOnboardSpeakerFieldId } from "./734OptionsFields/DeviceInformationActivateOnboardSpeakerField";
import { deviceInformationActivateZone2BypassFieldId } from "./734OptionsFields/DeviceInformationActivateZone2BypassField";
import { deviceInformationActivateZone3RequestToExitFieldId } from "./734OptionsFields/DeviceInformationActivateZone3RequestToExitField";
import { deviceInformationCardOptionsFieldId } from "./734OptionsFields/DeviceInformationCardOptionsField";
import { deviceInformationNoCommWithPanelFieldId } from "./734OptionsFields/DeviceInformationNoCommWithPanelField";
import { deviceInformationRelockOnZone2ChangeFieldId } from "./734OptionsFields/DeviceInformationRelockOnZone2ChangeField";
import { deviceInformationRequireSiteCodeFieldId } from "./734OptionsFields/DeviceInformationRequireSiteCodeField";
import { deviceInformationSiteCode1FieldId } from "./734OptionsFields/DeviceInformationSiteCode1Field";
import { deviceInformationSiteCode2FieldId } from "./734OptionsFields/DeviceInformationSiteCode2Field";
import { deviceInformationSiteCode3FieldId } from "./734OptionsFields/DeviceInformationSiteCode3Field";
import { deviceInformationSiteCode4FieldId } from "./734OptionsFields/DeviceInformationSiteCode4Field";
import { deviceInformationSiteCode5FieldId } from "./734OptionsFields/DeviceInformationSiteCode5Field";
import { deviceInformationSiteCode6FieldId } from "./734OptionsFields/DeviceInformationSiteCode6Field";
import { deviceInformationSiteCode7FieldId } from "./734OptionsFields/DeviceInformationSiteCode7Field";
import { deviceInformationSiteCode8FieldId } from "./734OptionsFields/DeviceInformationSiteCode8Field";
import { deviceInformationSiteCodeLengthFieldId } from "./734OptionsFields/DeviceInformationSiteCodeLengthField";
import { deviceInformationSiteCodePositionFieldId } from "./734OptionsFields/DeviceInformationSiteCodePositionField";
import { deviceInformationUserCodeDigitsFieldId } from "./734OptionsFields/DeviceInformationUserCodeDigitsField";
import { deviceInformationUserCodeLengthFieldId } from "./734OptionsFields/DeviceInformationUserCodeLengthField";
import { deviceInformationUserCodePositionFieldId } from "./734OptionsFields/DeviceInformationUserCodePositionField";
import { deviceInformationWiegandCodeLengthFieldId } from "./734OptionsFields/DeviceInformationWiegandCodeLengthField";
import { deviceInformationZone2BypassTimeFieldId } from "./734OptionsFields/DeviceInformationZone2BypassTimeField";
import { deviceInformationZone3RequestToExitTimeFieldId } from "./734OptionsFields/DeviceInformationZone3RequestToExitTimeField";
import { deviceInformation1100TFieldId } from "./DeviceInformation1100TField";
import { deviceInformation1100TWirelessFrequencyFieldId } from "./DeviceInformation1100TWirelessFrequencyField";
import { deviceInformationAccessAreasFieldId } from "./DeviceInformationAccessAreasField";
import { deviceInformationAutoForceArmFieldId } from "./DeviceInformationAutoForceArmField";
import { useDeviceInformationFragment } from "./DeviceInformationContext";
import { deviceInformationDeviceCommunicationTypeFieldId } from "./DeviceInformationDeviceCommunicationTypeField";
import { deviceInformationDeviceTypeFieldId } from "./DeviceInformationDeviceTypeField";
import { deviceInformationDisplayAreasFieldId } from "./DeviceInformationDisplayAreasField";
import { deviceInformationDoorRealTimeStatusFieldId } from "./DeviceInformationDoorRealTimeStatusField";
import { deviceInformationEgressAreasFieldId } from "./DeviceInformationEgressAreasField";
import { deviceInformationFireExitReleaseFieldId } from "./DeviceInformationFireExitReleaseField";
import { deviceInformationNameFieldId } from "./DeviceInformationNameField";
import { deviceInformationOutputGroupFieldId } from "./DeviceInformationOutputGroupField";
import { deviceInformationPinDisarmFieldId } from "./DeviceInformationPinDisarmField";
import { deviceInformationPrivateDoorFieldId } from "./DeviceInformationPrivateDoorField";
import { deviceInformationProgram734OptionsFieldId } from "./DeviceInformationProgram734OptionsField";
import { deviceInformationPublicDoorFieldId } from "./DeviceInformationPublicDoorField";
import { deviceInformationScheduleOverrideFieldId } from "./DeviceInformationScheduleOverrideField";
import { deviceInformationSendDoorForcedMessageFieldId } from "./DeviceInformationSendDoorForcedMessageField";
import { deviceInformationStrikeDelayFieldId } from "./DeviceInformationStrikeDelayField";
import { deviceInformationStrikeTimeFieldId } from "./DeviceInformationStrikeTimeField";
import { deviceInformationSupervisionTimeFieldId } from "./DeviceInformationSupervisionTimeField";
import { DeviceInformationNumberField_deviceInformation$key } from "./__generated__/DeviceInformationNumberField_deviceInformation.graphql";
import { DeviceInformationNumberField_panel$key } from "./__generated__/DeviceInformationNumberField_panel.graphql";

export const deviceListItemTemplateId = (number: string) => `device-${number}`;
export const DEVICE_INFORMATION_NUMBER = "device-information-number-";
export const deviceInformationNumberFieldId = (number: string) =>
  DEVICE_INFORMATION_NUMBER + number;
export const DEVICE_IDS = [
  deviceListItemTemplateId,
  xrDeviceInformationNumberFieldId,
  deviceInformationNameFieldId,
  deviceInformationSupervisionTimeFieldId,
  deviceInformation1100TWirelessFrequencyFieldId,
  deviceInformationDeviceTypeFieldId,
  deviceInformationDeviceCommunicationTypeFieldId,
  deviceInformationAccessAreasFieldId,
  deviceInformationEgressAreasFieldId,
  deviceInformationDisplayAreasFieldId,
  deviceInformationStrikeTimeFieldId,
  deviceInformationStrikeDelayFieldId,
  deviceInformationFireExitReleaseFieldId,
  deviceInformationPublicDoorFieldId,
  deviceInformationOutputGroupFieldId,
  deviceInformationScheduleOverrideFieldId,
  deviceInformationAutoForceArmFieldId,
  deviceInformationDoorRealTimeStatusFieldId,
  deviceInformationSendDoorForcedMessageFieldId,
  deviceInformationPinDisarmFieldId,
  deviceInformationProgram734OptionsFieldId,
  deviceInformationPrivateDoorFieldId,
  deviceInformation1100TFieldId,
  deviceInformationActivateZone2BypassFieldId,
  deviceInformationZone2BypassTimeFieldId,
  deviceInformationRelockOnZone2ChangeFieldId,
  deviceInformationActivateZone3RequestToExitFieldId,
  deviceInformationZone3RequestToExitTimeFieldId,
  deviceInformationActivateOnboardSpeakerFieldId,
  deviceInformationCardOptionsFieldId,
  deviceInformationWiegandCodeLengthFieldId,
  deviceInformationSiteCodePositionFieldId,
  deviceInformationSiteCodeLengthFieldId,
  deviceInformationUserCodePositionFieldId,
  deviceInformationUserCodeLengthFieldId,
  deviceInformationRequireSiteCodeFieldId,
  deviceInformationSiteCode1FieldId,
  deviceInformationSiteCode2FieldId,
  deviceInformationSiteCode3FieldId,
  deviceInformationSiteCode4FieldId,
  deviceInformationSiteCode5FieldId,
  deviceInformationSiteCode6FieldId,
  deviceInformationSiteCode7FieldId,
  deviceInformationSiteCode8FieldId,
  deviceInformationUserCodeDigitsFieldId,
  deviceInformationNoCommWithPanelFieldId,
];

function DeviceInformationNumberField() {
  const [{ id, number, isNew }, updateDeviceInformation] =
    useDeviceInformationFragment<DeviceInformationNumberField_deviceInformation$key>(
      graphql`
        fragment DeviceInformationNumberField_deviceInformation on DeviceInformation {
          id
          number
          isNew
        }
      `
    );

  const [{ deviceInformations }] =
    usePanelFragment<DeviceInformationNumberField_panel$key>(graphql`
      fragment DeviceInformationNumberField_panel on Panel {
        deviceInformations {
          number
        }
      }
    `);

  const fieldId = deviceInformationNumberFieldId(String(number));
  const deviceNumberRange = useDeviceNumberRange();
  const softwareVersion = useSoftwareVersion();
  const hardwareModel = useHardwareModel();
  const relayEnv = useRelayEnvironment();
  const controlSystem = useControlSystem();
  const supportsFiveKeypads =
    hardwareModel === "XTL" ||
    (hardwareModel === "XTLP" && softwareVersion < 172);
  const disabled = !isNew;

  const duplicateDevicesExist =
    deviceInformations.filter(
      ({ number: deviceNumber }) => deviceNumber === number
    ).length > 1;

  const [onChangeNumberUpdate, onBlurNumberUpdate] =
    useUpdateListItemOnNumberChange(
      DEVICE_IDS,
      duplicateDevicesExist,
      String(number),
      deviceListItemTemplateId
    );

  return (
    <ProgrammingConceptForm.Field fieldId={fieldId} label="Device Number">
      <NumericInput
        id={fieldId}
        value={number}
        required
        disabled={disabled}
        inlineHelp={supportsFiveKeypads ? `1-5` : `1-8`}
        onChange={({ target }) => {
          onChangeNumberUpdate(target.value);
          updateDeviceInformation((recordProxy) => {
            recordProxy.setValue(emptyStringOrNumber(target.value), "number");
          });
        }}
        onBlur={({ target }) => {
          /*This is to make sure you cannot duplicate device numbers.  
          If you try to use a taken number or invalid number you are directed 
          back to the closest next man up value.*/
          let blurredNumber = number;
          relayEnv.commitUpdate((store) => {
            const controlSystemRecord = store.get(controlSystem.id);
            const currentDevice = store.get(id);
            if (currentDevice) {
              const panelRecord = controlSystemRecord?.getLinkedRecord("panel");
              const deviceInformationRecords =
                panelRecord?.getLinkedRecords("deviceInformations");

              if (deviceInformationRecords) {
                const takenNumbers = new Set(
                  deviceInformationRecords.map((device) =>
                    device.getValue("id") !== id
                      ? device.getValue("number")?.toString()
                      : "0"
                  )
                );

                const valueAsNumber = Number(target.value);
                const value = closest(
                  !target.value || isNaN(valueAsNumber) ? 1 : valueAsNumber,
                  deviceNumberRange.filter(
                    (num) => !takenNumbers.has(num.toString())
                  )
                );
                currentDevice.setValue(Number(value), "number");
                blurredNumber = Number(value);
              }
            }
          });
          onBlurNumberUpdate(blurredNumber.toString());
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default DeviceInformationNumberField;
