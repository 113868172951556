/**
 * @generated SignedSource<<c8019a4c107e02d133ed4b85d8303f6d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type PanelProgrammingLanguage = "CZECH" | "DUTCH" | "ENGLISH" | "FRENCH" | "GREEK" | "NONE" | "SPANISH";
import { FragmentRefs } from "relay-runtime";
export type SystemOptionsSecondaryProgrammingLanguageField_systemOptions$data = {
  readonly secondaryProgrammingLanguage?: PanelProgrammingLanguage | null;
  readonly " $fragmentType": "SystemOptionsSecondaryProgrammingLanguageField_systemOptions";
};
export type SystemOptionsSecondaryProgrammingLanguageField_systemOptions$key = {
  readonly " $data"?: SystemOptionsSecondaryProgrammingLanguageField_systemOptions$data;
  readonly " $fragmentSpreads": FragmentRefs<"SystemOptionsSecondaryProgrammingLanguageField_systemOptions">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "secondaryProgrammingLanguage",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SystemOptionsSecondaryProgrammingLanguageField_systemOptions",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "XrSystemOptions",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "XtSystemOptions",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "XfSystemOptions",
      "abstractKey": null
    }
  ],
  "type": "SystemOptions",
  "abstractKey": "__isSystemOptions"
};
})();

(node as any).hash = "1b0bd35952d21a187eac563611bfc4de";

export default node;
