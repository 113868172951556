import graphql from "babel-plugin-relay/macro";
import { MessagePosition } from "common/components/web/Tooltip";
import {
  camectHealthCheck,
  getHttpStatusClass,
  HttpStatusClass,
} from "common/utils/universal/health-check";
import {
  panelVersionGTOE,
  resolvePanelType,
} from "components/FullProgramming/utils/panel";
import { GenericPageFallback } from "components/GenericPageFallback";
import Icon from "components/Icon";
import InlineTooltip from "components/InlineTooltip";
import RecorderAddCameraModal from "components/RecorderAddCameraModal";
import RecorderAddModal from "components/RecorderAddModal";
import PanelConnectionBadge from "components/RecorderEdit/PanelConnectionBadge";
import RecorderMegapixelProgressBar from "components/RecorderMegapixelProgressBar";
import {
  CameraStatusPuck,
  VarStatusPuckWithAutoRefresh,
} from "components/VarStatusBadge";
import { AlertsContextProvider } from "contexts/AlertsContext";
import * as React from "react";
import { RelayEnvironmentProvider, useLazyLoadQuery } from "react-relay/hooks";
import { react2angular } from "react2angular";
import {
  asID,
  fromVarHubId,
  idAsString,
  PanelHardwareModel,
  toControlSystemId,
} from "securecom-graphql/client";
import styled from "styled-components/macro";
import { FPS_RANGE } from "../Recorder/constants";
import { RecorderTableQuery } from "./__generated__/RecorderTableQuery.graphql";

export default function RecorderTableRoot({
  RelayService,
  $state,
  $stateParams,
  $rootScope,
  UserService,
}: {
  RelayService: any;
  $state: any;
  $rootScope: any;
  UserService: any;
  $stateParams: {
    customer_id: string;
    control_system_id: string;
  };
}) {
  const environment = React.useMemo(
    () => RelayService.getEnvironment(),
    [RelayService]
  );

  return (
    <React.Suspense fallback={<GenericPageFallback />}>
      <RelayEnvironmentProvider environment={environment}>
        <AlertsContextProvider $rootScope={$rootScope}>
          <RecorderTable
            state={$state}
            customerId={$stateParams.customer_id}
            systemId={$stateParams.control_system_id}
            dealerId={UserService.dealer_id}
            userService={UserService}
          />
        </AlertsContextProvider>
      </RelayEnvironmentProvider>
    </React.Suspense>
  );
}

const RecorderTable = ({
  state,
  customerId,
  systemId,
  dealerId,
  userService,
}: {
  state: any;
  customerId: string;
  systemId: string;
  dealerId?: string;
  userService: any;
}): JSX.Element | null => {
  const globalSystemId = idAsString(toControlSystemId(systemId));
  const [camectStatus, setCamectStatus] = React.useState(
    HttpStatusClass.SUCCESS_2XX
  );
  /*
   * If the Camect-API is down, GraphQL will just return 0 hubs, which
   * could be confusing to users. We need to check to see what the status
   * of Camect-API is an then combine that with the GraphQL results to get
   * effective messaging to the users.
   */
  React.useEffect(() => {
    camectHealthCheck()
      .then((response) => {
        setCamectStatus(getHttpStatusClass(response.status));
      })
      .catch((err) => {
        setCamectStatus(HttpStatusClass.NO_RESPONSE);
      });
  }, [camectStatus]);

  const [isAddCameraModalOpen, setIsAddCameraModalOpen] = React.useState(false);

  const data = useLazyLoadQuery<RecorderTableQuery>(
    graphql`
      query RecorderTableQuery($systemId: ID!) {
        node(id: $systemId) {
          id
          __typename
          ... on ControlSystem {
            id
            panel {
              hardwareModel
              softwareVersion
            }
            varHubs {
              ...RecorderAddCameraModal_varHub
              ...PanelConnectionBadge_varHub
              ...VarStatusBadge_Puck_varHub
              id
              hubId
              systemId
              hubName
              macAddress
              isOnline
              panelConnectionEnabled
              hardwareModel
              hardwareModelDisplayName
              megapixelThresholdWarning
              megapixelThresholdCritical
              supportedMegapixels
              cameras {
                id
                addedToDB
                camectCamId
                cameraName
                height
                hubId
                ipAddress
                isStreaming
                make
                megapixels
                width
                videoCodec
                framesPerSecond
                manuallyAdded
                isHidden
              }
              __typename
            }
          }
        }
      }
    `,
    { systemId: globalSystemId },
    { fetchPolicy: "network-only" }
  );

  const { isXr } = resolvePanelType(
    data?.node?.panel?.hardwareModel ?? PanelHardwareModel.XR500
  );
  const firmwareSupportsPanelIntegration = panelVersionGTOE(
    221,
    data?.node?.panel?.softwareVersion ?? ""
  );
  const [cw1, cw2, cw3, cw4] = ["auto", "auto", "28%", "33%"];

  const panelSupportsPanelIntegration =
    isXr && firmwareSupportsPanelIntegration;

  if (!data?.node?.varHubs) {
    return null;
  }

  const megapixelsEnabled =
    data.node.varHubs[0]?.cameras?.reduce(
      (total, camera) => total + (camera.addedToDB ? camera.megapixels : 0),
      0
    ) || 0;

  const hardwareModelDisplayName =
    data?.node?.varHubs[0]?.hardwareModelDisplayName?.trim() ?? "XV Gateway";

  const BrandingComponent = (): JSX.Element => (
    <>
      <TitleContainer>
        <Icon name="var_box" size="4rem" style={{ paddingRight: "1rem" }} />
        <Title>
          {hardwareModelDisplayName}
          <SubTitle>with AlarmVision&trade;</SubTitle>
        </Title>
      </TitleContainer>
    </>
  );

  const ErrorVideoDeviceHeader = ({
    message,
  }: {
    message: string;
  }): JSX.Element => (
    <>
      <VideoDeviceHeader>
        <BrandingComponent />
        <div style={{ marginLeft: "auto" }}>
          <RecorderAddModal UserService={userService} systemId={systemId} />
        </div>
      </VideoDeviceHeader>
      <span className="info-alert mar-l-24">
        <Icon name="radial_info" />
        {message}
      </span>
    </>
  );

  return data.node?.varHubs?.length &&
    camectStatus === HttpStatusClass.SUCCESS_2XX ? (
    <>
      <VideoDeviceHeader>
        <BrandingComponent />
        <div style={{ marginLeft: "auto" }}>
          {userService.canEditVideoDevice() && (
            <button
              onClick={() => {
                setIsAddCameraModalOpen(true);
              }}
              className="btn btn-dmp btn-sm"
              disabled={!data?.node?.varHubs[0]?.isOnline}
            >
              <InnerAddCameraButtonContainer>
                <Icon name="add" size="1.6rem" color="primary" />
                <div>Camera to XV Gateway</div>
              </InnerAddCameraButtonContainer>
            </button>
          )}
        </div>
        {isAddCameraModalOpen ? (
          <RecorderAddCameraModal
            userService={userService}
            setIsOpen={setIsAddCameraModalOpen}
            queryRef={data.node?.varHubs[0]}
            systemId={globalSystemId}
            dealerId={dealerId ? dealerId.toString() : ""}
            canViewPricing={userService.canViewSecureComPricing()}
            megapixelsEnabled={megapixelsEnabled}
          />
        ) : null}
      </VideoDeviceHeader>
      <TableResponsive>
        <table
          className="video-device__header--camera__table table table-striped "
          style={{
            marginBottom: "var(--measure-3x)",
            width: "100%",
            tableLayout: "fixed",
          }}
        >
          <thead>
            <TableHeadCells width={cw1}>Device</TableHeadCells>
            {panelSupportsPanelIntegration &&
            data.node?.varHubs[0]?.panelConnectionEnabled ? (
              <TableHeadCells
                width={cw4}
                title="Activation Status"
                style={{
                  whiteSpace: "nowrap",
                }}
              >
                Panel Status
              </TableHeadCells>
            ) : (
              <TableHeadCells width={cw3}>&nbsp;</TableHeadCells>
            )}

            <TableHeadCells width={cw2}>
              <EnabledResolutionHeader>
                <InlineTooltip
                  message="Performance may vary based on factors such as the
                  number of cameras, frame rate, codec, and motion detection.
                  Consider these factors when setting up the system for optimal
                  performance."
                  position={MessagePosition.Left}
                />
                <div>Total Resolution</div>
              </EnabledResolutionHeader>
            </TableHeadCells>
          </thead>
          <tbody>
            {data.node?.varHubs.map((hub, index) => {
              const megapixelThresholdWarning =
                hub?.megapixelThresholdWarning ?? false;
              const megapixelThresholdCritical =
                hub?.megapixelThresholdCritical ?? false;
              const supportedMegapixels = hub?.supportedMegapixels ?? 24;

              return (
                <tr key={index}>
                  <RecorderTableTd>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                    >
                      {hub && <VarStatusPuckWithAutoRefresh varHub={hub} />}{" "}
                      <TableButtons
                        title={hub?.hubName ?? ""}
                        onClick={() =>
                          hub?.id
                            ? state.go("app.edit-var", {
                                customer_id: state.params.customer_id,
                                control_system_id:
                                  state.params.control_system_id,
                                var_hub_id: fromVarHubId(asID(hub.id)).hubId,
                              })
                            : null
                        }
                      >
                        {hub?.hubName}
                      </TableButtons>
                    </div>
                    <div className="table-deemphasis">{hub?.macAddress}</div>
                  </RecorderTableTd>
                  <td>
                    {panelSupportsPanelIntegration &&
                      hub?.panelConnectionEnabled && (
                        <PanelConnectionBadge varHub={hub} />
                      )}
                  </td>
                  <td style={{ paddingTop: "2rem" }}>
                    <RecorderMegapixelProgressBar
                      megapixelsEnabled={megapixelsEnabled}
                      megapixelThresholdWarning={megapixelThresholdWarning}
                      megapixelThresholdCritical={megapixelThresholdCritical}
                      supportedMegapixels={supportedMegapixels}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </TableResponsive>
      <TableResponsive>
        <table
          className="video-device__header--camera__table table table-striped "
          style={{
            marginBottom: "var(--measure-3x)",
            width: "100%",
            tableLayout: "fixed",
          }}
        >
          <thead>
            <TableHeadCells width={cw1}>Camera</TableHeadCells>
            <TableHeadCells width={cw4}>IP Address</TableHeadCells>
            <TableHeadCells width={cw2}>Settings</TableHeadCells>
          </thead>
          <tbody>
            {!data.node.varHubs[0]?.cameras ||
            (data?.node?.varHubs[0]?.cameras?.filter((cam) => cam.addedToDB)
              ?.length ?? 0) < 1 ? (
              <td colSpan={3}>
                <span
                  className="info-alert mar-l-24"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: "67px",
                    color: "#5a677f",
                  }}
                >
                  <Icon name="radial_info" />
                  No cameras added to device
                </span>
              </td>
            ) : (
              data.node.varHubs[0]?.cameras?.map((cam) => {
                if (cam.addedToDB) {
                  const href = state.href("app.edit-cam", {
                    customer_id: customerId,
                    control_system_id: systemId,
                    hub_id: cam.hubId,
                    camera_id: cam.camectCamId,
                  });
                  return (
                    <tr key={cam.camectCamId}>
                      <RecorderTableTd>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                          }}
                        >
                          <CameraStatusPuck
                            status={
                              !!data.node?.varHubs?.[0]?.isOnline
                                ? cam.isStreaming
                                : null
                            }
                          />
                          <TableButtons
                            title={cam.cameraName}
                            href={href}
                            onClick={(event) => {
                              event.preventDefault();
                              state.go("app.edit-cam", {
                                customer_id: customerId,
                                control_system_id: systemId,
                                hub_id: cam.hubId,
                                camera_id: cam.camectCamId,
                              });
                            }}
                          >
                            {cam.cameraName}
                          </TableButtons>
                        </div>
                      </RecorderTableTd>
                      <td>
                        <span title={cam?.ipAddress ?? ""}>
                          {cam?.ipAddress}
                        </span>
                      </td>
                      <td>
                        <div style={{ textAlign: "left" }}>
                          <span>
                            {cam?.megapixels > 0
                              ? `${cam?.megapixels} MP`
                              : "N/A"}
                            <br />
                            <span
                              className={
                                cam?.framesPerSecond > 0 &&
                                (cam?.framesPerSecond < FPS_RANGE.MIN ||
                                  cam?.framesPerSecond > FPS_RANGE.MAX)
                                  ? "text-danger"
                                  : ""
                              }
                            >
                              {cam?.framesPerSecond > 0
                                ? `${cam?.framesPerSecond} FPS`
                                : ""}
                            </span>
                            <br />
                            <span
                              className={
                                cam?.videoCodec !== "H.264" ? "text-danger" : ""
                              }
                            >
                              {cam?.videoCodec !== ""
                                ? `${cam?.videoCodec}`
                                : ""}
                            </span>
                          </span>
                        </div>
                      </td>
                    </tr>
                  );
                }
                return null;
              })
            )}
          </tbody>
        </table>
      </TableResponsive>
    </>
  ) : !data.node?.varHubs?.length &&
    camectStatus === HttpStatusClass.SUCCESS_2XX ? (
    <ErrorVideoDeviceHeader
      message={
        "XV Gateways with AlarmVision are enabled, but no XV Gateway has been added"
      }
    />
  ) : (
    <ErrorVideoDeviceHeader
      message={`Error: There was a failure to connect to the XV Gateway. 
      If the issue persists, please contact our technical support team for further assistance.`}
    />
  );
};

export function dangerouslyAddToApp() {
  App.component(
    "recorderTable",
    react2angular(
      RecorderTableRoot,
      [],
      [
        "RelayService",
        "$rootScope",
        "$scope",
        "$state",
        "$stateParams",
        "UserService",
      ]
    )
  );
}

const VideoDeviceHeader = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  padding: var(--measure-3x) var(--measure-3x) 0;
  margin-bottom: var(--measure-2x);

  @media screen and (min-width: 768px) {
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    font-size: 2rem;
  }
`;
const InnerAddCameraButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.4rem;
`;
const TableResponsive = styled.div`
  overflow-x: auto;
  @media screen and (max-width: $screen-xs-max) {
    width: 100%;
    overflow-y: hidden;
  }
`;
const RecorderTableTd = styled.td`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
const TableButtons = styled.a`
  width: 100%;

  color: #3d85a3;
  cursor: pointer;
  border: none;
  padding: 0;
  background-color: transparent;
  text-decoration: none;
  transition: all 0.2s ease-out;
  font-size: 1.4rem;
  font-weight: 600;
  outline: none !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
const TableHeadCells = styled.th<{ width?: string | number }>`
  width: ${(props) => (props.width ? props.width : "auto")};
  white-space: nowrap !important;
  overflow: visible !important;
  margin: auto 0;
  padding: 12px 24px;
  color: #5a677f;
`;
const EnabledResolutionHeader = styled.div`
  min-width: 10rem;
  display: flex;
  align-items: center;
  margin-left: -0.5rem;
`;
const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`;
const Title = styled.span`
  font-size: 2.4rem;
`;
const SubTitle = styled.span`
  padding-left: 0.5rem;
  font-size: 1.2rem;
`;
