import graphql from "babel-plugin-relay/macro";
import Switch from "components/FullProgramming/common/Switch";
import * as React from "react";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useSystemOptionsFragment } from "./SystemOptionsContext";
import { SystemOptionsClosingWaitField_systemOptions$key } from "./__generated__/SystemOptionsClosingWaitField_systemOptions.graphql";

export const systemOptionsClosingWaitFieldId = () =>
  "system-options-closing-wait";

function ClosingWaitField() {
  const [{ closingWait }, updateSystemOptions] =
    useSystemOptionsFragment<SystemOptionsClosingWaitField_systemOptions$key>(
      graphql`
        fragment SystemOptionsClosingWaitField_systemOptions on SystemOptions {
          ... on XrSystemOptions {
            closingWait
          }
        }
      `
    );

  const fieldId = systemOptionsClosingWaitFieldId();
  const label = "Closing Wait";

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label={label}
      tooltip="Attempt to verify communication with the receiver with each System arming."
    >
      <Switch
        label={label}
        id={fieldId}
        name={fieldId}
        checked={closingWait}
        onChange={() => {
          updateSystemOptions((recordProxy) => {
            recordProxy.setValue(!closingWait, "closingWait");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default ClosingWaitField;
