import { isNotNullOrUndefined } from "common/utils/universal/function";
import { PanelHardwareModel } from "securecom-graphql/client";
import { PanelHardwareModel as RelayPanelHardwareModel } from "../__generated__/PanelContextUseHardwareModel_panel.graphql";
import { PanelContextGetAllDeviceIds_panel$data } from "./../__generated__/PanelContextGetAllDeviceIds_panel.graphql";
import { DeviceInformationType } from "./__generated__/DeviceInformationDeviceTypeField_deviceInformation.graphql";
export const isAXBusRange = (number: number): boolean =>
  new RegExp(
    /([5-9](01|05|09|13|17|21|25|29|33|37|41|45|49|53|57|61))/,
    "u"
  ).test(number.toString());

export const isAxBusDisabledByVar = (
  deviceNumber: string,
  remoteZoneList: number[],
  allIensoZones: number[]
): boolean => {
  const getFistCharIfLengthThree = (number: number | string) =>
    number?.toString().length === 3 ? number.toString()[0] : "";
  const lxBus = getFistCharIfLengthThree(deviceNumber);
  return (
    !!lxBus &&
    remoteZoneList.some(
      (zone) =>
        getFistCharIfLengthThree(zone) &&
        lxBus === getFistCharIfLengthThree(zone) &&
        !allIensoZones.includes(zone)
    )
  );
};
// get all the zones associated with an XV (ienso) Camera
export const isIensoZone = (
  deviceList: PanelContextGetAllDeviceIds_panel$data
): number[] => {
  let numberList: number[] = [];
  deviceList.deviceInformations.forEach((device) => {
    if (device.deviceType === "CAMERA" && isNotNullOrUndefined(device.number)) {
      if (device.number <= 16) {
        numberList.push(
          parseInt(`${device.number}1`),
          parseInt(`${device.number}2`),
          parseInt(`${device.number}3`),
          parseInt(`${device.number}4`)
        );
      } else {
        numberList.push(
          device.number,
          device.number + 1,
          device.number + 2,
          device.number + 3
        );
      }
    }
  });

  return numberList;
};

export const resolveDeviceInformationAreaListRules = (
  hardwareModel: RelayPanelHardwareModel
) => {
  if (hardwareModel === PanelHardwareModel.XR150) {
    return {
      pattern: "([1-8]([\\,\\-][1-8]){0,7})",
      validationMessage: "Valid values are any combination of 1-8.",
      inlineHelp: "1, 2, 3, 4, 5, 6, 7, 8",
    };
  }
  if (hardwareModel === PanelHardwareModel.XR350) {
    return {
      pattern: "(([1-9]|1[0-6])(([,])([1-9]|1[0-6])){0,15})",
      validationMessage: "Valid values are any combination of 1-16.",
      inlineHelp: "1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16",
    };
  }
  return {
    pattern:
      "(([1-9]|[1-2][0-9]|3[0-2])(([\\,\\-])([1-9]|[1-2][0-9]|3[0-2])){0,31})",
    validationMessage: "Valid values are any combination of 1-32.",
    inlineHelp: "1, 2, 3, 4, 5, 6, 7-32",
  };
};

export const resolveDeviceInformationSerialNumberRules = (
  type: DeviceInformationType
) => {};

/* {
  "5": {
    "DISPLAY": {
      "Data_Type": "Numeric",
      "NAME": "Serial Number",
      "PLACEHOLDER": "14000000-14499999",
      "PATTERN": "(14[0-4][0-9]{5})",
      "VALIDATION_MSG": "Valid values are 14000000-14499999.",
      "Default_Value": 14,
      "ORDER": 7
    }
  },
  "7": {
    "DISPLAY": {
      "Data_Type": "Numeric",
      "NAME": "Serial Number",
      "PLACEHOLDER": "13000000-13999999",
      "PATTERN": "(13[0-9]{6})",
      "VALIDATION_MSG": "Valid values are 13000000-13999999.",
      "Default_Value": 13,
      "ORDER": 7
    }
  },
  "1|2|3|4|6": {
    "DISPLAY": {
      "Data_Type": "Numeric",
      "NAME": "Serial Number",
      "PLACEHOLDER": "14000000-14999999",
      "PATTERN": "(14[0-9]{6})",
      "VALIDATION_MSG": "Valid values are 14000000-14999999.",
      "Default_Value": 14,
      "ORDER": 7
    }
  }
} */
