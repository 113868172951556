import graphql from "babel-plugin-relay/macro";
import { Clickable, Panel } from "components/DaStyledElements";
import { GenericPageFallback } from "components/GenericPageFallback";
import Icon from "components/Icon";
import { useShowAlert } from "contexts/AlertsContext";
import * as React from "react";
import { Suspense } from "react";
import { TabPanel, useTabs } from "react-headless-tabs";
import { useFragment, useLazyLoadQuery, useMutation } from "react-relay";
import {
  DownloadableFileType,
  idAsString,
  toDealerId,
} from "securecom-graphql/client";
import styled from "styled-components";
import ConfirmingDeleteTooltip from "../../ConfirmingDeleteTooltip";
import EditFileModal from "../EditFileModal";
import RemoteLinkInfoModal from "../RemoteLinkInfoModal";
import { TabsetDownloadableFileDeleteMutation } from "./__generated__/TabsetDownloadableFileDeleteMutation.graphql";
import { TabsetDownloadableFile_downloadableFile$key } from "./__generated__/TabsetDownloadableFile_downloadableFile.graphql";
import { TabsetQuery } from "./__generated__/TabsetQuery.graphql";

const ListItemGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr min-content;
  grid-gap: 1.6rem;
  grid-template-rows: 1fr 1fr;
  grid-template-areas: "title edit" "dl-and-notes dl-and-notes";
  @media (min-width: 32rem) {
    grid-template-columns: 2fr minmax(22rem, 1fr) minmax(0, min-content);
    grid-template-rows: 4.2rem;
    grid-template-areas: "title dl-and-notes edit";
  }
`;
const ListItemGridTitle = styled.div`
  display: block;
  grid-area: title;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  @media (min-width: 32rem) {
    display: flex;
    justify-content: flex-start;
  }
`;

const ListItemGridDownload = styled.div`
  grid-area: dl-and-notes;
  display: inline-flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  @media (min-width: 32rem) {
    justify-content: flex-end;
  }
`;

const ListItemGridEdit = styled.div`
  grid-area: edit;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  @media (min-width: 40rem) {
    justify-content: flex-end;
  }
`;

export const DownloadsTabset: React.FC<
  React.PropsWithChildren<{
    dealerId: string;
    authToken: string;
    isWhitelisted: boolean;
  }>
> = ({ dealerId, authToken, isWhitelisted }) => {
  const [selectedTab, setSelectedTab] = useTabs(["remoteLink", "firmware"]);

  return (
    <>
      <nav className="mar-b-8">
        {/* Hidden for code clarity - until more types of downloads are implemented */}
        {/* <TabSelector
          isActive={selectedTab === "remoteLink"}
          onClick={() => setSelectedTab("remoteLink")}
        >
        </TabSelector> */}
      </nav>
      <Panel.Default>
        <TabPanel hidden={selectedTab !== "remoteLink"}>
          <Suspense fallback={<GenericPageFallback />}>
            <RemoteLinkList
              dealerId={dealerId}
              authToken={authToken}
              isWhitelisted={isWhitelisted}
            />
          </Suspense>
        </TabPanel>
      </Panel.Default>
    </>
  );
};

const RemoteLinkList: React.FC<
  React.PropsWithChildren<{
    dealerId: string;
    authToken: string;
    isWhitelisted: boolean;
  }>
> = ({ dealerId, authToken, isWhitelisted }) => {
  const data = useLazyLoadQuery<TabsetQuery>(
    graphql`
      query TabsetQuery(
        $id: ID!
        $downloadableFileTypes: [DownloadableFileType!]!
      ) {
        dealer(id: $id) {
          id
          downloadableFiles(
            sort: { keys: ["createdAt"], order: DESC }
            downloadableFileTypes: $downloadableFileTypes
          ) {
            nodes {
              id
              createdAt
              ...EditFileModalDownloadableFile_downloadableFile
              ...TabsetDownloadableFile_downloadableFile
            }
          }
        }
      }
    `,
    {
      id: idAsString(toDealerId(dealerId)),
      downloadableFileTypes: [DownloadableFileType.REMOTE_LINK],
    }
  );

  return (
    <>
      {data.dealer?.downloadableFiles.nodes.map((downloadableFile) => (
        <RemoteLinkListItem
          key={`${downloadableFile.id}-key`}
          authToken={authToken}
          downloadableFileKey={downloadableFile}
          isWhitelisted={isWhitelisted}
        />
      ))}
    </>
  );
};

const RemoteLinkListItem: React.FC<
  React.PropsWithChildren<{
    authToken: string;
    downloadableFileKey: TabsetDownloadableFile_downloadableFile$key;
    isWhitelisted: boolean;
  }>
> = ({ authToken, downloadableFileKey, isWhitelisted }) => {
  const downloadableFile = useFragment(
    graphql`
      fragment TabsetDownloadableFile_downloadableFile on DownloadableFile {
        id
        name
        downloadUrl
        isPublic
        infoUrl
        createdAt
        ...EditFileModalDownloadableFile_downloadableFile
      }
    `,
    downloadableFileKey
  );
  const [deleteDownloadableFile] =
    useMutation<TabsetDownloadableFileDeleteMutation>(graphql`
      mutation TabsetDownloadableFileDeleteMutation(
        $downloadableFileId: ID!
        $downloadableFileTypes: [DownloadableFileType!]!
      ) {
        deleteDownloadableFile(downloadableFileId: $downloadableFileId) {
          ... on DeleteDownloadableFileErrorResponse {
            errorType
          }

          ... on DeleteDownloadableFileSuccessResponse {
            dealer {
              id
              downloadableFiles(
                sort: { keys: ["createdAt"], order: DESC }
                downloadableFileTypes: $downloadableFileTypes
              ) {
                nodes {
                  id
                  name
                  downloadUrl
                  isPublic
                  infoUrl
                  createdAt
                  ...EditFileModalDownloadableFile_downloadableFile
                }
              }
            }
          }
        }
      }
    `);
  const [editModalOpen, setEditModalOpen] = React.useState(false);
  const [remoteLinkInfoModalOpen, setRemoteLinkInfoModalOpen] =
    React.useState(false);

  const showAlert = useShowAlert();
  return (
    <>
      <Panel.Default
        style={
          !downloadableFile.isPublic
            ? { backgroundColor: "var(--color-neutral-400)" }
            : { backgroundColor: "white" }
        }
      >
        <ListItemGrid>
          <ListItemGridTitle className="h4 mar-r-8">
            {downloadableFile.name}
          </ListItemGridTitle>
          <ListItemGridDownload>
            <Clickable.LinkThatLooksLikeButton
              href={downloadableFile.downloadUrl}
            >
              Download
            </Clickable.LinkThatLooksLikeButton>
            <Clickable.LinkThatLooksLikeButton
              href={downloadableFile.infoUrl}
              target="_blank"
            >
              Release&nbsp;Notes
            </Clickable.LinkThatLooksLikeButton>
            <Clickable.IconButton
              icon={
                <Icon
                  name="radial_info"
                  color="var(--color-primary)"
                  size="2.4rem"
                />
              }
              color="var(--color-primary)"
              onClick={() => setRemoteLinkInfoModalOpen(true)}
            />
          </ListItemGridDownload>
          {isWhitelisted && (
            <ListItemGridEdit>
              <Clickable.IconButton
                className="mar-r-12"
                icon={
                  <Icon
                    name="settings"
                    size="2.4rem"
                    color="var(--color-primary)"
                  />
                }
                onClick={() => setEditModalOpen(true)}
              ></Clickable.IconButton>

              <ConfirmingDeleteTooltip
                iconSize="2.4rem"
                onConfirm={() => {
                  deleteDownloadableFile({
                    variables: {
                      downloadableFileId: downloadableFile.id,
                      downloadableFileTypes: [DownloadableFileType.REMOTE_LINK],
                    },
                    onCompleted: (result) => {
                      if (result.deleteDownloadableFile.errorType) {
                        showAlert({
                          type: "error",
                          text:
                            "Unable to delete file: " + downloadableFile.name,
                        });
                      } else {
                        showAlert({
                          type: "success",
                          text: "Deleted file: " + downloadableFile.name,
                        });
                      }
                    },
                  });
                }}
                message={`Deleting file ${downloadableFile.name}. Are you sure?`}
              />
            </ListItemGridEdit>
          )}
        </ListItemGrid>
      </Panel.Default>

      {editModalOpen && (
        <EditFileModal
          authToken={authToken}
          closeModal={() => setEditModalOpen(false)}
          downloadableFileKey={downloadableFile}
        />
      )}
      {remoteLinkInfoModalOpen && (
        <RemoteLinkInfoModal
          closeModal={() => setRemoteLinkInfoModalOpen(false)}
        />
      )}
    </>
  );
};
