import graphql from "babel-plugin-relay/macro";
import React from "react";
import { useLazyLoadQuery } from "react-relay";
import styled from "styled-components/macro";
import Modal from "../../Modal";
import { useTemplateContext } from "../common/TemplateContext";
import {
  ApplyTemplateModalQuery,
  PanelHardwareModel,
} from "./__generated__/ApplyTemplateModalQuery.graphql";

const HeaderText = styled.div`
  margin: 0 var(--measure-half) 0 0;
  font-size: 2rem;
  font-weight: 500;
  color: var(--color-text-default);
`;

function TemplatesList(props: {
  customerId: string;
  hardwareModel: PanelHardwareModel;
}) {
  const data = useLazyLoadQuery<ApplyTemplateModalQuery>(
    graphql`
      query ApplyTemplateModalQuery(
        $customerId: ID!
        $hardwareModel: PanelHardwareModel!
      ) {
        customer: node(id: $customerId) {
          id
          ... on Customer {
            programmingTemplates(
              hardwareModels: [$hardwareModel]
              sort: { keys: ["name"], order: ASC }
            ) {
              edges {
                cursor
                node {
                  id
                  name
                }
              }
            }
          }
        }
      }
    `,
    { customerId: props.customerId, hardwareModel: props.hardwareModel }
  );
  const templates = data.customer?.programmingTemplates?.edges ?? [];

  return (
    <>
      {templates.map(
        ({ node, cursor }) =>
          node && (
            <option key={cursor} value={node.id}>
              {node.name}
            </option>
          )
      )}
    </>
  );
}

const ApplyTemplateModal: React.FC<{
  onCancel: () => void;
  onSave: (templateId: string) => Promise<void> | void;
  customerId: string;
  hardwareModel: PanelHardwareModel;
}> = ({ onCancel, onSave, customerId, hardwareModel }) => {
  const { isSaving, toggleIsSaving, toggleIsApplying } = useTemplateContext();
  const [selectedTemplateId, setSelectedTemplateId] =
    React.useState<string>("");

  return (
    <Modal size="medium">
      <Modal.Header className="pad-b-0">
        <HeaderText>Apply Template</HeaderText>
      </Modal.Header>
      <Modal.Body>
        <select
          id="programming-template"
          value={selectedTemplateId}
          onChange={(event) => {
            setSelectedTemplateId(event.target.value);
          }}
        >
          <option value="">Please select a template</option>
          <React.Suspense fallback={null}>
            <TemplatesList
              customerId={customerId}
              hardwareModel={hardwareModel}
            />
          </React.Suspense>
        </select>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-default"
          disabled={isSaving}
          onClick={onCancel}
        >
          Cancel
        </button>
        <button
          type="button"
          className="btn btn-dmp"
          disabled={!selectedTemplateId || isSaving}
          onClick={async () => {
            if (selectedTemplateId) {
              toggleIsSaving();
              try {
                await onSave(selectedTemplateId);
              } catch {}
              toggleIsSaving();
              toggleIsApplying();
            }
          }}
        >
          {isSaving ? "Applying..." : "Apply"}
        </button>
      </Modal.Footer>
    </Modal>
  );
};
export default ApplyTemplateModal;
