/**
 * @generated SignedSource<<794dce7b6f13709fcef54550f6d4b39c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SystemOptionsClosingWaitField_systemOptions$data = {
  readonly closingWait?: boolean;
  readonly " $fragmentType": "SystemOptionsClosingWaitField_systemOptions";
};
export type SystemOptionsClosingWaitField_systemOptions$key = {
  readonly " $data"?: SystemOptionsClosingWaitField_systemOptions$data;
  readonly " $fragmentSpreads": FragmentRefs<"SystemOptionsClosingWaitField_systemOptions">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SystemOptionsClosingWaitField_systemOptions",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "closingWait",
          "storageKey": null
        }
      ],
      "type": "XrSystemOptions",
      "abstractKey": null
    }
  ],
  "type": "SystemOptions",
  "abstractKey": "__isSystemOptions"
};

(node as any).hash = "a98201000ce5a2cd3801bbd4f9877ad9";

export default node;
