import { getAuthFromJWT } from "utils/string";
App.controller(
  "BillingAccountModalInstanceCtrl",
  function (
    $scope,
    $state,
    $modalInstance,
    $q,
    UserService,
    PROPS,
    $modal,
    PaymentsService,
    BillingAccount,
    $rootScope
  ) {
    $scope.PROPS = PROPS;
    $scope.apiEvent = "";
    $scope.amount = "";
    $scope.paymentForm = {};
    $scope.getAuthFromJWT = getAuthFromJWT;

    function waitFor(predicate) {
      return new Promise((resolve) => {
        function tick() {
          setTimeout(() => {
            if (predicate()) {
              resolve();
            } else {
              tick();
            }
          }, 16);
        }

        tick();
      });
    }

    function waitForElement(selector) {
      return waitFor(() => !!document.querySelector(selector));
    }

    /**
     * OrbiPay is a third party script that mutates window object.
     * This script is dynamically injected when a user is using billing.
     * Specifically we utilize OrbipayCheckoutSimple.
     */
    const waitForOrbiPay = async () => {
      if (window.OrbipayCheckoutSimple) {
        // OrbiPay already loaded
        return;
      }

      if (!document.getElementById("orbipay-checkout-script")) {
        $("<script>")
          .attr({
            src: PROPS.orbiPayHostedForms,
            id: "orbipay-checkout-script",
            "data-api_event": "create_funding_account",
            "data-prevent_posting": true,
          })
          .appendTo("#checkout-form-wrapper");
      }

      await waitFor(() => !!window.OrbipayCheckoutSimple);

      const handleFormSubmit = window.OrbipayCheckoutSimple.handleFormSubmit;

      // Dangerously overwrites handleFormSubmit for local
      // DMP side effects and updating server state.
      // This should only be set once, once OrbipayCheckoutSimple
      // has mutated the window object, we do not want to load this again.
      window.OrbipayCheckoutSimple.handleFormSubmit = (...args) => {
        handlePaymentMethodChange(...args);
        handleFormSubmit(...args);
      };
    };

    async function refetchBillingAccount(dealerCode, id) {
      const account = new BillingAccount();
      await account.get(dealerCode, id);
      return account;
    }

    function init() {
      waitForElement("#orbipay-checkout-iframe-div")
        .then(waitForOrbiPay)
        .then(() => {
          window.OrbipayCheckoutSimple.setClientKey(PROPS.orbiPayClientId);
          window.OrbipayCheckoutSimple.setCustomerReference(
            UserService.dealerInfo.dealer_code
          );
          window.OrbipayCheckoutSimple.setCustomerAccountReference(
            UserService.dealerInfo.dealer_code + "-account"
          );

          if ($scope.billingAccount.hasPaymentCustomer()) {
            window.OrbipayCheckoutSimple.setIdCustomer(
              $scope.billingAccount.orbiCustomerId
            );
          }

          window.OrbipayCheckoutSimple.setApiEvent("create_funding_account");

          document.getElementById("orbipay-checkout-button").click();
        });
    }

    $scope.cancel = function () {
      $modalInstance.dismiss("cancel");
    };

    $scope.logout = function () {
      $rootScope.logout();
    };

    $scope.activePaymentSources = function (source) {
      return !source.is_deactivated;
    };

    /**
     * Shows a modal to have the user confirm they want to delete the payment source.
     */
    $scope.showDeletePaymentSourceModal = function () {
      const modal = $modal.open({
        templateUrl: "app/billing/delete-pay-src-modal.html",
        controller: "DeletePaymentSourceModalInstanceCtrl",
        windowClass: "",
        size: "sm",
        backdrop: true,
        scope: $scope,
      });

      modal.result.then(
        () => {
          refetchBillingAccount(
            UserService.dealerInfo.dealer_code,
            UserService.dealerInfo.id
          ).then((nextBillingAccount) => {
            $scope.billingAccount = nextBillingAccount;
            $scope.refreshBase();
          });
          $modal.close();
        },
        () => {}
      );
    };

    /**
     * Handle the DMP local effects of the payment method update.
     */
    function handlePaymentMethodChange(event) {
      const hasPreviousPaymentMethod = !!$scope.billingAccount.paymentSource;
      const isAutoPay = $scope.billingAccount.isAutoPayment;

      Promise.resolve()
        .then(
          () =>
            isAutoPay &&
            PaymentsService.updateAutoPayment(
              $scope.billingAccount.dealerCode,
              false, // Is Enabled Flag, false to deactivate
              $scope.billingAccount.paymentSource.id
            )
        )
        .then(
          () =>
            hasPreviousPaymentMethod &&
            PaymentsService.deletePaymentSource(
              $scope.billingAccount.dealerCode,
              $scope.billingAccount.paymentSource.id
            )
        )
        .then(() =>
          PaymentsService.createPaymentSource(
            // https://developers.orbipay.com/integration-guide-hosted-forms/
            // this event returns an event.detail with the details
            // we are injecting more data (not set through orbikey)
            // to our DMP apis.
            {
              ...event.detail,
              dealerCode: UserService.dealerInfo.dealer_code,
              auth_token: getAuthFromJWT(UserService.auth_token), // We need to pass the auth token to the pay service to make the request work properly. This is in the body of the request so it is not visible per se.
              origination_url: window.location.href,
            }
          )
        )
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          refetchBillingAccount(
            UserService.dealerInfo.dealer_code,
            UserService.dealerInfo.id
          )
            .then(function (nextBillingAccount) {
              if (isAutoPay) {
                return PaymentsService.updateAutoPayment(
                  nextBillingAccount.dealerCode,
                  true,
                  nextBillingAccount.paymentSource.id
                ).then(() => nextBillingAccount);
              } else {
                return nextBillingAccount;
              }
            })
            .then((nextBillingAccount) => {
              $scope.billingAccount = nextBillingAccount;
              $scope.refreshBase();
              init();
            })
            .catch(() => {});
        });
    }

    init();

    // We are using the hosted form options of OrbiPay:
    // https://developers.orbipay.com/integration-guide-hosted-forms/
    // This allows for progammatic updates using the checkout-event, this event is called ONLY on
    // the window, it does not bubble up from a dom event or the document object itself.
    window.addEventListener("checkout-event", handlePaymentMethodChange);

    $scope.$on("$destroy", function () {
      window.removeEventListener("checkout-event", handlePaymentMethodChange);
    });
  }
);
