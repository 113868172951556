import graphql from "babel-plugin-relay/macro";
import useEffectOnce from "common/hooks/useEffectOnce";
import Select from "components/FullProgramming/common/Select";
import {
  panelVersionGTOE,
  resolvePanelType,
} from "components/FullProgramming/utils/panel";
import * as React from "react";
import {
  PanelHardwareModel,
  ZoneArmingStyle,
  ZoneChimeSound,
  ZoneMessage,
  ZoneNumberOfEols,
  ZoneOutputAction,
  ZoneType,
} from "securecom-graphql/client";
import { useHardwareModel, useSoftwareVersion } from "../PanelContext";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useTemplateContext } from "../TemplateContext";
import { useZoneInformationFragment } from "./ZoneInformationContext";
import { ZoneInformationTypeField_zone$key } from "./__generated__/ZoneInformationTypeField_zone.graphql";

export const zoneInformationTypeFieldId = (number: string) =>
  `zone-information-type-${number}`;

function ZoneInformationTypeField() {
  const [zoneInformation, updateZoneInformation] =
    useZoneInformationFragment<ZoneInformationTypeField_zone$key>(
      graphql`
        fragment ZoneInformationTypeField_zone on Zone {
          id
          type
          number
          swingerBypassEnabled
          disarmedOpenActionMessage
          disarmedShortActionMessage
          armedShortActionMessage
          armedOpenActionMessage
          chimeSound
          armingStyle
          isECP
          isNew
        }
      `
    );

  const fieldId = zoneInformationTypeFieldId(zoneInformation.number);
  const { templateId } = useTemplateContext();
  const isSavedTemplateZone = Boolean(templateId);

  const { type, isECP } = zoneInformation;
  const softwareVersion = useSoftwareVersion();
  const hardwareModel = useHardwareModel();
  const { isTakeoverPanel, isXf, isXr, isTMSentry } =
    resolvePanelType(hardwareModel);

  const supportsCOType =
    (panelVersionGTOE(173, softwareVersion) &&
      [
        PanelHardwareModel.XR150,
        PanelHardwareModel.XR350,
        PanelHardwareModel.XR550,
        PanelHardwareModel.XT50,
        PanelHardwareModel.XT30,
        PanelHardwareModel.XT50L,
        PanelHardwareModel.XT30L,
        PanelHardwareModel.XTLP,
        PanelHardwareModel.XTLN,
        PanelHardwareModel.XTLW,
      ].includes(hardwareModel as PanelHardwareModel)) ||
    [PanelHardwareModel.XF6_500, PanelHardwareModel.XF6_100].includes(
      hardwareModel as PanelHardwareModel
    );

  const supportsInstantType =
    panelVersionGTOE(184, softwareVersion) &&
    !isTakeoverPanel &&
    !isXf &&
    !isTMSentry;

  const supportsDoorbellType =
    panelVersionGTOE(192, softwareVersion) &&
    !isTakeoverPanel &&
    !isXf &&
    !isTMSentry;

  const setActionsBasedOnZoneType = (value: any) => {
    //sets default values for non boolean fields when zone type
    switch (value) {
      case ZoneType.ARMING:
        updateZoneInformation((recordProxy) => {
          recordProxy.setValue(ZoneArmingStyle.TOGGLE, "armingStyle");
          recordProxy.setValue(undefined, "armedShortActionMessage");
          recordProxy.setValue(undefined, "lockdownEnabled");
          recordProxy.setValue(null, "armAreas");
          recordProxy.setValue(ZoneMessage.NONE, "disarmedOpenActionMessage");
          recordProxy.setValue(ZoneMessage.NONE, "disarmedShortActionMessage");
          recordProxy.setValue("1", "armedAreasForArmingZone");
          recordProxy.setValue(false, "tamperEnabled");
          if (isXr) {
            const outputNumber =
              recordProxy.getValue("disarmedOpenOutputNumber") ??
              recordProxy.getValue("fireBellOutputNumber") ??
              "000";
            recordProxy.setValue(outputNumber, "disarmedOpenOutputNumber");
          }
        });
        break;

      case ZoneType.DAY:
        updateZoneInformation((recordProxy) => {
          recordProxy.setValue(undefined, "armingStyle");
          recordProxy.setValue(undefined, "lockdownEnabled");
          recordProxy.setValue(
            ZoneMessage.TROUBLE,
            "disarmedOpenActionMessage"
          );
          recordProxy.setValue(
            ZoneMessage.TROUBLE,
            "disarmedShortActionMessage"
          );
          recordProxy.setValue(ZoneMessage.ALARM, "armedOpenActionMessage");
          recordProxy.setValue(ZoneMessage.ALARM, "armedShortActionMessage");
          if (isXr) {
            const outputNumber =
              recordProxy.getValue("disarmedOpenOutputNumber") ??
              recordProxy.getValue("fireBellOutputNumber") ??
              "000";
            recordProxy.setValue(outputNumber, "disarmedOpenOutputNumber");
          }
        });
        break;

      case ZoneType.DOORBELL:
        updateZoneInformation((recordProxy) => {
          recordProxy.setValue(undefined, "armingStyle");
          recordProxy.setValue(undefined, "lockdownEnabled");
          recordProxy.setValue(ZoneMessage.NONE, "disarmedOpenActionMessage");
          recordProxy.setValue(ZoneMessage.NONE, "disarmedShortActionMessage");
          recordProxy.setValue(ZoneMessage.NONE, "armedOpenActionMessage");
          recordProxy.setValue(ZoneMessage.NONE, "armedShortActionMessage");
          recordProxy.setValue(false, "tamperEnabled");
          if (isXr) {
            const outputNumber =
              recordProxy.getValue("disarmedOpenOutputNumber") ??
              recordProxy.getValue("fireBellOutputNumber") ??
              "000";
            recordProxy.setValue(outputNumber, "disarmedOpenOutputNumber");
          }
        });
        break;

      case ZoneType.BLANK:
        updateZoneInformation((recordProxy) => {
          recordProxy.setValue(undefined, "lockdownEnabled");
          recordProxy.setValue(true, "swingerBypassEnabled");
          recordProxy.setValue(ZoneMessage.ALARM, "armedOpenActionMessage");
          recordProxy.setValue(ZoneMessage.ALARM, "armedShortActionMessage");

          recordProxy.setValue(ZoneMessage.NONE, "disarmedOpenActionMessage");

          recordProxy.setValue(ZoneMessage.NONE, "disarmedShortActionMessage");
          if (isXr) {
            const outputNumber =
              recordProxy.getValue("disarmedOpenOutputNumber") ??
              recordProxy.getValue("fireBellOutputNumber") ??
              "000";
            recordProxy.setValue(outputNumber, "disarmedOpenOutputNumber");
          }
        });

        break;

      case ZoneType.NIGHT:
        updateZoneInformation((recordProxy) => {
          recordProxy.setValue(undefined, "lockdownEnabled");
          recordProxy.setValue(ZoneChimeSound.OFF, "chimeSound");
          recordProxy.setValue(ZoneMessage.NONE, "disarmedOpenActionMessage");
          recordProxy.setValue(ZoneMessage.NONE, "disarmedShortActionMessage");
          recordProxy.setValue(ZoneMessage.ALARM, "armedOpenActionMessage");
          recordProxy.setValue(ZoneMessage.ALARM, "armedShortActionMessage");
          if (isXr) {
            const outputNumber =
              recordProxy.getValue("disarmedOpenOutputNumber") ??
              recordProxy.getValue("fireBellOutputNumber") ??
              "000";
            recordProxy.setValue(outputNumber, "disarmedOpenOutputNumber");
          }
        });
        break;

      case ZoneType.EXIT:
      case ZoneType.FINAL_EXIT:
        updateZoneInformation((recordProxy) => {
          recordProxy.setValue(undefined, "lockdownEnabled");
          recordProxy.setValue(ZoneChimeSound.DOORBELL, "chimeSound");
          recordProxy.setValue(ZoneMessage.NONE, "disarmedOpenActionMessage");
          recordProxy.setValue(ZoneMessage.NONE, "disarmedShortActionMessage");
          recordProxy.setValue(ZoneMessage.ALARM, "armedOpenActionMessage");
          recordProxy.setValue(ZoneMessage.ALARM, "armedShortActionMessage");
          if (isXr) {
            const outputNumber =
              recordProxy.getValue("disarmedOpenOutputNumber") ??
              recordProxy.getValue("fireBellOutputNumber") ??
              "000";
            recordProxy.setValue(outputNumber, "disarmedOpenOutputNumber");
          }
        });
        break;

      case ZoneType.INSTANT:
        updateZoneInformation((recordProxy) => {
          recordProxy.setValue(undefined, "lockdownEnabled");
          recordProxy.setValue(undefined, "armingStyle");
          recordProxy.setValue(ZoneMessage.NONE, "disarmedOpenActionMessage");
          recordProxy.setValue(ZoneMessage.NONE, "disarmedShortActionMessage");
          recordProxy.setValue(ZoneMessage.ALARM, "armedOpenActionMessage");
          recordProxy.setValue(ZoneMessage.ALARM, "armedShortActionMessage");
          recordProxy.setValue(
            value === ZoneType.EXIT
              ? ZoneChimeSound.DOORBELL
              : ZoneChimeSound.OFF,
            "chimeSound"
          );
          if (isXr) {
            const outputNumber =
              recordProxy.getValue("disarmedOpenOutputNumber") ??
              recordProxy.getValue("fireBellOutputNumber") ??
              "000";
            recordProxy.setValue(outputNumber, "disarmedOpenOutputNumber");
          }
        });
        break;

      case ZoneType.AUXILIARY_1:
        updateZoneInformation((recordProxy) => {
          recordProxy.setValue(undefined, "lockdownEnabled");
          recordProxy.setValue(ZoneMessage.TROUBLE, "armedOpenActionMessage");
          recordProxy.setValue(ZoneMessage.ALARM, "armedShortActionMessage");
          recordProxy.setValue(
            ZoneMessage.TROUBLE,
            "disarmedOpenActionMessage"
          );
          recordProxy.setValue(ZoneMessage.ALARM, "disarmedShortActionMessage");
          recordProxy.setValue(false, "tamperEnabled");
          if (isXr) {
            const outputNumber =
              recordProxy.getValue("disarmedOpenOutputNumber") ??
              recordProxy.getValue("fireBellOutputNumber") ??
              "000";
            recordProxy.setValue(outputNumber, "disarmedOpenOutputNumber");
          }
        });
        break;

      case ZoneType.AUXILIARY_2:
        updateZoneInformation((recordProxy) => {
          recordProxy.setValue(undefined, "armingStyle");
          recordProxy.setValue(undefined, "lockdownEnabled");
          recordProxy.setValue(ZoneMessage.TROUBLE, "armedOpenActionMessage");
          recordProxy.setValue(ZoneMessage.ALARM, "armedShortActionMessage");
          recordProxy.setValue(
            ZoneMessage.TROUBLE,
            "disarmedOpenActionMessage"
          );
          recordProxy.setValue(ZoneMessage.ALARM, "disarmedShortActionMessage");
          recordProxy.setValue(false, "tamperEnabled");
          if (isXr) {
            const outputNumber =
              recordProxy.getValue("disarmedOpenOutputNumber") ??
              recordProxy.getValue("fireBellOutputNumber") ??
              "000";
            recordProxy.setValue(outputNumber, "disarmedOpenOutputNumber");
          }
        });
        break;

      case ZoneType.PANIC:
        updateZoneInformation((recordProxy) => {
          recordProxy.setValue(undefined, "lockdownEnabled");
          recordProxy.setValue(ZoneMessage.TROUBLE, "armedOpenActionMessage");
          recordProxy.setValue(ZoneMessage.ALARM, "armedShortActionMessage");
          recordProxy.setValue(ZoneMessage.NONE, "disarmedOpenActionMessage");
          recordProxy.setValue(ZoneMessage.NONE, "disarmedShortActionMessage");
          recordProxy.setValue(false, "tamperEnabled");
          if (isXr) {
            const outputNumber =
              recordProxy.getValue("disarmedOpenOutputNumber") ??
              recordProxy.getValue("fireBellOutputNumber") ??
              "000";
            recordProxy.setValue(outputNumber, "disarmedOpenOutputNumber");
          }
        });
        break;

      case ZoneType.SUPERVISORY:
        updateZoneInformation((recordProxy) => {
          recordProxy.setValue(undefined, "lockdownEnabled");
          recordProxy.setValue(ZoneMessage.TROUBLE, "armedOpenActionMessage");
          recordProxy.setValue(ZoneMessage.ALARM, "armedShortActionMessage");
          recordProxy.setValue(ZoneMessage.NONE, "disarmedOpenActionMessage");
          recordProxy.setValue(ZoneMessage.NONE, "disarmedShortActionMessage");
          if (isXr) {
            const outputNumber =
              recordProxy.getValue("fireBellOutputNumber") ??
              recordProxy.getValue("disarmedOpenOutputNumber") ??
              "000";
            recordProxy.setValue(outputNumber, "fireBellOutputNumber");
          }
        });
        break;

      case ZoneType.FIRE:
        updateZoneInformation((recordProxy) => {
          recordProxy.setValue(undefined, "lockdownEnabled");
          recordProxy.setValue(ZoneMessage.NONE, "disarmedOpenActionMessage");
          recordProxy.setValue(ZoneMessage.NONE, "disarmedShortActionMessage");
          recordProxy.setValue(ZoneMessage.TROUBLE, "armedOpenActionMessage");
          recordProxy.setValue(ZoneMessage.ALARM, "armedShortActionMessage");
          recordProxy.setValue(false, "tamperEnabled");
          recordProxy.setValue(false, "numberOfEol");
          if (isXr) {
            const outputNumber =
              recordProxy.getValue("fireBellOutputNumber") ??
              recordProxy.getValue("disarmedOpenOutputNumber") ??
              "000";
            recordProxy.setValue(outputNumber, "fireBellOutputNumber");
          }
        });

        break;

      case ZoneType.FIRE_VERIFY:
        updateZoneInformation((recordProxy) => {
          recordProxy.setValue(undefined, "lockdownEnabled");
          recordProxy.setValue(ZoneMessage.TROUBLE, "armedOpenActionMessage");
          recordProxy.setValue(ZoneMessage.ALARM, "armedShortActionMessage");
          recordProxy.setValue(ZoneMessage.NONE, "disarmedOpenActionMessage");
          recordProxy.setValue(ZoneMessage.NONE, "disarmedShortActionMessage");
          recordProxy.setValue(false, "tamperEnabled");
          if (isXr) {
            const outputNumber =
              recordProxy.getValue("fireBellOutputNumber") ??
              recordProxy.getValue("disarmedOpenOutputNumber") ??
              "000";
            recordProxy.setValue(outputNumber, "fireBellOutputNumber");
          }
        });
        break;

      case ZoneType.EMERGENCY:
        updateZoneInformation((recordProxy) => {
          recordProxy.setValue(undefined, "lockdownEnabled");
          recordProxy.setValue(ZoneMessage.TROUBLE, "armedOpenActionMessage");
          recordProxy.setValue(ZoneMessage.ALARM, "armedShortActionMessage");
          recordProxy.setValue(ZoneMessage.NONE, "disarmedOpenActionMessage");
          recordProxy.setValue(ZoneMessage.NONE, "disarmedShortActionMessage");
          if (isXr) {
            const outputNumber =
              recordProxy.getValue("disarmedOpenOutputNumber") ??
              recordProxy.getValue("fireBellOutputNumber") ??
              "000";
            recordProxy.setValue(outputNumber, "disarmedOpenOutputNumber");
          }
        });
        break;

      case ZoneType.CARBON_MONOXIDE:
        updateZoneInformation((recordProxy) => {
          recordProxy.setValue(undefined, "lockdownEnabled");
          recordProxy.setValue(ZoneMessage.TROUBLE, "armedOpenActionMessage");
          recordProxy.setValue(ZoneMessage.ALARM, "armedShortActionMessage");
          recordProxy.setValue(ZoneMessage.NONE, "disarmedOpenActionMessage");
          recordProxy.setValue(ZoneMessage.NONE, "disarmedShortActionMessage");
          recordProxy.setValue(false, "tamperEnabled");
          if (isXr) {
            const outputNumber =
              recordProxy.getValue("fireBellOutputNumber") ??
              recordProxy.getValue("disarmedOpenOutputNumber") ??
              "000";
            recordProxy.setValue(outputNumber, "fireBellOutputNumber");
          }
        });
        break;

      default:
        updateZoneInformation((recordProxy) => {
          recordProxy.setValue(undefined, "armingStyle");
          recordProxy.setValue(undefined, "lockdownEnabled");
          recordProxy.setValue(ZoneMessage.NONE, "disarmedOpenActionMessage");
          recordProxy.setValue(ZoneMessage.NONE, "disarmedShortActionMessage");
          recordProxy.setValue(ZoneMessage.TROUBLE, "armedOpenActionMessage");
          recordProxy.setValue(ZoneMessage.ALARM, "armedShortActionMessage");
        });
        break;
    }
  };

  //Ensures that we update the store at least once with the default zone type value. Otherwise, we default to what SCAPI gives us from the zone_informations/new route, which isn't always accurate.
  //isNew is always true for template zones, so we also have to check for that, otherwise we overwrite existing template values with defaults.
  useEffectOnce(() => {
    if (zoneInformation.isNew && !isSavedTemplateZone) {
      setActionsBasedOnZoneType(type);
    }
  });

  return (
    <ProgrammingConceptForm.Field fieldId={fieldId} label="Zone Type">
      <Select
        id={fieldId}
        name={fieldId}
        value={type}
        required
        disabled={isECP}
        onChange={({ target }) => {
          updateZoneInformation((recordProxy) => {
            recordProxy.setValue(target.value, "type");
          });
          if (
            ![ZoneType.NIGHT, ZoneType.EXIT, ZoneType.INSTANT].includes(
              target.value as ZoneType
            )
          ) {
            updateZoneInformation((recordProxy) => {
              recordProxy.setValue(ZoneChimeSound.OFF, "chimeSound");
              recordProxy.setValue(false, "wirelessDisarmDisableEnabled");
            });
          }
          if (
            [ZoneType.FIRE, ZoneType.FIRE_VERIFY].includes(
              target.value as ZoneType
            )
          ) {
            updateZoneInformation((recordProxy) => {
              recordProxy.setValue(ZoneNumberOfEols.ONE, "numberOfEols");
            });
          }
          if (ZoneType.TAMPER.includes(target.value as ZoneType)) {
            updateZoneInformation((recordProxy) => {
              recordProxy.setValue(ZoneChimeSound.OFF, "chimeSound");
              recordProxy.setValue(ZoneNumberOfEols.ONE, "numberOfEols");
              recordProxy.setValue(false, "crossZoneEnabled");

              recordProxy.setValue(
                ZoneOutputAction.NONE,
                "armedShortOutputAction"
              );
              recordProxy.setValue(
                ZoneOutputAction.NONE,
                "armedOpenOutputAction"
              );
              recordProxy.setValue(
                ZoneOutputAction.NONE,
                "disarmedShortOutputAction"
              );

              recordProxy.setValue(
                ZoneOutputAction.NONE,
                "disarmedOpenOutputAction"
              );
              recordProxy.setValue(ZoneMessage.NONE, " armedOpenActionMessage");
              recordProxy.setValue(ZoneMessage.NONE, "armedShortActionMessage");
              recordProxy.setValue(
                ZoneMessage.NONE,
                "disarmedOpenActionMessage"
              );
              recordProxy.setValue(
                ZoneMessage.NONE,
                "disarmedShortActionMessage"
              );

              recordProxy.setValue(ZoneMessage.NONE, "armedOpenOutput");
              recordProxy.setValue(ZoneMessage.NONE, "armedShortOutput");
              recordProxy.setValue(ZoneMessage.NONE, "disarmedOpenOutput");
              recordProxy.setValue(ZoneMessage.NONE, "disarmedShortOutput");
            });
          }
          if (
            ![
              ZoneType.FIRE,
              ZoneType.PANIC,
              ZoneType.SUPERVISORY,
              ZoneType.CARBON_MONOXIDE,
              ZoneType.AUXILIARY_1,
              ZoneType.AUXILIARY_2,
            ].includes(target.value as ZoneType)
          ) {
            updateZoneInformation((recordProxy) => {
              recordProxy.setValue(false, "retardDelayEnabled");
            });
          }
          if (
            [ZoneType.ARMING, ZoneType.DOORBELL, ZoneType.FIRE_VERIFY].includes(
              target.value as ZoneType
            )
          ) {
            updateZoneInformation((recordProxy) => {
              recordProxy.setValue(false, "crossZoneEnabled");
            });
          }
          if (
            [
              ZoneType.FIRE,
              ZoneType.FIRE_VERIFY,
              ZoneType.CARBON_MONOXIDE,
              ZoneType.SUPERVISORY,
            ].includes(target.value as ZoneType)
          ) {
            updateZoneInformation((recordProxy) => {
              const originalFireBellOutputNumber = recordProxy.getValue(
                "fireBellOutputNumber"
              );
              if (
                originalFireBellOutputNumber === "0" ||
                originalFireBellOutputNumber === "000" ||
                originalFireBellOutputNumber === ""
              ) {
                recordProxy.setValue("000", "fireBellOutputNumber");
              } else {
                recordProxy.setValue(
                  originalFireBellOutputNumber,
                  "fireBellOutputNumber"
                );
              }
            });
          }
          updateZoneInformation((recordProxy) => {
            //sets default value for swingerBypassEnabled when zone type changes
            recordProxy.setValue(
              [
                ZoneType.NIGHT,
                ZoneType.EXIT,
                ZoneType.FINAL_EXIT,
                ZoneType.DAY,
                ZoneType.BLANK,
                ZoneType.INSTANT,
              ].includes(target.value as ZoneType),
              "swingerBypassEnabled"
            );
          });
          setActionsBasedOnZoneType(target.value ?? type);
        }}
      >
        {!isXf ? (
          <Select.Option value={ZoneType.BLANK}>Blank</Select.Option>
        ) : null}
        {!isXf ? (
          <Select.Option value={ZoneType.NIGHT}>Night</Select.Option>
        ) : null}
        {!isXf ? <Select.Option value={ZoneType.DAY}>Day</Select.Option> : null}
        {!isXf ? (
          <Select.Option value={ZoneType.EXIT}>Exit</Select.Option>
        ) : null}
        {!isXf && softwareVersion >= 742 ? (
          <Select.Option value={ZoneType.FINAL_EXIT}>Final Exit</Select.Option>
        ) : null}
        <Select.Option value={ZoneType.FIRE}>Fire</Select.Option>
        {!isXf ? (
          <Select.Option value={ZoneType.PANIC}>Panic</Select.Option>
        ) : null}
        {!isXf ? (
          <Select.Option value={ZoneType.EMERGENCY}>Emergency</Select.Option>
        ) : null}
        <Select.Option value={ZoneType.SUPERVISORY}>Supervisory</Select.Option>
        {!isXf ? (
          <Select.Option value={ZoneType.AUXILIARY_1}>
            Auxiliary 1
          </Select.Option>
        ) : null}
        {!isXf ? (
          <Select.Option value={ZoneType.AUXILIARY_2}>
            Auxiliary 2
          </Select.Option>
        ) : null}
        <Select.Option value={ZoneType.FIRE_VERIFY}>Fire Verify</Select.Option>
        {!isXf ? (
          <Select.Option value={ZoneType.ARMING}>Arming</Select.Option>
        ) : null}
        {supportsCOType ? (
          <Select.Option value={ZoneType.CARBON_MONOXIDE}>
            Carbon Monoxide
          </Select.Option>
        ) : null}
        {supportsInstantType ? (
          <Select.Option value={ZoneType.INSTANT}>Instant</Select.Option>
        ) : null}
        {!isXf && softwareVersion >= 742 ? (
          <Select.Option value={ZoneType.TAMPER}>Tamper</Select.Option>
        ) : null}
        {supportsDoorbellType ? (
          <Select.Option value={ZoneType.DOORBELL}>Doorbell</Select.Option>
        ) : null}
      </Select>
    </ProgrammingConceptForm.Field>
  );
}

export default ZoneInformationTypeField;
