import graphql from "babel-plugin-relay/macro";
import Select from "components/FullProgramming/common/Select";
import { resolvePanelType } from "components/FullProgramming/utils/panel";
import * as React from "react";
import { PanelProgrammingLanguage } from "securecom-graphql/client";
import { useHardwareModel, useSoftwareVersion } from "../PanelContext";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useSystemOptionsFragment } from "./SystemOptionsContext";
import { SystemOptionsSecondaryProgrammingLanguageField_systemOptions$key } from "./__generated__/SystemOptionsSecondaryProgrammingLanguageField_systemOptions.graphql";

export const systemOptionsSecondaryProgrammingLanguageFieldId = () =>
  `system-options-secondary-programming-language`;

function SecondaryProgrammingLanguageField() {
  const [{ secondaryProgrammingLanguage }, updateSystemOptions] =
    useSystemOptionsFragment<SystemOptionsSecondaryProgrammingLanguageField_systemOptions$key>(
      graphql`
        fragment SystemOptionsSecondaryProgrammingLanguageField_systemOptions on SystemOptions {
          ... on XrSystemOptions {
            secondaryProgrammingLanguage
          }
          ... on XtSystemOptions {
            secondaryProgrammingLanguage
          }
          ... on XfSystemOptions {
            secondaryProgrammingLanguage
          }
        }
      `
    );

  const fieldId = systemOptionsSecondaryProgrammingLanguageFieldId();
  const softwareVersion = useSoftwareVersion();
  const hardwareModel = useHardwareModel();
  const { isXr, isXf } = resolvePanelType(hardwareModel);

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Secondary Programming Language"
    >
      <Select
        id={fieldId}
        name={fieldId}
        value={secondaryProgrammingLanguage ?? PanelProgrammingLanguage.ENGLISH}
        required
        onChange={({ target }) => {
          updateSystemOptions((recordProxy) => {
            recordProxy.setValue(target.value, "secondaryProgrammingLanguage");
          });
        }}
      >
        <Select.Option value={PanelProgrammingLanguage.NONE}>
          None
        </Select.Option>
        <Select.Option value={PanelProgrammingLanguage.ENGLISH}>
          English
        </Select.Option>
        <Select.Option value={PanelProgrammingLanguage.SPANISH}>
          Spanish
        </Select.Option>
        <Select.Option value={PanelProgrammingLanguage.FRENCH}>
          French
        </Select.Option>
        {((softwareVersion >= 673 && isXr) || isXf) && (
          <Select.Option value={PanelProgrammingLanguage.CZECH}>
            Czech
          </Select.Option>
        )}
        {((softwareVersion >= 673 && isXr) ||
          (softwareVersion >= 683 && !isXr) ||
          isXf) && (
          <Select.Option value={PanelProgrammingLanguage.GREEK}>
            Greek
          </Select.Option>
        )}
        {((softwareVersion >= 683 && isXr) || isXf) && (
          <Select.Option value={PanelProgrammingLanguage.DUTCH}>
            Dutch
          </Select.Option>
        )}
      </Select>
    </ProgrammingConceptForm.Field>
  );
}

export default SecondaryProgrammingLanguageField;
