import graphql from "babel-plugin-relay/macro";
import Switch from "components/FullProgramming/common/Switch";
import { range } from "ramda";
import * as React from "react";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useSystemOptionsFragment } from "./SystemOptionsContext";
import { SystemOptionsDetectWirelessJammingField_systemOptions$key } from "./__generated__/SystemOptionsDetectWirelessJammingField_systemOptions.graphql";

export const systemOptionsDetectWirelessJammingFieldId = () =>
  "system-options-detect-wireless-jamming";

function DetectWirelessJammingField() {
  const [{ detectJam, houseCode }, updateSystemOptions] =
    useSystemOptionsFragment<SystemOptionsDetectWirelessJammingField_systemOptions$key>(
      graphql`
        fragment SystemOptionsDetectWirelessJammingField_systemOptions on SystemOptions {
          ... on XrSystemOptions {
            detectJam
            houseCode
          }
          ... on XtSystemOptions {
            detectJam
            houseCode
          }
          ... on XfSystemOptions {
            detectJam
            houseCode
          }
        }
      `
    );

  const fieldId = systemOptionsDetectWirelessJammingFieldId();
  const label = "Detect Wireless Jamming";
  const houseCodeRange = range(1, 51);
  const disabled = !houseCodeRange.includes(Number(houseCode));

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label={label}
      disabled={disabled}
      tooltip="When enabled and the wireless receiver detects jamming, a jammed trouble 
      (when disarmed) or jammed alarm (when armed) message displays in the Status List and is sent to the central station receiver."
    >
      <Switch
        label={label}
        id={fieldId}
        name={fieldId}
        checked={detectJam}
        disabled={disabled}
        onChange={() => {
          updateSystemOptions((recordProxy) => {
            recordProxy.setValue(!detectJam, "detectJam");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default DetectWirelessJammingField;
