import { omit } from "ramda";

App.controller("DefaultProgrammingEditCtrl", [
  "$scope",
  "$state",
  "$rootScope",
  "$stateParams",
  "UserService",
  "DealerDefaultsService",
  "DEALER_API_DEFAULTS",
  "SimManagementService",
  "VALIDATION_PATTERNS",
  "$q",
  "$filter",
  "PanelDefinitionService",
  "ProgrammingTemplateService",
  "Panel",
  "ODataService",
  function (
    $scope,
    $state,
    $rootScope,
    $stateParams,
    UserService,
    DealerDefaultsService,
    DEALER_API_DEFAULTS,
    SimManagementService,
    VALIDATION_PATTERNS,
    $q,
    $filter,
    PanelDefinitionService,
    ProgrammingTemplateService,
    Panel,
    ODataService
  ) {
    $scope.VALIDATION_PATTERNS = VALIDATION_PATTERNS;
    $scope.checkboxTooltip = {};
    $scope.checkboxTooltip.text = "Check to Include";
    $scope.checkboxTooltip.placement = "top";
    $scope.checkboxTooltip.delay = 800;
    // deciding programming state
    if ($state.is("app.dealer.default_programming_edit")) {
      $scope.state = "edit";
      $scope.default_id = $stateParams.id;
      $scope.default_hardware_model = $stateParams.hardware_model;
    } else if ($state.is("app.dealer.default_programming_new")) {
      $scope.state = "new";
    } else {
      $scope.state = "view";
    }

    $scope.enabled = {};
    $scope.xrEnabled = {};

    $scope.stateIsEdit = function () {
      return $scope.state == "edit";
    };
    $scope.stateIsNew = function () {
      return $scope.state == "new";
    };
    $scope.editState = $scope.stateIsEdit();
    $scope.newState = $scope.stateIsNew();
    $scope.triedToSave = false;
    $scope.cellcomRatePlans = [
      {
        rate_plan: {
          value: "406",
          display: "406",
        },
      },
      {
        rate_plan: {
          value: "408",
          display: "408",
        },
      },
      {
        rate_plan: {
          value: "410",
          display: "Fire",
        },
      },
      {
        rate_plan: {
          value: "416",
          display: "416",
        },
      },
      {
        rate_plan: {
          value: "425",
          display: "425",
        },
      },
      {
        rate_plan: {
          value: "435",
          display: "435",
        },
      },
      {
        rate_plan: {
          value: "445",
          display: "445",
        },
      },
      {
        rate_plan: {
          value: "Backup",
          display: "Backup",
        },
      },
      {
        rate_plan: {
          value: "CellComSL",
          display: "CellComSL",
        },
      },
    ];

    $scope.ratePlans = [];
    $scope.Panel = {};
    $scope.defaultTemplate = {};

    let PanelDefs = {
      XR550: {
        baseModel: "XR550",
        PDS: null,
      },
      CELLCOM: {
        baseModel: "DualCom",
        PDS: null,
      },
    };

    //NOTE: This function will accept a SIM/MEID but I didn't include it because we aren't using it on the page.
    /**
     *Gathering rate and text plan information
     * @param hardwareModel
     */
    $scope.setPlans = function (hardwareModel) {
      if (
        $scope.default_prog_data.receiver_communication == "CELL" ||
        ($scope.default_prog_data.communication &&
          $scope.default_prog_data.communication.back_cell == "Y") ||
        hardwareModel == "XR550" ||
        hardwareModel == "CELLCOM"
      ) {
        if (!(hardwareModel == "XR550" || hardwareModel == "CELLCOM")) {
          $scope.enabled.rate_plan = true;
          $scope.enabled.text_plan = true;
          $scope.setValueOnCheck("rate_plan");
          $scope.setValueOnCheck("text_plan");
          $scope.default_prog_data.text_plan = "0";
        }
        if (hardwareModel == "CELLCOM") {
          $scope.ratePlans = $scope.cellcomRatePlans;
        } else {
          SimManagementService.getRatePlans(
            null,
            hardwareModel,
            UserService.dealerInfo.id
          )
            .then(
              function (data) {
                // success
                $scope.ratePlans = cleanupBackupPlans(data);
              },
              function (error) {
                $rootScope.alerts.push({
                  type: "error",
                  text: "Rate plans",
                  json: error,
                });
              }
            )
            .catch(function (error) {
              console.error(error);
            });
        }
      }
    };
    //filter out backup plans if 'N' present
    function cleanupBackupPlans(plans) {
      if (
        $scope.default_prog_data.communication &&
        $scope.default_prog_data.communication.back_cell == "N"
      ) {
        return $filter("filter")(
          plans,
          function (plan) {
            return plan.rate_plan.value != "Backup";
          },
          true
        );
      }
      return plans;
    }
    // if XTLP, receiver communication must be WIFI
    $scope.changeHardwareModel = function (hardwareModel) {
      //this forces the rate_plan to undefined so that special plans like XT or XR won't be selected for the wrong panel type.
      if ($scope.state !== "edit")
        $scope.default_prog_data.rate_plan = undefined;

      switch (hardwareModel) {
        case "XTLP":
          $scope.setPlans(hardwareModel);
          $scope.default_prog_data.receiver_communication = "WIFI";
          break;
        case "XR550":
          $scope.setPlans(hardwareModel);
          $scope.concepts = [
            "communication_paths",
            "system_options",
            "remote_options",
            "system_reports",
            "bell_options",
            "output_options",
            "status_list",
            "lockout_code",
          ];
          $scope.Panel = new Panel(
            null,
            PanelDefs[hardwareModel].baseModel,
            PanelDefs[hardwareModel].PDS.firmwareVersion,
            $scope.concepts,
            PanelDefs[hardwareModel].PDS
          );
          $scope.Panel.communication_paths = [
            {
              number: "01",
              isOpen: true,
            },
            { number: "02" },
            { number: "03" },
            { number: "04" },
            { number: "05" },
            { number: "06" },
            { number: "07" },
            { number: "08" },
          ];
          $scope.Panel.communication_paths.isArray = true;
          break;
        case "CELLCOM":
          $scope.setPlans(hardwareModel);
          $scope.concepts = [
            "communication",
            "remote_options",
            "system_reports",
            "system_options",
            "output_options",
            "lockout_code",
          ];
          $scope.Panel = new Panel(
            null,
            PanelDefs[hardwareModel].baseModel,
            PanelDefs[hardwareModel].PDS.firmwareVersion,
            $scope.concepts,
            PanelDefs[hardwareModel].PDS
          );
          break;
        default:
          $scope.setPlans(hardwareModel);
      }
    };

    $scope.shownFullConcept = function (concept) {
      return $scope.concepts.includes(concept.key);
    };

    $scope.omitAccountNumber = function (field) {
      return field.$key === "acct_num";
    };

    $scope.omitConceptDisplay = function (field) {
      return field.$key === "DISPLAY";
    };

    $scope.omitArmingModeCellCom = function (field) {
      if ($scope.Panel.panel_model === "DualCom") {
        return field.$key === "arm_mode";
      }
    };
    // set chkin min and fail time based on communication type
    $scope.changeCommunication = function (hardwareModel, commType) {
      if (commType == "DD" || commType == "CID") {
        $scope.default_prog_data.communication.chkin_min = null;
        $scope.default_prog_data.communication.fail_time = null;
      }
      if (commType == "CELL") {
        $scope.default_prog_data.communication.back_cell = "N";
        $scope.default_prog_data.communication.chkin_min = "000";
        $scope.default_prog_data.communication.fail_time = "000";
      }
      if (commType == "NET" || commType == "WIFI") {
        $scope.default_prog_data.communication.chkin_min =
          $scope.default_prog_data.communication.chkin_min == null ||
          $scope.default_prog_data.communication.chkin_min == "000"
            ? "200"
            : $scope.default_prog_data.communication.chkin_min;
        $scope.default_prog_data.communication.fail_time =
          $scope.default_prog_data.communication.fail_time == null ||
          $scope.default_prog_data.communication.fail_time == "000"
            ? "240"
            : $scope.default_prog_data.communication.fail_time;
      }
      // Null the rate_plan and text_plan if it's not cell, and not backup_cell (we're hiding the fields on the UI, but we need to null also)
      if (
        (commType == "DD" ||
          commType == "CID" ||
          commType == "NET" ||
          commType == "WIFI") &&
        $scope.default_prog_data.communication.back_cell == "N"
      ) {
        $scope.default_prog_data.rate_plan = null;
        $scope.default_prog_data.text_plan = null;
      }
      $scope.setPlans(hardwareModel);
    };
    // If backupCell is 'N', rate and text plans are NULL
    $scope.changeBackupCell = function (hardwareModel, backupCell) {
      // Null the rate_plan and text_plan if it's not cell, and not backup_cell (we're hiding the fields on the UI, but we need to null also)
      if (backupCell == "N") {
        $scope.default_prog_data.rate_plan = null;
        $scope.default_prog_data.text_plan = null;
      } else {
        $scope.setPlans(hardwareModel);
      }
    };

    init();

    function init() {
      $scope.isBusy = true;
      // getXRDefaultData();
      initPanelDefs().then(function () {
        getNewXRTemplate();
        getDealerCustomers();
        DealerDefaultsService.newDealerDefaults(
          UserService.dealer_id,
          DEALER_API_DEFAULTS.XT_DEFAULTS
        )
          .then(function (data) {
            $scope.new_defaults = data; // used in set value on check as a default null value
            DealerDefaultsService.getAllDefaults(
              UserService.dealer_id,
              DEALER_API_DEFAULTS.XT_DEFAULTS
            )
              .then(
                function (data) {
                  ProgrammingTemplateService.getProgTpls(
                    UserService.dealer_id,
                    DEALER_API_DEFAULTS.XR_DEFAULTS
                  ).then(
                    function (xrDefaults) {
                      $scope.allTheDefaults = [...data, ...xrDefaults];
                      if ($scope.editState) {
                        for (var i = 0; i < $scope.allTheDefaults.length; i++) {
                          if (
                            $scope.default_id == $scope.allTheDefaults[i].Id &&
                            $scope.default_hardware_model ==
                              $scope.allTheDefaults[i].hardware_model
                          ) {
                            $scope.default_prog_data = $scope.allTheDefaults[i];
                            i = $scope.allTheDefaults.length;
                          }
                          //$scope.setReceiver2Backup();
                        }
                      } else {
                        $scope.default_prog_data = $scope.new_defaults;
                      }
                      if (
                        $scope.default_hardware_model !== "XR550" &&
                        $scope.default_hardware_model !== "CELLCOM"
                      ) {
                        $scope.default_prog_data.customer = {};
                        $scope.default_prog_data.customer.id =
                          $scope.default_prog_data.customer_id;
                        $scope.default_prog_data.customer.name =
                          $scope.default_prog_data.customer_name;
                        if ($scope.default_prog_data.customer.id === 0)
                          $scope.clearMyValue($scope.default_prog_data);
                        initializeEnabledChecks()
                          .then(function () {
                            $scope.setPlans(
                              $scope.default_prog_data.hardware_model
                            );
                            $scope.isBusy = false;
                          })
                          .catch(function (error) {
                            console.error(error);
                          });
                      } else {
                        descrambleTemplateData($scope.default_prog_data);
                      }
                    },
                    function () {
                      // deferred.reject(error);
                    }
                  );
                },
                function (error) {
                  $rootScope.alerts.push({
                    type: "error",
                    json: error,
                  });
                }
              )
              .catch(function (error) {
                console.error(error);
              });
            $scope.isBusy = false;
          })
          .catch(function (error) {
            console.error(error);
          });
      });
    }
    //this makes sure the minimum information is checked for communications paths in a template
    $scope.commPathCheck = function (field, isChecked, pathNumber) {
      var arrayNumber = pathNumber - 1;
      if (isChecked) {
        if (field == "comm_type") {
          $scope.xrEnabled.Panel.communication_paths[
            pathNumber
          ].path_type = true;
          $scope.Panel.communication_paths[arrayNumber].path_type = "P";
        } else if (field == "path_type") {
          $scope.xrEnabled.Panel.communication_paths[
            pathNumber
          ].comm_type = true;
        }
      } else {
        $scope.xrEnabled.Panel.communication_paths[pathNumber] = {};
        $scope.Panel.communication_paths[arrayNumber] = {
          number: pathNumber,
          isOpen: "true",
        };
      }
    };

    $scope.deleteOrInitiallizeTemplateProgrammingValue = function (
      conceptObject,
      field,
      defaultValue,
      intitialize,
      number
    ) {
      if (number) {
        number = number - 1; //to have com path number match with array item.
        if (intitialize) {
          if (!$scope.Panel[conceptObject]) {
            $scope.Panel[conceptObject] = {};
          }
          if (!$scope.Panel[conceptObject][number]) {
            $scope.Panel[conceptObject][number] = {};
          }

          $scope.Panel[conceptObject][number][field] = defaultValue;
        } else {
          delete $scope.Panel[conceptObject][number][field];
        }
      } else {
        if (intitialize) {
          if (!$scope.Panel[conceptObject]) {
            $scope.Panel[conceptObject] = {};
          }
          $scope.Panel[conceptObject][field] = defaultValue;
        } else {
          delete $scope.Panel[conceptObject][field];
        }
      }
    };

    $scope.searchDealerCustomerList = function (search) {
      if (!search || search.length < 2) return;
      let searchedCustomers = $scope.dealerCustomers.slice();
      if (search && searchedCustomers.indexOf(search) === -1)
        searchedCustomers.unshift(search);
      return searchedCustomers;
    };

    $scope.xrAndCellComCommType = function (panelType) {
      var cellComPath = undefined;
      if (panelType === "XR550") {
        for (let com in $scope.Panel.communication_paths) {
          if ($scope.Panel.communication_paths[com].comm_type === "L") {
            cellComPath =
              $scope.Panel.communication_paths[com].comm_type === "L";
          }
        }
      } else if (panelType === "CELLCOM" && $scope.Panel.communication) {
        cellComPath =
          $scope.Panel.communication.com_type === "6" ||
          $scope.Panel.communication.back_cell === "Y";
      }
      return cellComPath;
    };

    function getDealerCustomers() {
      ODataService.getCustomersForTemplateCreation(
        UserService.dealer_id,
        "name,id",
        ""
      ).then(
        function (customers) {
          $scope.dealerCustomers = customers;
        },
        function () {
          $rootScope.alerts.push({
            type: "error",
            text: "Unable to get Customer List",
          });
        }
      );
    }

    function getNewXRTemplate() {
      ProgrammingTemplateService.newProgTpl(
        UserService.dealer_id,
        DEALER_API_DEFAULTS.XR_DEFAULTS
      )
        .then(
          function (defaultTemplate) {
            $scope.defaultTemplate = defaultTemplate;
          },
          function (error) {
            $rootScope.alerts.push({
              type: "error",
              json: error,
            });
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    }

    function descrambleTemplateData(xrDefaultToEdit) {
      $scope.changeHardwareModel(xrDefaultToEdit.hardware_model);
      $scope.default_prog_data.customer_name = xrDefaultToEdit.customer_name;
      $scope.default_prog_data.customer_id = xrDefaultToEdit.customer_id;
      $scope.default_prog_data.customer = {};
      $scope.default_prog_data.customer.id = xrDefaultToEdit.customer_id;
      $scope.default_prog_data.customer.name = xrDefaultToEdit.customer_name;

      if ($scope.default_prog_data.customer.id === 0)
        $scope.clearMyValue($scope.default_prog_data);
      $scope.default_prog_data.receiver_communication =
        xrDefaultToEdit.receiver_communication;
      let templateData = angular.fromJson(xrDefaultToEdit.template_data);
      $scope.Panel = ProgrammingTemplateService.applyTplData(
        $scope.Panel,
        templateData
      );
      $scope.xrEnabled = templateData.enabled;
    }

    /**
     * Set the initial values for the checkboxes that control enable/disable for each default
     */
    function initializeEnabledChecks() {
      var deferred = $q.defer();
      angular.forEach(
        $scope.default_prog_data,
        function (concept, conceptName) {
          if (
            typeof $scope.default_prog_data[conceptName] === "object" &&
            $scope.default_prog_data[conceptName] != undefined
          ) {
            //added undefined for empty root attributes (like rate_plan and text_plan)
            $scope.enabled[conceptName] = {};
            angular.forEach(
              $scope.default_prog_data[conceptName],
              function (value, key) {
                $scope.enabled[conceptName][key] = value == null ? false : true;
              }
            );
          } else {
            $scope.enabled[conceptName] = concept == null ? false : true;
          }
        }
      );
      deferred.resolve();
      return deferred.promise;
    }

    /**
     * Set the value of the referenced fieldName to either null, or the default value
     * @param fieldName
     */
    $scope.setValueOnCheck = function (fieldName) {
      var parts = fieldName.split(".");
      if (parts.length > 1) {
        var defaultEnabled = $scope.enabled[parts[0]][parts[1]];
        var defaultValue = $scope.new_defaults[parts[0]][parts[1]];
        $scope.default_prog_data[parts[0]][parts[1]] = defaultEnabled
          ? defaultValue
          : null; //if using a checkbox, this should be "(!defaultEnabled) ? defaultValue : null"

        //This block is to remove the has-error class conditional if the checkbox is unchecked (we set it untouched on uncheck, then set untouched again on re-check
        if ($scope.DefaultProgrammingForm.communicationDefaultForm[parts[1]])
          $scope.DefaultProgrammingForm.communicationDefaultForm[
            parts[1]
          ].$setUntouched();
        if (
          $scope.DefaultProgrammingForm.communicationDefaultForm
            .receiver1DefaultForm[parts[1]]
        )
          $scope.DefaultProgrammingForm.communicationDefaultForm.receiver1DefaultForm[
            parts[1]
          ].$setUntouched();
        if (
          $scope.DefaultProgrammingForm.communicationDefaultForm
            .receiver2DefaultForm[parts[1]]
        )
          $scope.DefaultProgrammingForm.communicationDefaultForm.receiver2DefaultForm[
            parts[1]
          ].$setUntouched();
        if ($scope.DefaultProgrammingForm.remoteOptsDefaultForm[parts[1]])
          $scope.DefaultProgrammingForm.remoteOptsDefaultForm[
            parts[1]
          ].$setUntouched();
        if ($scope.DefaultProgrammingForm.systemOptsDefaultForm[parts[1]])
          $scope.DefaultProgrammingForm.systemOptsDefaultForm[
            parts[1]
          ].$setUntouched();
        if ($scope.DefaultProgrammingForm.systemRptDefaultForm[parts[1]])
          $scope.DefaultProgrammingForm.systemRptDefaultForm[
            parts[1]
          ].$setUntouched();
      } else {
        defaultEnabled = $scope.enabled[parts[0]];
        defaultValue =
          $scope.default_prog_data[parts[0]] != null
            ? $scope.default_prog_data[parts[0]]
            : $scope.new_defaults[parts[0]];
        $scope.default_prog_data[parts[0]] = defaultEnabled
          ? defaultValue
          : null;
        if (
          $scope.DefaultProgrammingForm.communicationDefaultForm &&
          $scope.DefaultProgrammingForm.communicationDefaultForm[parts[0]]
        )
          $scope.DefaultProgrammingForm.communicationDefaultForm[
            parts[0]
          ].$setUntouched();
      }
    };

    $scope.setReceiver2Backup = function () {
      if ($scope.default_prog_data.communication.rec2_bkup === "Y") {
        $scope.default_prog_data.communication.alarms2 = "N";
        $scope.enabled.communication.alarms2 = false;
        $scope.default_prog_data.communication.supv_trbl2 = "N";
        $scope.enabled.communication.supv_trbl2 = false;
        $scope.default_prog_data.communication.o_c_user2 = "N";
        $scope.enabled.communication.o_c_user2 = false;
      }
    };

    /**
     * The following code displays what is enabled, as well as errors for field,
     * into the console.
     */
    $scope.validateDefault = function () {
      var deferred = $q.defer();

      var formErrors = [];
      $scope.defaultFormInvalid = false;
      $scope.genFormInvalid = false;
      $scope.commFormInvalid = false;
      $scope.rec1FormInvalid = false;
      $scope.rec2FormInvalid = false;
      $scope.rOptsFormInvalid = false;
      $scope.sOptsFormInvalid = false;
      $scope.sRptsFormInvalid = false;

      //Doesn't need the extra ifs b/c the inputs are always enabled
      angular.forEach(
        $scope.DefaultProgrammingForm.generalDefaultForm.$error,
        function (errorType) {
          angular.forEach(errorType, function (field) {
            if (field.$name != "name") {
              formErrors.push(field);
              field.$setTouched();
              $scope.defaultFormInvalid = true;
              $scope.genFormInvalid = true;
            }
          });
        }
      );
      if (
        $scope.default_prog_data.hardware_model !== "XR550" &&
        $scope.default_prog_data.hardware_model !== "CELLCOM"
      ) {
        angular.forEach(
          $scope.DefaultProgrammingForm.communicationDefaultForm.$error,
          function (errorType) {
            angular.forEach(errorType, function (field) {
              if ($scope.enabled.communication[field.$name]) {
                if (field.$invalid) {
                  formErrors.push(field);
                  field.$setTouched();
                  $scope.commFormInvalid = true;
                  $scope.defaultFormInvalid = true;
                }
              } else {
                if (
                  field.$invalid &&
                  (field.$name == "rate_plan" || field.$name == "text_plan")
                ) {
                  formErrors.push(field);
                  field.$setTouched();
                  $scope.commFormInvalid = true;
                  $scope.defaultFormInvalid = true;
                }
              }
            });
          }
        );
        angular.forEach(
          $scope.DefaultProgrammingForm.communicationDefaultForm
            .receiver1DefaultForm.$error,
          function (errorType) {
            angular.forEach(errorType, function (field) {
              if ($scope.enabled.communication[field.$name]) {
                if (field.$invalid) {
                  formErrors.push(field);
                  field.$setTouched();
                  $scope.rec1FormInvalid = true;
                  $scope.defaultFormInvalid = true;
                }
              }
            });
          }
        );
        angular.forEach(
          $scope.DefaultProgrammingForm.communicationDefaultForm
            .receiver2DefaultForm.$error,
          function (errorType) {
            angular.forEach(errorType, function (field) {
              if ($scope.enabled.communication[field.$name]) {
                if (field.$invalid) {
                  formErrors.push(field);
                  field.$setTouched();
                  $scope.rec2FormInvalid = true;
                  $scope.defaultFormInvalid = true;
                }
              }
            });
          }
        );
        angular.forEach(
          $scope.DefaultProgrammingForm.remoteOptsDefaultForm.$error,
          function (errorType) {
            angular.forEach(errorType, function (field) {
              if ($scope.enabled.remote_options[field.$name]) {
                if (field.$invalid) {
                  formErrors.push(field);
                  field.$setTouched();
                  $scope.rOptsFormInvalid = true;
                  $scope.defaultFormInvalid = true;
                }
              }
            });
          }
        );
        angular.forEach(
          $scope.DefaultProgrammingForm.systemOptsDefaultForm.$error,
          function (errorType) {
            angular.forEach(errorType, function (field) {
              if ($scope.enabled.system_options[field.$name]) {
                if (field.$invalid) {
                  formErrors.push(field);
                  field.$setTouched();
                  $scope.sOptsFormInvalid = true;
                  $scope.defaultFormInvalid = true;
                }
              }
            });
          }
        );
        angular.forEach(
          $scope.DefaultProgrammingForm.systemRptDefaultForm.$error,
          function (errorType) {
            angular.forEach(errorType, function (field) {
              if ($scope.enabled.system_reports[field.$name]) {
                if (field.$invalid) {
                  formErrors.push(field);
                  field.$setTouched();
                  $scope.sRptsFormInvalid = true;
                  $scope.defaultFormInvalid = true;
                }
              }
            });
          }
        );
      }

      if (formErrors.length > 0) {
        deferred.reject(formErrors);
      } else {
        deferred.resolve();
      }

      return deferred.promise;
    };

    const omitHouseCodeAndUse1100 = (defaultData) => {
      return {
        ...defaultData,
        system_options: omit(
          ["house_code", "use_1100"],
          defaultData.system_options
        ),
      };
    };

    const getZeroPaddedNum = (num, padLen) => {
      // Hopefully future-proofing for a rewrite, attempt to isolate the AngularJS specific code
      return $filter("zpad")(num, padLen);
    };

    const setupHouseCodeAndUse1100 = (defaultData) => {
      if (defaultData.system_options.use_1100) {
        return {
          ...defaultData,
          system_options: {
            ...defaultData.system_options,
            house_code: getZeroPaddedNum(Math.floor(Math.random() * 50) + 1, 3), // in order to allow a wireless device (1100T), we need to randomly generate a house code.
          },
        };
      } else {
        return omitHouseCodeAndUse1100(defaultData);
      }
    };

    const modifiedXTDefaults = (defaultData) => {
      //we need to remove or modify certain system_options to make them play well with automatially applied templates
      switch (defaultData.hardware_model) {
        case "XT30":
          return omitHouseCodeAndUse1100(defaultData);
        case "XT50":
          return setupHouseCodeAndUse1100(defaultData);
        default:
          return defaultData;
      }
    };

    // Displays save status as well as saves defaults
    $scope.saveDefault = function () {
      const correctDefaults = modifiedXTDefaults($scope.default_prog_data);
      $scope.triedToSave = true;
      if ($scope.default_prog_data.customer) {
        $scope.default_prog_data.customer_id =
          $scope.default_prog_data.customer.id;
        $scope.default_prog_data.customer_name =
          $scope.default_prog_data.customer.name;
      } else {
        $scope.default_prog_data.customer_id = 0;
        $scope.default_prog_data.customer_name = "";
      }
      if ($scope.stateIsNew()) {
        DealerDefaultsService.createDealerDefaults(
          UserService.dealer_id,
          DEALER_API_DEFAULTS.XT_DEFAULTS,
          correctDefaults
        )
          .then(
            function () {
              $rootScope.alerts.push({
                type: "success",
                text: "Saved!",
              });
              $state.go(
                "app.dealer.default_programming",
                {
                  dealer_id: UserService.dealer_id,
                },
                {
                  reload: true,
                }
              );
            },
            function (error) {
              $rootScope.alerts.push({
                type: "error",
                json: error,
              });
            }
          )
          .catch(function (error) {
            console.error(error);
          });
      } else {
        DealerDefaultsService.updateDealerDefaults(
          UserService.dealer_id,
          DEALER_API_DEFAULTS.XT_DEFAULTS,
          correctDefaults
        )
          .then(
            function () {
              $rootScope.alerts.push({
                type: "success",
                text: "Saved!",
              });
              $state.go(
                "app.dealer.default_programming",
                {
                  dealer_id: UserService.dealer_id,
                },
                {
                  reload: true,
                }
              );
            },
            function (error) {
              $rootScope.alerts.push({
                type: "error",
                json: error,
              });
            }
          )
          .catch(function (error) {
            console.error(error);
          });
      }
    };

    function saveXRDefaults() {
      if ($scope.stateIsNew()) {
        $scope.defaultTemplate.name = $scope.default_prog_data.name;
        $scope.defaultTemplate.hardware_model =
          $scope.default_prog_data.hardware_model;
        // $scope.defaultTemplate.receiver_communication = $scope.default_prog_data.receiver_communication;
        if ($scope.default_prog_data.customer) {
          $scope.defaultTemplate.customer_id =
            $scope.default_prog_data.customer.id;
          $scope.defaultTemplate.customer_name =
            $scope.default_prog_data.customer.name;
        }
        if ($scope.default_prog_data.rate_plan)
          $scope.defaultTemplate.rate_plan = $scope.default_prog_data.rate_plan;
        ProgrammingTemplateService.createProgTpl(
          $scope.Panel,
          $scope.xrEnabled,
          $scope.defaultTemplate,
          DEALER_API_DEFAULTS.XR_DEFAULTS
        ).then(
          function (success) {
            // send the updated template back
            $rootScope.alerts.push({
              type: "success",
              text: "Saved!",
            });
            $state.go(
              "app.dealer.default_programming",
              {
                dealer_id: UserService.dealer_id,
              },
              {
                reload: true,
              }
            );
          },
          function (error) {
            $rootScope.alerts.push({
              type: "error",
              json: error,
            });
          }
        );
      } else {
        if ($scope.default_prog_data.customer) {
          $scope.default_prog_data.customer_id =
            $scope.default_prog_data.customer.id;
          $scope.default_prog_data.customer_name =
            $scope.default_prog_data.customer.name;
        } else {
          $scope.default_prog_data.customer_id = 0;
          $scope.default_prog_data.customer_name = "";
        }
        if ($scope.default_prog_data.rate_plan)
          $scope.default_prog_data.rate_plan =
            $scope.default_prog_data.rate_plan;
        ProgrammingTemplateService.updateProgTpl(
          UserService.dealer_id,
          $scope.Panel,
          $scope.xrEnabled,
          $scope.default_prog_data,
          DEALER_API_DEFAULTS.XR_DEFAULTS
        ).then(
          function (yes) {
            $rootScope.alerts.push({
              type: "success",
              text: "Saved!",
            });
            $state.go(
              "app.dealer.default_programming",
              {
                dealer_id: UserService.dealer_id,
              },
              {
                reload: true,
              }
            );
          },
          function (no) {
            $rootScope.alerts.push({
              type: "error",
              json: error,
            });
          }
        );
      }
    }

    $scope.clickTheSaveDefaultButton = function () {
      $scope.triedToSave = true;
      $scope
        .validateDefault()
        .then(
          function (data) {
            ["XR550", "CELLCOM"].includes(
              $scope.default_prog_data.hardware_model
            )
              ? saveXRDefaults()
              : $scope.saveDefault();
          },
          function (errors) {
            $rootScope.alerts.push({
              type: "error",
              text: "Invalid form entries",
            });
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    };

    function initPanelDefs() {
      var deferred = $q.defer();
      var promises = [];
      angular.forEach(Object.keys(PanelDefs), function (series) {
        promises.push(initPanelDef(series, false));
      });
      $q.all(promises).then(function () {
        deferred.resolve();
      });
      return deferred.promise;
    }

    function initPanelDef(series, international) {
      var deferred = $q.defer();
      PanelDefinitionService.generateHighestVersion(
        PanelDefs[series].baseModel,
        international
      )
        .then(
          function (data) {
            PanelDefs[series].PDS = data;
            if (data.hardwareProperties.Model !== PanelDefs[series].baseModel) {
              console.warn(
                `PanelDefs[${series}].baseModel (${PanelDefs[series].baseModel}) does not match compiled panel def model (${data.hardwareProperties.Model})`
              );
              $rootScope.alerts.push({
                type: "error",
                text: `error generating ${series} panel definition`,
              });
            }
            deferred.resolve();
          },
          function (error) {
            console.error(
              `error generating ${series} panel definition service: ${angular.toJson(
                error
              )}`
            );
            $rootScope.alerts.push({
              type: "error",
              text: `error generating ${series} panel definition`,
            });
            deferred.reject();
          }
        )
        .catch(function (error) {
          console.error(`Caught error: ${error}`);
        });
      return deferred.promise;
    }

    $scope.clearMyValue = function (value) {
      delete value.customer;
    };
  },
]);
