import graphql from "babel-plugin-relay/macro";
import { isNotNullOrUndefined } from "common/utils/universal/function";
import { emptyStringOrNumber } from "components/FullProgramming/utils/format";
import { clamp } from "ramda";
import * as React from "react";
import { useRelayEnvironment } from "react-relay";
import {
  Device734,
  DeviceInformation,
  DeviceInformationType,
} from "securecom-graphql/client";
import NumericInput from "../../NumericInput";
import ProgrammingConceptForm from "../../ProgrammingConceptForm";
import { useDeviceInformationFragment } from "../DeviceInformationContext";
import { DeviceInformationZone3RequestToExitTimeField_deviceInformation$key } from "./__generated__/DeviceInformationZone3RequestToExitTimeField_deviceInformation.graphql";

export const deviceInformationZone3RequestToExitTimeFieldId = (
  number: string
) => `device-information-zone3-request-to-exit-time-${number}`;

function DeviceInformationZone3RequestToExitTimeField() {
  const [{ id, number, device734, deviceType, remoteProgram734 }] =
    useDeviceInformationFragment<DeviceInformationZone3RequestToExitTimeField_deviceInformation$key>(
      graphql`
        fragment DeviceInformationZone3RequestToExitTimeField_deviceInformation on DeviceInformation {
          id
          number
          ... on XrDeviceInformation {
            remoteProgram734
            deviceType
            device734 {
              ... on Device734 {
                zone3RequestToExitTime
                enableZone3RequestToExit
              }
            }
          }
        }
      `
    );

  const fieldId = deviceInformationZone3RequestToExitTimeFieldId(
    String(number)
  );
  const relayEnv = useRelayEnvironment();
  const { current: originalValue } = React.useRef(
    device734?.zone3RequestToExitTime
  );
  const disabled =
    !device734?.enableZone3RequestToExit ||
    deviceType !== DeviceInformationType.DOOR ||
    !remoteProgram734;

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Zone 3 REX Strike Time"
      tooltip="Seconds before the Zone 3 Request to Exit timer expires. Upon expiration, the door is locked (or the bypass expires if using the bypass-only option on the 734)."
      disabled={disabled}
    >
      <NumericInput
        id={fieldId}
        pattern="(0{0,2}[5-9]|0?[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|250)"
        value={device734?.zone3RequestToExitTime ?? 5}
        validationMessage="Valid values are 5-250."
        inlineHelp="5-250"
        min={5}
        max={250}
        disabled={disabled}
        required
        onChange={({ target }) => {
          relayEnv.commitUpdate((store) => {
            const device734Options = store
              .get<DeviceInformation>(id)
              ?.getLinkedRecord<Device734>("device734");

            if (device734Options && isNotNullOrUndefined(target.value)) {
              device734Options.setValue(
                emptyStringOrNumber(target.value),
                "zone3RequestToExitTime"
              );
            }
          });
        }}
        onBlur={({ target }) => {
          relayEnv.commitUpdate((store) => {
            const device734Options = store
              .get<DeviceInformation>(id)
              ?.getLinkedRecord<Device734>("device734");

            if (device734Options && isNotNullOrUndefined(target.value)) {
              const valueAsNumber = Number(target.value);
              const value = isNaN(valueAsNumber)
                ? originalValue
                : clamp(5, 250, valueAsNumber);
              device734Options.setValue(
                Number(value),
                "zone3RequestToExitTime"
              );
            }
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default DeviceInformationZone3RequestToExitTimeField;
