import graphql from "babel-plugin-relay/macro";
import Switch from "components/FullProgramming/common/Switch";
import * as React from "react";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useCommPathFragment } from "./CommPathContext";
import { CommPathEncryptionField_communicationPath$key } from "./__generated__/CommPathEncryptionField_communicationPath.graphql";

export const commPathEncryptionFieldId = (number: string) =>
  `comm-path-encryption-${number}`;

function CommPathEncryptionField() {
  const [{ id, number, encryptionEnabled, commType }, updateCommPath] =
    useCommPathFragment<CommPathEncryptionField_communicationPath$key>(
      graphql`
        fragment CommPathEncryptionField_communicationPath on CommunicationPath {
          id
          number
          encryptionEnabled
          commType
        }
      `
    );

  const fieldId = commPathEncryptionFieldId(String(number));
  const disabled = !(commType === "NETWORK" || commType === "WIFI");
  const label = "Encryption";

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label={label}
      tooltip="Enable to use IPv6 in communications with the Receiver"
      disabled={disabled}
    >
      <Switch
        label={label}
        id={fieldId}
        name={id}
        checked={encryptionEnabled}
        disabled={disabled}
        onChange={() => {
          updateCommPath((recordProxy) => {
            recordProxy.setValue(!encryptionEnabled, "encryptionEnabled");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default CommPathEncryptionField;
