import graphql from "babel-plugin-relay/macro";
import { closest } from "common/utils";
import TextInput from "components/FullProgramming/common/TextInput";
import {
  panelVersionGTOE,
  resolvePanelType,
} from "components/FullProgramming/utils/panel";
import { range } from "ramda";
import * as React from "react";
import { useHardwareModel, useSoftwareVersion } from "../PanelContext";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useOutputOptionsFragment } from "./OutputOptionsContext";
import { OutputOptionsHeatSaverTemperatureField_outputOptions$key } from "./__generated__/OutputOptionsHeatSaverTemperatureField_outputOptions.graphql";

export const outputOptionsHeatSaverTemperatureFieldId = () =>
  "output-options-heat-saver-temperature";

function OutputOptionsHeatSaverTemperatureField() {
  const [{ heatSaverTemperature }, updateOutputOptions] =
    useOutputOptionsFragment<OutputOptionsHeatSaverTemperatureField_outputOptions$key>(
      graphql`
        fragment OutputOptionsHeatSaverTemperatureField_outputOptions on OutputOptions {
          heatSaverTemperature
        }
      `
    );

  const fieldId = outputOptionsHeatSaverTemperatureFieldId();
  const softwareVersion = useSoftwareVersion();
  const hardwareModel = useHardwareModel();
  const { isXr } = resolvePanelType(hardwareModel);
  const isVersionGreaterThan192 = panelVersionGTOE(192, softwareVersion);

  const validNumericalRange = isVersionGreaterThan192
    ? range(0, 100)
    : range(55, 96);

  const pattern = isVersionGreaterThan192
    ? "(--|[0-9]{1,2})"
    : "(--|0{0,1}0|5[5-9]|[6-8][0-9]|9[0-5])";

  const inlineHelp = isVersionGreaterThan192
    ? `${isXr ? "" : "--, "}0-99`
    : `${isXr ? "" : "--, "}0, 55-95`;

  const validationMessage = isVersionGreaterThan192
    ? `Valid values are ${isXr ? "" : "--, "}0-99.`
    : `Valid values are ${isXr ? "" : "--, "}0, 55-95.`;

  const { current: originalValue } = React.useRef(heatSaverTemperature);

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Heat Saver Temperature"
      tooltip="The System sends this temperature (F) to all Z-Wave thermostats when the System is Armed All or Away. Returns to previous temperature when Disarmed."
    >
      <TextInput
        id={fieldId}
        value={heatSaverTemperature}
        required
        pattern={pattern}
        inlineHelp={inlineHelp}
        validationMessage={validationMessage}
        onChange={({ target }) => {
          updateOutputOptions((recordProxy) => {
            recordProxy.setValue(target.value, "heatSaverTemperature");
          });
        }}
        onBlur={({ target }) => {
          updateOutputOptions((recordProxy) => {
            const valueAsNumber = Number(target.value);
            const value =
              !target.value || isNaN(valueAsNumber)
                ? originalValue
                : closest(valueAsNumber, validNumericalRange);
            recordProxy.setValue(
              value.toString().padStart(2, "0"),
              "heatSaverTemperature"
            );
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default OutputOptionsHeatSaverTemperatureField;
