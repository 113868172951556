/**
 * @generated SignedSource<<bd86c3f2f212b1879bd38dee654a81f5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type DeviceInformationType = "CAMERA" | "DOOR" | "FIRE" | "KEYPAD" | "NONE" | "VPLEX" | "XR550" | "ZONE_EXPANDER" | "_1100T";
import { FragmentRefs } from "relay-runtime";
export type DeviceInformationRelockOnZone2ChangeField_deviceInformation$data = {
  readonly device734?: {
    readonly zone2RelockOnStateChange: boolean | null;
  } | null;
  readonly deviceType?: DeviceInformationType;
  readonly id: string;
  readonly number: number;
  readonly remoteProgram734?: boolean;
  readonly " $fragmentType": "DeviceInformationRelockOnZone2ChangeField_deviceInformation";
};
export type DeviceInformationRelockOnZone2ChangeField_deviceInformation$key = {
  readonly " $data"?: DeviceInformationRelockOnZone2ChangeField_deviceInformation$data;
  readonly " $fragmentSpreads": FragmentRefs<"DeviceInformationRelockOnZone2ChangeField_deviceInformation">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DeviceInformationRelockOnZone2ChangeField_deviceInformation",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "number",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "remoteProgram734",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "deviceType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Device734",
          "kind": "LinkedField",
          "name": "device734",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "zone2RelockOnStateChange",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "XrDeviceInformation",
      "abstractKey": null
    }
  ],
  "type": "DeviceInformation",
  "abstractKey": "__isDeviceInformation"
};

(node as any).hash = "3286b49f839e5d5c3f13175905d28f9a";

export default node;
