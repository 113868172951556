import graphql from "babel-plugin-relay/macro";
import Select from "components/FullProgramming/common/Select";
import * as React from "react";
import { InspectionReminder } from "securecom-graphql/client";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import Switch from "../Switch";
import { useSystemOptionsFragment } from "./SystemOptionsContext";
import { SystemOptionsInspectionReminderField_systemOptions$key } from "./__generated__/SystemOptionsInspectionReminderField_systemOptions.graphql";

export const systemOptionsInspectionReminderFieldId = () =>
  "system-options-inspection-reminder";

function InspectionReminderField() {
  const [{ inspectionReminder }, updateSystemOptions] =
    useSystemOptionsFragment<SystemOptionsInspectionReminderField_systemOptions$key>(
      graphql`
        fragment SystemOptionsInspectionReminderField_systemOptions on SystemOptions {
          ... on XfSystemOptions {
            inspectionReminder
          }
        }
      `
    );

  const fieldId = systemOptionsInspectionReminderFieldId();
  const label = "Inspection Reminder";

  return (
    <ProgrammingConceptForm.Field fieldId={fieldId} label={label}>
      <Select
        id={fieldId}
        name={fieldId}
        value={inspectionReminder ?? undefined}
        required
        onChange={({ target }) => {
          updateSystemOptions((recordProxy) => {
            recordProxy.setValue(target.value, "inspectionReminder");
          });
        }}
      >
        <Select.Option value={InspectionReminder.NONE}>None</Select.Option>
        <Select.Option value={InspectionReminder.THREE_MONTHS}>
          3 Months
        </Select.Option>
        <Select.Option value={InspectionReminder.SIX_MONTHS}>
          6 Months
        </Select.Option>
        <Select.Option value={InspectionReminder.ONE_YEAR}>
          1 Year
        </Select.Option>
      </Select>
    </ProgrammingConceptForm.Field>
  );
}

export default InspectionReminderField;
