/**
 * @generated SignedSource<<d16f9e2317c630cddb933732c0f0d3ce>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type WirelessAudibles = "ANY" | "DAY" | "MIN";
import { FragmentRefs } from "relay-runtime";
export type SystemOptionsWirelessAudiblesField_systemOptions$data = {
  readonly houseCode?: string;
  readonly wirelessAudibles?: WirelessAudibles | null;
  readonly " $fragmentType": "SystemOptionsWirelessAudiblesField_systemOptions";
};
export type SystemOptionsWirelessAudiblesField_systemOptions$key = {
  readonly " $data"?: SystemOptionsWirelessAudiblesField_systemOptions$data;
  readonly " $fragmentSpreads": FragmentRefs<"SystemOptionsWirelessAudiblesField_systemOptions">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "wirelessAudibles",
  "storageKey": null
},
v1 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "houseCode",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SystemOptionsWirelessAudiblesField_systemOptions",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": (v1/*: any*/),
      "type": "XrSystemOptions",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v1/*: any*/),
      "type": "XtSystemOptions",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v1/*: any*/),
      "type": "XfSystemOptions",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v0/*: any*/)
      ],
      "type": "TMSentrySystemOptions",
      "abstractKey": null
    }
  ],
  "type": "SystemOptions",
  "abstractKey": "__isSystemOptions"
};
})();

(node as any).hash = "340827478a6d4fef555626cff66115c1";

export default node;
