import graphql from "babel-plugin-relay/macro";
import Switch from "components/FullProgramming/common/Switch";
import * as React from "react";
import { DeviceInformationType } from "securecom-graphql/client";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useDeviceInformationFragment } from "./DeviceInformationContext";
import { DeviceInformationPublicDoorField_deviceInformation$key } from "./__generated__/DeviceInformationPublicDoorField_deviceInformation.graphql";

export const deviceInformationPublicDoorFieldId = (number: string) =>
  `device-information-public-door-${number}`;

function DeviceInformationPublicDoorField() {
  const [{ number, publicDoor, deviceType }, updateDeviceInformation] =
    useDeviceInformationFragment<DeviceInformationPublicDoorField_deviceInformation$key>(
      graphql`
        fragment DeviceInformationPublicDoorField_deviceInformation on DeviceInformation {
          number
          ... on XrDeviceInformation {
            publicDoor
            deviceType
          }
        }
      `
    );

  const fieldId = deviceInformationPublicDoorFieldId(String(number));
  const label = "Public Door";
  const disabled = deviceType !== DeviceInformationType.DOOR;

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label={label}
      disabled={disabled}
      tooltip="Public Door allows the door access relay to be released whenever the Lockdown command is issued."
    >
      <Switch
        id={fieldId}
        label={label}
        disabled={disabled}
        checked={publicDoor}
        onChange={() => {
          updateDeviceInformation((recordProxy) => {
            recordProxy.setValue(!publicDoor, "publicDoor");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default DeviceInformationPublicDoorField;
