import graphql from "babel-plugin-relay/macro";
import { hyphenScoreToTitleCase } from "common/utils";
import { isNotNullOrUndefined } from "common/utils/universal/function";
import noop from "common/utils/universal/noop";
import ActiveConceptContext from "components/FullProgramming/common/ActiveConceptContext";
import {
  areaListItemTemplateId,
  AREA_IDS,
} from "components/FullProgramming/common/AreaInformationFields/AreaFields/AreaNumberField";
import { SystemAreaInformationContextProvider } from "components/FullProgramming/common/AreaInformationFields/SystemAreaInformationFields/SystemAreaInformationContext";
import { PanelContextProvider } from "components/FullProgramming/common/PanelContext";
import ProgrammingConceptForm from "components/FullProgramming/common/ProgrammingConceptForm";
import { useProgrammingActionsContext } from "components/FullProgramming/common/ProgrammingContext";
import { useTemplateContext } from "components/FullProgramming/common/TemplateContext";
import { useUncheckListItem } from "components/FullProgramming/Templates/utils";
import {
  applyTemplateScalarDataToRecordProxy,
  selectPanelRecordProxy,
} from "components/FullProgramming/utils/templates";
import { useParentRelayEnvironment } from "components/RelayEnvironmentCloneProvider";
import SecondaryFields from "components/SecondaryFields";
import Spacer from "components/SiteForm/Layout/Spacer";
import { useShowAlert } from "contexts/AlertsContext";
import { ascend, omit, range } from "ramda";
import * as React from "react";
import {
  ConnectionHandler,
  readInlineData,
  useMutation,
  useRelayEnvironment,
} from "react-relay";
import {
  createOperationDescriptor,
  RecordProxy,
  RecordSourceProxy,
} from "relay-runtime";
import {
  Area,
  AreaConnection,
  AreaEdge,
  asID,
  BadZonesOption,
  ControlSystem,
  DualAuthorityEnum,
  fromAreaId,
  fromControlSystemId,
  ID,
  idAsString,
  Panel,
  PanelHardwareModel,
  SystemType,
  toAreaId,
  toGlobalId,
} from "securecom-graphql/client";
import SystemAreaInformationAnyBypassField from "../../common/AreaInformationFields/SystemAreaInformationFields/SystemAreaInformationAnyBypassField";
import SystemAreaInformationAreaSchedulesField from "../../common/AreaInformationFields/SystemAreaInformationFields/SystemAreaInformationAreaSchedulesField";
import AreaInformationBurglaryBellOutputNumberField from "../../common/AreaInformationFields/SystemAreaInformationFields/SystemAreaInformationBurglaryBellOutputNumberField";
import AreaInformationClosingCheckField from "../../common/AreaInformationFields/SystemAreaInformationFields/SystemAreaInformationClosingCheckField";
import AreaInformationClosingCodeField from "../../common/AreaInformationFields/SystemAreaInformationFields/SystemAreaInformationClosingCodeField";
import AreaInformationEarlyMorningAmbushField from "../../common/AreaInformationFields/SystemAreaInformationFields/SystemAreaInformationEarlyMorningAmbush";
import AreaInformationExitDelayField from "../../common/AreaInformationFields/SystemAreaInformationFields/SystemAreaInformationExitDelayField";
import AreaInformationOpenClosingReportsField from "../../common/AreaInformationFields/SystemAreaInformationFields/SystemAreaInformationOpenClosingReportsField";
import {
  listItemHasChanged,
  useChangedProgrammingConcept,
} from "../../common/ChangedProgrammingConceptsContext";
import { useControlSystemFragment } from "../../common/ControlSystemContext";
import {
  ProgrammingConceptSidebarButton,
  SaveErrors,
  SaveMutationHookResponse,
} from "../../common/FullProgrammingForm";
import {
  RemountOnUpdateContainer,
  useResetLastUpdated,
} from "../../common/LastUpdatedContext";
import { useOriginalControlSystemData } from "../../common/OriginalControlSystemContext";
import { SystemOptionsContextProvider } from "../../common/SystemOptionsFields/SystemOptionsContext";
import { removeAreaFromStore } from "../../utils";
import { emptyStringOrNumber } from "../../utils/format";
import XRAreaInformationForm from "./XRAreaInformationForm";
import refreshMutationConcreteRequest, {
  XRAreaInformationProgrammingConceptFormAreaInformationRefreshMutation,
} from "./__generated__/XRAreaInformationProgrammingConceptFormAreaInformationRefreshMutation.graphql";
import { XRAreaInformationProgrammingConceptFormAreaInformationSendMutation } from "./__generated__/XRAreaInformationProgrammingConceptFormAreaInformationSendMutation.graphql";
import { XRAreaInformationProgrammingConceptFormInline_area$key } from "./__generated__/XRAreaInformationProgrammingConceptFormInline_area.graphql";
import { XRAreaInformationProgrammingConceptFormInline_controlSystem$key } from "./__generated__/XRAreaInformationProgrammingConceptFormInline_controlSystem.graphql";
import { XRAreaInformationProgrammingConceptFormInline_systemAreaInformation$key } from "./__generated__/XRAreaInformationProgrammingConceptFormInline_systemAreaInformation.graphql";
import { XRAreaInformationProgrammingConceptFormInline_xrProgrammingTemplateConcepts$key } from "./__generated__/XRAreaInformationProgrammingConceptFormInline_xrProgrammingTemplateConcepts.graphql";
import { XRAreaInformationProgrammingConceptFormNavButton_controlSystem$key } from "./__generated__/XRAreaInformationProgrammingConceptFormNavButton_controlSystem.graphql";
import { XRAreaInformationProgrammingConceptFormOriginalAreas_controlSystem$key } from "./__generated__/XRAreaInformationProgrammingConceptFormOriginalAreas_controlSystem.graphql";
import { XRAreaInformationProgrammingConceptFormRemoveMutation } from "./__generated__/XRAreaInformationProgrammingConceptFormRemoveMutation.graphql";
import { XRAreaInformationProgrammingConceptForm_controlSystem$key } from "./__generated__/XRAreaInformationProgrammingConceptForm_controlSystem.graphql";

export const title = "Area Information";
export const conceptId = "xr-area-information";

export const getState = (
  controlSystem: XRAreaInformationProgrammingConceptFormInline_controlSystem$key
) =>
  readInlineData(
    graphql`
      fragment XRAreaInformationProgrammingConceptFormInline_controlSystem on ControlSystem
      @inline {
        __typename
        id
        supportsTwoManRule
        supportsDualAuthority
        supportsCardPlusPin
        supportsBurglaryBellOutput
        supportsOpenClosingReports
        panel {
          __typename
          id
          areas(first: 32, sort: { keys: ["number"], order: ASC }) {
            __id
            __typename
            edges {
              __typename
              cursor
              node {
                __typename
                id
                number
                isNew
                ...XRAreaInformationProgrammingConceptFormInline_area
              }
            }
            totalCount
          }
          systemAreaInformation {
            __typename
            exitDelay
            morningAmbush
            closingCheck
            closingCode
            anyBypass
            areaSchedules
            supportsOpenClosingReports
            openClosingReports
            burglaryBellOutputNumber
            ...XRAreaInformationProgrammingConceptFormInline_systemAreaInformation
          }
        }
      }
    `,
    controlSystem
  );

export const getAreaState = (
  area: XRAreaInformationProgrammingConceptFormInline_area$key
) =>
  readInlineData(
    graphql`
      fragment XRAreaInformationProgrammingConceptFormInline_area on Area
      @inline {
        __typename
        id
        name
        number
        autoArmEnabled
        badZonesOption
        autoDisarmEnabled
        hasChanges
        isNew
        ... on XrArea {
          __typename
          accountNumber
          armedOutputNumber
          lateOutputNumber
          lateArmDelay
          bankSafeVault
          commonArea
          armFirstArea
          armedOutputNumber
          burglaryBellOutputNumber
          openClosingReports
          twoManRule
          dualAuthority
          cardPlusPin
        }
      }
    `,
    area
  );

export const getSystemAreaInformationState = (
  controlSystem: XRAreaInformationProgrammingConceptFormInline_systemAreaInformation$key
) =>
  readInlineData(
    graphql`
      fragment XRAreaInformationProgrammingConceptFormInline_systemAreaInformation on SystemAreaInformation
      @inline {
        __typename
        supportsOpenClosingReports
        openClosingReports
        burglaryBellOutputNumber
        exitDelay
        closingCheck
        closingCode
        anyBypass
        areaSchedules
        morningAmbush
      }
    `,
    controlSystem
  );

const deleteMutation = graphql`
  mutation XRAreaInformationProgrammingConceptFormRemoveMutation($areaId: ID!) {
    deleteAreaInformation(areaId: $areaId) {
      ... on DeleteAreaInformationSuccessPayload {
        __typename
        deletedAreaId
      }
      ... on FailedToRemoveAreaErrorPayload {
        error: type
      }
    }
  }
`;

const saveMutation = graphql`
  mutation XRAreaInformationProgrammingConceptFormAreaInformationSendMutation(
    $systemId: ID!
    $areas: [XrAreaInput!]!
    $systemAreaInformation: SystemAreaInformationInput!
  ) {
    sendXrAreaProgramming(systemId: $systemId, areas: $areas) {
      ... on Error {
        type
      }
      ... on SendAreasProgrammingSuccessPayload {
        results {
          __typename
          ... on SendAreasProgrammingAreaSuccessPayload {
            area {
              __typename
              id
              number
              ...XRAreaInformationProgrammingConceptFormInline_area
            }
          }
          ... on SendListItemsErrorPayload {
            number
            errors {
              __typename
              ... on InvalidInputError {
                type
                invalidField {
                  fieldName
                  reason
                }
              }
              ... on Error {
                type
              }
            }
          }
        }
      }
    }
    sendSystemAreaInformation(
      systemId: $systemId
      systemAreaInformation: $systemAreaInformation
    ) {
      ... on SendSystemAreaInformationSuccessPayload {
        __typename
        controlSystem {
          __typename
          panel {
            __typename
            systemAreaInformation {
              __typename
              ...XRAreaInformationProgrammingConceptFormInline_systemAreaInformation
            }
          }
        }
      }
      ... on SendSystemAreaInformationErrorPayload {
        errors {
          __typename
          ... on InvalidInputError {
            type
            invalidField {
              fieldName
              reason
            }
          }
          ... on Error {
            type
          }
        }
      }
    }
  }
`;

export const useSaveMutation = (props: {
  controlSystem: XRAreaInformationProgrammingConceptFormInline_controlSystem$key;
}): SaveMutationHookResponse => {
  const [save, isSaving] =
    useMutation<XRAreaInformationProgrammingConceptFormAreaInformationSendMutation>(
      saveMutation
    );

  const [deleteAreaInformation, isDeleting] =
    useMutation<XRAreaInformationProgrammingConceptFormRemoveMutation>(
      deleteMutation
    );

  const deleteArea = async (areaId: string): Promise<void> =>
    new Promise((resolve) => {
      deleteAreaInformation({
        variables: {
          areaId,
        },
        onCompleted: () => {
          resolve();
        },
      });
    });

  const showAlert = useShowAlert();
  const changedAreas = useChangedProgrammingConcept(conceptId);
  const lookupOriginalData = useOriginalControlSystemData();
  const resetLastUpdated = useResetLastUpdated();

  return [
    async (showAlerts = false, isSavingAllListItems = false) =>
      new Promise(async (resolve, reject) => {
        try {
          const {
            id: systemId,
            panel: { areas, systemAreaInformation },
            supportsTwoManRule,
            supportsDualAuthority,
            supportsOpenClosingReports,
            supportsCardPlusPin,
            supportsBurglaryBellOutput,
          } = getState(props.controlSystem);

          const {
            panel: { areas: originalAreas },
          } = lookupOriginalData<XRAreaInformationProgrammingConceptFormOriginalAreas_controlSystem$key>(
            graphql`
              fragment XRAreaInformationProgrammingConceptFormOriginalAreas_controlSystem on ControlSystem {
                panel {
                  areas(first: 32, sort: { keys: ["number"], order: ASC }) {
                    edges {
                      node {
                        id
                        number
                      }
                    }
                  }
                }
              }
            `
          );

          const areaNumbers = areas.edges
            .flatMap((area) => area.node?.number)
            .filter(isNotNullOrUndefined)
            .map((number) => Number(number));

          await Promise.allSettled(
            originalAreas.edges
              .flatMap((ogArea) => ogArea.node)
              .filter(isNotNullOrUndefined)
              // eslint-disable-next-line array-callback-return
              .map((ogArea) => {
                // If the current areas do not include the original
                // areas, then delete the original areas
                if (!areaNumbers.includes(Number(ogArea.number))) {
                  if (ogArea.id) {
                    return deleteArea(ogArea.id);
                  } else {
                    return null;
                  }
                } else {
                  return null;
                }
              })
              .filter(isNotNullOrUndefined)
          );

          save({
            variables: {
              systemId,
              areas: areas.edges
                .flatMap((edge) => edge.node)
                .filter(isNotNullOrUndefined)
                .filter(
                  (area) =>
                    (!!changedAreas &&
                      listItemHasChanged(area.id, changedAreas)) ||
                    area.isNew ||
                    isSavingAllListItems
                )
                .map(getAreaState)
                .map((area) => ({
                  id: area.id,
                  name: area.name,
                  number: area.number,
                  accountNumber: area.accountNumber ?? "",
                  autoArmEnabled: area.autoArmEnabled,
                  autoDisarmEnabled: area.autoDisarmEnabled,
                  badZonesOption: area.badZonesOption,
                  armedOutputNumber: `${area.armedOutputNumber ?? 0}`,
                  lateOutputNumber: `${area.lateOutputNumber ?? 0}`,
                  lateArmDelay: area.lateArmDelay ?? 60,
                  bankSafeVault: area.bankSafeVault ?? false,
                  commonArea: area.commonArea ?? false,
                  armFirstArea: area.armFirstArea ?? false,
                  isNew: area.isNew,
                  ...(isNotNullOrUndefined(area.twoManRule) &&
                    supportsTwoManRule && {
                      twoManRule: area.twoManRule,
                    }),
                  ...(isNotNullOrUndefined(area.dualAuthority) &&
                    supportsDualAuthority && {
                      dualAuthority: area.dualAuthority,
                    }),
                  ...(supportsOpenClosingReports && {
                    openClosingReports: area.openClosingReports,
                  }),
                  ...(isNotNullOrUndefined(area.cardPlusPin) &&
                    supportsCardPlusPin && {
                      cardPlusPin: area.cardPlusPin,
                    }),
                  ...(supportsBurglaryBellOutput && {
                    burglaryBellOutputNumber: area.burglaryBellOutputNumber,
                  }),
                })),
              systemAreaInformation: {
                exitDelay: systemAreaInformation?.exitDelay!,
                morningAmbush: systemAreaInformation?.morningAmbush!,
                closingCheck: systemAreaInformation?.closingCheck ?? false,
                closingCode: systemAreaInformation?.closingCode ?? false,
                anyBypass: systemAreaInformation?.anyBypass ?? false,
                areaSchedules: systemAreaInformation?.areaSchedules ?? false,
                ...(systemAreaInformation?.supportsOpenClosingReports && {
                  openClosingReports: systemAreaInformation?.openClosingReports,
                }),
                ...(systemAreaInformation?.burglaryBellOutputNumber && {
                  burglaryBellOutputNumber:
                    systemAreaInformation?.burglaryBellOutputNumber,
                }),
              },
            },
            onCompleted: (response) => {
              const saveErrors: SaveErrors = [];
              if (response.sendXrAreaProgramming.type) {
                showAlert({
                  type: "error",
                  text: `Error Sending ${title} - Panel Not Found`,
                });
              } else if (response.sendXrAreaProgramming.results) {
                response.sendXrAreaProgramming.results.forEach((response) => {
                  if (
                    response.__typename ===
                    "SendAreasProgrammingAreaSuccessPayload"
                  ) {
                    resetLastUpdated(response.area.id);
                  } else if (
                    response.__typename === "SendListItemsErrorPayload"
                  ) {
                    saveErrors.push({
                      programmingConcept: title,
                      errors: response.errors,
                      listItemNumber: response.number,
                    });
                  }
                });
              }

              if (response.sendSystemAreaInformation.controlSystem) {
                resetLastUpdated(conceptId);
              } else if (response.sendSystemAreaInformation.errors) {
                saveErrors.push({
                  programmingConcept: title,
                  errors: response.sendSystemAreaInformation.errors,
                });
              }

              if (!saveErrors.length && showAlerts) {
                showAlert({
                  type: "success",
                  text: `Successfully Updated ${title}`,
                });
              }
              resolve(saveErrors);
            },
            onError: () => {
              reject();
            },
          });
        } catch {
          showAlert({
            type: "error",
            text: `Failed To Update ${title}`,
          });
          reject();
        }
      }),
    isSaving || isDeleting,
  ];
};
const retrieveMutation = graphql`
  mutation XRAreaInformationProgrammingConceptFormAreaInformationRefreshMutation(
    $systemId: ID!
  ) {
    refreshAreaInformations(systemId: $systemId) {
      ... on RefreshAreaInformationsSuccessPayload {
        __typename
        controlSystem {
          __typename
          ...XRAreaInformationProgrammingConceptFormInline_controlSystem
        }
      }
      ... on Error {
        error: type
      }
    }
  }
`;

export const useRetrieveMutation = (props: {
  controlSystem: XRAreaInformationProgrammingConceptFormInline_controlSystem$key;
}): [(showAlerts: boolean) => Promise<void>, boolean] => {
  const [retrieve, isRetrieving] =
    useMutation<XRAreaInformationProgrammingConceptFormAreaInformationRefreshMutation>(
      retrieveMutation
    );

  const showAlert = useShowAlert();
  const parentRelayEnv = useParentRelayEnvironment();
  const resetLastUpdated = useResetLastUpdated();

  return [
    async (showAlerts: boolean) =>
      new Promise((resolve, reject) => {
        const { id: systemId } = getState(props.controlSystem);
        retrieve({
          variables: { systemId },
          onCompleted: (response) => {
            const { controlSystem, error } = response.refreshAreaInformations;
            if (controlSystem) {
              if (showAlerts) {
                showAlert({
                  type: "success",
                  text: "Area Information Programming Retrieved From the System",
                });
              }
              resetLastUpdated(conceptId);
              // Update original data store
              const operation = createOperationDescriptor(
                refreshMutationConcreteRequest,
                { id: systemId }
              );
              if (parentRelayEnv) {
                parentRelayEnv.commitPayload(operation, {
                  refreshAreaInformations: {
                    __typename: response.refreshAreaInformations.__typename,
                    controlSystem: getState(controlSystem),
                  },
                });
              }
              resolve();
            } else {
              if (showAlerts) {
                if (error) {
                  showAlert({
                    type: "error",
                    text: `Unable to refresh Area Information: ${hyphenScoreToTitleCase(
                      error
                    )}`,
                  });
                } else {
                  showAlert({
                    type: "error",
                    text: "Unable to refresh Area Information",
                  });
                }
              }
              reject(error);
            }
          },
        });
      }),
    isRetrieving,
  ];
};

const readAreaInformationsTemplateData = (
  programmingTemplateConcepts: XRAreaInformationProgrammingConceptFormInline_xrProgrammingTemplateConcepts$key
) =>
  readInlineData(
    graphql`
      fragment XRAreaInformationProgrammingConceptFormInline_xrProgrammingTemplateConcepts on XrProgrammingTemplateConcepts
      @inline {
        systemOptions {
          included
          systemType {
            data
            included
          }
        }
        areaInformations {
          included
          number
          autoArmEnabled {
            data
            included
          }
          autoDisarmEnabled {
            data
            included
          }
          name {
            data
            included
          }
          badZonesOption {
            data
            included
          }
          armedOutputNumber {
            data
            included
          }
          lateOutputNumber {
            data
            included
          }
          lateArmDelay {
            data
            included
          }
          bankSafeVault {
            data
            included
          }
          commonArea {
            data
            included
          }
          armFirstArea {
            data
            included
          }
          dualAuthority {
            data
            included
          }
          openClosingReports {
            data
            included
          }
          burglaryBellOutputNumber {
            data
            included
          }
          cardPlusPin {
            data
            included
          }
        }
        systemAreaInformation {
          id
          included
          exitDelay {
            data
            included
          }
          morningAmbush {
            data
            included
          }
          closingCheck {
            data
            included
          }
          closingCode {
            data
            included
          }
          anyBypass {
            data
            included
          }
          areaSchedules {
            data
            included
          }
        }
      }
    `,
    programmingTemplateConcepts
  );

export function applyTemplateData(
  programmingTemplateConcepts: XRAreaInformationProgrammingConceptFormInline_xrProgrammingTemplateConcepts$key,
  controlSystemRecordProxy: RecordProxy<ControlSystem>,
  store: RecordSourceProxy
) {
  const systemId = controlSystemRecordProxy.getValue("id");
  const panelRecordProxy = selectPanelRecordProxy(controlSystemRecordProxy);
  const hardwareModel = panelRecordProxy.getValue("hardwareModel");
  const areasConnection = panelRecordProxy.getLinkedRecord("areas", {
    first: 32,
    sort: { keys: ["number"], order: "ASC" },
  });
  const areaEdgesByNumber = new Map<number, RecordProxy<AreaEdge>>(
    areasConnection
      .getLinkedRecords("edges")
      .map((edge) => [
        Number(edge.getLinkedRecord("node").getValue("number")),
        edge,
      ]) ?? []
  );

  const templateData = readAreaInformationsTemplateData(
    programmingTemplateConcepts
  );

  const systemType =
    templateData?.systemOptions?.systemType?.included &&
    templateData?.systemOptions?.systemType?.data
      ? templateData.systemOptions.systemType.data
      : panelRecordProxy
          .getLinkedRecord("systemOptions")
          .getValue("systemType");

  const allowedSystemTypeAreaNumbers = new Set(
    systemType === SystemType.ALL_PERIMETER
      ? [1, 2]
      : systemType === SystemType.HOME_SLEEP_AWAY
      ? [1, 2, 3]
      : systemType === SystemType.HOME_SLEEP_AWAY_GUEST
      ? hardwareModel === PanelHardwareModel.XR150 //HSAG has only 6 areas on XR150
        ? range(1, 7)
        : range(1, 10)
      : hardwareModel === PanelHardwareModel.XR150 //AREA mode has only 8 areas on XR150
      ? range(1, 9)
      : range(1, 33)
  );

  areasConnection.setValue(
    allowedSystemTypeAreaNumbers.size,
    "maxNumberOfAreas"
  );

  const areasTemplateData = templateData.areaInformations ?? [];
  areasTemplateData.forEach((areaTemplateData) => {
    if (
      areaTemplateData?.included &&
      allowedSystemTypeAreaNumbers.has(areaTemplateData.number)
    ) {
      const existingAreaEdgeRecordProxy = areaEdgesByNumber.get(
        areaTemplateData.number
      );
      if (existingAreaEdgeRecordProxy) {
        applyTemplateScalarDataToRecordProxy(
          existingAreaEdgeRecordProxy.getLinkedRecord("node"),
          systemType !== SystemType.AREA
            ? omit(["name"], areaTemplateData)
            : areaTemplateData
        );
      } else {
        const newAreaId = toAreaId(systemId, areaTemplateData.number);
        applyNewAreaToAreaList(
          {
            id: panelRecordProxy.getValue("id"),
            areas: {
              __id: areasConnection.getDataID(),
            },
          },
          newAreaId,
          store
        );
        if (newAreaId) {
          const areaRecordProxy = store.get(
            idAsString(newAreaId)
          ) as RecordProxy<Area>;
          if (areaRecordProxy) {
            const areaEdgeRecordProxy = ConnectionHandler.createEdge(
              store,
              areasConnection,
              areaRecordProxy,
              "AreaEdge"
            ) as RecordProxy<AreaEdge>;

            applyTemplateScalarDataToRecordProxy(
              areaRecordProxy,
              areaTemplateData
            );
            areaEdgesByNumber.set(areaTemplateData.number, areaEdgeRecordProxy);
          }
        }
      }
    }
  });

  panelRecordProxy.setLinkedRecords(
    [...areaEdgesByNumber.entries()]
      .sort(ascend(([number]) => number))
      .map(([, areaEdgeRecordProxy]) => areaEdgeRecordProxy),
    "areas"
  );

  if (templateData.systemAreaInformation?.included) {
    applyTemplateScalarDataToRecordProxy(
      panelRecordProxy.getOrCreateLinkedRecord(
        "systemAreaInformation",
        "SystemAreaInformation"
      ),
      templateData.systemAreaInformation
    );
  }
}

export function NavButton() {
  const [controlSystem] =
    useControlSystemFragment<XRAreaInformationProgrammingConceptFormNavButton_controlSystem$key>(
      graphql`
        fragment XRAreaInformationProgrammingConceptFormNavButton_controlSystem on ControlSystem {
          id
          panel {
            areas(first: 32, sort: { keys: ["number"], order: ASC }) {
              edges {
                node {
                  isNew
                }
              }
            }
          }
        }
      `
    );
  const { areas } = controlSystem.panel;
  const itemsCount = areas.edges?.length ?? 0;
  const hasNewItems =
    itemsCount > 0 &&
    areas.edges
      .flatMap((edge) => edge.node)
      .filter(isNotNullOrUndefined)
      .some(({ isNew }) => isNew);

  return (
    <ProgrammingConceptSidebarButton
      conceptId={conceptId}
      title={title}
      hasNewItems={hasNewItems}
      itemsCount={itemsCount}
    />
  );
}

export function Form() {
  const [controlSystem] =
    useControlSystemFragment<XRAreaInformationProgrammingConceptForm_controlSystem$key>(
      graphql`
        fragment XRAreaInformationProgrammingConceptForm_controlSystem on ControlSystem {
          id
          copiedArea {
            id
          }
          panel {
            ...PanelContext_panel
            ...PanelContextUseHardwareModel_panel
            ...AreaNumberField_panel
            hardwareModel
            accountNumber
            online
            helpFiles {
              programmingGuideUrl
              installGuideUrl
            }
            systemOptions {
              ... on XrSystemOptions {
                systemType
              }
              ...SystemOptionsContext_systemOptions
              ...SystemOptionsContextSystemType_systemOptions
              ...SystemOptionsContextHouseCode_systemOptions
              ...SystemOptionsContextIsAreaSystem_systemOptions
            }
            id
            newArea {
              id
              hasChanges
              isNew
              name
              number
              ...XRAreaInformationForm_area
              ... on XrArea {
                ...AreaListItem_area
              }
            }
            areas(first: 32, sort: { keys: ["number"], order: ASC }) {
              __id
              maxNumberOfAreas
              minNumberOfAreas
              totalCount
              edges {
                cursor
                node {
                  id
                  name
                  number
                  isNew
                  ...XRAreaInformationForm_area
                  ...AreaListItem_area
                }
              }
            }
            systemAreaInformation {
              supportsOpenClosingReports
              supportsBurglaryBellOutput
              ...SystemAreaInformationContext_systemAreaInformation
              ...SystemAreaInformationExitDelayField_systemAreaInformation
              ...SystemAreaInformationClosingCheckField_systemAreaInformation
              ...SystemAreaInformationClosingCodeField_systemAreaInformation
              ...SystemAreaInformationAnyBypassField_systemAreaInformation
              ...SystemAreaInformationAreaSchedulesField_systemAreaInformation
              ...SystemAreaInformationEarlyMorningAmbushField_systemAreaInformation
              ...SystemAreaInformationBurglaryBellOutputNumberField_systemAreaInformation
              ...SystemAreaInformationOpenClosingReportsField_systemAreaInformation
            }
          }
          ...XRAreaInformationForm_controlSystem
        }
      `
    );

  const {
    id: controlSystemId,
    panel,
    panel: {
      areas,
      hardwareModel,
      systemOptions,
      newArea,
      systemAreaInformation,
      areas: { maxNumberOfAreas, minNumberOfAreas },
      helpFiles: { programmingGuideUrl },
    },
  } = controlSystem;

  const relayEnv = useRelayEnvironment();
  const showAlert = useShowAlert();
  const parentRelayEnv = useParentRelayEnvironment();
  const { isEditing: templateIsEditing, isApplying } = useTemplateContext();
  const {
    programmingConcepts,
    isSavingAllProgramming,
    isSendingAllChanges,
    isSendingAllProgramming,
    isSendingConcept,
  } = useProgrammingActionsContext();
  const isSavingAll =
    isSavingAllProgramming ||
    isSendingAllChanges ||
    isSendingAllProgramming ||
    isSendingConcept;
  const [activeConcept] = React.useContext(ActiveConceptContext);

  const [deleteAreaInformation, isDeleting] =
    useMutation<XRAreaInformationProgrammingConceptFormRemoveMutation>(
      deleteMutation
    );

  const [selectedListItemId, setSelectedListItemId] = React.useState(
    areas.edges[0]?.node?.id ?? null
  );

  const uncheckListItem = useUncheckListItem()(AREA_IDS);
  const newAreaId = getNewAreaId(controlSystemId, areas);
  const numberOfAvailableAreas = maxNumberOfAreas - areas.edges.length;
  const availableAreasExist = numberOfAvailableAreas > 0;
  const canRemove = controlSystem.panel.online
    ? areas.edges.length > minNumberOfAreas || templateIsEditing
    : true; //Pre-programming panels can delete whatever they want
  const canAdd =
    systemOptions?.systemType === SystemType.AREA ||
    systemOptions?.systemType === SystemType.HOME_SLEEP_AWAY_GUEST;

  const removeSelectedArea = () => {
    if (selectedListItemId && canRemove) {
      setSelectedListItemId(() => {
        const selectedIndex = areas.edges.findIndex(
          (area) => area.node?.id === selectedListItemId
        );
        const lastItemIsSelected = selectedIndex === areas.edges.length - 1;
        const newSelectedIndex = lastItemIsSelected
          ? selectedIndex - 1
          : selectedIndex + 1;
        return areas.edges[newSelectedIndex]?.node?.id ?? null;
      });

      const area = areas.edges.find(
        (area) => area.node?.id === selectedListItemId
      );
      uncheckListItem(area?.node?.number ?? "");
      if (area?.node?.isNew || templateIsEditing) {
        relayEnv.commitUpdate((store) => {
          removeAreaFromStore(areas.__id, selectedListItemId, store);
        });
      } else {
        const unSaltedId = idAsString(
          toGlobalId(
            "Area",
            fromControlSystemId(asID(controlSystem.id)).systemId,
            area?.node?.number ?? -1
          )
        );
        //need to use the calculated unSaltedId here instead of the selected id so that new items that have just been created can be deleted
        deleteAreaInformation({
          variables: {
            areaId: unSaltedId,
          },
          optimisticUpdater: (store) => {
            const areasConnection = store.get<AreaConnection>(areas.__id);
            if (areasConnection) {
              ConnectionHandler.deleteNode(areasConnection, selectedListItemId);
            }
          },
          updater: (store, response) => {
            const { deletedAreaId } = response.deleteAreaInformation;
            if (deletedAreaId) {
              removeAreaFromStore(areas.__id, selectedListItemId, store);
              showAlert({
                type: "success",
                text: "Area Deleted From the System",
              });
            }
          },
          onCompleted: (response) => {
            const { deletedAreaId, error } = response.deleteAreaInformation;
            if (deletedAreaId) {
              if (parentRelayEnv) {
                parentRelayEnv.commitUpdate((parentStore) => {
                  if (deletedAreaId) {
                    removeAreaFromStore(
                      areas.__id,
                      selectedListItemId,
                      parentStore
                    );
                  }
                });
              }
            } else {
              if (error) {
                showAlert({
                  type: "error",
                  text: `Unable to Delete Area: ${hyphenScoreToTitleCase(
                    error
                  )}`,
                });
              } else {
                showAlert({
                  type: "error",
                  text: "Unable to Delete Area",
                });
              }
            }
          },
          onError: () => {
            showAlert({
              type: "error",
              text: "Unable to Delete Area",
            });
          },
        });
      }
    } else {
      showAlert({
        type: "error",
        text: "Unable to Delete Area",
      });
    }
  };

  return (
    <PanelContextProvider panel={panel}>
      <SystemAreaInformationContextProvider
        systemAreaInformation={systemAreaInformation}
      >
        <SystemOptionsContextProvider systemOptions={systemOptions}>
          <ProgrammingConceptForm
            conceptId={conceptId}
            title={title}
            deleting={isDeleting}
            helpLink={`${programmingGuideUrl}#Area%20Information`}
            initialDataIsNotEmptyOrNull={!!areas.edges}
            amountAvailable={canAdd ? maxNumberOfAreas - areas.edges.length : 0}
            addButton={
              <ProgrammingConceptForm.AddButton
                onClick={() => {
                  relayEnv.commitUpdate((store) => {
                    const createdAreaId = applyNewAreaToAreaList(
                      controlSystem.panel,
                      newAreaId,
                      store
                    );
                    if (createdAreaId) {
                      setSelectedListItemId(createdAreaId);
                    }
                  });
                }}
              >
                Add Area
              </ProgrammingConceptForm.AddButton>
            }
          >
            <RemountOnUpdateContainer nodeId={conceptId}>
              <SecondaryFields key={conceptId}>
                <ProgrammingConceptForm.Fields>
                  <AreaInformationExitDelayField />
                  <AreaInformationClosingCheckField />
                  <AreaInformationClosingCodeField />
                  <SystemAreaInformationAnyBypassField />
                  <SystemAreaInformationAreaSchedulesField />
                  {hardwareModel === "XR550" && (
                    <AreaInformationEarlyMorningAmbushField />
                  )}
                  {systemAreaInformation?.supportsBurglaryBellOutput && (
                    <AreaInformationBurglaryBellOutputNumberField />
                  )}
                  {systemAreaInformation?.supportsOpenClosingReports && (
                    <AreaInformationOpenClosingReportsField />
                  )}
                </ProgrammingConceptForm.Fields>
              </SecondaryFields>
            </RemountOnUpdateContainer>
            <Spacer size="4x" />
            {(conceptId === activeConcept || isApplying || isSavingAll) && (
              <ProgrammingConceptForm.ListItemsContainer>
                <ProgrammingConceptForm.ListItemPicker
                  selectedId={selectedListItemId ?? ""}
                  onChange={(id) => {
                    setSelectedListItemId(id);
                  }}
                  newItemId={newArea?.id}
                  items={areas.edges
                    .map(({ node }) => node)
                    .filter(isNotNullOrUndefined)
                    .map((area) => ({
                      id: area.id,
                      templateListItemId: areaListItemTemplateId(area.number),
                      isnew: area.isNew,
                      templateUpdater: noop,
                      label: `#${emptyStringOrNumber(area.number)} ${
                        area.name
                      }`,
                    }))}
                />
                <ProgrammingConceptForm.SelectedItemsContainer
                  selectedListItemId={selectedListItemId}
                  setSelectedListItemId={setSelectedListItemId}
                >
                  {areas.edges.map(
                    ({ node }) =>
                      node &&
                      (node.id === selectedListItemId || //Rendering at the last second before saving to check if there are errors or changes
                        programmingConcepts[conceptId].isSaving ||
                        isApplying || // Allows the fields to be in the DOM so diff and invalid indicators will be registered when a template is applied
                        isSavingAll) && (
                        <RemountOnUpdateContainer nodeId={node.id}>
                          <XRAreaInformationForm
                            conceptId={conceptId}
                            key={node.id}
                            area={node}
                            removeSelectedArea={removeSelectedArea}
                            canRemove={canRemove}
                            controlSystem={controlSystem}
                            connectionId={areas.__id}
                            visible={node.id === selectedListItemId}
                            availableAreasExist={availableAreasExist}
                            setSelectedListItemId={setSelectedListItemId}
                            newAreaId={newAreaId}
                          />
                        </RemountOnUpdateContainer>
                      )
                  )}
                </ProgrammingConceptForm.SelectedItemsContainer>
              </ProgrammingConceptForm.ListItemsContainer>
            )}
          </ProgrammingConceptForm>
        </SystemOptionsContextProvider>
      </SystemAreaInformationContextProvider>
    </PanelContextProvider>
  );
}

const applyNewAreaToAreaList = (
  panel: {
    readonly id: string;
    readonly areas: {
      readonly __id: string;
    };
  },
  newAreaId: ID,
  store: RecordSourceProxy
) => {
  const panelRecord = store.get<Panel>(panel.id);
  const newAreaTemplate = panelRecord?.getLinkedRecord("newArea");
  if (panel.areas.__id && newAreaTemplate) {
    const areasConnection = store.get<AreaConnection>(panel.areas.__id);

    if (areasConnection) {
      const id = `${newAreaId}`;
      const newAreaRecord = store.create(id, "XrArea");
      if (newAreaTemplate) {
        newAreaRecord.copyFieldsFrom(newAreaTemplate);
      }
      newAreaRecord.setValue(id, "id");
      newAreaRecord.setValue("", "name");
      newAreaRecord.setValue(true, "isNew");
      newAreaRecord.setValue(fromAreaId(newAreaId).number, "number");
      newAreaRecord.setValue(false, "autoArmEnabled");
      newAreaRecord.setValue(BadZonesOption.BYPASS, "badZonesOption");
      newAreaRecord.setValue(32, "maxNameLength");
      newAreaRecord.setValue(false, "autoDisarmEnabled");
      newAreaRecord.setValue(0, "armedOutputNumber");
      newAreaRecord.setValue(0, "lateOutputNumber");
      newAreaRecord.setValue(60, "lateArmDelay");
      newAreaRecord.setValue(
        panelRecord?.getValue("accountNumber"),
        "accountNumber"
      );
      newAreaRecord.setValue(false, "bankSafeVault");
      newAreaRecord.setValue(false, "commonArea");
      newAreaRecord.setValue(false, "armFirstArea");
      newAreaRecord.setValue(DualAuthorityEnum.NO, "dualAuthority");
      newAreaRecord.setValue("0", "burglaryBellOutputNumber");
      newAreaRecord.setValue(true, "cardPlusPin");
      const newEdge = ConnectionHandler.createEdge(
        store,
        areasConnection,
        newAreaRecord,
        "AreaEdge"
      );

      newEdge.setValue(id, "cursor");

      ConnectionHandler.insertEdgeAfter(areasConnection, newEdge);

      return id;
    }
  }

  return null;
};

function getNewAreaId(
  globalSystemId: string,
  areas: {
    readonly edges: ReadonlyArray<{
      readonly node: { readonly number: string } | null;
    }>;
    readonly maxNumberOfAreas: number;
  }
) {
  const { systemId } = fromControlSystemId(asID(globalSystemId));
  const currentAreaNumbers = new Set(
    areas.edges.map((area) => Number(area.node?.number))
  );
  const nextAreaNumber = Math.min(
    ...range(1, areas.maxNumberOfAreas + 1).filter(
      (areaNumber) => !currentAreaNumbers.has(areaNumber)
    )
  );
  return toAreaId(systemId, nextAreaNumber, true);
}
