/**
 * @generated SignedSource<<49350040c2018cca02b1394089c3c0b9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type PanelProgrammingLanguage = "CZECH" | "DUTCH" | "ENGLISH" | "FRENCH" | "GREEK" | "NONE" | "SPANISH";
import { FragmentRefs } from "relay-runtime";
export type SystemOptionsPrimaryProgrammingLanguageField_systemOptions$data = {
  readonly primaryProgrammingLanguage?: PanelProgrammingLanguage | null;
  readonly " $fragmentType": "SystemOptionsPrimaryProgrammingLanguageField_systemOptions";
};
export type SystemOptionsPrimaryProgrammingLanguageField_systemOptions$key = {
  readonly " $data"?: SystemOptionsPrimaryProgrammingLanguageField_systemOptions$data;
  readonly " $fragmentSpreads": FragmentRefs<"SystemOptionsPrimaryProgrammingLanguageField_systemOptions">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "primaryProgrammingLanguage",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SystemOptionsPrimaryProgrammingLanguageField_systemOptions",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "XrSystemOptions",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "XtSystemOptions",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "XfSystemOptions",
      "abstractKey": null
    }
  ],
  "type": "SystemOptions",
  "abstractKey": "__isSystemOptions"
};
})();

(node as any).hash = "506b1e5404173beab45642e0be8dcbed";

export default node;
