/**
 * @generated SignedSource<<fb94ba2359e63ed2caaa8b65e9b31e46>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type DeviceInformationType = "CAMERA" | "DOOR" | "FIRE" | "KEYPAD" | "NONE" | "VPLEX" | "XR550" | "ZONE_EXPANDER" | "_1100T";
import { FragmentRefs } from "relay-runtime";
export type PanelContextGetAllDeviceIds_panel$data = {
  readonly deviceInformations: ReadonlyArray<{
    readonly deviceType?: DeviceInformationType;
    readonly id: string;
    readonly number?: number;
  }>;
  readonly id: string;
  readonly " $fragmentType": "PanelContextGetAllDeviceIds_panel";
};
export type PanelContextGetAllDeviceIds_panel$key = {
  readonly " $data"?: PanelContextGetAllDeviceIds_panel$data;
  readonly " $fragmentSpreads": FragmentRefs<"PanelContextGetAllDeviceIds_panel">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PanelContextGetAllDeviceIds_panel",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "deviceInformations",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "deviceType",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "number",
              "storageKey": null
            }
          ],
          "type": "XrDeviceInformation",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Panel",
  "abstractKey": null
};
})();

(node as any).hash = "7c9bbd7a72974b86b585de53634ad51e";

export default node;
