import { MessagePosition } from "common/components/web/Tooltip";
import Tooltip, {
  DaTooltipTheme,
  TooltipThemeProvider,
} from "components/Tooltip";
import React, { useState } from "react";
import styled, { ThemeProvider } from "styled-components/macro";
import DaColors from "../../app/common/DaColors";
import Icon from "../Icon";

const TooltipContainer = styled.div`
  font-size: 1.4rem;
  width: 28rem;
`;

const TrashIcon = styled.i`
  cursor: pointer;
  color: var(--color-danger-500);
  position: relative;
`;

const ConfirmHeader = styled.h3`
  background-color: #fafafa;
  font-size: 1.4rem;
  margin: 0;
  border-radius: 5px 5px 0 0;
  padding: 8px 14px;
`;

const PopoverContent = styled.div`
  padding: 9px 14px;
`;

const PopoverMessage = styled.div`
  margin: 0 0 10.5px;
`;

const PopoverButtons = styled.p`
  margin: 0 0 10.5px;
`;

const ConfirmingDeleteTheme = {
  gray1000: DaColors.Neutral950,
  gray200: DaColors.Neutral25,
};

//Provides a Tooltip style report for cell signal strength
//Provides a confirming delete tooltip
const ConfirmingDeleteTooltip: React.FC<
  React.PropsWithChildren<{
    onConfirm: { (): void };
    confirmText?: string;
    cancelText?: string;
    message: string;
    iconSize?: string | "2.4rem";
    theme?: DaTooltipTheme;
  }>
> = ({
  onConfirm,
  confirmText = "OK",
  cancelText = "Cancel",
  message,
  iconSize,
  theme,
}) => {
  const [pickerOpen, setPickerOpen] = useState(false);
  return (
    <ThemeProvider theme={ConfirmingDeleteTheme}>
      <TooltipThemeProvider theme={theme}>
        <Tooltip
          anchor={({ registerAnchor }) => (
            <TrashIcon
              ref={registerAnchor}
              onClick={() => {
                if (!pickerOpen) {
                  setPickerOpen(true);
                }
              }}
              onBlur={() => {
                if (pickerOpen) {
                  setPickerOpen(false);
                }
              }}
            >
              <Icon name="trash" size={iconSize} />
            </TrashIcon>
          )}
          position={MessagePosition.Left}
          open={pickerOpen}
          onDismiss={() => {
            setPickerOpen(false);
          }}
          zIndex={5000}
          children={
            <TooltipContainer>
              <ConfirmHeader>Confirm</ConfirmHeader>
              <PopoverContent>
                <PopoverMessage>{message}</PopoverMessage>
                <PopoverButtons>
                  <button
                    className="btn btn-danger btn-xs"
                    onClick={() => {
                      onConfirm();
                      setPickerOpen(false);
                    }}
                  >
                    {confirmText}
                  </button>
                  {"    "}
                  <button
                    className="btn btn-dmp btn-xs"
                    onClick={() => {
                      setPickerOpen(false);
                    }}
                  >
                    {cancelText}
                  </button>
                </PopoverButtons>
              </PopoverContent>
            </TooltipContainer>
          }
        ></Tooltip>
      </TooltipThemeProvider>
    </ThemeProvider>
  );
};

export default ConfirmingDeleteTooltip;
