/**
 * @generated SignedSource<<9cee156c16370ee6433bd1582b2d0979>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AreaBurglaryBellOutputNumberField_xrArea$data = {
  readonly burglaryBellOutputNumber: string;
  readonly number: string;
  readonly " $fragmentType": "AreaBurglaryBellOutputNumberField_xrArea";
};
export type AreaBurglaryBellOutputNumberField_xrArea$key = {
  readonly " $data"?: AreaBurglaryBellOutputNumberField_xrArea$data;
  readonly " $fragmentSpreads": FragmentRefs<"AreaBurglaryBellOutputNumberField_xrArea">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AreaBurglaryBellOutputNumberField_xrArea",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "number",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "burglaryBellOutputNumber",
      "storageKey": null
    }
  ],
  "type": "XrArea",
  "abstractKey": null
};

(node as any).hash = "d9c4751f23bf69bc67ea59900c789db7";

export default node;
