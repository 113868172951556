/**
 * @generated SignedSource<<61acb833f800d3f310c765398f079966>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type DeviceInformation1100TWirelessFrequencyType = "DSC" | "HONEYWELL" | "INTERLOGIX" | "NONE" | "_2GIG";
export type DeviceInformationType = "CAMERA" | "DOOR" | "FIRE" | "KEYPAD" | "NONE" | "VPLEX" | "XR550" | "ZONE_EXPANDER" | "_1100T";
import { FragmentRefs } from "relay-runtime";
export type DeviceInformation1100TWirelessFrequencyField_deviceInformation$data = {
  readonly deviceType?: DeviceInformationType;
  readonly id: string;
  readonly number: number;
  readonly wirelessTranslator1100t?: boolean;
  readonly wirelessTranslator1100tFrequency?: DeviceInformation1100TWirelessFrequencyType;
  readonly " $fragmentType": "DeviceInformation1100TWirelessFrequencyField_deviceInformation";
};
export type DeviceInformation1100TWirelessFrequencyField_deviceInformation$key = {
  readonly " $data"?: DeviceInformation1100TWirelessFrequencyField_deviceInformation$data;
  readonly " $fragmentSpreads": FragmentRefs<"DeviceInformation1100TWirelessFrequencyField_deviceInformation">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "wirelessTranslator1100tFrequency",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DeviceInformation1100TWirelessFrequencyField_deviceInformation",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "number",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "deviceType",
          "storageKey": null
        },
        (v0/*: any*/)
      ],
      "type": "XrDeviceInformation",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "wirelessTranslator1100t",
          "storageKey": null
        },
        (v0/*: any*/)
      ],
      "type": "XtDeviceInformation",
      "abstractKey": null
    }
  ],
  "type": "DeviceInformation",
  "abstractKey": "__isDeviceInformation"
};
})();

(node as any).hash = "271eee538eddb6489e24a666be2f64b9";

export default node;
