/**
 * @generated SignedSource<<16c7197b32828dba553d1700dbee7e77>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type DeviceInformationType = "CAMERA" | "DOOR" | "FIRE" | "KEYPAD" | "NONE" | "VPLEX" | "XR550" | "ZONE_EXPANDER" | "_1100T";
import { FragmentRefs } from "relay-runtime";
export type PanelContextUseVplexNumbers_panel$data = {
  readonly deviceInformations: ReadonlyArray<{
    readonly deviceType?: DeviceInformationType;
    readonly lxNumber?: string;
    readonly number?: number;
  }>;
  readonly supportsVplex: boolean;
  readonly " $fragmentType": "PanelContextUseVplexNumbers_panel";
};
export type PanelContextUseVplexNumbers_panel$key = {
  readonly " $data"?: PanelContextUseVplexNumbers_panel$data;
  readonly " $fragmentSpreads": FragmentRefs<"PanelContextUseVplexNumbers_panel">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PanelContextUseVplexNumbers_panel",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "supportsVplex",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "deviceInformations",
      "plural": true,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "number",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "lxNumber",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "deviceType",
              "storageKey": null
            }
          ],
          "type": "XrDeviceInformation",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Panel",
  "abstractKey": null
};

(node as any).hash = "8911b82a651cb852f26a7312b0af65f8";

export default node;
