/**
 * @generated SignedSource<<723c093643db5a79f961982fca0290a4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type DeviceInformationType = "CAMERA" | "DOOR" | "FIRE" | "KEYPAD" | "NONE" | "VPLEX" | "XR550" | "ZONE_EXPANDER" | "_1100T";
import { FragmentRefs } from "relay-runtime";
export type DeviceInformationSiteCode1Field_deviceInformation$data = {
  readonly device734?: {
    readonly enforceSiteCode: boolean | null;
    readonly siteCode1: string | null;
  } | null;
  readonly deviceType?: DeviceInformationType;
  readonly id: string;
  readonly number: number;
  readonly " $fragmentType": "DeviceInformationSiteCode1Field_deviceInformation";
};
export type DeviceInformationSiteCode1Field_deviceInformation$key = {
  readonly " $data"?: DeviceInformationSiteCode1Field_deviceInformation$data;
  readonly " $fragmentSpreads": FragmentRefs<"DeviceInformationSiteCode1Field_deviceInformation">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DeviceInformationSiteCode1Field_deviceInformation",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "number",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "deviceType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Device734",
          "kind": "LinkedField",
          "name": "device734",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "siteCode1",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "enforceSiteCode",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "XrDeviceInformation",
      "abstractKey": null
    }
  ],
  "type": "DeviceInformation",
  "abstractKey": "__isDeviceInformation"
};

(node as any).hash = "6a64ec0f25989b0b28309c9ee51f50b5";

export default node;
