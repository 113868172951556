import graphql from "babel-plugin-relay/macro";
import Switch from "components/FullProgramming/common/Switch";
import * as React from "react";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useSystemOptionsFragment } from "./SystemOptionsContext";
import { SystemOptionsCelsiusField_systemOptions$key } from "./__generated__/SystemOptionsCelsiusField_systemOptions.graphql";

export const systemOptionsCelsiusFieldId = () => "system-options-celsius";

function CelsiusField() {
  const [{ celsius }, updateSystemOptions] =
    useSystemOptionsFragment<SystemOptionsCelsiusField_systemOptions$key>(
      graphql`
        fragment SystemOptionsCelsiusField_systemOptions on SystemOptions {
          ... on XrSystemOptions {
            celsius
          }
          ... on XtSystemOptions {
            celsius
          }
        }
      `
    );

  const fieldId = systemOptionsCelsiusFieldId();
  const label = "Use Celsius Units";

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label={label}
      tooltip="Enable to display thermostat temperatures in Celsius."
    >
      <Switch
        label={label}
        id={fieldId}
        name={fieldId}
        checked={celsius}
        onChange={() => {
          updateSystemOptions((recordProxy) => {
            recordProxy.setValue(!celsius, "celsius");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default CelsiusField;
