import graphql from "babel-plugin-relay/macro";
import Select from "components/FullProgramming/common/Select";
import * as React from "react";
import { useRelayEnvironment } from "react-relay";
import {
  Device734,
  Device734CardOptions,
  DeviceInformation,
  DeviceInformationType,
} from "securecom-graphql/client";
import ProgrammingConceptForm from "../../ProgrammingConceptForm";
import { useDeviceInformationFragment } from "../DeviceInformationContext";
import { DeviceInformationCardOptionsField_deviceInformation$key } from "./__generated__/DeviceInformationCardOptionsField_deviceInformation.graphql";

export const deviceInformationCardOptionsFieldId = (number: string) =>
  `device-information-card-options-${number}`;

function DeviceInformationCardOptionsField() {
  const [{ id, number, device734, deviceType, remoteProgram734 }] =
    useDeviceInformationFragment<DeviceInformationCardOptionsField_deviceInformation$key>(
      graphql`
        fragment DeviceInformationCardOptionsField_deviceInformation on DeviceInformation {
          id
          number
          ... on XrDeviceInformation {
            remoteProgram734
            deviceType
            device734 {
              ... on Device734 {
                cardFormatOptions
                numberOfUserCodeDigits
                enforceSiteCode
              }
            }
          }
        }
      `
    );

  const fieldId = deviceInformationCardOptionsFieldId(String(number));
  const relayEnv = useRelayEnvironment();
  const disabled =
    deviceType !== DeviceInformationType.DOOR || !remoteProgram734;

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Card Options"
      tooltip="Define which type of Wiegand Credentials the device will use."
      disabled={disabled}
    >
      <Select
        id={fieldId}
        value={device734?.cardFormatOptions ?? Device734CardOptions.DMP}
        disabled={disabled}
        onChange={({ target }) => {
          relayEnv.commitUpdate((store) => {
            const device734Options = store
              .get<DeviceInformation>(id)
              ?.getLinkedRecord<Device734>("device734");

            if (device734Options) {
              device734Options.setValue(target.value, "cardFormatOptions");
              device734Options.setValue(false, "enforceSiteCode"); // regardless of the value selected these need to go back to defaults if this is changed
              device734Options.setValue(5, "numberOfUserCodeDigits");
            }
          });
        }}
      >
        <Select.Option value={Device734CardOptions.DMP}>DMP</Select.Option>
        <Select.Option value={Device734CardOptions.CUSTOM}>
          Custom
        </Select.Option>
        <Select.Option value={Device734CardOptions.ANY}>Any</Select.Option>
      </Select>
    </ProgrammingConceptForm.Field>
  );
}

export default DeviceInformationCardOptionsField;
