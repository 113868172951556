import graphql from "babel-plugin-relay/macro";
import Select from "components/FullProgramming/common/Select";
import { panelVersionGTOE } from "components/FullProgramming/utils/panel";
import { range } from "ramda";
import * as React from "react";
import { WirelessAudibles } from "securecom-graphql/client";
import { useSoftwareVersion } from "../PanelContext";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useSystemOptionsFragment } from "./SystemOptionsContext";
import { SystemOptionsWirelessAudiblesField_systemOptions$key } from "./__generated__/SystemOptionsWirelessAudiblesField_systemOptions.graphql";

export const systemOptionsWirelessAudiblesFieldId = () =>
  "system-options-wireless-audibles";

function WirelessAudiblesField() {
  const [{ wirelessAudibles, houseCode }, updateSystemOptions] =
    useSystemOptionsFragment<SystemOptionsWirelessAudiblesField_systemOptions$key>(
      graphql`
        fragment SystemOptionsWirelessAudiblesField_systemOptions on SystemOptions {
          ... on XrSystemOptions {
            wirelessAudibles
            houseCode
          }
          ... on XtSystemOptions {
            wirelessAudibles
            houseCode
          }
          ... on XfSystemOptions {
            wirelessAudibles
            houseCode
          }
          ... on TMSentrySystemOptions {
            wirelessAudibles
          }
        }
      `
    );

  const fieldId = systemOptionsWirelessAudiblesFieldId();
  const troubleAudiblesFirmware = panelVersionGTOE(202, useSoftwareVersion());
  const houseCodeRange = range(1, 51);
  const disabled =
    !houseCodeRange.includes(Number(houseCode)) && !troubleAudiblesFirmware;
  const label = !troubleAudiblesFirmware
    ? "Wireless Audibles"
    : "Trouble Audibles";

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label={label}
      disabled={disabled}
      tooltip={
        !troubleAudiblesFirmware
          ? "Sets annunciation strategy for low battery and missing conditions over the wireless buzzer."
          : "This option allows you to choose when trouble audibles will annunciate from the keypad. This also includes AC Trouble for Fire Keypads, Battery Trouble, Panel Tamper, and other System Troubles."
      }
    >
      <Select
        id={fieldId}
        name={fieldId}
        disabled={disabled}
        value={wirelessAudibles ?? undefined}
        required
        onChange={({ target }) => {
          updateSystemOptions((recordProxy) => {
            recordProxy.setValue(target.value, "wirelessAudibles");
          });
        }}
      >
        <Select.Option value={WirelessAudibles.ANY}>Any</Select.Option>
        <Select.Option value={WirelessAudibles.DAY}>Day</Select.Option>
        <Select.Option value={WirelessAudibles.MIN}>Min</Select.Option>
      </Select>
    </ProgrammingConceptForm.Field>
  );
}

export default WirelessAudiblesField;
