/**
 * @generated SignedSource<<8fd4ab4aabec17de250123753233cb34>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type DeviceInformationCommType = "KEYPAD_BUS" | "NETWORK" | "NONE" | "WIRELESS";
export type DeviceInformationType = "CAMERA" | "DOOR" | "FIRE" | "KEYPAD" | "NONE" | "VPLEX" | "XR550" | "ZONE_EXPANDER" | "_1100T";
import { FragmentRefs } from "relay-runtime";
export type DeviceInformationProgram734OptionsField_deviceInformation$data = {
  readonly deviceCommunicationMethod?: DeviceInformationCommType;
  readonly deviceType?: DeviceInformationType;
  readonly number: number;
  readonly remoteProgram734?: boolean;
  readonly " $fragmentType": "DeviceInformationProgram734OptionsField_deviceInformation";
};
export type DeviceInformationProgram734OptionsField_deviceInformation$key = {
  readonly " $data"?: DeviceInformationProgram734OptionsField_deviceInformation$data;
  readonly " $fragmentSpreads": FragmentRefs<"DeviceInformationProgram734OptionsField_deviceInformation">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DeviceInformationProgram734OptionsField_deviceInformation",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "number",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "remoteProgram734",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "deviceType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "deviceCommunicationMethod",
          "storageKey": null
        }
      ],
      "type": "XrDeviceInformation",
      "abstractKey": null
    }
  ],
  "type": "DeviceInformation",
  "abstractKey": "__isDeviceInformation"
};

(node as any).hash = "de5dabb80a08395fb57638c5d90f37cf";

export default node;
