import graphql from "babel-plugin-relay/macro";
import useEffectOnce from "common/hooks/useEffectOnce";
import { isNotNullOrUndefined } from "common/utils/universal/function";
import Select from "components/FullProgramming/common/Select";
import { resolvePanelType } from "components/FullProgramming/utils/panel";
import * as React from "react";
import { ConnectionHandler, useRelayEnvironment } from "react-relay";
import { RecordProxy } from "relay-runtime";
import RelayModernEnvironment from "relay-runtime/lib/store/RelayModernEnvironment";
import {
  Area,
  AreaConnection,
  BadZonesOption,
  fromGlobalId,
  ID,
  ProgrammingTemplate,
  SystemType,
  SystemType as SystemTypeEnum,
  toAreaId,
} from "securecom-graphql/client";
import { addNewArea, addNewXrArea } from "../../utils";
import { useInitialConnectHasBeenEstablished } from "../ControlSystemContext";
import { usePanelFragment } from "../PanelContext";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useTemplateContext } from "../TemplateContext";
import { useSystemOptionsFragment } from "./SystemOptionsContext";

import {
  SystemOptionsSystemTypeField_panel$data,
  SystemOptionsSystemTypeField_panel$key,
} from "./__generated__/SystemOptionsSystemTypeField_panel.graphql";
import { SystemOptionsSystemTypeField_systemOptions$key } from "./__generated__/SystemOptionsSystemTypeField_systemOptions.graphql";

export const systemOptionsSystemTypeFieldId = () =>
  "system-options-system-type";

function SystemTypeField() {
  const [{ systemType }, updateSystemOptions] =
    useSystemOptionsFragment<SystemOptionsSystemTypeField_systemOptions$key>(
      graphql`
        fragment SystemOptionsSystemTypeField_systemOptions on SystemOptions {
          ... on XrSystemOptions {
            systemType
          }
          ... on XtSystemOptions {
            systemType
          }
        }
      `
    );

  const [panel] = usePanelFragment<SystemOptionsSystemTypeField_panel$key>(
    graphql`
      fragment SystemOptionsSystemTypeField_panel on Panel {
        id
        hardwareModel
        accountNumber
        online
        areas(first: 32, sort: { keys: ["number"], order: ASC }) {
          __id
          maxNumberOfAreas
          minNumberOfAreas
          edges {
            cursor
            node {
              id
              number
            }
          }
        }
      }
    `
  );

  const fieldId = systemOptionsSystemTypeFieldId();
  const initialConnectionHasBeenEstablished =
    useInitialConnectHasBeenEstablished();
  const relayEnv = useRelayEnvironment();
  const systemId = fromGlobalId(panel.id as unknown as ID)[1];
  const { isXr } = resolvePanelType(panel.hardwareModel);
  const { templateId } = useTemplateContext();

  let areasHandledByTemplate: number[] = [];

  relayEnv.commitUpdate((store) => {
    if (templateId) {
      const template = store.get<ProgrammingTemplate>(templateId);
      areasHandledByTemplate =
        template
          ?.getLinkedRecord("concepts")
          ?.getLinkedRecords("areaInformations")
          ?.map((area) => {
            return Number(area.getValue("number"));
          }) ?? [];
    }
  });

  const previousSystemType = React.useRef<SystemType | null>(
    systemType as SystemType
  );

  React.useEffect(() => {
    if (isNotNullOrUndefined(systemType)) {
      if ((systemType as SystemType) !== previousSystemType.current) {
        previousSystemType.current = systemType as SystemType;

        isXr
          ? updateXrAreaInformation(
              relayEnv,
              panel,
              systemId,
              systemType as SystemTypeEnum,
              areasHandledByTemplate
            )
          : updateAreaInformation(
              relayEnv,
              panel,
              systemId,
              systemType as SystemTypeEnum,
              areasHandledByTemplate
            );
      }
    }
  }, [systemType, relayEnv, panel, systemId, isXr, areasHandledByTemplate]);

  useEffectOnce(() => {
    if (isNotNullOrUndefined(systemType)) {
      if (
        !panel.online ||
        (panel.online && !initialConnectionHasBeenEstablished)
      ) {
        // If the panel is in pre-programming, we need to set the area information because SCAPI doesn't return the correct areas.
        isXr
          ? updateXrAreaInformation(
              relayEnv,
              panel,
              systemId,
              systemType as SystemTypeEnum,
              areasHandledByTemplate
            )
          : updateAreaInformation(
              relayEnv,
              panel,
              systemId,
              systemType as SystemTypeEnum,
              areasHandledByTemplate
            );
      }
    }
  });

  return (
    <ProgrammingConceptForm.Field fieldId={fieldId} label="System Type">
      <Select
        id={fieldId}
        name={fieldId}
        value={systemType}
        required
        onChange={({ target }) => {
          updateSystemOptions((recordProxy) => {
            recordProxy.setValue(target.value, "systemType");
          });
        }}
      >
        <Select.Option value={SystemTypeEnum.AREA}>Area</Select.Option>
        <Select.Option value={SystemTypeEnum.ALL_PERIMETER}>
          All/Perimeter
        </Select.Option>
        <Select.Option value={SystemTypeEnum.HOME_SLEEP_AWAY}>
          Home/Sleep/Away
        </Select.Option>
        {isXr && (
          <Select.Option value={SystemTypeEnum.HOME_SLEEP_AWAY_GUEST}>
            HSA with Guest
          </Select.Option>
        )}
      </Select>
    </ProgrammingConceptForm.Field>
  );
}

export default SystemTypeField;

const setAreaDefaults = (areaRecord: RecordProxy<Area>) => {
  areaRecord.setValue(false, "autoArmEnabled");
  areaRecord.setValue(BadZonesOption.BYPASS, "badZonesOption");
  areaRecord.setValue(false, "autoDisarmEnabled");
};

const updateAreaInformation = (
  relayEnv: RelayModernEnvironment,
  panel: SystemOptionsSystemTypeField_panel$data,
  systemId: string,
  systemType: SystemTypeEnum,
  areasHandledByTemplate: number[]
) => {
  const existingAreaNumbers = panel.areas.edges
    .map((area) => Number(area.node?.number))
    .filter(isNotNullOrUndefined)
    .concat(areasHandledByTemplate);

  switch (systemType) {
    case SystemTypeEnum.AREA: {
      const maxAreasInAreaMode = 6;

      relayEnv.commitUpdate((store) => {
        const areasConnection = store.get<AreaConnection>(panel.areas.__id);
        if (areasConnection) {
          areasConnection.setValue(maxAreasInAreaMode, "maxNumberOfAreas");
          areasConnection.setValue(1, "minNumberOfAreas");
        }
      });
      break;
    }
    case SystemTypeEnum.ALL_PERIMETER: {
      relayEnv.commitUpdate((store) => {
        const areasConnection = store.get<AreaConnection>(panel.areas.__id);
        if (areasConnection) {
          areasConnection.setValue(2, "maxNumberOfAreas");
          areasConnection.setValue(2, "minNumberOfAreas");
          if (!existingAreaNumbers.includes(1)) {
            addNewArea(
              store,
              areasConnection,
              toAreaId(systemId, 1, true),
              "PERIMETER"
            );
          }
          if (!existingAreaNumbers.includes(2)) {
            addNewArea(
              store,
              areasConnection,
              toAreaId(systemId, 2, true),
              "INTERIOR"
            );
          }

          panel.areas.edges.forEach((area) => {
            if (area.node) {
              const areaRecord = store.get<Area>(area.node.id);

              if (areaRecord) {
                const areaNumber = Number(areaRecord.getValue("number"));

                if (areaNumber > 2) {
                  ConnectionHandler.deleteNode(areasConnection, area.node.id);
                  store.delete(area.node.id);
                  return;
                } else if (areaNumber === 1) {
                  areaRecord.setValue("PERIMETER", "name");
                } else if (areaNumber === 2) {
                  areaRecord.setValue("INTERIOR", "name");
                }
                if (!areasHandledByTemplate.includes(areaNumber)) {
                  setAreaDefaults(areaRecord);
                }
              }
            }
          });
        }
      });
      break;
    }
    case SystemTypeEnum.HOME_SLEEP_AWAY: {
      relayEnv.commitUpdate((store) => {
        const areasConnection = store.get<AreaConnection>(panel.areas.__id);
        if (areasConnection) {
          areasConnection.setValue(3, "maxNumberOfAreas");
          areasConnection.setValue(2, "minNumberOfAreas");
          if (!existingAreaNumbers.includes(1)) {
            addNewArea(
              store,
              areasConnection,
              toAreaId(systemId, 1, true),
              "PERIMETER"
            );
          }
          if (!existingAreaNumbers.includes(2)) {
            addNewArea(
              store,
              areasConnection,
              toAreaId(systemId, 2, true),
              "INTERIOR"
            );
          }
          if (!existingAreaNumbers.includes(3)) {
            addNewArea(
              store,
              areasConnection,
              toAreaId(systemId, 3, true),
              "BEDROOMS"
            );
          }

          panel.areas.edges.forEach((area) => {
            if (area.node) {
              const areaRecord = store.get<Area>(area.node.id);

              if (areaRecord) {
                const areaNumber = Number(areaRecord.getValue("number"));

                if (areaNumber > 3) {
                  ConnectionHandler.deleteNode(areasConnection, area.node.id);
                  store.delete(area.node.id);
                  return;
                } else if (areaNumber === 1) {
                  areaRecord.setValue("PERIMETER", "name");
                } else if (areaNumber === 2) {
                  areaRecord.setValue("INTERIOR", "name");
                } else if (areaNumber === 3) {
                  areaRecord.setValue("BEDROOMS", "name");
                }
                if (!areasHandledByTemplate.includes(areaNumber)) {
                  setAreaDefaults(areaRecord);
                }
              }
            }
          });
        }
      });
      break;
    }
  }
};

const updateXrAreaInformation = (
  relayEnv: RelayModernEnvironment,
  panel: SystemOptionsSystemTypeField_panel$data,
  systemId: string,
  systemType: SystemTypeEnum,
  areasHandledByTemplate: number[]
) => {
  const { isXr150, isXr350, isXr550 } = resolvePanelType(panel.hardwareModel);
  const existingAreaNumbers = panel.areas.edges
    .map((area) => Number(area.node?.number))
    .filter(isNotNullOrUndefined)
    .concat(areasHandledByTemplate);

  switch (systemType) {
    case SystemTypeEnum.AREA: {
      const maxAreasInAreaMode = isXr150 ? 8 : isXr350 ? 16 : isXr550 ? 32 : 8;

      relayEnv.commitUpdate((store) => {
        const areasConnection = store.get<AreaConnection>(panel.areas.__id);
        if (areasConnection) {
          areasConnection.setValue(maxAreasInAreaMode, "maxNumberOfAreas");
          areasConnection.setValue(1, "minNumberOfAreas");
        }
      });
      break;
    }
    case SystemTypeEnum.ALL_PERIMETER: {
      relayEnv.commitUpdate((store) => {
        const areasConnection = store.get<AreaConnection>(panel.areas.__id);
        if (areasConnection) {
          areasConnection.setValue(2, "maxNumberOfAreas");
          areasConnection.setValue(2, "minNumberOfAreas");
          if (!existingAreaNumbers.includes(1)) {
            addNewXrArea(
              store,
              areasConnection,
              toAreaId(systemId, 1, true),
              panel.accountNumber,
              "PERIMETER"
            );
          }
          if (!existingAreaNumbers.includes(2)) {
            addNewXrArea(
              store,
              areasConnection,
              toAreaId(systemId, 2, true),
              panel.accountNumber,
              "INTERIOR"
            );
          }

          panel.areas.edges.forEach((area) => {
            if (area.node) {
              const areaRecord = store.get<Area>(area.node.id);

              if (areaRecord) {
                const areaNumber = Number(areaRecord.getValue("number"));

                if (areaNumber > 2) {
                  ConnectionHandler.deleteNode(areasConnection, area.node.id);
                  store.delete(area.node.id);
                  return;
                } else if (areaNumber === 1) {
                  areaRecord.setValue("PERIMETER", "name");
                } else if (areaNumber === 2) {
                  areaRecord.setValue("INTERIOR", "name");
                }
                if (!areasHandledByTemplate.includes(areaNumber)) {
                  setAreaDefaults(areaRecord);
                }
              }
            }
          });
        }
      });
      break;
    }
    case SystemTypeEnum.HOME_SLEEP_AWAY: {
      relayEnv.commitUpdate((store) => {
        const areasConnection = store.get<AreaConnection>(panel.areas.__id);
        if (areasConnection) {
          areasConnection.setValue(3, "maxNumberOfAreas");
          areasConnection.setValue(2, "minNumberOfAreas");
          if (!existingAreaNumbers.includes(1)) {
            addNewXrArea(
              store,
              areasConnection,
              toAreaId(systemId, 1, true),
              panel.accountNumber,
              "PERIMETER"
            );
          }
          if (!existingAreaNumbers.includes(2)) {
            addNewXrArea(
              store,
              areasConnection,
              toAreaId(systemId, 2, true),
              panel.accountNumber,
              "INTERIOR"
            );
          }
          if (!existingAreaNumbers.includes(3)) {
            addNewXrArea(
              store,
              areasConnection,
              toAreaId(systemId, 3, true),
              panel.accountNumber,
              "BEDROOMS"
            );
          }

          panel.areas.edges.forEach((area) => {
            if (area.node) {
              const areaRecord = store.get<Area>(area.node.id);

              if (areaRecord) {
                const areaNumber = Number(areaRecord.getValue("number"));

                if (areaNumber > 3) {
                  ConnectionHandler.deleteNode(areasConnection, area.node.id);
                  store.delete(area.node.id);
                  return;
                } else if (areaNumber === 1) {
                  areaRecord.setValue("PERIMETER", "name");
                } else if (areaNumber === 2) {
                  areaRecord.setValue("INTERIOR", "name");
                } else if (areaNumber === 3) {
                  areaRecord.setValue("BEDROOMS", "name");
                }
                if (!areasHandledByTemplate.includes(areaNumber)) {
                  setAreaDefaults(areaRecord);
                }
              }
            }
          });
        }
      });
      break;
    }
    case SystemTypeEnum.HOME_SLEEP_AWAY_GUEST: {
      relayEnv.commitUpdate((store) => {
        const areasConnection = store.get<AreaConnection>(panel.areas.__id);
        if (areasConnection) {
          areasConnection.setValue(isXr150 ? 6 : 9, "maxNumberOfAreas");
          areasConnection.setValue(6, "minNumberOfAreas");
          if (!existingAreaNumbers.includes(1)) {
            addNewXrArea(
              store,
              areasConnection,
              toAreaId(systemId, 1, true),
              panel.accountNumber,
              "PERIMETER"
            );
          }
          if (!existingAreaNumbers.includes(2)) {
            addNewXrArea(
              store,
              areasConnection,
              toAreaId(systemId, 2, true),
              panel.accountNumber,
              "INTERIOR"
            );
          }
          if (!existingAreaNumbers.includes(3)) {
            addNewXrArea(
              store,
              areasConnection,
              toAreaId(systemId, 3, true),
              panel.accountNumber,
              "BEDROOMS"
            );
          }
          if (!existingAreaNumbers.includes(4)) {
            addNewXrArea(
              store,
              areasConnection,
              toAreaId(systemId, 4, true),
              panel.accountNumber,
              "GUEST PERIMETER"
            );
          }
          if (!existingAreaNumbers.includes(5)) {
            addNewXrArea(
              store,
              areasConnection,
              toAreaId(systemId, 5, true),
              panel.accountNumber,
              "GUEST INTERIOR"
            );
          }
          if (!existingAreaNumbers.includes(6)) {
            addNewXrArea(
              store,
              areasConnection,
              toAreaId(systemId, 6, true),
              panel.accountNumber,
              "GUEST BEDROOMS"
            );
          }

          panel.areas.edges.forEach((area) => {
            if (area.node) {
              const areaRecord = store.get<Area>(area.node.id);

              if (areaRecord) {
                const areaNumber = Number(areaRecord.getValue("number"));

                if (areaNumber > 9) {
                  ConnectionHandler.deleteNode(areasConnection, area.node.id);
                  store.delete(area.node.id);
                  return;
                } else if (areaNumber === 1) {
                  areaRecord.setValue("PERIMETER", "name");
                } else if (areaNumber === 2) {
                  areaRecord.setValue("INTERIOR", "name");
                } else if (areaNumber === 3) {
                  areaRecord.setValue("BEDROOMS", "name");
                } else if (areaNumber === 4) {
                  areaRecord.setValue("GUEST PERIMETER", "name");
                } else if (areaNumber === 5) {
                  areaRecord.setValue("GUEST INTERIOR", "name");
                } else if (areaNumber === 6) {
                  areaRecord.setValue("GUEST BEDROOMS", "name");
                } else if (areaNumber === 7) {
                  areaRecord.setValue("GUEST 2 PERIMETER", "name");
                } else if (areaNumber === 8) {
                  areaRecord.setValue("GUEST 2 INTERIOR", "name");
                } else if (areaNumber === 9) {
                  areaRecord.setValue("GUEST 2 BEDROOMS", "name");
                }
                if (!areasHandledByTemplate.includes(areaNumber)) {
                  setAreaDefaults(areaRecord);
                }
              }
            }
          });
        }
      });
      break;
    }
  }
};
