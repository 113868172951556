import graphql from "babel-plugin-relay/macro";
import TextInput from "components/FullProgramming/common/TextInput";
import { resolvePanelType } from "components/FullProgramming/utils/panel";
import { curry } from "ramda";
import * as React from "react";
import { KeyfobButtonType, KeyfobOutputAction } from "securecom-graphql/client";
import { resolveOutputNumbers } from "../OutputInformationFields/utils";
import { getClosestOutputValue } from "../OutputOptionsFields/utils";
import { useHardwareModel } from "../PanelContext";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { PanelHardwareModel } from "../__generated__/PanelContextUseHardwareModel_panel.graphql";
import { useKeyfobFragment } from "./KeyfobContext";
import { KeyfobButtonNumberType } from "./utils";
import { KeyfobButtonOutputField_keyfob$key } from "./__generated__/KeyfobButtonOutputField_keyfob.graphql";

export const keyfobButtonOutputFieldId = curry(
  (buttonNumber: KeyfobButtonNumberType, number: string) =>
    `keyfob-button-output-button-number-${buttonNumber.toLowerCase()}-${number}`
);

function KeyfobButtonOutputField(props: {
  buttonNumber: "One" | "Two" | "Three" | "Four";
}) {
  const [
    {
      number,
      buttonOneAction,
      buttonTwoAction,
      buttonThreeAction,
      buttonFourAction,
      buttonOneOutput,
      buttonTwoOutput,
      buttonThreeOutput,
      buttonFourOutput,
      buttonOneOutputAction,
      buttonTwoOutputAction,
      buttonThreeOutputAction,
      buttonFourOutputAction,
    },
    updateKeyfob,
  ] = useKeyfobFragment<KeyfobButtonOutputField_keyfob$key>(
    graphql`
      fragment KeyfobButtonOutputField_keyfob on Keyfob {
        number
        buttonOneAction
        buttonTwoAction
        buttonThreeAction
        buttonFourAction
        buttonOneOutput
        buttonTwoOutput
        buttonThreeOutput
        buttonFourOutput
        buttonOneOutputAction
        buttonTwoOutputAction
        buttonThreeOutputAction
        buttonFourOutputAction
      }
    `
  );
  const hardwareModel = useHardwareModel();
  const { buttonNumber } = props;
  const fieldId = keyfobButtonOutputFieldId(buttonNumber, String(number));

  const pattern = (hardwareModel: PanelHardwareModel) => {
    const {
      isXt30,
      isXt50,
      isXtl,
      isXtlN,
      isXtlPlus,
      isXtlW,
      isTakeoverPanel,
      isXr150,
      isXr350,
      isCellComEx,
    } = resolvePanelType(hardwareModel);

    if (isXt30 || isXt50) {
      return "(0{0,2}0|0{0,2}[1-4]|0{0,1}3[1-4]|0{0,1}4[1-4]|F0[1-9]|F1[0-9]|F20)";
    }
    if (isXtl || isXtlPlus || isXtlN || isXtlW) {
      return "(0{0,2}0|0{0,1}5[1-4]|0{0,1}6[1-4]|F0[1-9]|F1[0-9]|F20)";
    }
    if (isCellComEx) {
      return "(0{0,2}0|0{0,2}[1]";
    }
    if (isTakeoverPanel) {
      return "(0{0,2}0|0{0,2}[1-2]|F0[1-9]|F1[0-9]|F20)";
    }

    if (isXr150) {
      return "(0{0,3}|0{0,2}[1-6]|4([5-6][0-9]|7[0-4]|[8-9][0-9])|5[0-9][0-9]|D0[1-8]|[FG](0[1-9]|1[0-9]|20))";
    }
    if (isXr350) {
      return "(0{0,3}|0{0,2}[1-6]|4([5-6][0-9]|7[0-4]|[8-9][0-9])|[5-7][0-9][0-9]|D(0[1-9]|1[0-6])|[FG](0[1-9]|1[0-9]|20))";
    } // Return for XR550
    else
      return "(0{0,3}|0{0,2}[1-6]|4([5-6][0-9]|7[0-4]|[8-9][0-9])|[5-9][0-9][0-9]|D(0[1-9]|1[0-6])|[FG](0[1-9]|1[0-9]|20))";
  };
  const validationMessage = (hardwareModel: PanelHardwareModel) => {
    const {
      isXt30,
      isXt50,
      isXtl,
      isXtlN,
      isXtlPlus,
      isXtlW,
      isTakeoverPanel,
      isXr150,
      isXr350,
      isXr550,
      isCellComEx,
    } = resolvePanelType(hardwareModel);

    if (isXt30 || isXt50) {
      return "Valid values are 0, 1-4, 31-34, 41-44, F01-F20.";
    }
    if (isXtl || isXtlPlus || isXtlN || isXtlW) {
      return "Valid values are 0, 51-54, 61-64, F01-F20.";
    }
    if (isCellComEx) {
      return "Valid values are 0, 1.";
    }
    if (isTakeoverPanel) {
      return "Valid values are 0, 1-2, F01-F20.";
    }

    if (isXr150) {
      return "Valid values are 1-6, 450-474, 480-599, D01-D08, G01-G20, and F01-F20.";
    }
    if (isXr350) {
      return "Valid values are 1-6, 450-474, 480-799, D01-D16, G01-G20, and F01-F20.";
    }
    if (isXr550) {
    } // Return for XR550
    else
      return "Valid values are 1-6, 450-474, 480-999, D01-D16, G01-G20, and F01-F20.";
  };

  const inlineHelp = (hardwareModel: PanelHardwareModel) => {
    const {
      isXt30,
      isXt50,
      isXtl,
      isXtlN,
      isXtlPlus,
      isXtlW,
      isTakeoverPanel,
      isXr150,
      isXr350,
      isCellComEx,
    } = resolvePanelType(hardwareModel);

    if (isXt30 || isXt50) {
      return "0, 1-4, 31-34, 41-44, F01-F20.";
    }
    if (isXtl || isXtlPlus || isXtlN || isXtlW) {
      return "0, 51-54, 61-64, F01-F20";
    }
    if (isCellComEx) {
      return "0, 1";
    }
    if (isTakeoverPanel) {
      return "0, 1-2, F01-F20";
    }

    if (isXr150) {
      return "1-6, 450-474, 480-599, D01-D08, G01-G20, F01-F20";
    }
    if (isXr350) {
      return "1-6, 450-474, 480-799, D01-D16, G01-G20, F01-F20";
    } // Return for XR550
    else return "1-6, 450-474, 480-999, D01-D16, G01-G20, F01-F20";
  };

  const disableButtonTypes = [
    KeyfobButtonType.UNUSED,
    KeyfobButtonType.ARMING,
    KeyfobButtonType.TOGGLE_ARM_DISARM,
    KeyfobButtonType.DISARMING,
    KeyfobButtonType.SENSOR_RESET,
    KeyfobButtonType.STATUS,
  ];

  let disabled;
  let value;
  let outputAction: KeyfobOutputAction;

  switch (buttonNumber) {
    case "One":
      disabled = disableButtonTypes.includes(
        buttonOneAction as KeyfobButtonType
      );
      value = buttonOneOutput ?? 0;
      outputAction = buttonOneOutputAction as KeyfobOutputAction;
      break;
    case "Two":
      disabled = disableButtonTypes.includes(
        buttonTwoAction as KeyfobButtonType
      );
      value = buttonTwoOutput ?? 0;
      outputAction = buttonTwoOutputAction as KeyfobOutputAction;
      break;
    case "Three":
      disabled = disableButtonTypes.includes(
        buttonThreeAction as KeyfobButtonType
      );
      value = buttonThreeOutput ?? 0;
      outputAction = buttonThreeOutputAction as KeyfobOutputAction;
      break;
    case "Four":
      disabled = disableButtonTypes.includes(
        buttonFourAction as KeyfobButtonType
      );
      value = buttonFourOutput ?? 0;
      outputAction = buttonFourOutputAction as KeyfobOutputAction;
      break;
    default:
      disabled = false;
      value = 0;
      outputAction = KeyfobOutputAction.STEADY;
  }

  const validNumericalRange = resolveOutputNumbers(hardwareModel, true).concat(
    0
  ); // 0 disables outputs
  const originalValue = React.useRef(`button${buttonNumber}Output`).current;

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Output"
      disabled={disabled}
    >
      <TextInput
        id={fieldId}
        pattern={`${pattern(hardwareModel)}`}
        validationMessage={`${validationMessage(hardwareModel)}`}
        inlineHelp={`${inlineHelp(hardwareModel)}`}
        title={`${inlineHelp(hardwareModel)}`}
        disabled={disabled}
        value={value}
        required
        onChange={({ target }) => {
          updateKeyfob((recordProxy) => {
            recordProxy.setValue(target.value, `button${buttonNumber}Output`);
          });
        }}
        onBlur={({ target }) => {
          const newValue = target.value;
          updateKeyfob((recordProxy) => {
            const isOutputGroupRegex = new RegExp(/[G](0[1-9]|1[0-9]|20)/);
            const isFavoriteGroupRegex = new RegExp(/[F](0[1-9]|1[0-9]|20)/);
            const closestValue = getClosestOutputValue(
              newValue,
              originalValue,
              hardwareModel,
              validNumericalRange
            );

            recordProxy.setValue(closestValue, `button${buttonNumber}Output`);

            // Set 'Output Action' to 'Steady' if 'Output' is a favorite group or is an output group and 'Output Action' is 'Pulse' or 'Toggle'
            if (
              isFavoriteGroupRegex.test(closestValue) ||
              (isOutputGroupRegex.test(closestValue) &&
                (outputAction === KeyfobOutputAction.PULSE ||
                  outputAction === KeyfobOutputAction.TOGGLE))
            ) {
              recordProxy.setValue(
                KeyfobOutputAction.STEADY,
                `button${buttonNumber}OutputAction`
              );
            }
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default KeyfobButtonOutputField;
