import { any, equals } from "ramda";
import { PanelHardwareModel } from "securecom-graphql/client";
import { PanelHardwareModel as PanelContextHardwareModel } from "../common/__generated__/PanelContextUseHardwareModel_panel.graphql";

export const resolvePanelType = (hardwareModel: PanelContextHardwareModel) => ({
  isTakeoverPanel: any(equals(hardwareModel), [
    PanelHardwareModel.CELLCOM_SL,
    PanelHardwareModel.CELLCOM_EX,
    PanelHardwareModel.ICOM_SL,
    PanelHardwareModel.DUALCOM,
    PanelHardwareModel.MINI_CELLCOM,
    PanelHardwareModel.CELLCOM,
  ]),
  isCellComEx: hardwareModel === PanelHardwareModel.CELLCOM_EX,
  isXt: any(equals(hardwareModel), [
    PanelHardwareModel.XTL,
    PanelHardwareModel.XTLN,
    PanelHardwareModel.XTLW,
    PanelHardwareModel.XTLP,
    PanelHardwareModel.XT30,
    PanelHardwareModel.XT30L,
    PanelHardwareModel.XT50,
    PanelHardwareModel.XT50L,
  ]),
  isXtl: equals(hardwareModel, PanelHardwareModel.XTL),
  isXtlN: equals(hardwareModel, PanelHardwareModel.XTLN),
  isXtlW: equals(hardwareModel, PanelHardwareModel.XTLW),
  isXtlPlus: equals(hardwareModel, PanelHardwareModel.XTLP),
  isXt30: any(equals(hardwareModel), [
    PanelHardwareModel.XT30,
    PanelHardwareModel.XT30L,
  ]),
  isXt50: any(equals(hardwareModel), [
    PanelHardwareModel.XT50,
    PanelHardwareModel.XT50L,
  ]),
  isXr: any(equals(hardwareModel), [
    PanelHardwareModel.XR150,
    PanelHardwareModel.XR350,
    PanelHardwareModel.XR550,
  ]),
  isXr150: equals(hardwareModel, PanelHardwareModel.XR150),
  isXr350: equals(hardwareModel, PanelHardwareModel.XR350),
  isXr550: equals(hardwareModel, PanelHardwareModel.XR550),
  isXf: any(equals(hardwareModel), [
    PanelHardwareModel.XF6_100,
    PanelHardwareModel.XF6_500,
  ]),
  isXf6_100: equals(hardwareModel, PanelHardwareModel.XF6_100),
  isXf6_500: equals(hardwareModel, PanelHardwareModel.XF6_500),
  isTMSentry: any(equals(hardwareModel), [PanelHardwareModel.TMS6]),
  isTMS6: equals(hardwareModel, PanelHardwareModel.TMS6),
});

export const domesticPanelWithinRange =
  (softwareVersion: number | string) =>
  (minSoftwareVersion: number, maxSoftwareVersion?: number) =>
    Number(softwareVersion) >= minSoftwareVersion &&
    Number(softwareVersion) <= (maxSoftwareVersion ?? 599);

export const internationalPanelWithinRange =
  (softwareVersion: number | string) =>
  (minSoftwareVersion: number, maxSoftwareVersion?: number) =>
    Number(softwareVersion) >= minSoftwareVersion &&
    Number(softwareVersion) <= (maxSoftwareVersion ?? 999);

export const domesticOrInternationalPanelWithinRange =
  (softwareVersion: number | string) =>
  (
    [domesticMin, domesticMax]: [number, number?],
    [internationalMin, internationalMax]: [number, number?]
  ) =>
    domesticPanelWithinRange(softwareVersion)(domesticMin, domesticMax) ||
    internationalPanelWithinRange(softwareVersion)(
      internationalMin,
      internationalMax
    );

export const panelVersionLTOE = (
  checkVersion: number | string,
  softwareVersion: number | string
) =>
  domesticOrInternationalPanelWithinRange(softwareVersion)(
    [0, Number(checkVersion)],
    [600, Number(checkVersion) + 500]
  );

export const panelVersionGTOE = (
  checkVersion: number | string,
  softwareVersion: number | string
) =>
  domesticOrInternationalPanelWithinRange(softwareVersion)(
    [Number(checkVersion)],
    [Number(checkVersion) + 500]
  );
