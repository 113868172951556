import graphql from "babel-plugin-relay/macro";
import ConfirmingDeleteTooltip from "components/ConfirmingDeleteTooltip";
import { useResetControlSystemIntegrations } from "components/StatusBadge";
import { useShowAlert } from "contexts/AlertsContext";
import { ascend } from "ramda";
import React from "react";
import { useFragment, useMutation } from "react-relay/hooks";
import styled from "styled-components";
import { Clickable } from "../DaStyledElements";
import Icon from "../Icon/";
import { ReceiverForm } from "./ReceiverForm";
import { useUserIsTechSupport } from "./UserIsTechSupportContext";
import { ReceiverTableDealer_dealer$key } from "./__generated__/ReceiverTableDealer_dealer.graphql";
import { ReceiverTableMutation } from "./__generated__/ReceiverTableMutation.graphql";
import { ReceiverTableReceiver_receiver$key } from "./__generated__/ReceiverTableReceiver_receiver.graphql";
import { ReceiverTableUninitializedReceiver_dealer$key } from "./__generated__/ReceiverTableUninitializedReceiver_dealer.graphql";

const ReceiversInteriorButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @media screen and (max-width: 768px) {
    justify-content: flex-start;
  }
`;
const PanelTable = styled.div`
  border-top-left-radius: 0.4rem;
  border-top-right-radius: 0.4rem;
  border-bottom-left-radius: 0.4rem;
  border-bottom-right-radius: 0.4rem;
`;
const TableBr = styled.table`
  border-top-left-radius: 0.4rem;
  border-top-right-radius: 0.4rem;
  border-bottom-left-radius: 0.4rem;
  border-bottom-right-radius: 0.4rem;
`;
const TableData = styled.td`
  @media screen and (max-width: 768px) {
    &:before {
      content: attr(aria-label);
      font-weight: bold;
      width: 120px;
      min-width: 120px;
    }
    display: flex;
    flex-direction: column;
    width: 100%;
  }
`;

const TableRow = styled.tr`
  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
`;

const TableHead = styled.thead`
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const tableHeaders = [
  "Receiver Number",
  "Automation Prefix",
  "IP",
  "Port",
  "Description",
];

export const ReceiverTable: React.FC<
  React.PropsWithChildren<{
    dealerRef: ReceiverTableDealer_dealer$key;
  }>
> = ({ dealerRef }) => {
  const dealer = useFragment(
    graphql`
      fragment ReceiverTableDealer_dealer on Dealer {
        id
        receivers(sort: { keys: ["number"], order: ASC }) {
          nodes {
            id
            number
            ...ReceiverTableReceiver_receiver
          }
        }
        uninitializedReceiverNumbers
        ...ReceiverTableUninitializedReceiver_dealer
      }
    `,
    dealerRef
  );

  return (
    <PanelTable className="panel panel-table">
      <div className="panel-body">
        <TableBr className="table table-striped table-fixed--not-mobile">
          <TableHead>
            <tr>
              <th>{tableHeaders[0]}</th>
              <th>{tableHeaders[1]}</th>
              <th>{tableHeaders[2]}</th>
              <th>{tableHeaders[3]}</th>
              <th>{tableHeaders[4]}</th>
              <th>&nbsp;</th>
            </tr>
          </TableHead>
          <tbody>
            {[...dealer.receivers.nodes, ...dealer.uninitializedReceiverNumbers]
              .sort(
                ascend((receiver) =>
                  typeof receiver === "number" ? receiver : receiver.number
                )
              )
              .map((receiver) =>
                typeof receiver === "number" ? (
                  <UninitializedReceiver
                    key={receiver}
                    dealerRef={dealer}
                    number={receiver}
                  />
                ) : (
                  <ReceiverItem receiverRef={receiver} key={receiver.id} />
                )
              )}
          </tbody>
          <tfoot>&nbsp;</tfoot>
        </TableBr>
      </div>
    </PanelTable>
  );
};

export const ReceiverItem: React.FC<
  React.PropsWithChildren<{
    receiverRef: ReceiverTableReceiver_receiver$key;
  }>
> = ({ receiverRef }) => {
  const receiver = useFragment(
    graphql`
      fragment ReceiverTableReceiver_receiver on Receiver {
        id
        automationPrefix
        number
        ip
        port
        description
        ...EditReceiverFormReceiver_receiver
      }
    `,
    receiverRef
  );

  const [deleteReceiver] = useMutation<ReceiverTableMutation>(graphql`
    mutation ReceiverTableMutation($receiverId: ID!) {
      deleteReceiver(receiverId: $receiverId) {
        ... on DeleteReceiverSuccessPayload {
          __typename
          dealer {
            receivers(sort: { keys: ["number"], order: ASC }) {
              nodes {
                ...ReceiverTableReceiver_receiver
              }
            }
            uninitializedReceiverNumbers
          }
        }
        ... on DeleteReceiverErrorPayload {
          __typename
          errorType: type
        }
      }
    }
  `);

  const resetControlSystemIntegrations = useResetControlSystemIntegrations();

  const [modalIsOpen, setModalOpen] = React.useState(false);
  const showAlert = useShowAlert();
  const { isTechSupport } = useUserIsTechSupport();

  return (
    <>
      <TableRow key={receiver.id}>
        <TableData aria-label={tableHeaders[0]}>{receiver.number}</TableData>
        <TableData aria-label={tableHeaders[1]}>
          {receiver.automationPrefix}
        </TableData>
        <TableData aria-label={tableHeaders[2]}>{receiver.ip}</TableData>
        <TableData aria-label={tableHeaders[3]}>{receiver.port}</TableData>
        <TableData aria-label={tableHeaders[4]}>
          {receiver.description}
        </TableData>
        <TableData aria-label="">
          <ReceiversInteriorButtonContainer>
            <Clickable.IconButton
              icon={
                <Icon
                  name="settings"
                  size="2.4rem"
                  color="var(--color-primary)"
                  style={{ marginRight: ".8rem" }}
                />
              }
              onClick={() => setModalOpen(true)}
            />
            {isTechSupport ? null : (
              <ConfirmingDeleteTooltip
                iconSize="2.4rem"
                onConfirm={() => {
                  deleteReceiver({
                    variables: {
                      receiverId: receiver.id,
                    },
                    onCompleted(data) {
                      if (
                        data.deleteReceiver.__typename ===
                        "DeleteReceiverSuccessPayload"
                      ) {
                        showAlert({
                          type: "success",
                          text: `Deleted receiver: ${receiver.number}`,
                        });
                        resetControlSystemIntegrations();
                      }
                    },
                  });
                }}
                message={`Deleting receiver ${receiver.number}. Are you sure?`}
              />
            )}
          </ReceiversInteriorButtonContainer>
        </TableData>
      </TableRow>
      {modalIsOpen && (
        <ReceiverForm
          receiverRef={receiver}
          onCancel={() => setModalOpen(false)}
        />
      )}
    </>
  );
};

const ButtonThatLooksLikeBadge = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.4rem 0.8rem;
  font-size: 1.2rem;
  font-weight: var(--font-weight-medium);
  color: white;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 100px;
  background-color: var(--color-primary-300);
  border: 1px solid var(--color-primary-400);
  color: white;
`;

const UninitializedReceiver: React.FC<
  React.PropsWithChildren<{
    dealerRef: ReceiverTableUninitializedReceiver_dealer$key;
    number: number;
  }>
> = ({ dealerRef, number }) => {
  const dealer = useFragment(
    graphql`
      fragment ReceiverTableUninitializedReceiver_dealer on Dealer {
        ...NewReceiverFormModalDealer_dealer
      }
    `,
    dealerRef
  );

  const [modalIsOpen, setModalOpen] = React.useState(false);

  return (
    <>
      <TableRow>
        <TableData aria-label={tableHeaders[0]}>{number}</TableData>
        <TableData aria-label={tableHeaders[1]}>&nbsp;</TableData>
        <TableData aria-label={tableHeaders[2]}>&nbsp;</TableData>
        <TableData aria-label={tableHeaders[3]}>&nbsp;</TableData>
        <TableData aria-label={tableHeaders[4]}>&nbsp;</TableData>
        <TableData aria-label="">
          <ButtonThatLooksLikeBadge onClick={() => setModalOpen(true)}>
            Set Up Receiver
          </ButtonThatLooksLikeBadge>
        </TableData>
      </TableRow>
      {modalIsOpen && (
        <ReceiverForm
          dealerRef={dealer}
          initialNumber={number}
          onCancel={() => setModalOpen(false)}
        />
      )}
    </>
  );
};
