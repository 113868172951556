/**
 * @generated SignedSource<<9d1db403678567d93c652bc70f553911>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AreaArmedOutputNumberField_xrArea$data = {
  readonly armedOutputNumber: string;
  readonly number: string;
  readonly " $fragmentType": "AreaArmedOutputNumberField_xrArea";
};
export type AreaArmedOutputNumberField_xrArea$key = {
  readonly " $data"?: AreaArmedOutputNumberField_xrArea$data;
  readonly " $fragmentSpreads": FragmentRefs<"AreaArmedOutputNumberField_xrArea">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AreaArmedOutputNumberField_xrArea",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "number",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "armedOutputNumber",
      "storageKey": null
    }
  ],
  "type": "XrArea",
  "abstractKey": null
};

(node as any).hash = "fe83ccf46c6a5ccbb8d337a519ab5baf";

export default node;
