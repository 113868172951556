import { between } from "app/common/services/util";
import graphql from "babel-plugin-relay/macro";
import Flex from "common/components/web/Flex";
import { isNotNullOrUndefined } from "common/utils/universal/function";
import { setAdd, setDifference, setFirst } from "common/utils/universal/set";
import {
  FieldLabelCell,
  FieldLabelContainer,
  FieldLabelText,
  FieldValueCell,
  RegionSettingsDeleteButton,
  RegionSettingsHeaderText,
  RegionsSettingsRoot,
  RegionsSettingsRow,
  RegionsSettingsTable,
} from "components/CameraEditCommon/CameraEditStyledComponents";
import { Tooltip } from "components/DaStyledElements";
import Switch from "components/FullProgramming/common/Switch";
import {
  panelVersionGTOE,
  resolvePanelType,
} from "components/FullProgramming/utils/panel";
import Icon from "components/Icon";
import TextInput from "components/Inputs/TextInput";
import Select from "components/Select";

import { MessagePosition } from "common/components/web/Tooltip";
import InlineTooltip from "components/InlineTooltip";
import { range } from "ramda";
import * as React from "react";
import { useFragment, useRelayEnvironment } from "react-relay";
import { fetchQuery, RecordProxy } from "relay-runtime";
import {
  Area,
  idAsString,
  toZoneId,
  VarHubCameraDetectionRegion,
  Zone,
} from "securecom-graphql/client";
import AddButton from "../CameraEditCommon/AddButton";
import { removeRegionUpdater } from "./utils";
import { RecorderCameraDetectionRegionSettingsZonesQuery } from "./__generated__/RecorderCameraDetectionRegionSettingsZonesQuery.graphql";
import { RecorderCameraDetectionRegionSettings_panel$key } from "./__generated__/RecorderCameraDetectionRegionSettings_panel.graphql";
import { RecorderCameraDetectionRegionSettings_varHub$key } from "./__generated__/RecorderCameraDetectionRegionSettings_varHub.graphql";
import { RecorderCameraDetectionRegionSettings_varHubCamera$key } from "./__generated__/RecorderCameraDetectionRegionSettings_varHubCamera.graphql";
import { RecorderCameraDetectionRegionSettings_varHubCameraDetectionRegion$key } from "./__generated__/RecorderCameraDetectionRegionSettings_varHubCameraDetectionRegion.graphql";

function RecorderCameraDetectionRegionSettings(props: {
  detectionRegion: RecorderCameraDetectionRegionSettings_varHubCameraDetectionRegion$key;
  panel: RecorderCameraDetectionRegionSettings_panel$key;
  camera: RecorderCameraDetectionRegionSettings_varHubCamera$key;
  varHub: RecorderCameraDetectionRegionSettings_varHub$key | null;
  index: number;
  isEditable: boolean;
  globalSystemId: string;
  systemId: string;
  setRemovedDetectionRegionIds: React.Dispatch<
    React.SetStateAction<Set<string>>
  >;
  regionZoneNumbers: number[];
}) {
  const {
    detectionRegion,
    panel,
    camera,
    varHub,
    index,
    isEditable,
    globalSystemId,
    systemId,
    setRemovedDetectionRegionIds,
    regionZoneNumbers,
  } = props;
  const cameraData = useFragment(
    graphql`
      fragment RecorderCameraDetectionRegionSettings_varHubCamera on VarConnectedCamera {
        id
        cameraName
        detectionRegions {
          id
          slotNumber
          zone {
            number
          }
        }
      }
    `,
    camera
  );
  const regionData = useFragment(
    graphql`
      fragment RecorderCameraDetectionRegionSettings_varHubCameraDetectionRegion on VarHubCameraDetectionRegion {
        id
        name
        detectPeople
        detectAnimals
        detectVehicles
        drawRegionOnAlerts
        index
        isNew
        loiteringPersonSeconds
        slotNumber
        zone {
          id
          name
          number
          isNew
          area {
            id
          }
        }
      }
    `,
    detectionRegion
  );
  const panelData = useFragment(
    graphql`
      fragment RecorderCameraDetectionRegionSettings_panel on Panel {
        hardwareModel
        softwareVersion
        areas(first: 32, sort: { keys: ["number"], order: ASC }) {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    `,
    panel
  );

  const varHubData = useFragment(
    graphql`
      fragment RecorderCameraDetectionRegionSettings_varHub on VarHub {
        id
        panelConnectionEnabled
      }
    `,
    varHub
  );
  const relayEnv = useRelayEnvironment();

  const [fetchingZoneNumber, setFetchingZoneNumber] = React.useState(false);
  const [zoneAvailable, setZoneAvailable] = React.useState(true);
  const [takenZoneNumbersList, setTakenZoneNumbersList] = React.useState<
    Set<number>
  >(new Set() as Set<number>);
  const [possibleZoneNumbersList, setPossibleZoneNumbersList] = React.useState<
    Set<number>
  >(new Set() as Set<number>);
  const [axZoneNumbersList, setAxZoneNumbersList] = React.useState<Set<number>>(
    new Set() as Set<number>
  );
  const [zoneIsDuplicated, setZoneIsDuplicated] = React.useState(false);
  const [zoneIsOutOfRange, setZoneIsOutOfRange] = React.useState(false);
  const [zoneIsInAxRange, setZoneIsInAxRange] = React.useState(false);
  const initialDetectLoitering =
    regionData.loiteringPersonSeconds !== null &&
    regionData.loiteringPersonSeconds !== 0;
  const [initalLoiteringSeconds, setInitalLoiteringSeconds] = React.useState(
    regionData.loiteringPersonSeconds !== null &&
      regionData.loiteringPersonSeconds !== 0
      ? regionData.loiteringPersonSeconds % 60
      : null
  );
  const [initalLoiteringMinutes, setInitalLoiteringMinutes] = React.useState(
    regionData.loiteringPersonSeconds !== null &&
      regionData.loiteringPersonSeconds !== 0
      ? Math.floor(regionData.loiteringPersonSeconds / 60)
      : null
  );
  const [detectLoitering, setDetectLoitering] = React.useState(
    initialDetectLoitering
  );
  const [loiteringSeconds, setLoiteringSeconds] = React.useState<number | null>(
    initalLoiteringSeconds
  );
  const [loiteringMinutes, setLoiteringMinutes] = React.useState<number | null>(
    initalLoiteringMinutes
  );

  const handleMinutesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(event.target.value);
    if (!isNaN(value)) {
      setLoiteringMinutes(value);
    } else {
      setLoiteringMinutes(null);
    }
  };

  const handleSecondsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(event.target.value);
    if (!isNaN(value)) {
      setLoiteringSeconds(value);
    } else {
      setLoiteringSeconds(null);
    }
  };
  React.useEffect(() => {
    const totalLoiteringSeconds =
      (loiteringMinutes ?? 0) * 60 + (loiteringSeconds ?? 0);
    relayEnv.commitUpdate((store) => {
      const region = store.get(
        regionData.id
      ) as RecordProxy<VarHubCameraDetectionRegion>;
      if (region) {
        region.setValue(totalLoiteringSeconds, "loiteringPersonSeconds");
      }
    });
  }, [loiteringMinutes, loiteringSeconds]);

  const panelSupportsVarIntegration =
    resolvePanelType(panelData.hardwareModel).isXr &&
    panelVersionGTOE(221, panelData.softwareVersion);

  const regionDetectionTypeNotSelected = !(
    regionData.detectPeople ||
    regionData.detectAnimals ||
    regionData.detectVehicles
  );

  const noDetectionRegionTypeCustomMessage = (input: HTMLInputElement) => {
    if (input.validity.valueMissing) {
      return "At least one detection type must be selected";
    }
    return "";
  };

  const handleLoiteringChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const isChecked = event.target.checked;
    if (!isChecked) {
      setLoiteringMinutes(null);
      setLoiteringSeconds(null);
    } else {
      setLoiteringMinutes(initalLoiteringMinutes);
      setLoiteringSeconds(initalLoiteringSeconds);
    }
  };

  return (
    <RegionsSettingsRoot>
      <RegionsSettingsTable>
        <thead>
          <RegionsSettingsRow>
            <th colSpan={2}>
              <Flex alignItems="center" justifyContent="space-between">
                <RegionSettingsHeaderText
                  index={regionData.slotNumber ?? index}
                  onMouseEnter={() => {
                    // brings the region to the top of the pile
                    relayEnv.commitUpdate((store) => {
                      const cameraRecord = store.get(idAsString(cameraData.id));
                      if (cameraRecord && isEditable) {
                        cameraRecord.setValue(
                          regionData.index,
                          "activeDetectionRegionIndex"
                        );
                      }
                    });
                  }}
                >
                  #{(regionData.slotNumber ?? index) + 1} {regionData.name}
                </RegionSettingsHeaderText>
                {isEditable ? (
                  <RegionSettingsDeleteButton
                    type="button"
                    onClick={() => {
                      if (!regionData.isNew) {
                        setRemovedDetectionRegionIds(setAdd(regionData.id));
                      }
                      relayEnv.commitUpdate((store) => {
                        removeRegionUpdater(
                          store,
                          cameraData.id,
                          regionData.id
                        );
                      });
                    }}
                  >
                    <Icon name="trash" />
                  </RegionSettingsDeleteButton>
                ) : null}
              </Flex>
            </th>
          </RegionsSettingsRow>
        </thead>
        <tbody>
          <RegionsSettingsRow>
            <FieldLabelCell>
              <FieldLabelContainer>
                <FieldLabelText
                  htmlFor={`detection-region-${regionData.id}-name`}
                >
                  Name
                </FieldLabelText>
              </FieldLabelContainer>
            </FieldLabelCell>
            <FieldValueCell>
              <TextInput
                id={`detection-region-${regionData.id}-name`}
                value={regionData.name}
                required
                autoFocus={!!regionData.isNew}
                onChange={(event) => {
                  relayEnv.commitUpdate((store) => {
                    const region = store.get(
                      regionData.id
                    ) as RecordProxy<VarHubCameraDetectionRegion>;
                    if (region) {
                      region.setValue(event.target.value, "name");
                    }
                  });
                }}
              />
            </FieldValueCell>
          </RegionsSettingsRow>
          <RegionsSettingsRow>
            <FieldLabelCell>
              <FieldLabelContainer>
                <FieldLabelText
                  htmlFor={`detection-region-${regionData.id}-detect-people`}
                >
                  Detect People
                </FieldLabelText>
              </FieldLabelContainer>
            </FieldLabelCell>
            <FieldValueCell>
              <Switch
                id={`detection-region-${regionData.id}-detect-people`}
                label="Detect People"
                required={regionDetectionTypeNotSelected}
                checked={regionData.detectPeople}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  handleLoiteringChange(event);
                  relayEnv.commitUpdate((store) => {
                    const region = store.get(
                      regionData.id
                    ) as RecordProxy<VarHubCameraDetectionRegion>;
                    if (region) {
                      region.setValue(event.target.checked, "detectPeople");
                    }
                  });
                }}
                getValidationMessage={noDetectionRegionTypeCustomMessage}
              />
            </FieldValueCell>
          </RegionsSettingsRow>
          {regionData.detectPeople ? (
            <>
              <RegionsSettingsRow>
                <FieldLabelCell>
                  <FieldLabelContainer>
                    <FieldLabelText
                      htmlFor={`detection-region-${regionData.id}-detect-people-loitering`}
                      style={{ marginLeft: "20px" }}
                    >
                      Detect Loitering
                    </FieldLabelText>
                    <InlineTooltip
                      message="Enable to only alert when a person is within this region for the programmed period of time."
                      position={MessagePosition.Right}
                    />
                  </FieldLabelContainer>
                </FieldLabelCell>
                <FieldValueCell>
                  <Switch
                    id={`detection-region-${regionData.id}-detect-people-loitering`}
                    label="Detect Loitering"
                    checked={detectLoitering}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setDetectLoitering(event.target.checked);
                      handleLoiteringChange(event);
                    }}
                    getValidationMessage={noDetectionRegionTypeCustomMessage}
                  />
                </FieldValueCell>
              </RegionsSettingsRow>
              {detectLoitering ? (
                <>
                  <RegionsSettingsRow>
                    <FieldLabelCell>
                      <FieldLabelContainer>
                        <FieldLabelText
                          htmlFor={`detection-region-${regionData.id}-loitering-min`}
                          style={{ marginLeft: "20px" }}
                        >
                          Minutes
                        </FieldLabelText>
                      </FieldLabelContainer>
                    </FieldLabelCell>
                    <FieldValueCell>
                      <TextInput
                        id={`detection-region-${regionData.id}-loitering-min`}
                        maxLength={32}
                        disabled={!detectLoitering}
                        value={
                          loiteringMinutes !== null
                            ? loiteringMinutes.toString()
                            : ""
                        }
                        onChange={handleMinutesChange}
                      />
                    </FieldValueCell>
                  </RegionsSettingsRow>
                  <RegionsSettingsRow>
                    <FieldLabelCell>
                      <FieldLabelContainer>
                        <FieldLabelText
                          htmlFor={`detection-region-${regionData.id}-loitering-sec`}
                          style={{ marginLeft: "20px" }}
                        >
                          Seconds
                        </FieldLabelText>
                      </FieldLabelContainer>
                    </FieldLabelCell>
                    <FieldValueCell>
                      <TextInput
                        id={`detection-region-${regionData.id}-loitering-sec`}
                        maxLength={32}
                        disabled={!detectLoitering}
                        value={
                          loiteringSeconds !== null
                            ? loiteringSeconds.toString()
                            : ""
                        }
                        onChange={handleSecondsChange}
                      />
                    </FieldValueCell>
                  </RegionsSettingsRow>
                </>
              ) : null}
            </>
          ) : null}
          <RegionsSettingsRow>
            <FieldLabelCell>
              <FieldLabelContainer>
                <FieldLabelText
                  htmlFor={`detection-region-${regionData.id}-detect-animals`}
                >
                  Detect Animals
                </FieldLabelText>
              </FieldLabelContainer>
            </FieldLabelCell>
            <FieldValueCell>
              <Switch
                id={`detection-region-${regionData.id}-detect-animals`}
                label="Detect Animals"
                required={regionDetectionTypeNotSelected}
                checked={regionData.detectAnimals}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  relayEnv.commitUpdate((store) => {
                    const region = store.get(
                      regionData.id
                    ) as RecordProxy<VarHubCameraDetectionRegion>;
                    if (region) {
                      region.setValue(event.target.checked, "detectAnimals");
                    }
                  });
                }}
                getValidationMessage={noDetectionRegionTypeCustomMessage}
              />
            </FieldValueCell>
          </RegionsSettingsRow>
          <RegionsSettingsRow>
            <FieldLabelCell>
              <FieldLabelContainer>
                <FieldLabelText
                  htmlFor={`detection-region-${regionData.id}-detect-vehicles`}
                >
                  Detect Vehicles
                </FieldLabelText>
              </FieldLabelContainer>
            </FieldLabelCell>
            <FieldValueCell>
              <Switch
                id={`detection-region-${regionData.id}-detect-vehicles`}
                label="Detect Vehicles"
                checked={regionData.detectVehicles}
                required={regionDetectionTypeNotSelected}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  relayEnv.commitUpdate((store) => {
                    const region = store.get(
                      regionData.id
                    ) as RecordProxy<VarHubCameraDetectionRegion>;
                    if (region) {
                      region.setValue(event.target.checked, "detectVehicles");
                    }
                  });
                }}
                getValidationMessage={noDetectionRegionTypeCustomMessage}
              />
            </FieldValueCell>
          </RegionsSettingsRow>
          <RegionsSettingsRow>
            <FieldLabelCell>
              <FieldLabelContainer>
                <Tooltip content="Display the configured region on event previews.">
                  <Tooltip.LabelText
                    htmlFor={`detection-region-${regionData.id}-bounding-boxes`}
                  >
                    Display Regions
                  </Tooltip.LabelText>
                </Tooltip>
              </FieldLabelContainer>
            </FieldLabelCell>
            <FieldValueCell>
              <Switch
                id={`detection-region-${regionData.id}-bounding-boxes`}
                label="Display Regions"
                checked={regionData.drawRegionOnAlerts}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  relayEnv.commitUpdate((store) => {
                    const region = store.get(
                      regionData.id
                    ) as RecordProxy<VarHubCameraDetectionRegion>;
                    if (region) {
                      region.setValue(
                        event.target.checked,
                        "drawRegionOnAlerts"
                      );
                    }
                  });
                }}
              />
            </FieldValueCell>
          </RegionsSettingsRow>
          {regionData.zone ? (
            <>
              <RegionsSettingsRow>
                <FieldLabelCell>
                  <FieldLabelContainer>
                    <FieldLabelText
                      htmlFor={`detection-region-${regionData.id}-zone-name`}
                    >
                      Zone Name
                    </FieldLabelText>
                  </FieldLabelContainer>
                </FieldLabelCell>
                <FieldValueCell>
                  <TextInput
                    id={`detection-region-${regionData.id}-zone-name`}
                    value={regionData.zone.name}
                    required
                    maxLength={32}
                    onChange={(event) => {
                      relayEnv.commitUpdate((store) => {
                        const region = store.get(
                          regionData.id
                        ) as RecordProxy<VarHubCameraDetectionRegion>;
                        if (region) {
                          const zone = region.getLinkedRecord("zone");
                          if (zone) {
                            zone.setValue(event.target.value, "name");
                          }
                        }
                      });
                    }}
                  />
                </FieldValueCell>
              </RegionsSettingsRow>
              <RegionsSettingsRow>
                <FieldLabelCell>
                  <FieldLabelContainer>
                    <FieldLabelText
                      htmlFor={`detection-region-${regionData.id}-zone-number`}
                    >
                      Zone Number
                    </FieldLabelText>
                  </FieldLabelContainer>
                </FieldLabelCell>
                <FieldValueCell>
                  <TextInput
                    id={`detection-region-${regionData.id}-zone-number`}
                    value={regionData.zone.number}
                    disabled={!regionData.zone.isNew}
                    required
                    onChange={({ target }) => {
                      relayEnv.commitUpdate((store) => {
                        const region = store.get(
                          regionData.id
                        ) as RecordProxy<VarHubCameraDetectionRegion>;
                        if (region) {
                          const zone = region.getLinkedRecord("zone");
                          if (zone) {
                            zone.setValue(target.value, "number");
                          }
                        }
                      });
                    }}
                    onBlur={({ target }) => {
                      setZoneIsDuplicated(
                        () =>
                          takenZoneNumbersList.has(Number(target.value)) ||
                          cameraData.detectionRegions
                            .map(
                              (region) =>
                                region.id !== regionData.id &&
                                Number(region.zone?.number) ===
                                  Number(target.value)
                            )
                            .includes(true)
                      );
                      setZoneIsOutOfRange(
                        () =>
                          target.value !== "" &&
                          !possibleZoneNumbersList.has(Number(target.value))
                      );
                      setZoneIsInAxRange(() =>
                        axZoneNumbersList.has(Number(target.value))
                      );
                    }}
                  />
                </FieldValueCell>
              </RegionsSettingsRow>

              {regionData.zone.isNew ? (
                zoneIsDuplicated || zoneIsOutOfRange || zoneIsInAxRange ? (
                  <RegionsSettingsRow>
                    <FieldLabelCell />
                    <FieldValueCell>
                      <span className="text-danger">
                        {zoneIsOutOfRange
                          ? "Zone Number Is Out Of Range"
                          : zoneIsInAxRange
                          ? "Zone Number Is In AX Bus Range"
                          : "Zone Number Is Already In Use"}
                      </span>
                    </FieldValueCell>
                  </RegionsSettingsRow>
                ) : null
              ) : null}
              <RegionsSettingsRow>
                <FieldLabelCell>
                  <FieldLabelContainer>
                    <FieldLabelText
                      htmlFor={`detection-region-${regionData.id}-area`}
                    >
                      Area
                    </FieldLabelText>
                  </FieldLabelContainer>
                </FieldLabelCell>
                <FieldValueCell>
                  <Select
                    id={`detection-region-${regionData.id}-area`}
                    value={regionData.zone.area?.id ?? ""}
                    required
                    onChange={(event) => {
                      relayEnv.commitUpdate((store) => {
                        const region = store.get(
                          regionData.id
                        ) as RecordProxy<VarHubCameraDetectionRegion>;
                        if (region) {
                          const zone = region.getLinkedRecord("zone");
                          if (zone) {
                            const area = store.get(
                              event.target.value
                            ) as RecordProxy<Area>;
                            if (event.target.value && area) {
                              zone.setLinkedRecord(area, "area");
                            } else {
                              zone.setValue(null, "area");
                            }
                          }
                        }
                      });
                    }}
                  >
                    <Select.Option value="">Select an area</Select.Option>
                    {panelData.areas.edges.map(({ node }) =>
                      node ? (
                        <Select.Option key={node.id} value={node.id}>
                          {node.name}
                        </Select.Option>
                      ) : null
                    )}
                  </Select>
                </FieldValueCell>
              </RegionsSettingsRow>
            </>
          ) : panelSupportsVarIntegration &&
            varHubData?.panelConnectionEnabled ? (
            zoneAvailable ? (
              <RegionsSettingsRow>
                <FieldLabelCell />
                <FieldValueCell>
                  <AddButton
                    loading={fetchingZoneNumber}
                    onClick={async () => {
                      setFetchingZoneNumber(true);
                      const data =
                        await fetchQuery<RecorderCameraDetectionRegionSettingsZonesQuery>(
                          relayEnv,
                          graphql`
                            query RecorderCameraDetectionRegionSettingsZonesQuery(
                              $systemId: ID!
                            ) {
                              controlSystem: node(id: $systemId) {
                                ... on ControlSystem {
                                  varHubDetectionRegionZoneNumberMin
                                  varHubDetectionRegionZoneNumberMax
                                  panel {
                                    deviceInformations {
                                      ... on XrDeviceInformation {
                                        axNumber
                                        deviceType
                                        deviceCommunicationMethod
                                      }
                                    }
                                  }
                                  zones {
                                    number
                                  }
                                }
                              }
                            }
                          `,
                          { systemId: globalSystemId },
                          { fetchPolicy: "network-only" }
                        ).toPromise();

                      if (
                        data?.controlSystem?.panel?.deviceInformations &&
                        data?.controlSystem?.zones &&
                        isNotNullOrUndefined(
                          data.controlSystem.varHubDetectionRegionZoneNumberMin
                        ) &&
                        isNotNullOrUndefined(
                          data.controlSystem.varHubDetectionRegionZoneNumberMax
                        )
                      ) {
                        const {
                          zones,
                          varHubDetectionRegionZoneNumberMin,
                          varHubDetectionRegionZoneNumberMax,
                          panel: { deviceInformations },
                        } = data.controlSystem;
                        const axTakenZoneNumbers = new Set(
                          deviceInformations.flatMap((device) => {
                            const number = Number(device.axNumber);
                            switch (true) {
                              case device.deviceType === "CAMERA" ||
                                device.deviceType === "VPLEX":
                                return [];
                              case between(number, 17, 32):
                                return range(500, 600);
                              case between(number, 33, 48):
                                return range(600, 700);
                              case between(number, 49, 64):
                                return range(700, 800);
                              case between(number, 65, 80):
                                return range(800, 900);
                              case between(number, 81, 96):
                                return range(900, 1000);
                              default:
                                return [];
                            }
                          })
                        );
                        const possibleZoneNumbers = new Set(
                          range(
                            varHubDetectionRegionZoneNumberMin,
                            varHubDetectionRegionZoneNumberMax + 1
                          )
                        );

                        const takenZoneNumbers = new Set(
                          zones
                            .map(({ number }) => parseInt(number, 10))
                            .concat(regionZoneNumbers)
                            .concat(Array.from(axTakenZoneNumbers))
                        );
                        const availableNumbers = setDifference(
                          takenZoneNumbers,
                          possibleZoneNumbers
                        ) as Set<number>;
                        setTakenZoneNumbersList(() => takenZoneNumbers);
                        setAxZoneNumbersList(() => axTakenZoneNumbers);
                        setPossibleZoneNumbersList(() => possibleZoneNumbers);
                        const nextAvailableNumber = setFirst(availableNumbers);

                        if (isNotNullOrUndefined(nextAvailableNumber)) {
                          relayEnv.commitUpdate((store) => {
                            const region = store.get(
                              regionData.id
                            ) as RecordProxy<VarHubCameraDetectionRegion>;
                            if (region) {
                              const zone = store.create(
                                idAsString(
                                  toZoneId(systemId, nextAvailableNumber)
                                ),
                                "Zone"
                              ) as RecordProxy<Zone>;
                              zone.setValue(``, "name");
                              zone.setValue(nextAvailableNumber, "number");
                              zone.setValue(true, "isNew");
                              zone.setValue(null, "area");
                              region.setLinkedRecord(zone, "zone");
                            }
                          });
                        } else {
                          setZoneAvailable(false);
                        }

                        setFetchingZoneNumber(true);
                      }
                    }}
                  >
                    Add Alarm Zone
                  </AddButton>
                </FieldValueCell>
              </RegionsSettingsRow>
            ) : (
              <span>No zones available</span>
            )
          ) : null}
        </tbody>
      </RegionsSettingsTable>
    </RegionsSettingsRoot>
  );
}

export default RecorderCameraDetectionRegionSettings;
