import graphql from "babel-plugin-relay/macro";
import Select from "components/FullProgramming/common/Select";
import { resolvePanelType } from "components/FullProgramming/utils/panel";
import { formatToLxDeviceNumber } from "components/FullProgramming/XRFullProgramming/XRDeviceSetupProgrammingConceptForm/utils";
import * as React from "react";
import { RecordProxy } from "relay-runtime";
import {
  DeviceInformationCommType,
  DeviceInformationType,
  PanelHardwareModel,
} from "securecom-graphql/client";
import {
  removeInvalidFieldTypeFromAllListItems,
  useInvalidFields,
  useSetInvalidFields,
} from "../InvalidFieldsContext";
import {
  useGetAllDeviceIds,
  useHardwareModel,
  useHas1100T,
  useRemoteZoneList,
  useSupports1100T,
  useSupportsAxBus,
  useSupportsNetworkExpander,
  useSupportsVplex,
  useSupportsXR550,
  useSupportsXVCamera,
} from "../PanelContext";
import ProgrammingConceptForm, {
  AxDoorDevicesAmountAvailableContext,
} from "../ProgrammingConceptForm";
import { useProgrammingConceptIdContext } from "../ProgrammingConceptIdContext";
import { useDeviceInformationFragment } from "./DeviceInformationContext";
import { isAxBusDisabledByVar, isAXBusRange, isIensoZone } from "./utils";
import {
  DeviceInformationDeviceTypeField_deviceInformation$data,
  DeviceInformationDeviceTypeField_deviceInformation$key,
} from "./__generated__/DeviceInformationDeviceTypeField_deviceInformation.graphql";

export const deviceInformationDeviceTypeFieldId = (number: string) =>
  `device-information-device-type-${number}`;

function DeviceInformationDeviceTypeField() {
  const [{ lxNumber, number, deviceType }, updateDeviceInformation] =
    useDeviceInformationFragment<DeviceInformationDeviceTypeField_deviceInformation$key>(
      graphql`
        fragment DeviceInformationDeviceTypeField_deviceInformation on DeviceInformation {
          id
          lxNumber
          number
          ... on XrDeviceInformation {
            deviceType
          }
          ... on XfDeviceInformation {
            deviceType
          }
        }
      `
    );

  const hardwareModel = useHardwareModel();
  const { isXf } = resolvePanelType(hardwareModel);
  const supportsXVCamera = useSupportsXVCamera();
  const fieldId = deviceInformationDeviceTypeFieldId(String(number));
  const isAXDevice = isAXBusRange(Number(lxNumber));
  const supports1100T = useSupports1100T();
  const supportsXR550 = useSupportsXR550() && number !== 1;
  const has1100T = useHas1100T();
  const show1100TSelection =
    !isXf && //Doesn't apply to XF6 panels
    !isAXDevice && //Can't be AX device
    supports1100T && // Version Check
    number !== 1 && //Cant be device 1
    ((has1100T && deviceType === DeviceInformationType._1100T) || !has1100T); //Panels Support only one 1100T but you don't want to exclude that device
  const supportsVplex =
    useSupportsVplex() && [501, 601, 701, 801, 901].includes(Number(lxNumber));
  const supportsAxBus = useSupportsAxBus();

  const supportsNetworkExpander = useSupportsNetworkExpander();
  const label = "Device Type";
  const deviceIsVplexOnly =
    hardwareModel === PanelHardwareModel.XR150 &&
    formatToLxDeviceNumber(number) === 501;
  const axDoorDeviceAmountAvailable =
    React.useContext(AxDoorDevicesAmountAvailableContext) ?? 0;
  const outOfAxDoorDevices =
    axDoorDeviceAmountAvailable < 0 &&
    hardwareModel === PanelHardwareModel.XR550 &&
    isAXDevice;
  const remoteZoneList = useRemoteZoneList();
  const allDevicesList = useGetAllDeviceIds();
  const allIensoZones = isIensoZone(allDevicesList);
  const axBusIsDisabled = isAxBusDisabledByVar(
    lxNumber,
    remoteZoneList,
    allIensoZones
  );
  const clear734Options = (
    record: RecordProxy<DeviceInformationDeviceTypeField_deviceInformation$data>
  ) => {
    record.setValue(false, "remoteProgram734");
    const device734 = record.getLinkedRecord("device734");
    if (device734) {
      device734.setValue("DMP", "cardFormatOptions");
      device734.setValue(false, "enableOnboardSpeaker");
      device734.setValue(false, "enableZone3RequestToExit");
      device734.setValue(false, "enforceSiteCode");
      device734.setValue("OFF", "noCommWithPanelRelayAction");
      device734.setValue(5, "numberOfUserCodeDigits");
      device734.setValue("127", "siteCode1");
      device734.setValue("", "siteCode2");
      device734.setValue("", "siteCode3");
      device734.setValue("", "siteCode4");
      device734.setValue("", "siteCode5");
      device734.setValue("", "siteCode6");
      device734.setValue("", "siteCode7");
      device734.setValue("", "siteCode8");
      device734.setValue(8, "siteCodeBitLength");
      device734.setValue(1, "siteCodeStartBitPosition");
      device734.setValue(16, "userCodeBitLength");
      device734.setValue(9, "userCodeStartBitPosition");
      device734.setValue(26, "wiegandBitLength");
      device734.setValue(false, "zone2BypassOnRequestToExit");
      device734.setValue(40, "zone2BypassTime");
      device734.setValue(false, "zone2RelockOnStateChange");
      device734.setValue(5, "zone3RequestToExitTime");
    }
  };

  const invalidFields = useInvalidFields();

  const programmingConceptId = useProgrammingConceptIdContext();
  const fieldTypeId = fieldId.split("-").slice(0, -1).join("-");
  const setInvalidFields = useSetInvalidFields();

  React.useEffect(() => {
    if (!outOfAxDoorDevices)
      setInvalidFields(
        removeInvalidFieldTypeFromAllListItems(
          programmingConceptId,
          fieldTypeId,
          invalidFields
        )
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [outOfAxDoorDevices]);

  return (
    <ProgrammingConceptForm.Field fieldId={fieldId} label={label}>
      <Select
        id={fieldId}
        name={fieldId}
        value={deviceType}
        required
        //If the device type is CAMERA - this field should be disabled.
        disabled={
          deviceIsVplexOnly || deviceType === DeviceInformationType.CAMERA
        }
        onChange={({ target }) => {
          updateDeviceInformation((recordProxy) => {
            recordProxy.setValue(
              target.value as DeviceInformationType,
              "deviceType"
            );
            if (target.value !== DeviceInformationType.DOOR) {
              clear734Options(recordProxy);
            }

            switch (target.value) {
              case DeviceInformationType.DOOR:
              case DeviceInformationType.KEYPAD:
                if (DeviceInformationCommType.WIRELESS) {
                  recordProxy.setValue("14", "serialNumber");
                }
                break;
              case DeviceInformationType._1100T:
                recordProxy.setValue(
                  DeviceInformationCommType.WIRELESS,
                  "deviceCommunicationMethod"
                );
                recordProxy.setValue("13", "serialNumber");
                recordProxy.setValue(true, "pinDisarm");
                break;
              case DeviceInformationType.ZONE_EXPANDER:
                recordProxy.setValue(true, "pinDisarm");
                if (isAXDevice) {
                  recordProxy.setValue(true, "pinDisarm");
                  recordProxy.setValue(
                    DeviceInformationCommType.NETWORK,
                    "deviceCommunicationMethod"
                  );
                } else {
                  recordProxy.setValue(
                    DeviceInformationCommType.KEYPAD_BUS,
                    "deviceCommunicationMethod"
                  );
                }
                recordProxy.setValue("", "serialNumber");
                break;
              case DeviceInformationType.XR550:
                recordProxy.setValue(true, "pinDisarm");
                recordProxy.setValue(
                  DeviceInformationCommType.NETWORK,
                  "deviceCommunicationMethod"
                );
                recordProxy.setValue("", "serialNumber");
                break;
              default:
                recordProxy.setValue(
                  DeviceInformationCommType.KEYPAD_BUS,
                  "deviceCommunicationMethod"
                );
                recordProxy.setValue("", "serialNumber");
                break;
            }
          });
        }}
        getValidationMessage={(input) => {
          if (axBusIsDisabled && input.value === DeviceInformationType.DOOR) {
            return "This Device Type is disabled due to an AlarmVision Detection Region Zone Conflict";
          }
          if (
            outOfAxDoorDevices &&
            input.value === DeviceInformationType.DOOR
          ) {
            return "You have exceeded your AX Door Device Limit";
          }
          return "";
        }}
      >
        <Select.Option selected default disabled={true} value="">
          Select a Type
        </Select.Option>
        {supportsXVCamera ? (
          <Select.Option disabled={true} value={DeviceInformationType.CAMERA}>
            Camera
          </Select.Option>
        ) : null}
        {((isAXDevice && supportsAxBus) || !isAXDevice) && !isXf ? (
          <Select.Option
            disabled={axBusIsDisabled || outOfAxDoorDevices}
            value={DeviceInformationType.DOOR}
          >
            {axBusIsDisabled
              ? "Door (AlarmVision Detection Region Zone Conflict)"
              : outOfAxDoorDevices
              ? "Door (Exceeded AX Door Device Limit)"
              : "Door"}
          </Select.Option>
        ) : null}
        {!isAXDevice ? (
          <Select.Option value={DeviceInformationType.FIRE}>Fire</Select.Option>
        ) : null}
        {!isAXDevice && !isXf ? (
          <Select.Option value={DeviceInformationType.KEYPAD}>
            Keypad
          </Select.Option>
        ) : null}
        {!isAXDevice || supportsNetworkExpander ? (
          <Select.Option value={DeviceInformationType.ZONE_EXPANDER}>
            Zone Expander
          </Select.Option>
        ) : null}
        {(supportsVplex || deviceIsVplexOnly) && !isXf ? (
          <Select.Option value={DeviceInformationType.VPLEX}>
            V-Plex
          </Select.Option>
        ) : null}
        {show1100TSelection ? (
          <Select.Option value={DeviceInformationType._1100T}>
            1100T
          </Select.Option>
        ) : null}
        {supportsXR550 ? (
          <Select.Option value={DeviceInformationType.XR550}>
            XR Panel
          </Select.Option>
        ) : null}
      </Select>
    </ProgrammingConceptForm.Field>
  );
}

export default DeviceInformationDeviceTypeField;
