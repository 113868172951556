/**
 * @generated SignedSource<<bcade1c7bb8504d830a4a53237276fc9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SystemOptionsLatchedSupervisoryZonesField_systemOptions$data = {
  readonly latchedSupervisoryZones?: boolean;
  readonly " $fragmentType": "SystemOptionsLatchedSupervisoryZonesField_systemOptions";
};
export type SystemOptionsLatchedSupervisoryZonesField_systemOptions$key = {
  readonly " $data"?: SystemOptionsLatchedSupervisoryZonesField_systemOptions$data;
  readonly " $fragmentSpreads": FragmentRefs<"SystemOptionsLatchedSupervisoryZonesField_systemOptions">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "latchedSupervisoryZones",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SystemOptionsLatchedSupervisoryZonesField_systemOptions",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "XrSystemOptions",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "XfSystemOptions",
      "abstractKey": null
    }
  ],
  "type": "SystemOptions",
  "abstractKey": "__isSystemOptions"
};
})();

(node as any).hash = "b6ad19c010c68c39f7107ae3e8f6911b";

export default node;
