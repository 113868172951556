/**
 * @generated SignedSource<<a5d7dab039a62579e307c28c61fe7b07>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SystemOptionsZoneActivityHoursField_systemOptions$data = {
  readonly zoneActivityHours?: number;
  readonly zoneActivityHoursMax?: number;
  readonly zoneActivityHoursMin?: number;
  readonly " $fragmentType": "SystemOptionsZoneActivityHoursField_systemOptions";
};
export type SystemOptionsZoneActivityHoursField_systemOptions$key = {
  readonly " $data"?: SystemOptionsZoneActivityHoursField_systemOptions$data;
  readonly " $fragmentSpreads": FragmentRefs<"SystemOptionsZoneActivityHoursField_systemOptions">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "zoneActivityHours",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "zoneActivityHoursMin",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "zoneActivityHoursMax",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SystemOptionsZoneActivityHoursField_systemOptions",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "XrSystemOptions",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "XtSystemOptions",
      "abstractKey": null
    }
  ],
  "type": "SystemOptions",
  "abstractKey": "__isSystemOptions"
};
})();

(node as any).hash = "1966bb7a0daa9d431dc358ad2f461be6";

export default node;
