/**
 * @ngdoc service
 * @name App.factory:LoginService
 * @requires $q
 * @requires PROPS
 * @requires SignInAPI
 *
 * @description
 * Factory/model for the login service. Handles user authentication against the VK API
 *
 */
App.factory("LoginService", [
  "$q",
  "$state",
  "PROPS",
  "SignInAPI",
  "AboutMeV2API",
  "CustomRolesService",
  "UserService",
  "DealerService",
  "ImagesService",
  "AuthenticationAPI",
  "RelayService",
  function (
    $q,
    $state,
    PROPS,
    SignInAPI,
    AboutMeV2API,
    CustomRolesService,
    UserService,
    DealerService,
    ImagesService,
    AuthenticationAPI,
    RelayService
  ) {
    var service = {
      /**
       * @ngdoc
       * @name  LoginService.signIn
       * @function Verifies a dealer's credentials using the SignInAPI
       * @param {string} email The user's email address
       * @param {string} password The user's password
       */
      signIn: function (email, password) {
        const _this = this;
        const deferred = $q.defer();
        const user = {};
        user.email = email;
        user.password = password;

        SignInAPI.login(
          {}, //Params
          '{"user":' + angular.toJson(user) + "}", // POST data
          function (data) {
            //success
            deferred.resolve(data);
          },
          function (error) {
            //failure
            deferred.reject(_this.getSCAPISignInErrorDescriptor(error));
          }
        );
        return deferred.promise;
      },

      /**
       * Parse a SCAPI login error and return a string description
       * @param {*} error
       * @returns {string} - description of the login error
       */
      getSCAPISignInErrorDescriptor: function (error) {
        if (
          error.hasOwnProperty("data") &&
          error.data != null &&
          error.data.hasOwnProperty("error") &&
          typeof error.data.error === "string" &&
          error.data.error.length > 0
        ) {
          return error.data.error;
        } else {
          return +error.status === -1 ? "Connection Error" : "Invalid Login";
        }
      },

      getMyInformation: function () {
        const deferred = $q.defer();
        // call the "about_me" API
        AboutMeV2API.get(
          { auth_Token: UserService.auth_token },
          function (data) {
            data.user.SizedImageURLs = ImagesService.GetAllSizeURLs(
              data.user.user_image_url
            );
            deferred.resolve(data.user);
          },
          function (error) {
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },
      canLoginToTechApp: function (user) {
        const loginObj = { canLogin: false, loginError: null };
        if (UserService.isDealerAccessible(user)) {
          loginObj.canLogin = true;
        } else {
          if (user.accessible_type !== "dealer") {
            loginObj.loginError = "Invalid user type.";
          } else {
            loginObj.loginError = "Invalid user role.";
          }
        }
        return loginObj;
      },
      DALoginRouter: function (userInfo) {
        const _this = this;
        const deferred = $q.defer();
        const accessType = userInfo.accessible_type.toLowerCase();
        const role = userInfo.role ? userInfo.role.toLowerCase() : "";
        switch (accessType) {
          case "supervisor":
            deferred.resolve({
              to: "app.dealer.search",
              toParams: {
                dealer_id: userInfo.accessible_id,
                criteria: "Dealers",
                parameter: "",
              },
            });
            break;
          case "dealer":
            if (role === "video_verifier") {
              deferred.resolve({ to: "page.vvhold" });
            } else if (role === "accountant") {
              deferred.resolve({
                to: "app.dealer.dealer-invoices-download",
                toParams: { dealer_id: userInfo.accessible_id },
              });
            } else {
              this.getMyInformation()
                .then(
                  function (user) {
                    _this
                      .signInDealerUser(user)
                      .then(
                        function () {
                          const expoCheck =
                            !user?.customRole?.user_auth_permissions
                              ?.tech_app && document.getElementById("expo");
                          if (
                            DoesNestedPropertyExist(
                              UserService,
                              "user.customRole.id"
                            ) &&
                            (user.role === "technician" ||
                              !UserService.canLoginToDealerAdmin() ||
                              expoCheck)
                          ) {
                            console.warn(
                              "Login attempt from technician with assigned role. Email: " +
                                user.email +
                                " | Role: " +
                                role.name +
                                " | Ineligible to sign into Dealer Admin."
                            );
                            deferred.reject("not authorized");
                            RelayService.logout();
                          } else {
                            if (UserService.isParentDealerLogin()) {
                              deferred.resolve({
                                to: "app.dealer.dealers",
                                toParams: { dealer_id: user.accessible_id },
                              });
                            } else {
                              deferred.resolve({
                                to: "app.dealer.dashboard",
                                toParams: { dealer_id: user.accessible_id },
                              });
                            }
                          }
                        },
                        function (error) {
                          deferred.reject(error);
                        }
                      )
                      .catch(function (error) {
                        console.error(error);
                      });
                  },
                  function (error) {
                    console.error(
                      "Error getting user information: " + angular.toJson(error)
                    );
                    deferred.reject("error getting user information");
                  }
                )
                .catch(function (error) {
                  console.error(error);
                });
            }
            break;
          case "customer":
          default:
            console.warn(
              "Login attempt from (ineligible) accessible_type: " +
                angular.toJson(userInfo.accessible_type) +
                "."
            );
            deferred.reject("not authorized");
            break;
        }
        return deferred.promise;
      },

      /**
       * Dealer users have extra properties to check that are stored on the UserService. This function checks those
       * properties and prepares the UserService.
       * @param {*} user
       */
      signInDealerUser: function (user) {
        const deferred = $q.defer();
        if (user.accessible_type.toLowerCase() === "dealer") {
          if (CustomRolesService.mayHaveCustomRole(user)) {
            CustomRolesService.getUserRole(user.accessible_id, user.id)
              .then(
                function (role) {
                  UserService.prepForDealerUser(
                    new DealerService({ dealer_id: user.accessible_id }),
                    user,
                    role
                  )
                    .then(
                      function () {
                        deferred.resolve();
                      },
                      function (error) {
                        console.error(
                          "loginCtrl->signInDealerUser() - error setting user: " +
                            angular.toJson(error)
                        );
                        deferred.reject(error);
                      }
                    )
                    .catch(function (error) {
                      console.error(error);
                    });
                },
                function (error) {
                  console.error(
                    "loginCtrl->signInDealerUser() - error getting role: " +
                      angular.toJson(error)
                  );
                  deferred.reject(error);
                }
              )
              .catch(function (error) {
                console.error(error);
              });
          } else {
            UserService.prepForDealerUser(
              new DealerService({ dealer_id: user.accessible_id }),
              user
            )
              .then(
                function () {
                  deferred.resolve();
                },
                function (error) {
                  console.error(
                    "loginCtrl->signInDealerUser() - error setting user: " +
                      angular.toJson(error)
                  );
                  deferred.reject(error);
                }
              )
              .catch(function (error) {
                console.error(error);
              });
          }
        } else {
          deferred.reject();
        }
        return deferred.promise;
      },

      /**
       * Returns the user of the same person with the highest authority
       * @returns {*}
       */
      getLoggedInPerson: function () {
        const deferred = $q.defer();
        AuthenticationAPI.authenticate(
          { auth_token: UserService.auth_token },
          {},
          function (data) {
            deferred.resolve(data);
          },
          function (error) {
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },

      /**
       * Returns the user of the same person with the highest authority
       * @param email
       * @param password
       * @returns {*}
       */
      getHighestAuthorityUser: function (email, password) {
        const deferred = $q.defer();
        const body = {
          user: {
            email: email,
            password: password,
          },
        };
        AuthenticationAPI.authenticate(
          {},
          angular.toJson(body),
          function (data) {
            const highestAuthorityUser = service.findHighestAuthorityUser(data);
            deferred.resolve(highestAuthorityUser);
          },
          function (error) {
            deferred.reject(error);
          }
        );
        return deferred.promise;
      },

      /**
       * Find the highest ranked user from a SCAPI authenticate response
       * @param {[{user: {}}]} data
       * @returns {*}
       */
      findHighestAuthorityUser: function (data) {
        let rankedUser = {
          user: data[0].user,
          values: rankUser(data[0].user),
        };
        for (let i = 1; i < data.length; i++) {
          const values = rankUser(data[i].user);
          // If the user is of a higher access type or is of the same with a higher role, set as the highest auth user
          if (
            values.accessibleType > rankedUser.values.accessibleType ||
            (values.accessibleType === rankedUser.values.accessibleType &&
              values.role > rankedUser.values.role)
          ) {
            rankedUser = {
              user: data[i].user,
              values: values,
            };
          }
        }
        return rankedUser.user;
      },
    };
    /**
     * Assign a numeric value to user accessible_type and role for comparison purposes
     * @param user
     * @returns {{accessibleType: number, role: number}}
     */
    function rankUser(user) {
      let values = {
        accessibleType: 0,
        role: 0,
      };
      switch (user.accessible_type.toLowerCase()) {
        case "customer":
          if (user.role === "admin") {
            values.role = 1;
          }
          break;
        case "dealer":
          values.accessibleType = 1;
          switch (user.role) {
            case "sales_person":
              values.role = 1;
              break;
            case "sales_manager":
              values.role = 2;
              break;
            case "technician":
              values.role = 3;
              break;
            case "operator":
              values.role = 4;
              break;
            case "admin":
              values.role = 5;
              break;
          }
          break;
        case "supervisor":
          values.accessibleType = 2;
          switch (user.role) {
            case "operator":
              values.role = 1;
              break;
            case "admin":
              values.role = 2;
              break;
          }
          break;
      }
      return values;
    }

    return service;
  },
]);
