/**
 * @generated SignedSource<<025fecbc5a8df2795a11e29d63d6f646>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RemoteOptionsApnField_remoteOptions$data = {
  readonly allowCellularRemoteProgramming?: boolean | null;
  readonly firstApn?: string | null;
  readonly " $fragmentType": "RemoteOptionsApnField_remoteOptions";
};
export type RemoteOptionsApnField_remoteOptions$key = {
  readonly " $data"?: RemoteOptionsApnField_remoteOptions$data;
  readonly " $fragmentSpreads": FragmentRefs<"RemoteOptionsApnField_remoteOptions">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstApn",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RemoteOptionsApnField_remoteOptions",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        (v0/*: any*/)
      ],
      "type": "XfRemoteOptions",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "allowCellularRemoteProgramming",
          "storageKey": null
        }
      ],
      "type": "XrRemoteOptions",
      "abstractKey": null
    }
  ],
  "type": "RemoteOptions",
  "abstractKey": "__isRemoteOptions"
};
})();

(node as any).hash = "df8a159938f5c44181b6d2d5214a9e56";

export default node;
