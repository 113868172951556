import React from "react";
import styled from "styled-components";

const TooltipBody = styled.div`
  position: absolute;
  top: calc(var(--tooltip-arrow-size) * -1 - 2px);
  left: 50%;
  transform: translateX(-50%) translateY(-100%);
  z-index: 2;
  width: max-content;
  pointer-events: none;
  opacity: 0;
  transition: 0.15s opacity;
`;
const TooltipBodyContent = styled.div`
  transform: translateX(${({ offsetX }) => offsetX}px);
  color: var(--tooltip-text-color);
  font-weight: 500;
  background-color: var(--tooltip-background-color);
  border-radius: 4px;
  padding: 8px 12px;
  max-width: 30rem;
  text-align: left;
  line-height: 1.2;

  @media screen and (max-width: 600px) {
    max-width: 20rem;
  }
`;
const TooltipArrow = styled.div`
  content: "";
  position: absolute;
  border: var(--tooltip-arrow-size) solid var(--tooltip-background-color);
`;
const TooltipArrowLeft = styled(TooltipArrow)`
  left: 0;
  transform: translateX(-100%) translateY(-50%);
  margin-right: -1px;
  top: 60%;
  border-color: transparent var(--tooltip-background-color) transparent
    transparent;
`;
const TooltipArrowRight = styled(TooltipArrow)`
  right: 0;
  transform: translateX(100%) translateY(-50%);
  margin-left: -1px;
  top: 60%;
  border-color: transparent transparent transparent
    var(--tooltip-background-color);
`;
const TooltipArrowTop = styled(TooltipArrow)`
  top: 0%;
  left: 50%;
  margin-bottom: -1px;
  transform: translateX(-50%) translateY(60%);
  border-color: transparent transparent var(--tooltip-background-color)
    transparent;
`;
const TooltipArrowBottom = styled(TooltipArrow)`
  left: 50%;
  margin-top: -1px;
  transform: translateX(-50%) translateY(0);
  top: 100%;
  border-color: var(--tooltip-background-color) transparent transparent
    transparent;
`;
const TooltipWrapper = styled.div`
  --tooltip-text-color: white;
  --tooltip-background-color: #3d85a3;
  --tooltip-margin: 10px;
  --tooltip-arrow-size: 6px;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  line-height: 1;

  &:hover ${TooltipBody} {
    opacity: 1;
    pointer-events: initial;
    transition-delay: 400ms;
  }
`;

const Tooltip = (props) => {
  const [screenOverflow, setScreenOverflow] = React.useState(0);
  const content = React.useRef(null);

  return (
    <TooltipWrapper
      onMouseEnter={() => {
        if (content.current) {
          const margin = 16;
          const bounds = content.current.getBoundingClientRect();
          if (bounds.left < margin) {
            setScreenOverflow(bounds.left - margin);
          } else {
            setScreenOverflow(
              Math.max(0, bounds.right - (window.innerWidth - margin))
            );
          }
        }
      }}
    >
      {props.children}
      <TooltipBody>
        <div ref={content}>
          <TooltipBodyContent role="tooltip" offsetX={-screenOverflow}>
            {props.content}
          </TooltipBodyContent>
        </div>
        {props.direction === "arrow-top" ? (
          <TooltipArrowTop />
        ) : props.direction === "arrow-left" ? (
          <TooltipArrowLeft />
        ) : props.direction === "arrow-right" ? (
          <TooltipArrowRight />
        ) : (
          <TooltipArrowBottom />
        )}
      </TooltipBody>
    </TooltipWrapper>
  );
};

const TooltipLabelText = styled.label`
  margin: 0;
  font-weight: 700;
  border-bottom-style: dotted;
  border-bottom-color: #49b6d7;
  border-bottom-width: 1px;
  display: inline-block;
  white-space: nowrap;
`;

Tooltip.LabelText = TooltipLabelText;

export default Tooltip;
