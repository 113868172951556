import graphql from "babel-plugin-relay/macro";
import Switch from "components/FullProgramming/common/Switch";
import * as React from "react";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useSystemOptionsFragment } from "./SystemOptionsContext";
import { SystemOptionsLatchedSupervisoryZonesField_systemOptions$key } from "./__generated__/SystemOptionsLatchedSupervisoryZonesField_systemOptions.graphql";

export const systemOptionsLatchedSupervisoryZonesFieldId = () =>
  "system-options-latched-supervisory-zones";

function LatchedSupervisoryZonesField() {
  const [{ latchedSupervisoryZones }, updateSystemOptions] =
    useSystemOptionsFragment<SystemOptionsLatchedSupervisoryZonesField_systemOptions$key>(
      graphql`
        fragment SystemOptionsLatchedSupervisoryZonesField_systemOptions on SystemOptions {
          ... on XrSystemOptions {
            latchedSupervisoryZones
          }
          ... on XfSystemOptions {
            latchedSupervisoryZones
          }
        }
      `
    );

  const fieldId = systemOptionsLatchedSupervisoryZonesFieldId();
  const label = "Latched Supervisory Zones";

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label={label}
      tooltip="If enabled, Supervisory Zones are latched until a Sensor Reset is performed. Otherwise, the alarm is cleared when the Zone restores."
    >
      <Switch
        label={label}
        id={fieldId}
        name={fieldId}
        checked={latchedSupervisoryZones}
        onChange={() => {
          updateSystemOptions((recordProxy) => {
            recordProxy.setValue(
              !latchedSupervisoryZones,
              "latchedSupervisoryZones"
            );
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default LatchedSupervisoryZonesField;
