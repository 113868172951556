import graphql from "babel-plugin-relay/macro";
import useEffectOnce from "common/react-hooks/use-effect-once";
import { useIncludedTemplateFields } from "components/FullProgramming/common/IncludedTemplateFieldsContext";
import TemplateContextProvider, {
  EditingFrom,
} from "components/FullProgramming/common/TemplateContext";
import { setAllIncludedFields as setAllTakeoverIncludedFields } from "components/FullProgramming/Templates/TakeoverTemplate/TakeoverTemplateData";
import { setAllIncludedFields as setAllTMSentryIncludedFields } from "components/FullProgramming/Templates/TMSentryTemplate/TMSentryTemplateData";
import { setAllIncludedFields as setAllXfIncludedFields } from "components/FullProgramming/Templates/XFTemplate/XFTemplateData";
import { setAllIncludedFields as setAllXrIncludedFields } from "components/FullProgramming/Templates/XRTemplate/XRTemplateData";
import { setAllIncludedFields as setAllXtIncludedFields } from "components/FullProgramming/Templates/XTTemplate/XTTemplateData";
import { resolvePanelType } from "components/FullProgramming/utils/panel";
import * as React from "react";
import { useLazyLoadQuery } from "react-relay";
import {
  asID,
  idAsString,
  PanelHardwareModel,
  toProgrammingTemplateId,
} from "securecom-graphql/client";
import { Templates } from "./Templates";
import { EditTemplateQuery } from "./__generated__/EditTemplateQuery.graphql";

type Props = { templateId: string; dealerId: string };

export const EditTemplate: React.FC<Props> = ({ templateId, dealerId }) => {
  const { template } = useLazyLoadQuery<EditTemplateQuery>(
    graphql`
      query EditTemplateQuery($templateId: ID!) {
        template: node(id: $templateId) {
          ... on ProgrammingTemplate {
            id
            hardwareModel
            customer {
              id
            }
            name
            dealer {
              id
            }
            xtConcepts: concepts {
              __typename
              ...XTTemplateDataInline_xtProgrammingTemplateConcepts
            }
            xrConcepts: concepts {
              __typename
              ...XRTemplateDataInline_xrProgrammingTemplateConcepts
            }
            xfConcepts: concepts {
              __typename
              ...XFTemplateDataInline_xfProgrammingTemplateConcepts
            }
            takeoverConcepts: concepts {
              ...TakeoverTemplateDataInline_takeoverProgrammingTemplateConcepts
            }
            tMSentryConcepts: concepts {
              ...TMSentryTemplateDataInline_tMSentryProgrammingTemplateConcepts
            }
          }
        }
      }
    `,
    {
      templateId: idAsString(toProgrammingTemplateId(templateId, dealerId)),
    },
    { fetchPolicy: "network-only" }
  );

  const { isXt, isXr, isXf, isTakeoverPanel, isTMSentry } = resolvePanelType(
    template?.hardwareModel ?? PanelHardwareModel.XT30
  );

  const setIncludedTemplateFields = useIncludedTemplateFields()[1];

  useEffectOnce(() => {
    if (isXr && template?.xrConcepts) {
      setAllXrIncludedFields(template.xrConcepts, setIncludedTemplateFields);
    } else if (isXt && template?.xtConcepts) {
      setAllXtIncludedFields(template.xtConcepts, setIncludedTemplateFields);
    } else if (isXf && template?.xfConcepts) {
      setAllXfIncludedFields(template.xfConcepts, setIncludedTemplateFields);
    } else if (isTakeoverPanel && template?.takeoverConcepts) {
      setAllTakeoverIncludedFields(
        template.takeoverConcepts,
        setIncludedTemplateFields
      );
    } else if (isTMSentry && template?.tMSentryConcepts) {
      setAllTMSentryIncludedFields(
        template.tMSentryConcepts,
        setIncludedTemplateFields
      );
    }
  });

  if (
    template === null ||
    template.id === null ||
    !template.hardwareModel ||
    !template.dealer ||
    !template.dealer?.id === null
  ) {
    return null;
  }

  return (
    <TemplateContextProvider
      settings={{
        templateId: idAsString(template.id),
        editingFrom: EditingFrom.EDIT_TEMPLATE_PAGE,
        hardwareModel: template.hardwareModel as PanelHardwareModel,
        templateName: template.name ?? "",
        customerId: template?.customer?.id ?? "",
        isEditing: true,
        isApplying: false,
        isCreating: true,
        isSaving: false,
        isLoadingEditPage: true,
      }}
    >
      <Templates dealerId={asID(template.dealer.id)} />
    </TemplateContextProvider>
  );
};
