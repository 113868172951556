/**
 * @generated SignedSource<<24e28f46a7d94567b005b563340b8523>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SystemOptionsBypassLimitField_systemOptions$data = {
  readonly bypassLimit?: number;
  readonly bypassLimitMax?: number;
  readonly bypassLimitMin?: number;
  readonly " $fragmentType": "SystemOptionsBypassLimitField_systemOptions";
};
export type SystemOptionsBypassLimitField_systemOptions$key = {
  readonly " $data"?: SystemOptionsBypassLimitField_systemOptions$data;
  readonly " $fragmentSpreads": FragmentRefs<"SystemOptionsBypassLimitField_systemOptions">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SystemOptionsBypassLimitField_systemOptions",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "bypassLimit",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "bypassLimitMin",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "bypassLimitMax",
          "storageKey": null
        }
      ],
      "type": "XrSystemOptions",
      "abstractKey": null
    }
  ],
  "type": "SystemOptions",
  "abstractKey": "__isSystemOptions"
};

(node as any).hash = "4659dbe5bbb147d9281aea371428a6c1";

export default node;
