/**
 * App.service.AuditService
 *
 * Service for the user-initiated event auditing
 */

App.service("AuditService", [
  "$q",
  "AuditAPI",
  "SitesODataAPI",
  "OdataPageService",
  "PANEL_CONCEPTS",
  "$filter",
  "DealerODataAPI",
  "UserService",
  "$sanitize",
  "TEMP_DEALER_APP_USER",
  "AXBusService",
  function (
    $q,
    AuditAPI,
    SitesODataAPI,
    OdataPageService,
    PANEL_CONCEPTS,
    $filter,
    DealerODataAPI,
    UserService,
    $sanitize,
    TEMP_DEALER_APP_USER,
    AXBusService
  ) {
    var _this = this;

    _this.getSiteLogs = function (site_id, show_all_values = false) {
      var dateFilter = new Date();
      dateFilter.setDate(dateFilter.getDate() - 30);
      var dateFilterStr = $filter("date")(
        dateFilter,
        "yyyy-MM-ddThh:mm:ss'Z'",
        "UTC"
      );

      var select =
        "event_at,person_id,first_name,last_name,email_address,batch_type,batch_message";
      var filter = show_all_values ? "" : "event_at gt " + dateFilterStr;
      return getLogsSite(select, filter, site_id);
    };

    _this.getSystemLogs = function (system_id, show_all_values = false) {
      var dateFilter = new Date();
      dateFilter.setDate(dateFilter.getDate() - 30);
      var dateFilterStr = $filter("date")(
        dateFilter,
        "yyyy-MM-ddThh:mm:ss'Z'",
        "UTC"
      );

      var select =
        "event_at,user_id,first_name,last_name,email,accessible_type,event_data";
      var filter = show_all_values
        ? "control_system eq " + system_id
        : "control_system eq " +
          system_id +
          " and event_at gt " +
          dateFilterStr;
      return getLogs(select, filter);
    };

    _this.getPersonnelLogs = function (personnel_id, show_all_values = false) {
      var dateFilter = new Date();
      dateFilter.setDate(dateFilter.getDate() - 30);
      var dateFilterStr = $filter("date")(
        dateFilter,
        "yyyy-MM-ddThh:mm:ss'Z'",
        "UTC"
      );

      var select =
        "event_at,customer_id,customer_name,control_system,control_system_name,event_data";
      var filter = show_all_values
        ? "user_id eq " + personnel_id
        : "user_id eq " + personnel_id + " and event_at gt " + dateFilterStr;
      return getLogs(select, filter);
    };

    _this.getDealerAppUserLogs = function (daysOfActivity) {
      return getDealerLogs("app_users", daysOfActivity);
    };

    _this.getDealerPersonnelLogs = function (daysOfActivity) {
      return getDealerLogs("personnel", daysOfActivity);
    };

    _this.getDealerSystemsLogs = function (daysOfActivity) {
      return getDealerLogs("systems", daysOfActivity);
    };

    _this.getAllDealerLogs = function (daysOfActivity) {
      return getDealerLogs(null, daysOfActivity);
    };

    function getLogs(select_statement, filter_statement) {
      var deferred = $q.defer();
      if (select_statement !== "*") {
        select_statement = mergeInDescriptorFields(select_statement);
      }
      var params = {};
      params.$select = select_statement;
      if (filter_statement) params.$filter = filter_statement;
      var auditPromise = AuditAPI.getLogs(params).$promise;
      var oDataPageService = new OdataPageService();
      oDataPageService
        .getAllPages(auditPromise)
        .then(
          function (data) {
            var events = [];
            angular.forEach(data, function (log) {
              if (validAuditLog(log)) {
                formatDescriptor(log);
                events.push(log);
              }
            });
            deferred.resolve(events);
          },
          function (error) {
            console.error(
              "AuditService->getLogs() error: " + angular.toJson(error)
            );
            deferred.reject(error);
          }
        )
        .catch(function (error) {
          console.error(error);
        });
      return deferred.promise;
    }

    function getLogsSite(select_statement, filter_statement, site_id) {
      var deferred = $q.defer();

      var params = {};
      params.$select = select_statement;
      params.siteId = site_id;
      if (filter_statement) params.$filter = filter_statement;
      var auditPromise = SitesODataAPI.getSiteLogs(params).$promise;
      var oDataPageService = new OdataPageService();
      oDataPageService
        .getAllPages(auditPromise)
        .then(
          function (data) {
            var events = [];
            angular.forEach(data, function (log) {
              if (validAuditLog(log)) {
                formatDescriptor(log);
                events.push(log);
              }
            });
            deferred.resolve(events);
          },
          function (error) {
            console.error(
              "AuditService->getLogs() error: " + angular.toJson(error)
            );
            deferred.reject(error);
          }
        )
        .catch(function (error) {
          console.error(error);
        });
      return deferred.promise;
    }

    function getDealerLogs(dataType, daysOfActivity) {
      var deferred = $q.defer();

      var dateFilter = new Date();
      dateFilter.setDate(dateFilter.getDate() - daysOfActivity);
      var dateFilterStr = $filter("date")(
        dateFilter,
        "yyyy-MM-ddThh:mm:ss'Z'",
        "UTC"
      );
      var filter = daysOfActivity ? "event_at gt " + dateFilterStr : null;

      var select =
        "event_at,user_id,first_name,last_name,email,customer_id,customer_name,control_system,control_system_name,accessible_type,role,event_data";

      select = mergeInDescriptorFields(select);

      var params = {
        dealer_id: UserService.dealer_id,
        $select: select,
        $filter: filter ? filter : null,
      };
      var auditPromise = null;
      switch (dataType) {
        case "app_users":
          auditPromise = DealerODataAPI.getAuditLogsOfAppUsers(params).$promise;
          break;
        case "personnel":
          auditPromise =
            DealerODataAPI.getAuditLogsOfPersonnel(params).$promise;
          break;
        case "systems":
          auditPromise = DealerODataAPI.getAuditLogsOfSystems(params).$promise;
          break;
        default:
          auditPromise = DealerODataAPI.getAuditLogs(params).$promise;
          break;
      }
      var oDataPageService = new OdataPageService();
      oDataPageService
        .getAllPages(auditPromise)
        .then(
          function (data) {
            var events = [];
            angular.forEach(data, function (log) {
              if (validAuditLog(log)) {
                formatDescriptor(log);
                events.push(log);
              }
            });
            deferred.resolve(events);
          },
          function (error) {
            console.error(
              "AuditService->getDealerLogs() error: " + angular.toJson(error)
            );
            deferred.reject(error);
          }
        )
        .catch(function (error) {
          console.error(error);
        });
      return deferred.promise;
    }

    var descriptorFields = [
      "api",
      "api_id",
      "concept",
      "concept_id",
      "event_type",
      "action",
      "item_name",
      "concept_item_name",
    ];
    function mergeInDescriptorFields(select_statement) {
      var selectFields = select_statement.split(",");
      angular.forEach(descriptorFields, function (field) {
        if (selectFields.indexOf(field) === -1) {
          selectFields.push(field);
        }
      });
      return selectFields.join(",");
    }

    /**
     * Lists of APIs, concepts and actions that should not show up in audit records
     * @type {string[]}
     */
    var invalidAPIs = ["authenticate"];
    var invalidConcepts = ["send_sempro_message"];
    var invalidActions = ["refresh"];
    /**
     * Returns true if log is valid for auditing purposes
     * @param log
     * @returns {boolean}
     */
    function validAuditLog(log) {
      if (
        invalidAPIs.includes(log.api) ||
        invalidConcepts.includes(log.concept) ||
        invalidActions.includes(log.action)
      ) {
        return false;
      }
      if (log.event_type === "CACHE_SYNC") {
        var eventData = angular.fromJson(log.event_data);
        // If it is a mobile credential delete event
        if (
          eventData &&
          log.concept === "credential" &&
          log.action === "delete"
        ) {
          return true;
        }
        // If there isn't valid event data
        if (
          !eventData ||
          !eventData.hasOwnProperty("Successful") ||
          // or it is a failed attempt and the logged in user is not a DMP employee, return false
          (!UserService.isSupervisorAccessible() && !eventData.Successful)
        ) {
          return false;
        }
      }
      if (UserService.isSupervisorAccessible()) {
        return true;
      } else if (UserService.isDealerAccessible()) {
        // Jobs service performs internal functions like App Feature Setup
        return log.email !== "jobsservice@dmp.com";
      } else {
        return false;
      }
    }

    /**
     * An array of routes for which a number can be included in the descriptor. ex: Updated zone 5: Front Door
     * @type {string[]}
     */
    var numberedAuditItems = ["receivers", "service request"];
    angular.forEach(Object.keys(PANEL_CONCEPTS), function (concept) {
      numberedAuditItems.push(concept);
    });

    /**
     * Function to form a description of each record in the audit database
     * @param log
     */
    function formatDescriptor(log) {
      log.descriptor = "";
      var act = log.event_type;
      var targetConcept = log.api;
      var targetId = +log.api_id;
      if (log.concept) {
        targetConcept = log.concept;
        targetId = +log.concept_id;
      }

      if (targetConcept == "device_informations") {
        targetId = AXBusService.getLxNum(targetId);
      }

      var targetName = log.concept_item_name
        ? log.concept_item_name.trim()
        : "";
      // Concept refreshes were removed during development. Leaving refresh formatting in in case they are added back.
      var refreshed = log.action === "refresh";
      var targetPretty = targetConcept;
      if (log.api === "panels" && PANEL_CONCEPTS.hasOwnProperty(log.concept)) {
        targetPretty =
          PANEL_CONCEPTS[log.concept].single_name ||
          PANEL_CONCEPTS[log.concept].data_name;
      } else {
        targetPretty = $filter("titleize")(
          $filter("humanize")($filter("singularize")(targetConcept))
        );
      }
      switch (log.event_type) {
        case "POST":
          act = refreshed ? "Refreshed" : "Created";
          switch (log.api) {
            case "dealers":
              switch (log.concept) {
                case "users":
                  targetPretty = "Personnel";
                  break;
                case "DMPTempUserAuthorization":
                  act = log.action === "granted" ? "Authorized" : "Revoked";
                  targetPretty = "Tech Support Login as Customer";
                  break;
                default:
                  break;
              }
              break;
            case "panels":
              if (
                !refreshed &&
                PANEL_CONCEPTS.hasOwnProperty(log.concept) &&
                PANEL_CONCEPTS[log.concept].hasOwnProperty("single_name")
              ) {
                act = "Added";
              }
              if (refreshed && PANEL_CONCEPTS.hasOwnProperty(log.concept)) {
                targetPretty = PANEL_CONCEPTS[log.concept].data_name;
              }
              switch (log.concept) {
                case "app_features":
                  act = "Sent";
                  targetPretty = "App Feature Setup";
                  break;
                case "zone_informations":
                  switch (log.action) {
                    case "bypass":
                      act = "Bypassed";
                      break;
                    case "reset_bypass":
                      act = "Reset";
                      targetPretty = "Bypassed Zone";
                      break;
                    default:
                      break;
                  }
                  break;
                case "barrier_operator_statuses":
                  act = "Changed";
                  targetPretty = "Z-Wave Garage Door State";
                  break;
                case "light_statuses":
                  act = "Changed";
                  targetPretty = "Z-Wave Light State";
                  break;
                case "lock_statuses":
                  act = "Changed";
                  targetPretty = "Z-Wave Lock State";
                  break;
                case "thermostat_statuses":
                  act = "Changed";
                  targetPretty = "Z-Wave Thermostat State";
                  break;
                case "output_statuses":
                  act = "Changed";
                  targetPretty = "Output State";
                  break;
                case "arm":
                  act = "Armed";
                  targetPretty = "System";
                  break;
                case "disarm":
                  act = "Disarmed";
                  targetPretty = "System";
                  break;
                case "doors":
                  if (log.action === "lockdown") {
                    act = "Initiated";
                    targetPretty = "Lockdown";
                  }
                  break;
                case "available_firmwares":
                  act = "Requested";
                  targetPretty = "System Firmware Update";
                  break;
                default:
                  break;
              }
              break;
            case "users":
              switch (log.concept) {
                case "reset_access":
                  act = "Reset";
                  targetPretty = "Password for User";
                  break;
                case "upload_image":
                  act = "Uploaded Image for";
                  var targetProps = parseTarget(targetName);
                  if (targetProps.pretty) {
                    targetPretty = targetProps.pretty;
                  }
                  if (targetProps.name) {
                    targetName = targetProps.name;
                  }
                  break;
                default:
                  break;
              }
              break;
            case "credentials":
              if (log.concept === "person") {
                targetPretty = "User for Credential";
              }
              break;
            case "video_devices":
              if (log.concept === "test_connection") {
                act = "Tested";
                targetPretty = "Camera Connection";
              }
              break;
            case "control_systems":
              if (log.concept === "video_devices") {
                act = "Added";
              }
              break;
            default:
              break;
          }
          break;
        case "PATCH":
        case "PUT":
          act = "Updated";
          switch (log.api) {
            case "users":
              var nameProps = parseTarget(targetName);
              if (nameProps.pretty) {
                targetPretty = nameProps.pretty;
              }
              if (nameProps.name) {
                targetName = nameProps.name;
              }
              break;
            default:
              break;
          }
          break;
        case "DELETE":
          act = "Deleted";
          break;
        case "CACHE_SYNC":
          var eventData = angular.fromJson(log.event_data);
          if (
            // If it is a mobile credential delete event
            eventData &&
            log.concept === "credential" &&
            log.action === "delete"
          ) {
            act = "Deleted";
            targetPretty = "Mobile Credential";
          } else {
            // If it is a cache sync event
            act = eventData.Successful
              ? "Synchronized"
              : "Attempted to Synchronize";
            targetPretty =
              eventData && eventData.Route && PANEL_CONCEPTS[eventData.Route]
                ? PANEL_CONCEPTS[eventData.Route].single_name ||
                  PANEL_CONCEPTS[eventData.Route].data_name
                : "Data";
            let lxNums = [];
            for (var i = 0; i < eventData.Records?.length; i++) {
              lxNums[i] = AXBusService.getLxNum(eventData.Records[i]);
            }
            if (angular.isArray(eventData.Records)) {
              if (targetPretty === "Device") {
                targetPretty +=
                  "s " +
                  lxNums
                    .sort(function (a, b) {
                      return a - b;
                    })
                    .join(", ");
              } else if (targetPretty.slice(-1) !== "s") {
                targetPretty +=
                  "s " +
                  eventData.Records.sort(function (a, b) {
                    return a - b;
                  }).join(", ");
              }
            }
            if (!eventData.Successful && eventData.FailMessage) {
              targetPretty += " - " + eventData.FailMessage;
            }
          }
          break;
        case "TEMP_USER":
          var tempUserData = angular.fromJson(log.event_data);

          if (log.accessible_type === "Supervisor") {
            act = "";

            targetPretty = "Tech Support Logged in as Customer";
          } else {
            act =
              log.action === "create"
                ? "Added"
                : log.action === "edit"
                ? "Updated"
                : "Extended";
            targetPretty = TEMP_DEALER_APP_USER.BUTTON_LABEL + " access";
            if (tempUserData && tempUserData.HoursTillDestruction) {
              targetPretty +=
                " for " + tempUserData.HoursTillDestruction + " hour";
              if (+tempUserData.HoursTillDestruction > 1) {
                targetPretty += "s";
              }
            }
          }
          break;
        case "PROG_BACKUP":
          switch (log.action) {
            case "create":
              act = "Created";
              targetPretty = "Programming Backup";
              break;
            case "backupFailed":
              act = "Programming Backup";
              targetPretty = "Failed";
              break;
            case "deleteBackups":
              act = "Deleted";
              targetPretty = "All Programming Backups";
              break;
            case "deleteSchedule":
              act = "Deleted";
              targetPretty = "Programming Backup Schedule";
              break;
            case "deleteBackup":
              act = "Deleted";
              targetPretty = `Programming Backup`;
              break;
            case "finalizeRestoral":
              act = "Restored";
              targetPretty = `Programming Backup`;

              let restored = angular.fromJson(log.event_data);
              if (restored) {
                targetPretty = `Programming Backup from ${$filter("date")(
                  restored.CreatedUtc,
                  "short"
                )}`;
              }
              break;
            default:
              act = log.action;
              targetPretty = "Programming Backup";
          }
          break;
        case "PANEL_CUSTOMER_MOVE":
          act = "Moved System From Customer: ";
          targetPretty = parseCustomerName(log.event_data);
          break;
        case "CSI_SYS_TEST":
          act = "Put System On Test For";
          const hours = parseHours(log.event_data);
          targetPretty = hours
            ? hours === 1
              ? "1 hour"
              : `${hours} hours`
            : "Some Time";
          break;
        case "VAR":
          act = "";
          targetPretty = log.action
            .split("_")
            .map((word) => word.charAt(0).toUpperCase() + word.substring(1))
            .join(" ");
          break;
        case "TAG_AUDIT":
          act = "Tag";

          switch (log.action) {
            case "CREATE":
              targetPretty = `"${log.item_name}" created.`;
              break;
            case "DELETE":
              targetPretty = `"${log.item_name}" deleted.`;
              break;
            case "UPDATE":
              targetPretty = `"${log.item_name}" updated.`;
              break;
            default:
              targetPretty = "ACTION UNKNOWN";
              break;
          }
          break;
        default:
          act = log.event_type;
          targetPretty = log.action;
      }
      log.descriptor = act + " " + targetPretty;
      if (+targetId > 0 && numberedAuditItems.indexOf(targetConcept) > -1) {
        log.descriptor += " " + targetId;
      }
      if (targetName) {
        log.descriptor += ": " + targetName;
      }
      log.descriptor = $sanitize(log.descriptor);
    }

    function parseCustomerName(eventInfo) {
      let eventJson = JSON.parse(eventInfo);
      return eventJson.oldCustomerName;
    }

    function parseHours(eventInfo) {
      try {
        const eventJson = JSON.parse(eventInfo);
        const number = Number(eventJson.Hours);
        return isNaN(number) ? null : number;
      } catch {
        return null;
      }
    }

    function parseTarget(targetInfo) {
      var response = {};
      var nameProps = targetInfo.split("|");
      switch (nameProps.length) {
        case 0:
        case 1:
          break;
        case 2:
          response["pretty"] = nameProps[0];
          response["name"] = nameProps[1];
          break;
        case 3:
          response["pretty"] = nameProps[0];
          response["name"] = nameProps[1] + " - " + nameProps[2];
          break;
        default:
          break;
      }
      return response;
    }
  },
]);
