import graphql from "babel-plugin-relay/macro";
import Switch from "components/FullProgramming/common/Switch";
import * as React from "react";
import {
  DeviceInformationCommType,
  DeviceInformationType,
} from "securecom-graphql/client";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useDeviceInformationFragment } from "./DeviceInformationContext";
import { DeviceInformationProgram734OptionsField_deviceInformation$key } from "./__generated__/DeviceInformationProgram734OptionsField_deviceInformation.graphql";

export const deviceInformationProgram734OptionsFieldId = (number: string) =>
  `device-information-program-734-options-${number}`;

function DeviceInformationProgram734OptionsField() {
  const [
    { number, remoteProgram734, deviceCommunicationMethod, deviceType },
    updateDeviceInformation,
  ] = useDeviceInformationFragment<DeviceInformationProgram734OptionsField_deviceInformation$key>(
    graphql`
      fragment DeviceInformationProgram734OptionsField_deviceInformation on DeviceInformation {
        number
        ... on XrDeviceInformation {
          remoteProgram734
          deviceType
          deviceCommunicationMethod
        }
      }
    `
  );

  const fieldId = deviceInformationProgram734OptionsFieldId(String(number));
  const label = "Program 734 Options";
  const disabled =
    deviceCommunicationMethod === DeviceInformationCommType.WIRELESS ||
    deviceType !== DeviceInformationType.DOOR;

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label={label}
      disabled={disabled}
    >
      <Switch
        id={fieldId}
        label={label}
        disabled={disabled}
        checked={remoteProgram734}
        onChange={() => {
          updateDeviceInformation((recordProxy) => {
            recordProxy.setValue(!remoteProgram734, "remoteProgram734");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default DeviceInformationProgram734OptionsField;
