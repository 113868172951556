import graphql from "babel-plugin-relay/macro";
import { clamp } from "ramda";
import * as React from "react";
import ProgrammingConceptForm from "../../ProgrammingConceptForm";
import TextInput from "../../TextInput";
import { useAreaFragment } from "./AreaContext";
import { AreaLateArmDelayField_xrArea$key } from "./__generated__/AreaLateArmDelayField_xrArea.graphql";

export const areaLateArmDelayFieldId = (number: string) =>
  `area-late-arm-delay-field-${number}`;

function AreaLateArmDelayField() {
  const [{ number, lateArmDelay }, updateArea] =
    useAreaFragment<AreaLateArmDelayField_xrArea$key>(
      graphql`
        fragment AreaLateArmDelayField_xrArea on XrArea {
          number
          lateArmDelay
        }
      `
    );

  const fieldId = areaLateArmDelayFieldId(number);
  const { current: originalValue } = React.useRef(lateArmDelay);

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Late Arm Delay"
      tooltip="Number of minutes before the Area automatically arms if the System is disarmed past the scheduled close time. Superseded by Re-Arm Delay for a User."
    >
      <TextInput
        id={fieldId}
        name={fieldId}
        value={lateArmDelay ?? 60}
        required
        maxLength={3}
        inlineHelp="4-250"
        validationMessage="Valid values are 4-250."
        pattern="(0{0,2}[4-9]|(0?[1-9]|1[0-9]|2[0-4])[0-9]|250)"
        onChange={({ target }) => {
          updateArea((recordProxy) => {
            recordProxy.setValue(target.value, "lateArmDelay");
          });
        }}
        onBlur={({ target }) => {
          updateArea((recordProxy) => {
            const valueAsNumber = Number(target.value);
            const value =
              !target.value || isNaN(valueAsNumber)
                ? originalValue
                : clamp(4, 250, valueAsNumber);
            recordProxy.setValue(
              target.value === "" ? originalValue : Number(value),
              "lateArmDelay"
            );
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default AreaLateArmDelayField;
