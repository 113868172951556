import graphql from "babel-plugin-relay/macro";
import Switch from "components/FullProgramming/common/Switch";
import * as React from "react";
import { useRelayEnvironment } from "react-relay";
import {
  Device734,
  DeviceInformation,
  DeviceInformationType,
} from "securecom-graphql/client";
import ProgrammingConceptForm from "../../ProgrammingConceptForm";
import { useDeviceInformationFragment } from "../DeviceInformationContext";
import { DeviceInformationActivateZone2BypassField_deviceInformation$key } from "./__generated__/DeviceInformationActivateZone2BypassField_deviceInformation.graphql";

export const deviceInformationActivateZone2BypassFieldId = (number: string) =>
  `device-information-activate-zone2-bypass-${number}`;

function DeviceInformationActivateZone2BypassField() {
  const [{ id, number, device734, deviceType, remoteProgram734 }] =
    useDeviceInformationFragment<DeviceInformationActivateZone2BypassField_deviceInformation$key>(
      graphql`
        fragment DeviceInformationActivateZone2BypassField_deviceInformation on DeviceInformation {
          id
          number
          ... on XrDeviceInformation {
            remoteProgram734
            deviceType
            device734 {
              ... on Device734 {
                zone2BypassOnRequestToExit
              }
            }
          }
        }
      `
    );

  const fieldId = deviceInformationActivateZone2BypassFieldId(String(number));
  const label = "Activate Zone 2 Bypass";
  const relayEnv = useRelayEnvironment();
  const disabled =
    deviceType !== DeviceInformationType.DOOR || !remoteProgram734;

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label={label}
      tooltip="Enable the bypass option for Zone 2 of the 734 device, allowing a programmable entry / exit timer."
      disabled={disabled}
    >
      <Switch
        id={fieldId}
        label={label}
        checked={device734?.zone2BypassOnRequestToExit ?? false}
        disabled={disabled}
        onChange={() => {
          relayEnv.commitUpdate((store) => {
            const device734Options = store
              .get<DeviceInformation>(id)
              ?.getLinkedRecord<Device734>("device734");

            if (device734Options) {
              device734Options.setValue(
                !device734?.zone2BypassOnRequestToExit,
                "zone2BypassOnRequestToExit"
              );
            }
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default DeviceInformationActivateZone2BypassField;
