import { HttpStatusClass, fetchImageUrl, getHttpStatusClass } from "./http";
import { getCamectUrl } from "./securecom-urls";
import { SecureComEnv } from "./types";

/**
 *
 * Pings Camect-API health endpoint
 *
 * @returns Promise<Response>
 */
const camectHealthCheck = async (): Promise<Response> => {
  const secureComEnv: any = (process.env?.REACT_APP_SECURECOM_ENV ??
    "production") as SecureComEnv;

  return await fetch(`${getCamectUrl(secureComEnv)}/api/v1/camect/health`, {
    method: "GET",
    headers: {
      Accept: "application/json",
    },
  });
};

export {
  HttpStatusClass,
  camectHealthCheck,
  fetchImageUrl,
  getHttpStatusClass,
};
