/**
 * @ngdoc api
 * @name App.factory:PaymentsAPI
 *
 * @description
 * API factory for Payments
 *
 */

App.factory("PaymentsAPI", [
  "$resource",
  "PROPS",
  function ($resource, PROPS) {
    return $resource(
      PROPS.dealerInvUrl + "/api/v1/CustomerAccount/DealerCode/:dealerCode",
      {},
      {
        getCustomerAccount: { method: "GET", params: {}, isArray: false },
        updateAutoPayment: {
          method: "PUT",
          url: PROPS.dealerInvUrl + "/api/v1/autopay",
          params: {},
          isArray: false,
        },
        chargeAccount: {
          method: "POST",
          url: PROPS.paymentsUrl + "/payments/orbipay",
          params: {},
          isArray: false,
        },
        getAutoPayment: {
          method: "GET",
          url: PROPS.dealerInvUrl + "/api/v1/autopay/dealer_codes/:dealerCode",
          params: {},
          isArray: false,
        },
        getAdjustedCost: {
          method: "POST",
          url: PROPS.paymentsUrl + "/adjusted_cost",
          params: {},
          isArray: false,
        },
        getScheduledPayments: {
          method: "GET",
          url: PROPS.paymentsUrl + "/payments/pending",
          params: {},
          isArray: true,
        },
        deletePaymentSource: {
          method: "DELETE",
          url: PROPS.dealerInvUrl + "/api/v1/Payments/FundingAccount",
          params: {},
          isArray: false,
        },
        createPaymentSource: {
          method: "POST",
          url: PROPS.dealerInvUrl + "/api/v1/Payments/FundingAccount",
          params: {},
          isArray: false,
        },
      }
    );
  },
]);
