// create styled button component to match classes in dealer-admin
// need btn-primary, btn-secondary, btn-danger, btn-success, btnDMP, btnDMP-secondary

import DaColors from "app/common/DaColors";
import styled from "styled-components/macro";

//
export const DaButton = styled.button`
  display: inline-block;
  margin-bottom: 0;
  text-align: center;
  vertical-align: middle;
  font-weight: var(--font-weight-regular);
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  white-space: nowrap;
  padding: 0.5rem 1rem;
  line-height: 1.5;
  border-radius: 0.3rem;
  user-select: none;
  border: 1px solid ${DaColors.Info500};
  background-color: ${DaColors.White};
  color: ${DaColors.Info500};
  font-size: 1.2rem;
  transition: all 0.3s;
  &:hover {
    background-color: ${DaColors.DmpButtonHover};
  }
  &:focus {
    outline: none;
    box-shadow: 0 0 0 0.2rem ${DaColors.DmpButtonHover};
  }
  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
    pointer-events: none;
  }
`;

export const DaButtonSolidRounded = styled(DaButton)`
 background: ${DaColors.Info500};
    color: white;
    border-color: ${DaColors.Info500};
    text-decoration: none;
    transition: all 10ms linear;
    margin-bottom: var(--measure-1x);
    position: relative;
    z-index: 1;
    border-radius: 40rem;
    font-size: 1.4rem;
    padding: var(--measure-half) var(--measure-1x);
    padding: 0 var(--measure-1x);
  }
  &:focus, &:hover {
    background: ${DaColors.Info400};
    border-color: ${DaColors.Info400};
    color: #ffffff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transform: translateY(-0.01rem);
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  }
`;

export const DaButtonDefault = styled(DaButton)`
  background-color: ${DaColors.White};
  border-color: ${DaColors.Neutral25};
  color: ${DaColors.Neutral2000};
  &:hover {
    background-color: #e6e6e6;
    border-color: #cbcbcb;
  }
  &:focus {
    outline: none;
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
  }
`;

export const DaButtonSecondary = styled(DaButton)`
  background-color: ${DaColors.White};
  border-color: #6c757d;
  &:hover {
    background-color: #545b62;
    border-color: #4e555b;
  }
  &:focus {
    outline: none;
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
  }
`;
export const DaButtonDanger = styled(DaButton)`
  background-color: ${DaColors.White};
  border-color: #dc3545;
  &:hover {
    background-color: #c82333;
    border-color: #bd2130;
  }
  &:focus {
    outline: none;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
  }
`;
export const DaButtonSuccess = styled(DaButton)`
  background-color: ${DaColors.White};
  border-color: ${DaColors.White};#28a745;
  &:hover {
    background-color: #218838;
    border-color: #1e7e34;
  }
  &:focus {
    outline: none;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
  }
`;
export const DaButtonDMP = styled(DaButton)`
  background-color: #f0ad4e;
  border-color: #f0ad4e;
  &:hover {
    background-color: #ec971f;
    border-color: #d58512;
  }
  &:focus {
    outline: none;
    box-shadow: 0 0 0 0.2rem rgba(240, 173, 78, 0.5);
  }
`;
export const DaButtonDMPSecondary = styled(DaButton)`
  background-color: #f0ad4e;
  border-color: #f0ad4e;
  &:hover {
    background-color: #ec971f;
    border-color: #d58512;
  }
  &:focus {
    outline: none;
    box-shadow: 0 0 0 0.2rem rgba(240, 173, 78, 0.5);
  }
`;

export const DaTableLinkButton = styled.button`
  color: ${DaColors.PrimaryBase};
  cursor: pointer;
  border: none;
  padding: 0;
  background-color: transparent;
  text-decoration: none;
  transition: all 0.2s ease-out;
`;
