import graphql from "babel-plugin-relay/macro";
import TextInput from "components/FullProgramming/common/TextInput";
import { formatListOfNumbers } from "components/FullProgramming/utils/format";
import * as React from "react";
import { DeviceInformationType } from "securecom-graphql/client";
import { useHardwareModel } from "../PanelContext";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useDeviceInformationFragment } from "./DeviceInformationContext";
import { isAXBusRange, resolveDeviceInformationAreaListRules } from "./utils";
import { DeviceInformationDisplayAreasField_deviceInformation$key } from "./__generated__/DeviceInformationDisplayAreasField_deviceInformation.graphql";

export const deviceInformationDisplayAreasFieldId = (number: string) =>
  `device-information-display-areas-${number}`;

function DeviceInformationDisplayAreasField() {
  const [{ number, displayAreas, deviceType }, updateDeviceInformation] =
    useDeviceInformationFragment<DeviceInformationDisplayAreasField_deviceInformation$key>(
      graphql`
        fragment DeviceInformationDisplayAreasField_deviceInformation on DeviceInformation {
          number
          ... on XrDeviceInformation {
            displayAreas
            deviceType
          }
        }
      `
    );

  const fieldId = deviceInformationDisplayAreasFieldId(String(number));
  const { pattern, inlineHelp, validationMessage } =
    resolveDeviceInformationAreaListRules(useHardwareModel());
  const disabled =
    isAXBusRange(number) ||
    deviceType === DeviceInformationType.ZONE_EXPANDER ||
    deviceType === DeviceInformationType._1100T ||
    deviceType === DeviceInformationType.VPLEX ||
    deviceType === DeviceInformationType.CAMERA ||
    deviceType === DeviceInformationType.XR550;

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Display Areas"
      disabled={disabled}
      tooltip="Display Areas segment the System's burglary activities so that only selected Areas and their associated operation appear at this keypad."
    >
      <TextInput
        id={fieldId}
        pattern={pattern}
        value={displayAreas}
        disabled={disabled}
        validationMessage={validationMessage}
        inlineHelp={inlineHelp}
        onChange={({ target }) => {
          updateDeviceInformation((recordProxy) => {
            recordProxy.setValue(target.value, "displayAreas");
          });
        }}
        onBlur={({ target }) => {
          updateDeviceInformation((recordProxy) => {
            recordProxy.setValue(
              formatListOfNumbers(target.value),
              "displayAreas"
            );
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default DeviceInformationDisplayAreasField;
