import graphql from "babel-plugin-relay/macro";
import ConfirmingDeleteTooltip from "components/ConfirmingDeleteTooltip";
import { Badge, Clickable } from "components/DaStyledElements";
import { useResetControlSystemIntegrations } from "components/StatusBadge";
import { useShowAlert } from "contexts/AlertsContext";
import React from "react";
import { useFragment, useMutation } from "react-relay";
import { CentralStationAutomationIntegrationStatus } from "securecom-graphql/client";
import styled from "styled-components";
import Icon from "../Icon/";
import IntegrationModal from "./IntegrationForm";
import { IntegrationForm_Query$key } from "./IntegrationForm/__generated__/IntegrationForm_Query.graphql";
import { useUserIsTechSupport } from "./UserIsTechSupportContext";
import { IntegrationGridViewDeleteIntegrationMutation } from "./__generated__/IntegrationGridViewDeleteIntegrationMutation.graphql";
import { IntegrationGridView_centralStationAutomationIntegration$key } from "./__generated__/IntegrationGridView_centralStationAutomationIntegration.graphql";
import { IntegrationGridView_query$key } from "./__generated__/IntegrationGridView_query.graphql";

const IntegrationSectionGrid = styled.div`
  display: grid;
  grid-gap: 1.6rem;
  grid-template-columns: 1fr;
  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: minmax(0, min-content);
  }
`;
const IntegrationPanel = styled.div`
  padding: var(--measure-2x);
  background-color: white;
  border: 1px solid transparent;
  border-radius: 0.8rem;
  border: 0.1rem solid var(--color-neutral-500);
  box-shadow: (0 1px 1px rgba(0, 0, 0, 0.5));
`;
const IntegrationPanelInteriorGrid = styled.div`
  display: grid;
  grid-gap: var(--measure-2x);
  grid-template-columns: min-content 1fr;
  grid-template-areas: "name-state buttons";
  justify-items: start;
  align-items: center;
`;
const IntegrationPanelInteriorGridTitleStateContainer = styled.div`
  grid-area: name-state;
  display: flex;
  flex-flow: row nowrap;
  font-size: 2rem;
  justify-content: flex-start;
  align-items: center;
`;
const IntegrationPanelInteriorGridTitle = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  @media (max-width: 768px) {
    font-size: 1.6rem;
  }
`;
const IntegrationPanelInteriorGridState = styled.div``;
const IntegrationPanelInteriorGridButtons = styled.div`
  grid-area: buttons;
  justify-self: end;
`;
const IntegrationPanelInteriorGridButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
const removeIntegration = graphql`
  mutation IntegrationGridViewDeleteIntegrationMutation($integrationId: ID!) {
    removeDealerCentralStationAutomationIntegration(
      integrationId: $integrationId
    ) {
      ... on RemoveDealerCentralStationAutomationIntegrationErrorResponse {
        __typename
        error {
          errorType: type
        }
      }
      ... on RemoveDealerCentralStationAutomationIntegrationSuccessResponse {
        __typename
        dealer {
          id
          centralStationAutomationIntegrations {
            name
            status
            emergencyContactsEnabled
            systemTestsEnabled
            ...IntegrationGridView_centralStationAutomationIntegration
          }
        }
      }
    }
  }
`;

export const IntegrationGridItem: React.FC<
  React.PropsWithChildren<{
    integrationRef: IntegrationGridView_centralStationAutomationIntegration$key;
    queryRef: IntegrationForm_Query$key;
  }>
> = ({ integrationRef, queryRef }) => {
  const integration = useFragment(
    graphql`
      fragment IntegrationGridView_centralStationAutomationIntegration on CentralStationAutomationIntegration {
        id
        name
        displayedName
        status
        emergencyContactsEnabled
        systemTestsEnabled
      }
    `,
    integrationRef
  );

  const [deleteIntegration] =
    useMutation<IntegrationGridViewDeleteIntegrationMutation>(
      removeIntegration
    );
  const showAlert = useShowAlert();

  const [editIntegrationModalOpen, setEditIntegrationModalOpen] =
    React.useState(false);

  const resetControlSystemIntegrations = useResetControlSystemIntegrations();
  const { isTechSupport } = useUserIsTechSupport();
  return (
    <>
      <IntegrationPanel>
        <IntegrationPanelInteriorGrid>
          <IntegrationPanelInteriorGridTitleStateContainer>
            <IntegrationPanelInteriorGridTitle>
              {integration.displayedName}
            </IntegrationPanelInteriorGridTitle>
            <IntegrationPanelInteriorGridState>
              <Badge
                stateColor={
                  integration.status ===
                  CentralStationAutomationIntegrationStatus.CONNECTED
                    ? "success"
                    : "danger"
                }
                message={
                  integration.status ===
                  CentralStationAutomationIntegrationStatus.CONNECTED
                    ? "CONNECTED"
                    : "NOT CONNECTED"
                }
              />
            </IntegrationPanelInteriorGridState>
          </IntegrationPanelInteriorGridTitleStateContainer>
          <IntegrationPanelInteriorGridButtons>
            <IntegrationPanelInteriorGridButtonsWrapper>
              <Clickable.IconButton
                style={{ marginRight: ".8rem" }}
                icon={
                  <Icon
                    name="settings"
                    size="2.4rem"
                    color="var(--color-primary)"
                  />
                }
                onClick={() => setEditIntegrationModalOpen(true)}
              />
              {isTechSupport ? null : (
                <ConfirmingDeleteTooltip
                  iconSize="2.4rem"
                  onConfirm={() => {
                    deleteIntegration({
                      variables: {
                        integrationId: integration.id,
                      },
                      onCompleted(data) {
                        if (
                          data.removeDealerCentralStationAutomationIntegration
                            .__typename ===
                          "RemoveDealerCentralStationAutomationIntegrationSuccessResponse"
                        ) {
                          showAlert({
                            type: "success",
                            text: `Deleted integration: ${integration.displayedName}`,
                          });
                          resetControlSystemIntegrations();
                        }
                      },
                    });
                  }}
                  message={`Deleting integration ${integration.displayedName}. Are you sure?`}
                />
              )}
            </IntegrationPanelInteriorGridButtonsWrapper>
          </IntegrationPanelInteriorGridButtons>
        </IntegrationPanelInteriorGrid>
      </IntegrationPanel>
      {editIntegrationModalOpen && (
        <IntegrationModal
          onCancel={() => {
            setEditIntegrationModalOpen(false);
          }}
          queryRef={queryRef}
          id={integration.id}
        />
      )}
    </>
  );
};

export const IntegrationGridView: React.FC<
  React.PropsWithChildren<{
    queryRef: IntegrationGridView_query$key;
  }>
> = ({ queryRef }) => {
  const data = useFragment(
    graphql`
      fragment IntegrationGridView_query on Query {
        ...IntegrationForm_Query
        dealer(id: $dealerId) {
          __typename
          centralStationAutomationIntegrations {
            id
            ...IntegrationGridView_centralStationAutomationIntegration
          }
        }
      }
    `,
    queryRef
  );

  return (
    <>
      <IntegrationSectionGrid>
        {data.dealer?.centralStationAutomationIntegrations.map(
          (integration) => (
            <IntegrationGridItem
              integrationRef={integration}
              queryRef={data}
              key={integration.id}
            />
          )
        )}
      </IntegrationSectionGrid>
    </>
  );
};
