/**
 * Created by jbrumfield on 7/13/15.
 */
App.factory("UserCode", [
  "UserService",
  "$filter",
  function (UserService, $filter) {
    var profileLimit = 4; // The max number of profiles that can be associated with a user code

    var UserCode = function (panelFamily) {
      // Setup any init value and methods
      angular.extend(this, {
        isBusy: false,
        family: "",
        profiles: {},
        areaList: {},
        profileLimit: profileLimit,

        /**
         * @ngdoc object
         * @name method:init
         * @methodOf App.factory:init
         *
         * @description
         * Initialize a new lock device
         *
         */
        init: function () {
          var _this = this;
          // Hardware specific usercode settings
          if (this.family == "XT30" || this.family == "TMSentry") {
            // XT30 ties user_codes to Areas
            this.preCheckAreas();
          } else {
            // XR550
            this.preCheckUserProfiles();
            // Convert the temp_date and start_date to Dates
            if (this.temp_date && this.temp_date != "") {
              this.temp_date = dateTimeForceUTC(new Date(_this.temp_date));
            }
            if (this.start_date && this.start_date != "") {
              this.start_date = dateTimeForceUTC(new Date(_this.start_date));
            }
          }
        },

        preCheckAreas: function () {
          var _this = this;
          // We also need to pre-set the area checkboxes for the form
          // If userAreas.areas contains systemArea, set the selected value for that area to true
          _this.areas = numberRangeToCSV(_this.areas).toString();
          var userAreas = _this.areas.split(",");
          for (var i = 0; i < _this.areaList.length; i++) {
            for (var x = 0; x < userAreas.length; x++) {
              if (Number(_this.areaList[i].number) === Number(userAreas[x])) {
                // Set the area.selected value to true
                _this.areaList[i].selected = true;
              }
            }
          }
        },

        /**
         * Function to pre-check Profiles in UserService.getActiveSystemProfiles() where they exist in the user_code
         * @param {String} userCode The userCode of the user being edited
         * @returns Sets the selected attribute of UserService.getActiveSystemProfiles()
         */
        preCheckUserProfiles: function () {
          var _this = this;
          // Clear all selected profiles in UserService.getSystems()
          //var allProfiles = UserService.getActiveSystemProfiles();
          for (var i = 0, len = _this.profiles.length; i < len; i++) {
            _this.profiles[i].selected = false;
          }

          // Parse userCode and pre-check UserService.getActiveSystemProfiles() with the matching profiles
          for (let element in _this) {
            if (element.match(/profile\d/)) {
              // Find the corresponding profile in UserService.getActiveSystemProfiles() and check it
              for (var i = 0, len = _this.profiles.length; i < len; i++) {
                if (
                  parseInt(_this.profiles[i].number) ===
                  parseInt(_this[element])
                ) {
                  _this.profiles[i].selected = true;
                  // Set the temp value on userCode, if this profile supports temp code
                  if (_this.profiles[i].temp_code == 1) {
                    _this.temp = "1";
                  }
                }
              }
            }
          }
        },

        /**
         * Function to return true if the temp_code form elements should be disabled
         */
        isTempProfileSelected: function () {
          if (angular.isArray(this.profiles)) {
            var profilesWithTemp = $filter("filter")(this.profiles, {
              temp_code: true,
            });
            var selectedProfilesWithTemp = $filter("filter")(profilesWithTemp, {
              selected: true,
            });
            if (selectedProfilesWithTemp.length > 0) {
              return true;
            }
          }
          return false;
        },

        /**
         * Function to convert selected profile checkboxes to an array
         * We use the UserService.getActiveSystemProfiles(), which returns the profiles array.  The selected attribute
         * is added to each profile array, with selected: true indicating a selected box
         * @returns Sets $scope._userCodes[i].profiles attribute
         */
        selectProfile: function (profile) {
          var _this = this;
          var selectedProfiles = $filter("filter")(this.profiles, {
            selected: true,
          });

          if (selectedProfiles.length <= profileLimit) {
            var profileNumber = 0;
            var currentIteration = 0;
            for (var i = 0; i < selectedProfiles.length; i++) {
              currentIteration = i + 1;
              profileNumber = "profile" + (i + 1);
              _this[profileNumber] = selectedProfiles[i].number;
            }

            // Remove any additional profile fields from the usercode after the current profileNumber
            if (currentIteration < profileLimit) {
              currentIteration++; // Need to go to the NEXT profile, don't want to delete what we just added.
              for (var i = currentIteration; i <= profileLimit; i++) {
                profileNumber = "profile" + i;
                _this[profileNumber] = "";
              }
            }
          }
        },

        selectAreas: function () {
          var _this = this;
          var selectedAreas = $filter("filter")(this.areaList, {
            selected: true,
          });
          //Convert the array area.numbers to a csv string
          var areaNumbers = [];
          for (var i = 0; i < selectedAreas.length; i++) {
            areaNumbers.push(selectedAreas[i].number);
          }
          _this.areas = areaNumbers.toString();
        },

        hasMaxProfiles: function (selected) {
          if (selected == undefined) return false;
          var _this = this;
          var profileLimitReached;
          var selectedProfiles = $filter("filter")(_this.profiles, {
            selected: true,
          });
          selectedProfiles.length == profileLimit
            ? (profileLimitReached = true)
            : (profileLimitReached = false);
          if (!selected && profileLimitReached) {
            return true;
          } else {
            return false;
          }
        },

        toJson: function () {
          var user_code = angular.copy(this);
          delete user_code.user;
          delete user_code.panel;
          // If the code attribute is empty, strip it off so we don't even submit the attribute name.
          if (user_code.code == null || user_code.code == "") {
            delete user_code.code;
          }
          // If this user is for XR550 family, convert the temp_date and expiretime to ISO8601 format
          if (this.family === "XR550") {
            // If temp is disabled, then no valid temp profiles are currently selected, so set temp values to empty
            if (_this.isTempProfileSelected()) {
              user_code.temp_date = dateTimeStringToISOString(
                user_code.temp_date
              );
              if (user_code.expiretime && user_code.expiretime != "") {
                // Since we only care about the time, we set a hardcoded date value.
                user_code.expiretime = dateTimeStringToISOString(
                  "01/01/2001 " + user_code.expiretime
                );
              }
            } else {
              user_code.temp_date = "";
              user_code.expiretime = "";
            }
          }
        },
      });

      this.family = panelFamily; // Set the panel family to the passed in value
    };
    return UserCode;
  },
]);
