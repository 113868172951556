/**
 * @generated SignedSource<<795cd4ce357b01eb9782f92b870d1a63>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ControlSystemContextUseControlSystemCapabilities_controlSystem$data = {
  readonly isCellComEx: boolean;
  readonly isCellComSl: boolean;
  readonly isDSC: boolean;
  readonly isDualCom: boolean;
  readonly isECP: boolean;
  readonly isIComSl: boolean;
  readonly isTakeoverPanel: boolean;
  readonly isXr: boolean;
  readonly isXr150: boolean;
  readonly isXr350: boolean;
  readonly isXr550: boolean;
  readonly isXt: boolean;
  readonly isXt30: boolean;
  readonly isXt30L: boolean;
  readonly isXt50: boolean;
  readonly isXt50L: boolean;
  readonly isXtl: boolean;
  readonly isXtlN: boolean;
  readonly isXtlPlus: boolean;
  readonly isXtlW: boolean;
  readonly " $fragmentType": "ControlSystemContextUseControlSystemCapabilities_controlSystem";
};
export type ControlSystemContextUseControlSystemCapabilities_controlSystem$key = {
  readonly " $data"?: ControlSystemContextUseControlSystemCapabilities_controlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"ControlSystemContextUseControlSystemCapabilities_controlSystem">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ControlSystemContextUseControlSystemCapabilities_controlSystem",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isECP",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isDSC",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isXt30",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isXt30L",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isXt50",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isXt50L",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isXtl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isXtlN",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isXtlW",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isXtlPlus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isXr150",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isXr350",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isXr550",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isXr",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isXt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isIComSl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isCellComSl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isCellComEx",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isDualCom",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isTakeoverPanel",
      "storageKey": null
    }
  ],
  "type": "ControlSystem",
  "abstractKey": null
};

(node as any).hash = "7740c859c1b72676d957647217d76353";

export default node;
