/**
 * @generated SignedSource<<b032073c506024a9ec4216c3ecf2a3e6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AreaLateArmDelayField_xrArea$data = {
  readonly lateArmDelay: number;
  readonly number: string;
  readonly " $fragmentType": "AreaLateArmDelayField_xrArea";
};
export type AreaLateArmDelayField_xrArea$key = {
  readonly " $data"?: AreaLateArmDelayField_xrArea$data;
  readonly " $fragmentSpreads": FragmentRefs<"AreaLateArmDelayField_xrArea">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AreaLateArmDelayField_xrArea",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "number",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lateArmDelay",
      "storageKey": null
    }
  ],
  "type": "XrArea",
  "abstractKey": null
};

(node as any).hash = "81d01c03b84840e237c8c5359e7ec163";

export default node;
