import graphql from "babel-plugin-relay/macro";
import NumericInput from "components/FullProgramming/common/NumericInput";
import { emptyStringOrNumber } from "components/FullProgramming/utils/format";
import { clamp } from "ramda";
import * as React from "react";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useSystemOptionsFragment } from "./SystemOptionsContext";
import { SystemOptionsEntryDelay2Field_systemOptions$key } from "./__generated__/SystemOptionsEntryDelay2Field_systemOptions.graphql";

export const systemOptionsEntryDelay2FieldId = () =>
  "system-options-entry-delay-2";

function EntryDelay2Field() {
  const [{ entryDelay2, entryDelayMin, entryDelayMax }, updateSystemOptions] =
    useSystemOptionsFragment<SystemOptionsEntryDelay2Field_systemOptions$key>(
      graphql`
        fragment SystemOptionsEntryDelay2Field_systemOptions on SystemOptions {
          ... on XrSystemOptions {
            entryDelay2
            entryDelayMin
            entryDelayMax
          }
          ... on XtSystemOptions {
            entryDelay2
            entryDelayMin
            entryDelayMax
          }
        }
      `
    );

  const fieldId = systemOptionsEntryDelay2FieldId();
  const originalValue = React.useRef(entryDelay2).current;

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Entry Delay 2"
      tooltip="Set the duration for Entry Delay 2 in seconds. Used in Zone Information Programming for Exit Zones."
    >
      <NumericInput
        id={fieldId}
        name={fieldId}
        value={entryDelay2?.toString()}
        required
        inlineHelp={`${entryDelayMin}–${entryDelayMax}`}
        min={entryDelayMin}
        max={entryDelayMax}
        onChange={({ target }) => {
          updateSystemOptions((recordProxy) => {
            recordProxy.setValue(
              emptyStringOrNumber(target.value),
              "entryDelay2"
            );
          });
        }}
        onBlur={({ target }) => {
          updateSystemOptions((recordProxy) => {
            const valueAsNumber = Number(target.value);
            const value =
              !target.value || isNaN(valueAsNumber)
                ? originalValue
                : clamp(entryDelayMin, entryDelayMax, valueAsNumber);
            recordProxy.setValue(value, "entryDelay2");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default EntryDelay2Field;
