import graphql from "babel-plugin-relay/macro";
import { closest } from "common/utils";
import NumericInput from "components/FullProgramming/common/NumericInput";
import { range } from "ramda";
import * as React from "react";
import { DeviceInformationType } from "securecom-graphql/client";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useDeviceInformationFragment } from "./DeviceInformationContext";
import { DeviceInformationStrikeTimeField_deviceInformation$key } from "./__generated__/DeviceInformationStrikeTimeField_deviceInformation.graphql";

export const deviceInformationStrikeTimeFieldId = (number: string) =>
  `device-information-strike-time-${number}`;

function DeviceInformationStrikeTimeField() {
  const [{ number, strikeTime, deviceType }, updateDeviceInformation] =
    useDeviceInformationFragment<DeviceInformationStrikeTimeField_deviceInformation$key>(
      graphql`
        fragment DeviceInformationStrikeTimeField_deviceInformation on DeviceInformation {
          number
          ... on XrDeviceInformation {
            deviceType
            strikeTime
          }
        }
      `
    );

  const fieldId = deviceInformationStrikeTimeFieldId(String(number));
  const disabled = deviceType !== DeviceInformationType.DOOR;
  const validStrikeTimeRange = range(0, 251);

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Strike Time"
      tooltip="Number of seconds the door access relay allows access after granting access to this door."
      disabled={disabled}
    >
      <NumericInput
        id={fieldId}
        value={strikeTime ?? 5}
        // required
        disabled={disabled}
        inlineHelp="0-250"
        validationMessage="Valid values are 0-250."
        pattern="([0-1]?[0-9]{0,2}|2[0-4][0-9]|250)"
        onChange={({ target }) => {
          updateDeviceInformation((recordProxy) => {
            recordProxy.setValue(target.value, "strikeTime");
          });
        }}
        onBlur={({ target }) => {
          updateDeviceInformation((recordProxy) => {
            recordProxy.setValue(
              closest(Number(target.value), validStrikeTimeRange),
              "strikeTime"
            );
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default DeviceInformationStrikeTimeField;
