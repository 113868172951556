import graphql from "babel-plugin-relay/macro";
import Switch from "components/FullProgramming/common/Switch";
import * as React from "react";
import ProgrammingConceptForm from "../../ProgrammingConceptForm";
import { useAreaFragment } from "./AreaContext";
import { AreaOpenClosingReportsField_xrArea$key } from "./__generated__/AreaOpenClosingReportsField_xrArea.graphql";

export const areaOpenClosingReportsFieldId = (number: string) =>
  `area-open-closing-reports-${number}`;

function AreaOpenClosingReportsField() {
  const [{ number, openClosingReports }, updateArea] =
    useAreaFragment<AreaOpenClosingReportsField_xrArea$key>(
      graphql`
        fragment AreaOpenClosingReportsField_xrArea on XrArea {
          number
          openClosingReports
        }
      `
    );

  const fieldId = areaOpenClosingReportsFieldId(number);

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Opening / Closing Reports"
      tooltip="If enabled, each area will send Opening and Closing reports on and paths with those reports programmed."
    >
      <Switch
        label="Opening / Closing Reports"
        id={fieldId}
        checked={openClosingReports}
        onChange={() => {
          updateArea((recordProxy) => {
            recordProxy.setValue(!openClosingReports, "openClosingReports");
          });
        }}
      />
    </ProgrammingConceptForm.Field>
  );
}

export default AreaOpenClosingReportsField;
