import { ifNotNumeric } from "common/utils/universal/numbers";
import { resolvePanelType } from "components/FullProgramming/utils/panel";
import { chain, concat, range, slice } from "ramda";
import { RecordProxy } from "relay-runtime";
import { PanelHardwareModel, Zone, ZoneType } from "securecom-graphql/client";
import { PanelHardwareModel as PanelContextHardwareModel } from "../__generated__/PanelContextUseHardwareModel_panel.graphql";

const TAKEOVER_ZONE_FIELD_RULES = {
  BASE: {
    NUMBER: {
      PATTERN: "(0{0,2}[1-4])",
      INLINE_HELP: "1-4",
      VALIDATION_MSG: "Valid values are 1-4.",
    },
    NAME: {
      PATTERN: "^.*$",
      VALIDATION_MSG: "16 character maximum.",
    },
    SERIAL_NUMBER: {
      PATTERN:
        "(0[1-4,6-9][0-9][0-9][0-9][0-9][0-9][0-9]|1[0-3][0-9][0-9][0-9][0-9][0-9][0-9])",
      INLINE_HELP: "01000000-04999999, 06000000-13999999",
      VALIDATION_MSG: "Valid values are 01000000-04999999, 06000000-13999999.",
    },
    COMPETITOR_WIRELESS_SERIAL_NUMBER: {
      PATTERN: "",
      INLINE_HELP: "",
      VALIDATION_MSG: "",
    },
    OUTPUT_NUMBER: {
      PATTERN: "(0{0,2}0|0{0,2}[1-2]|F0[1-9]|F1[0-9]|F20)",
      INLINE_HELP: "0, 1-2, F01-F20",
      VALIDATION_MSG: "Valid values are 0, 1-2, F01-F20.",
    },
    PREWARN_KEYPADS: {
      PATTERN: "",
      INLINE_HELP: "",
      VALIDATION_MSG: "",
    },
    ARMED_AREAS: {
      PATTERN: "([1-6]([\\,\\-][1-6]){0,7})",
      INLINE_HELP: "1, 2, 3, 4, 5, 6",
      VALIDATION_MSG: "Valid values are any combination of 1,2,3,4,5,6.",
    },
    ZONE_ACTIVITY_DAYS: {
      PATTERN: "",
      INLINE_HELP: "",
      VALIDATION_MSG: "",
    },
    EXPANDER_SERIAL_NUMBER: {
      PATTERN: "",
      INLINE_HELP: "",
      VALIDATION_MSG: "",
    },
  },
  ECP_OR_DSC_ENABLED: {
    NUMBER: {
      INLINE_HELP: "1-128, 201-204",
      PATTERN: "(0{0,2}[1-9]|0?[1-9][0-9]|1[0-1][0-9]|12[0-8]|20[1-4])",
      VALIDATION_MSG: "Valid values are 1-128, 201-204.",
    },
  },
  CELLCOM_EX: {
    NUMBER: {
      PATTERN: "(0{0,2}1)",
      INLINE_HELP: "",
      VALIDATION_MSG: "Valid values are 1.",
    },
    OUTPUT_NUMBER: {
      PATTERN: "^0?0?(0|1)$",
      INLINE_HELP: "0, 1",
      VALIDATION_MSG: "Valid values are 0, 1.",
    },
  },
  CELLCOM_EX_ECP_OR_DSC_ENABLED: {
    NUMBER: {
      INLINE_HELP: "1-128, 201",
      PATTERN: "(0{0,2}[1-9]|0?[1-9][0-9]|1[0-1][0-9]|12[0-8]|201)",
      VALIDATION_MSG: "Valid values are 1-128, 201.",
    },
  },
};
const XT_ZONE_FIELD_RULES = {
  BASE: {
    NUMBER: {
      PATTERN: "(0{0,2}[1-9]|0?1[0-4]|0?[2-8][1-4])",
      INLINE_HELP:
        "1-10, 11-14, 21-24, 31-34, 41-44, 51-54, 61-64, 71-74, 81-84",
      VALIDATION_MSG:
        "Valid values are 1-10, 11-14, 21-24, 31-34, 41-44, 51-54, 61-64, 71-74, 81-84.",
    },
    NAME: {
      PATTERN: "^.*$",
      VALIDATION_MSG: "16 character maximum.",
    },
    SERIAL_NUMBER: {
      PATTERN:
        "(0[1-4,6-9][0-9][0-9][0-9][0-9][0-9][0-9]|1[0-3][0-9][0-9][0-9][0-9][0-9][0-9])",
      INLINE_HELP: "01000000-04999999, 06000000-13999999",
      VALIDATION_MSG: "Valid values are 01000000-04999999, 06000000-13999999.",
    },
    COMPETITOR_WIRELESS_SERIAL_NUMBER: {
      PATTERN: "([0-9a-fA-F]{1,8})",
      INLINE_HELP: "00000000-FFFFFFFF",
      VALIDATION_MSG: "Valid values are 00000000-FFFFFFFF",
    },
    OUTPUT_NUMBER: {
      PATTERN:
        "(0{0,2}0|0{0,2}[1-4]|0{0,1}3[1-4]|0{0,1}4[1-4]|F0[1-9]|F1[0-9]|F20)",
      INLINE_HELP: "0, 1-4, 31-34, 41-44, F01-F20",
      VALIDATION_MSG: "Valid values are 0, 1-4, 31-34, 41-44, F01-F20.",
    },
    PREWARN_KEYPADS: {
      PATTERN: "([1-8]([\\,\\-][1-8]){0,7})",
      INLINE_HELP: "1, 2, 3, 4, 5, 6, 7, 8",
      VALIDATION_MSG: "Valid values are any combination of 1,2,3,4,5,6,7,8.",
    },
    ARMED_AREAS: {
      PATTERN: "([1-6]([\\,\\-][1-6]){0,7})",
      INLINE_HELP: "1, 2, 3, 4, 5, 6",
      VALIDATION_MSG: "Valid values are any combination of 1,2,3,4,5,6.",
    },
    ZONE_ACTIVITY_DAYS: {
      PATTERN: "([0-9]?[0-9])",
      INLINE_HELP: "0-99",
      VALIDATION_MSG: "Valid values are 0-99.",
    },
    EXPANDER_SERIAL_NUMBER: {
      PATTERN:
        "([0-3][0-9]{9}|4[0-1][0-9]{8}|42[0-8][0-9]{7}|429[0-3][0-9]{6}|4294[0-8][0-9]{5}|42949[0-5][0-9]{4}|429496[0-6][0-9]{3}|4294967[0-1][0-9]{2}|42949672[0-8][0-9]|429496729[0-5])",
      INLINE_HELP: "0000000000-4294967295",
      VALIDATION_MSG: "Valid range is 0000000000-4294967295",
    },
  },
  XT50: {},
  XT50_1100_WIRELESS_ENABLED: {
    NUMBER: {
      PATTERN: "(0{0,2}[1-9]|0?1[0-4]|0?[2-7][1-4]|0?[8-9][0-9])",
      INLINE_HELP:
        "1-10, 11-14, 21-24, 31-34, 41-44, 51-54, 61-64, 71-74, 80-99",
      VALIDATION_MSG:
        "Valid values are 1-10, 11-14, 21-24, 31-34, 41-44, 51-54, 61-64, 71-74, 80-99.",
    },
  },
  XTLP_48_ZONES: {
    NUMBER: {
      PATTERN: "(0{0,2}[1-9]|0?[1-3][0-9]|0?4[0-8])",
      INLINE_HELP: "1-48",
      VALIDATION_MSG: "Valid values are 1-48.",
    },
    OUTPUT_NUMBER: {
      PATTERN: "(0{0,2}0|0{0,1}5[1-4]|0{0,1}6[1-4]|F0[1-9]|F1[0-9]|F20)",
      INLINE_HELP: "0, 51-54, 61-64, F01-F20",
      VALIDATION_MSG: "Valid values are 0, 51-54, 61-64, F01-F20.",
    },
  },
  XTLP: {
    NUMBER: {
      PATTERN: "((?!000)0?[0-9]{1,2})",
      INLINE_HELP: "1-99",
      VALIDATION_MSG: "Valid values are 1-99.",
    },
    OUTPUT_NUMBER: {
      PATTERN: "(0{0,2}0|0{0,1}5[1-4]|0{0,1}6[1-4]|F0[1-9]|F1[0-9]|F20)",
      INLINE_HELP: "0, 51-54, 61-64, F01-F20",
      VALIDATION_MSG: "Valid values are 0, 51-54, 61-64, F01-F20.",
    },
  },
  XTL_XTLN_XTLW: {
    NUMBER: {
      PATTERN: "(0{0,1}[1-9]|1[0-9]|2[0-8])",
      INLINE_HELP: "1-28",
      VALIDATION_MSG: "Valid values are 1-28.",
    },
    COMPETITOR_WIRELESS_SERIAL_NUMBER: {
      PATTERN: "",
      INLINE_HELP: "",
      VALIDATION_MSG: "",
    },
    OUTPUT_NUMBER: {
      PATTERN: "(0{0,1}5[1-4]|0{0,1}6[1-4])",
      INLINE_HELP: "51-54, 61-64",
      VALIDATION_MSG: "Valid values are 51-54, 61-64.",
    },
    ARMED_AREAS: {
      PATTERN: "([1-6]([\\,\\-][1-6]){0,5})",
      INLINE_HELP: "1, 2, 3, 4, 5, 6",
      VALIDATION_MSG: "Valid values are any combination of 1,2,3,4,5,6.",
    },
    EXPANDER_SERIAL_NUMBER: {
      PATTERN: "",
      INLINE_HELP: "",
      VALIDATION_MSG: "",
    },
  },
};
const XR_ZONE_FIELD_RULES = {
  BASE: {
    NUMBER: {
      PATTERN: "(0{0,2}[0-9]|0?10|(0?[1-9]|1[0-6])[1-4]|[5-9][0-9][0-9])",
      INLINE_HELP:
        "1-14, 21-24, 31-34, 41-44, 51-54, 61-64, 71-74, 81-84, 91-94, 101-104, 111-114, 121-124, 131-134, 141-144, 151-154, 161-164, 500-999",
      VALIDATION_MSG:
        "Valid values are 1-14, 21-24, 31-34, 41-44, 51-54, 61-64, 71-74, 81-84, 91-94, 101-104, 111-114, 121-124, 131-134, 141-144, 151-154, 161-164, 500-999.",
    },
    NAME: {
      PATTERN: "^.*$",
      VALIDATION_MSG: "32 character maximum.",
    },
    SERIAL_NUMBER: {
      PATTERN:
        "(0[1-4,6-9][0-9][0-9][0-9][0-9][0-9][0-9]|1[0-3][0-9][0-9][0-9][0-9][0-9][0-9])",
      INLINE_HELP: "01000000-04999999, 06000000-13999999",
      VALIDATION_MSG: "Valid values are 01000000-04999999, 06000000-13999999.",
    },
    COMPETITOR_WIRELESS_SERIAL_NUMBER: {
      PATTERN: "([0-9a-fA-F]{1,8})",
      INLINE_HELP: "00000000-FFFFFFFF",
      VALIDATION_MSG: "Valid values are 00000000-FFFFFFFF",
    },
    OUTPUT_NUMBER: {
      PATTERN:
        "(0{0,3}|0{0,2}[1-6]|4([5-6][0-9]|7[0-4]|[8-9][0-9])|[5-9][0-9][0-9]|D(0[1-9]|1[0-6])|[FG](0[1-9]|1[0-9]|20))",
      INLINE_HELP: "1-6, 450-474, 480-999, D01-D16, G01-G20, F01-F20",
      VALIDATION_MSG:
        "Valid values are 1-6, 450-474, 480-999, D01-D16, G01-G20, and F01-F20.",
    },
    PREWARN_KEYPADS: {
      PATTERN: "(([1-9]|1[0-6])(([,])([1-9]|1[0-6])){0,15})",
      INLINE_HELP: "1, 2, 3, 4, 5, 6, 7-16",
      VALIDATION_MSG: "Valid values are any combination of 1-16.",
    },
    PRESIGNAL_KEYPADS: {
      PATTERN: "(([1-9]|1[0-6])(([,])([1-9]|1[0-6])){0,15})",
      INLINE_HELP: "1, 2, 3, 4, 5, 6, 7-16",
      VALIDATION_MSG: "Valid values are any combination of 1-16.",
    },
    ARMED_AREAS: {
      PATTERN:
        "(([1-9]|[1-2][0-9]|3[0-2])(([\\,\\-])([1-9]|[1-2][0-9]|3[0-2])){0,31})",
      INLINE_HELP: "1, 2, 3, 4, 5, 6, 7-32",
      VALIDATION_MSG: "Valid values are any combination of 1-32.",
    },
    ZONE_ACTIVITY_DAYS: {
      PATTERN: "(([0-2]?[0-9]?|3[0-5])[0-9]|36[0-5])",
      INLINE_HELP: "0-365",
      VALIDATION_MSG: "Valid values are 0-365.",
    },
    EXPANDER_SERIAL_NUMBER: {
      PATTERN:
        "([0-3][0-9]{9}|4[0-1][0-9]{8}|42[0-8][0-9]{7}|429[0-3][0-9]{6}|4294[0-8][0-9]{5}|42949[0-5][0-9]{4}|429496[0-6][0-9]{3}|4294967[0-1][0-9]{2}|42949672[0-8][0-9]|429496729[0-5])",
      INLINE_HELP: "0000000000-4294967295",
      VALIDATION_MSG: "Valid range is 0000000000-4294967295",
    },
  },
  XR550: {},
  XR350: {
    NUMBER: {
      PATTERN: "(0{0,2}[0-9]|0?10|(0?[1-9]|1[0-6])[1-4]|[5-7][0-9][0-9])",
      INLINE_HELP:
        "1-14, 21-24, 31-34, 41-44, 51-54, 61-64, 71-74, 81-84, 91-94, 101-104, 111-114, 121-124, 131-134, 141-144, 151-154, 161-164, 500-799",
      VALIDATION_MSG:
        "Valid values are 1-14, 21-24, 31-34, 41-44, 51-54, 61-64, 71-74, 81-84, 91-94, 101-104, 111-114, 121-124, 131-134, 141-144, 151-154, 161-164, 500-799.",
    },
    OUTPUT_NUMBER: {
      PATTERN:
        "(0{0,3}|0{0,2}[1-6]|4([5-6][0-9]|7[0-4]|[8-9][0-9])|[5-7][0-9][0-9]|D(0[1-9]|1[0-6])|[FG](0[1-9]|1[0-9]|20))",
      INLINE_HELP: "1-6, 450-474, 480-799, D01-D16, G01-G20, F01-F20",
      VALIDATION_MSG:
        "Valid values are 1-6, 450-474, 480-799, D01-D16, G01-G20, and F01-F20.",
    },
  },
  XR150: {
    NUMBER: {
      PATTERN: "(0{0,2}[0-9]|0?10|0?[1-8][1-4]|5[0-9]{2})",
      INLINE_HELP:
        "1-14, 21-24, 31-34, 41-44, 51-54, 61-64, 71-74, 81-84, 500-599",
      VALIDATION_MSG:
        "Valid values are 1-14, 21-24, 31-34, 41-44, 51-54, 61-64, 71-74, 81-84, 500-599.",
    },
    OUTPUT_NUMBER: {
      PATTERN:
        "(0{0,3}|0{0,2}[1-6]|4([5-6][0-9]|7[0-4]|[8-9][0-9])|5[0-9][0-9]|D0[1-8]|[FG](0[1-9]|1[0-9]|20))",
      INLINE_HELP: "1-6, 450-474, 480-599, D01-D08, G01-G20, F01-F20",
      VALIDATION_MSG:
        "Valid values are 1-6, 450-474, 480-599, D01-D08, G01-G20, and F01-F20.",
    },
  },
  EXPANDER_SERIAL_NUMBER: {
    PATTERN:
      "([0-3][0-9]{9}|4[0-1][0-9]{8}|42[0-8][0-9]{7}|429[0-3][0-9]{6}|4294[0-8][0-9]{5}|42949[0-5][0-9]{4}|429496[0-6][0-9]{3}|4294967[0-1][0-9]{2}|42949672[0-8][0-9]|429496729[0-5])",
    INLINE_HELP: "0000000000-4294967295",
    VALIDATION_MSG: "Valid range is 0000000000-4294967295",
  },
};
const XF_ZONE_FIELD_RULES = {
  XF6_500: {
    NUMBER: {
      PATTERN: "^((0{0,2}[1-6]|(0?[1-9]|1[0-6])[1-4]|[5-9][0-9][0-9]))$",
      INLINE_HELP:
        "1-6, 11-14, 21-24, 31-34, 41-44, 51-54, 61-64, 71-74, 81-84, 91-94, 101-104, 111-114, 121-124, 131-134, 141-144, 151-154, 161-164, 500-999",
      VALIDATION_MSG:
        "Valid values are 1-6, 11-14, 21-24, 31-34, 41-44, 51-54, 61-64, 71-74, 81-84, 91-94, 101-104, 111-114, 121-124, 131-134, 141-144, 151-154, 161-164, 500-999.",
    },
    NAME: {
      PATTERN: "^.*$",
      VALIDATION_MSG: "32 character maximum.",
    },
    SERIAL_NUMBER: {
      PATTERN:
        "(0[1,6-7][0-9][0-9][0-9][0-9][0-9][0-9]|13[0-9][0-9][0-9][0-9][0-9][0-9])",
      INLINE_HELP: "01000000-01999999, 06000000-07999999, 13000000-13999999",
      VALIDATION_MSG:
        "Valid values are 01000000-01999999, 06000000-07999999, 13000000-13999999.",
    },
    OUTPUT_NUMBER: {
      PATTERN: "(0{0,3}|0{0,2}[1-6]|[5-9][0-9][0-9]|[G](0[1-9]|1[0-9]|20))",
      INLINE_HELP: "1-6, 500-999, G01-G20",
      VALIDATION_MSG: "Valid values are 1-6, 500-999, and G01-G20.",
    },
    PRESIGNAL_KEYPADS: {
      PATTERN: "(([1-9]|1[0-6])(([,])([1-9]|1[0-6])){0,15})",
      INLINE_HELP: "1, 2, 3, 4, 5, 6, 7-16",
      VALIDATION_MSG: "Valid values are any combination of 1-16.",
    },
    EXPANDER_SERIAL_NUMBER: {
      PATTERN:
        "([0-3][0-9]{9}|4[0-1][0-9]{8}|42[0-8][0-9]{7}|429[0-3][0-9]{6}|4294[0-8][0-9]{5}|42949[0-5][0-9]{4}|429496[0-6][0-9]{3}|4294967[0-1][0-9]{2}|42949672[0-8][0-9]|429496729[0-5])",
      INLINE_HELP: "0000000000-4294967295",
      VALIDATION_MSG: "Valid range is 0000000000-4294967295",
    },
  },
  XF6_100: {
    NUMBER: {
      PATTERN: "^((0{0,2}[1-6]|(0?[1-8][1-4])|5[0-9][0-9]))$",
      INLINE_HELP:
        "1-6, 11-14, 21-24, 31-34, 41-44, 51-54, 61-64, 71-74, 81-84, 500-599",
      VALIDATION_MSG:
        "Valid values are 1-6, 11-14, 21-24, 31-34, 41-44, 51-54, 61-64, 71-74, 81-84, 500-599.",
    },
    NAME: {
      PATTERN: "^.*$",
      VALIDATION_MSG: "32 character maximum.",
    },
    SERIAL_NUMBER: {
      PATTERN:
        "(0[1,6-7][0-9][0-9][0-9][0-9][0-9][0-9]|13[0-9][0-9][0-9][0-9][0-9][0-9])",
      INLINE_HELP: "01000000-01999999, 06000000-07999999, 13000000-13999999",
      VALIDATION_MSG:
        "Valid values are 01000000-01999999, 06000000-07999999, 13000000-13999999.",
    },
    OUTPUT_NUMBER: {
      PATTERN: "(0{0,3}|0{0,2}[1-6]|5[0-9][0-9]|[G](0[1-9]|1[0-9]|20))",
      INLINE_HELP: "1-6, 500-599, G01-G20",
      VALIDATION_MSG: "Valid values are 1-6, 500-999, and G01-G20.",
    },
    PRESIGNAL_KEYPADS: {
      PATTERN: "(([1-9]|1[0-6])(([,])([1-9]|1[0-6])){0,15})",
      INLINE_HELP: "1, 2, 3, 4, 5, 6, 7-16",
      VALIDATION_MSG: "Valid values are any combination of 1-16.",
    },
    EXPANDER_SERIAL_NUMBER: {
      PATTERN:
        "([0-3][0-9]{9}|4[0-1][0-9]{8}|42[0-8][0-9]{7}|429[0-3][0-9]{6}|4294[0-8][0-9]{5}|42949[0-5][0-9]{4}|429496[0-6][0-9]{3}|4294967[0-1][0-9]{2}|42949672[0-8][0-9]|429496729[0-5])",
      INLINE_HELP: "0000000000-4294967295",
      VALIDATION_MSG: "Valid range is 0000000000-4294967295",
    },
  },
};
const TMS6_ZONE_FIELD_RULES = {
  BASE: {
    NUMBER: {
      PATTERN: "(0{0,2}[1-6])",
      INLINE_HELP: "1-6",
      VALIDATION_MSG: "Valid values are 1-6",
    },
    NAME: {
      PATTERN: "^.*$",
      VALIDATION_MSG: "16 character maximum.",
    },
    SERIAL_NUMBER: {
      PATTERN:
        "(0[1-4,6-7][0-9][0-9][0-9][0-9][0-9][0-9]|1[0-3][0-9][0-9][0-9][0-9][0-9][0-9])",
      INLINE_HELP: "01000000-04999999, 06000000-07999999",
      VALIDATION_MSG: "Valid values are 01000000-04999999, 06000000-07999999.",
    },
    OUTPUT_NUMBER: {
      PATTERN: "(0{0,2}0|0{0,2}[1-2])",
      INLINE_HELP: "0, 1-2",
      VALIDATION_MSG: "Valid values are 0, 1-2",
    },
    ARMED_AREAS: {
      PATTERN: "([1-6]([\\,\\-][1-6]){0,7})",
      INLINE_HELP: "1, 2, 3, 4, 5, 6",
      VALIDATION_MSG: "Valid values are any combination of 1,2,3,4,5,6.",
    },
    ZONE_ACTIVITY_DAYS: {
      PATTERN: "^[0-9]{0,2}?$",
      INLINE_HELP: "0-99",
      VALIDATION_MSG: "Valid values are 0-99.",
    },
    EXPANDER_SERIAL_NUMBER: {
      PATTERN: "",
      INLINE_HELP: "",
      VALIDATION_MSG: "",
    },
  },
};

/**
 *
 * @param softwareVersion
 * @returns true if XTLP software version supports 99 zones
 */
const supports99ZonesXTLP = (softwareVersion: number) => {
  return (
    (softwareVersion >= 172 && softwareVersion < 600) || softwareVersion >= 672
  );
};

/**
 *  Calculates the zones for any keypad address based omn the address
 * @param keypadAddress
 * @returns an array of zone numbers for one keypad address
 */
export const getKeypadZones = (keypadAddress: number) => {
  return range(1, 5).map((num) => {
    return keypadAddress * 10 + num;
  });
};

/**
 * Calculates all the keypad bus zones between on a min and  max keypad bus address
 * @param minKeypadAddress
 * @param maxKeypadAddress
 * @returns An array of keypad bus zones
 */
export const getKeypadBusZones = (
  minKeypadAddress: number,
  maxKeypadAddress: number
) => {
  return chain(getKeypadZones, range(minKeypadAddress, maxKeypadAddress + 1));
};

/**
 * Returns an array of all possible zone numbers for a particular hardwareModel and softwareVersion
 *@param hardwareModel ScapiHardwareModel
 *@param softwareVersion number of the softwareVersion
 *@param hasOnboardWirelessEnabled does xt50 panel have use onboard 1100 wireless set to yes
 *@returns array of all possible zone number
 */

export const getZoneNumberRange = (
  hardwareModel: PanelContextHardwareModel,
  softwareVersion: number,
  hasOnboardWirelessEnabled: boolean
): readonly number[] => {
  switch (hardwareModel) {
    case PanelHardwareModel.XR500:
    case PanelHardwareModel.XR550: {
      return concat(
        range(1, 11),
        concat(getKeypadBusZones(1, 16), range(500, 1000))
      );
    }
    case PanelHardwareModel.XF6_100: {
      return concat(
        range(1, 7),
        concat(getKeypadBusZones(1, 8), range(500, 600))
      );
    }
    case PanelHardwareModel.XF6_500: {
      return concat(
        range(1, 7),
        concat(getKeypadBusZones(1, 16), range(500, 1000))
      );
    }
    case PanelHardwareModel.XR350: {
      return concat(
        range(1, 11),
        concat(getKeypadBusZones(1, 16), range(500, 800))
      );
    }
    case PanelHardwareModel.XR100:
    case PanelHardwareModel.XR150: {
      return concat(
        range(1, 11),
        concat(getKeypadBusZones(1, 8), range(500, 600))
      );
    }
    case PanelHardwareModel.XT50: {
      //Returns all possible zones for XT50.
      return hasOnboardWirelessEnabled
        ? concat(range(1, 11), concat(getKeypadBusZones(1, 7), range(80, 100)))
        : concat(range(1, 11), getKeypadBusZones(1, 8));
    }
    case PanelHardwareModel.XTLN:
    case PanelHardwareModel.XTLW:
    case PanelHardwareModel.XTL: {
      return concat(range(1, 29), getKeypadBusZones(3, 4));
    }
    case PanelHardwareModel.XTLP: {
      return supports99ZonesXTLP(softwareVersion)
        ? range(1, 100)
        : concat(range(1, 49), getKeypadBusZones(5, 6));
    }
    case PanelHardwareModel.CELLCOM_EX:
      return [1];
    case PanelHardwareModel.ICOM:
    case PanelHardwareModel.ICOM_SL:
    case PanelHardwareModel.ICOM_E:
    case PanelHardwareModel.ICOM_NRL:
    case PanelHardwareModel.CELLCOM_SL:
    case PanelHardwareModel.MINI_CELLCOM:
    case PanelHardwareModel.DUALCOM: {
      return range(1, 5);
    }
    case PanelHardwareModel.ICOM_LNC: {
      return range(1, 20); // Should only be used for Zwave
    }
    case PanelHardwareModel.TMS6: {
      return range(1, 7);
    }
    case PanelHardwareModel.XT30:
    default: {
      return concat(range(1, 11), getKeypadBusZones(1, 8));
    }
  }
};

/**
 * Returns an array of all possible wireless zone numbers for a particular hardwareModel and softwareVersion
 *@param hardwareModel ScapiHardwareModel
 *@param softwareVersion number of the softwareVersion
 *@param hasOnboardWirelessEnabled does xt50 panel have use onboard 1100 wireless set to yes
 *@returns array of all possible wireless zone number
 */

export const getWirelessZoneNumberRange = (
  hardwareModel: PanelContextHardwareModel,
  softwareVersion: number,
  hasOnboardWirelessEnabled: boolean
): readonly number[] => {
  switch (hardwareModel) {
    case PanelHardwareModel.XR500:
    case PanelHardwareModel.XR550:
    case PanelHardwareModel.XF6_500: {
      return range(500, 1000);
    }
    case PanelHardwareModel.XR350: {
      return range(500, 800);
    }
    case PanelHardwareModel.XR100:
    case PanelHardwareModel.XR150:
    case PanelHardwareModel.XF6_100: {
      return range(500, 600);
    }
    case PanelHardwareModel.XT30: {
      return getKeypadBusZones(1, 8);
    }
    case PanelHardwareModel.XT50: {
      //Returns all possible wireless zones for XT50.
      return hasOnboardWirelessEnabled
        ? concat(getKeypadBusZones(1, 7), range(80, 100))
        : getKeypadBusZones(1, 8);
    }
    case PanelHardwareModel.XTLN:
    case PanelHardwareModel.XTLW:
    case PanelHardwareModel.XTL: {
      return concat(range(1, 29), getKeypadBusZones(3, 4));
    }
    case PanelHardwareModel.XTLP: {
      return supports99ZonesXTLP(softwareVersion)
        ? range(1, 100)
        : concat(range(1, 49), getKeypadBusZones(5, 6));
    }
    case PanelHardwareModel.ICOM:
    case PanelHardwareModel.ICOM_SL:
    case PanelHardwareModel.ICOM_E:
    case PanelHardwareModel.ICOM_NRL:
    case PanelHardwareModel.CELLCOM_SL:
    case PanelHardwareModel.MINI_CELLCOM:
    case PanelHardwareModel.ICOM_LNC:
    case PanelHardwareModel.DUALCOM:
    default: {
      return [];
    }
  }
};
export const getWirelessOnlyZoneNumberRange = (
  hardwareModel: PanelContextHardwareModel,
  softwareVersion: number,
  hasOnboardWirelessEnabled: boolean
): readonly number[] => {
  const { isXtl, isXtlN, isXtlPlus, isXtlW, isXt50 } =
    resolvePanelType(hardwareModel);

  if (isXtl || isXtlN || isXtlW) {
    return getWirelessZoneNumberRange(
      hardwareModel,
      softwareVersion,
      hasOnboardWirelessEnabled
    );
  }
  if (isXtlPlus) {
    return supports99ZonesXTLP(softwareVersion)
      ? range(1, 100)
      : concat(range(1, 49), getKeypadBusZones(5, 6));
  }
  if (isXt50) {
    return hasOnboardWirelessEnabled ? [80, ...range(85, 100)] : [];
  }
  return [];
};
/**
 * Returns an array of the on board zone numbers for a particular hardwareModel
 *@param hardwareModel ScapiHardwareModel
 *@returns array of on board zone numbers
 */

export const getOnBoardZoneNumberRange = (
  hardwareModel: PanelContextHardwareModel
): readonly number[] => {
  switch (hardwareModel) {
    case PanelHardwareModel.XTLN:
    case PanelHardwareModel.XTLW:
    case PanelHardwareModel.XTL:
    case PanelHardwareModel.ICOM_LNC:
    case PanelHardwareModel.ICOM:
    case PanelHardwareModel.ICOM_SL:
    case PanelHardwareModel.ICOM_E:
    case PanelHardwareModel.ICOM_NRL:
    case PanelHardwareModel.CELLCOM_SL:
    case PanelHardwareModel.MINI_CELLCOM:
    case PanelHardwareModel.DUALCOM: {
      return range(1, 5);
    }
    case PanelHardwareModel.XF6_500:
    case PanelHardwareModel.XF6_100: {
      return range(1, 7);
    }
    case PanelHardwareModel.XR500:
    case PanelHardwareModel.XR550:
    case PanelHardwareModel.XR350:
    case PanelHardwareModel.XR100:
    case PanelHardwareModel.XR150:
    case PanelHardwareModel.XT50:
    case PanelHardwareModel.XT30:
    default: {
      return range(1, 11);
    }
  }
};

type PanelZoneFieldRules = {
  NUMBER: {
    PATTERN: string;
    INLINE_HELP: string;
    VALIDATION_MSG: string;
  };
  NAME: {
    PATTERN: string;
    VALIDATION_MSG: string;
  };
  SERIAL_NUMBER: {
    PATTERN: string;
    INLINE_HELP: string;
    VALIDATION_MSG: string;
  };
  COMPETITOR_WIRELESS_SERIAL_NUMBER?: {
    PATTERN: string;
    INLINE_HELP: string;
    VALIDATION_MSG: string;
  };
  OUTPUT_NUMBER: {
    PATTERN: string;
    INLINE_HELP: string;
    VALIDATION_MSG: string;
  };
  PREWARN_KEYPADS?: {
    PATTERN: string;
    INLINE_HELP: string;
    VALIDATION_MSG: string;
  };
  PRESIGNAL_KEYPADS?: {
    PATTERN: string;
    INLINE_HELP: string;
    VALIDATION_MSG: string;
  };
  ARMED_AREAS?: {
    PATTERN: string;
    INLINE_HELP: string;
    VALIDATION_MSG: string;
  };
  ZONE_ACTIVITY_DAYS?: {
    PATTERN: string;
    INLINE_HELP: string;
    VALIDATION_MSG: string;
  };
  EXPANDER_SERIAL_NUMBER: {
    PATTERN: string;
    INLINE_HELP: string;
    VALIDATION_MSG: string;
  };
};

export const resolveZoneFieldRules = (
  hardwareModel: PanelContextHardwareModel,
  softwareVersion?: string | number,
  xt50BuiltIn1100Wireless?: Boolean,
  takeoverEcpOrDscEnabled?: Boolean
): PanelZoneFieldRules => {
  const {
    isTakeoverPanel,
    isTMS6,
    isXtl,
    isXtlN,
    isXtlW,
    isXtlPlus,
    isXt30,
    isXt50,
    isXr150,
    isXr350,
    isXr550,
    isCellComEx,
    isXf6_500,
    isXf6_100,
  } = resolvePanelType(hardwareModel);

  switch (true) {
    case isCellComEx: {
      return takeoverEcpOrDscEnabled
        ? {
            ...TAKEOVER_ZONE_FIELD_RULES.BASE,
            ...TAKEOVER_ZONE_FIELD_RULES.CELLCOM_EX,
            ...TAKEOVER_ZONE_FIELD_RULES.CELLCOM_EX_ECP_OR_DSC_ENABLED,
          }
        : {
            ...TAKEOVER_ZONE_FIELD_RULES.BASE,
            ...TAKEOVER_ZONE_FIELD_RULES.CELLCOM_EX,
          };
    }
    case isTMS6: {
      return { ...TMS6_ZONE_FIELD_RULES.BASE };
    }
    case isTakeoverPanel: {
      return takeoverEcpOrDscEnabled
        ? {
            ...TAKEOVER_ZONE_FIELD_RULES.BASE,
            ...TAKEOVER_ZONE_FIELD_RULES.ECP_OR_DSC_ENABLED,
          }
        : { ...TAKEOVER_ZONE_FIELD_RULES.BASE };
    }

    case isXr550: {
      return { ...XR_ZONE_FIELD_RULES.BASE };
    }
    case isXr350: {
      return { ...XR_ZONE_FIELD_RULES.BASE, ...XR_ZONE_FIELD_RULES.XR350 };
    }
    case isXr150: {
      return { ...XR_ZONE_FIELD_RULES.BASE, ...XR_ZONE_FIELD_RULES.XR150 };
    }
    case isXf6_500: {
      return { ...XF_ZONE_FIELD_RULES.XF6_500 };
    }
    case isXf6_100: {
      return { ...XF_ZONE_FIELD_RULES.XF6_100 };
    }
    case isXt50: {
      return xt50BuiltIn1100Wireless
        ? {
            ...XT_ZONE_FIELD_RULES.BASE,
            ...XT_ZONE_FIELD_RULES.XT50_1100_WIRELESS_ENABLED,
          }
        : { ...XT_ZONE_FIELD_RULES.BASE };
    }
    case isXtlPlus: {
      return ifNotNumeric(0, softwareVersion) > 171
        ? { ...XT_ZONE_FIELD_RULES.BASE, ...XT_ZONE_FIELD_RULES.XTLP }
        : { ...XT_ZONE_FIELD_RULES.BASE, ...XT_ZONE_FIELD_RULES.XTLP_48_ZONES };
    }
    case isXtlW:
    case isXtlN:
    case isXtl: {
      return {
        ...XT_ZONE_FIELD_RULES.BASE,
        ...XT_ZONE_FIELD_RULES.XTL_XTLN_XTLW,
      };
    }
    case isXt30:
    default:
      return { ...XT_ZONE_FIELD_RULES.BASE };
  }
};

export const TwentyFourHourZoneTypes = [
  ZoneType.FIRE,
  ZoneType.FIRE_VERIFY,
  ZoneType.SUPERVISORY,
  ZoneType.EMERGENCY,
  ZoneType.PANIC,
  ZoneType.CARBON_MONOXIDE,
];

export const wirelessZoneSerialNumberSupportsDisarmDisable = (
  softwareVersion: number,
  serialNumber: string
) =>
  (softwareVersion < 171 ? /^[0][9]\d{6}?$/ : /^[0][1|9]\d{6}?$/).test(
    serialNumber
  );

export const applyDupedZoneInformationProgrammingToZoneInformation = (
  source: RecordProxy<Zone>,
  dest: RecordProxy<Zone>,
  allPossibleWirelessNumbers?: Set<number>
) => {
  const id = dest.getValue("id");
  const number = dest.getValue("number");
  const wireless = dest.getValue("wireless");
  const isNew = dest.getValue("isNew");
  const serialNumber = dest.getValue("serialNumber");
  const contactNumber = dest.getValue("contactNumber");
  const supervisionTime = dest.getValue("supervisionTime");
  const wirelessLedEnabled = dest.getValue("wirelessLedEnabled");
  const wirelessDisarmDisableEnabled = dest.getValue(
    "wirelessDisarmDisableEnabled"
  );
  const wirelessPirPulseCount = dest.getValue("wirelessPirPulseCount");
  const wirelessPirSensitivity = dest.getValue("wirelessPirSensitivity");
  const wirelessPetImmunity = dest.getValue("wirelessPetImmunity");
  const wirelessContactNormallyOpen = dest.getValue(
    "wirelessContactNormallyOpen"
  );
  const sensorType = dest.getValue("sensorType");
  const competitorWireless = dest.getValue("competitorWireless");

  const canDestBeWireless = allPossibleWirelessNumbers?.has(Number(number));

  const remoteZoneType = dest.getValue("remoteZoneType");

  dest.copyFieldsFrom(source);
  dest.setValue(id, "id");
  dest.setValue(isNew, "isNew");
  dest.setValue(number, "number");
  if (remoteZoneType) {
    dest.setValue(remoteZoneType, "remoteZoneType");
  }
  if (!canDestBeWireless) {
    dest.setValue(wireless, "wireless");
    dest.setValue(serialNumber, "serialNumber");
    dest.setValue(contactNumber, "contactNumber");
    dest.setValue(supervisionTime, "supervisionTime");
    dest.setValue(wirelessLedEnabled, "wirelessLedEnabled");
    dest.setValue(wirelessDisarmDisableEnabled, "wirelessDisarmDisableEnabled");
    dest.setValue(wirelessPirPulseCount, "wirelessPirPulseCount");
    dest.setValue(wirelessPirSensitivity, "wirelessPirSensitivity");
    dest.setValue(wirelessPetImmunity, "wirelessPetImmunity");
    dest.setValue(wirelessContactNormallyOpen, "wirelessContactNormallyOpen");
    dest.setValue(sensorType, "sensorType");
    dest.setValue(competitorWireless, "competitorWireless");
  }
  return dest;
};

export const applyCopiedZoneInformationProgrammingToZoneInformation = (
  source: RecordProxy<Zone>,
  dest: RecordProxy<Zone>,
  availableWirelessNumbers?: Set<number>
) => {
  const destId = dest.getValue("id");
  const destNumber = dest.getValue("number");
  const destIsNew = dest.getValue("isNew");
  const destWireless = dest.getValue("wireless");
  const destSerialNumber = dest.getValue("serialNumber");
  const destContactNumber = dest.getValue("contactNumber");
  const destSupervisionTime = dest.getValue("supervisionTime");
  const destWirelessEnabled = dest.getValue("wirelessLedEnabled");
  const destWirelessDisarmDisableEnabled = dest.getValue(
    "wirelessDisarmDisableEnabled"
  );
  const destWirelessPirPulseCount = dest.getValue("wirelessPirPulseCount");
  const destWirelessPirSensitivity = dest.getValue("wirelessPirSensitivity");
  const destWirelessPetImmunity = dest.getValue("wirelessPetImmunity");
  const destWirelessContactNormallyOpen = dest.getValue(
    "wirelessContactNormallyOpen"
  );
  const destSensorType = dest.getValue("sensorType");
  const destCompetitorWireless = dest.getValue("competitorWireless");
  const remoteZoneType = dest.getValue("remoteZoneType");

  const srcNumber = source.getValue("number");

  dest.copyFieldsFrom(source);
  dest.setValue(destId, "id");
  dest.setValue(destIsNew, "isNew");
  dest.setValue(destNumber, "number");

  if (remoteZoneType) {
    dest.setValue(remoteZoneType, "remoteZoneType");
  }
  const canDestBeWireless =
    availableWirelessNumbers?.has(Number(destNumber)) ?? false;
  const canSrcBeWireless =
    availableWirelessNumbers?.has(Number(srcNumber)) ?? false;

  if ((!canSrcBeWireless && !canDestBeWireless) || !canDestBeWireless) {
    dest.setValue(destSensorType, "sensorType");
    dest.setValue(destWireless, "wireless");
    dest.setValue(destSerialNumber, "serialNumber");
    dest.setValue(destContactNumber, "contactNumber");
    dest.setValue(destSupervisionTime, "supervisionTime");
    dest.setValue(destWirelessEnabled, "wirelessLedEnabled");
    dest.setValue(
      destWirelessDisarmDisableEnabled,
      "wirelessDisarmDisableEnabled"
    );
    dest.setValue(destWirelessPirPulseCount, "wirelessPirPulseCount");
    dest.setValue(destWirelessPirSensitivity, "wirelessPirSensitivity");
    dest.setValue(destWirelessPetImmunity, "wirelessPetImmunity");
    dest.setValue(
      destWirelessContactNormallyOpen,
      "wirelessContactNormallyOpen"
    );
    dest.setValue(destCompetitorWireless, "competitorWireless");
  }
  return dest;
};

export const applyTakeoverZoneInformationProgrammingToZoneInformation = (
  source: RecordProxy<Zone>,
  dest: RecordProxy<Zone>,
  isWireless: boolean
) => {
  const id = dest.getValue("id");
  const number = dest.getValue("number");
  const wireless = dest.getValue("wireless");
  const isNew = dest.getValue("isNew");
  const serialNumber = dest.getValue("serialNumber");
  const contactNumber = dest.getValue("contactNumber");
  const supervisionTime = dest.getValue("supervisionTime");
  const wirelessLedEnabled = dest.getValue("wirelessLedEnabled");
  const wirelessDisarmDisableEnabled = dest.getValue(
    "wirelessDisarmDisableEnabled"
  );
  const wirelessPirPulseCount = dest.getValue("wirelessPirPulseCount");
  const wirelessPirSensitivity = dest.getValue("wirelessPirSensitivity");
  const wirelessPetImmunity = dest.getValue("wirelessPetImmunity");
  const wirelessContactNormallyOpen = dest.getValue(
    "wirelessContactNormallyOpen"
  );
  const sensorType = dest.getValue("sensorType");
  const competitorWireless = dest.getValue("competitorWireless");
  dest.copyFieldsFrom(source);
  dest.setValue(id, "id");
  dest.setValue(isNew, "isNew");
  dest.setValue(number, "number");
  if (!isWireless) {
    dest.setValue(sensorType, "sensorType");
    dest.setValue(wireless, "wireless");
    dest.setValue(serialNumber, "serialNumber");
    dest.setValue(contactNumber, "contactNumber");
    dest.setValue(supervisionTime, "supervisionTime");
    dest.setValue(wirelessLedEnabled, "wirelessLedEnabled");
    dest.setValue(wirelessDisarmDisableEnabled, "wirelessDisarmDisableEnabled");
    dest.setValue(wirelessPirPulseCount, "wirelessPirPulseCount");
    dest.setValue(wirelessPirSensitivity, "wirelessPirSensitivity");
    dest.setValue(wirelessPetImmunity, "wirelessPetImmunity");
    dest.setValue(wirelessContactNormallyOpen, "wirelessContactNormallyOpen");
    dest.setValue(competitorWireless, "competitorWireless");
  }
  return dest;
};

/**
 * @description
 *  Checks to see whether theNumber is within distance of the closest number in theArray
 * @param theNumber the number that is being tested
 * @param theArray the Array of existing numbers
 * @param distance the allowed maximum distance
 */
export function isNumberWithinRange(
  theNumber: number,
  theArray: number[],
  distance: number
) {
  theArray.sort(function (a, b) {
    return a - b;
  });
  theArray = theArray.map(Number);
  const offsets = range(0, distance);
  //creates an array of valid sets for the distance
  const validGroupings = offsets.map(
    (offset) =>
      new Set(range(theNumber - offset, theNumber - offset + distance))
  );
  //checks that each validGrouping and makes sure that every number is in at least one of them
  return validGroupings.some((grouping) =>
    theArray.every((num) => grouping.has(num))
  );
}

/**
 * @description
 *  Checks to see whether theNumber is within distance of a proper group of numbers in from the validValuesArray
 * @param theNumber the number that is being tested
 * @param theArray the Array of existing numbers
 * @param validValuesArray the Array of valid values for the number
 * @param distance the allowed maximum distance
 */
export function isNumberWithinIndexRange(
  theNumber: number,
  theArray: number[],
  validValuesArray: number[] | readonly number[],
  distance: number
) {
  theArray.sort(function (a, b) {
    return a - b;
  });
  theArray = theArray.map(Number);
  const theNumberIndex = validValuesArray.indexOf(theNumber);
  const offsets = range(0, distance);
  //creates an array of valid sets for the distance
  const validGroupings = offsets.map(
    (offset) =>
      new Set(
        slice(
          theNumberIndex - offset,
          theNumberIndex - offset + distance,
          validValuesArray
        )
      )
  );
  //checks that each validGrouping and makes sure that every number is in at least one of them
  return validGroupings.some((grouping) =>
    theArray.every((num) => grouping.has(num))
  );
}

export const wirelessPirPulseCountEnabledSerialRange = {
  min: 9100000,
  max: 9999999,
};

export const wirelessPirSensitivityEnabledSerialRange = {
  min: 9100000,
  max: 9999999,
};

export const wirelessPetImmunityEnabledSerialRange = {
  min: 9500000,
  max: 9999999,
};
