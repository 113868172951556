import { closest } from "common/utils";
import { resolvePanelType } from "components/FullProgramming/utils/panel";
import { concat, range, toUpper } from "ramda";
import { PanelHardwareModel } from "../__generated__/PanelContextUseHardwareModel_panel.graphql";

const resolveOutputOptionsFieldPattern = (
  hardwareModel: PanelHardwareModel,
  houseCode: number
) => {
  const {
    isXtl,
    isXtlN,
    isXtlPlus,
    isXtlW,
    isTakeoverPanel,
    isTMS6,
    isXr150,
    isXr350,
    isXr550,
    isCellComEx,
    isXf6_500,
    isXf6_100,
  } = resolvePanelType(hardwareModel);

  if (isXtl || isXtlPlus || isXtlN || isXtlW) {
    return "(0{0,2}0|0{0,1}5[1-4]|0{0,1}6[1-4]|F0[1-9]|F1[0-9]|F20)";
  }
  if (isCellComEx) {
    return "(0{0,2}0|0{0,2}[1])";
  }
  if (isTakeoverPanel) {
    return "(0{0,2}0|0{0,2}[1-2]|F0[1-9]|F1[0-9]|F20)";
  }
  if (isTMS6) {
    return "(0{0,2}0|0{0,2}[1-2])";
  }

  if (isXr150) {
    return "(0{0,3}|0{0,2}[1-6]|4([5-6][0-9]|7[0-4]|[8-9][0-9])|5[0-9][0-9]|D0[1-8]|[FG](0[1-9]|1[0-9]|20))";
  }
  if (isXr350) {
    return "(0{0,3}|0{0,2}[1-6]|4([5-6][0-9]|7[0-4]|[8-9][0-9])|[5-7][0-9][0-9]|D(0[1-9]|1[0-6])|[FG](0[1-9]|1[0-9]|20))";
  }
  if (isXr550) {
    return "(0{0,3}|0{0,2}[1-6]|4([5-6][0-9]|7[0-4]|[8-9][0-9])|[5-9][0-9][0-9]|D(0[1-9]|1[0-6])|[FG](0[1-9]|1[0-9]|20))";
  }
  if (isXf6_500) {
    return "(0{0,3}|0{0,2}[1-6]|[5-9][0-9][0-9]|[G](0[1-9]|1[0-9]|20))";
  }
  if (isXf6_100) {
    return "(0{0,3}|0{0,2}[1-6]|[5][0-9][0-9]|[G](0[1-9]|1[0-9]|20))";
  } else
    return houseCode === 0
      ? "(0{0,2}0|0{0,2}[1-4]|F0[1-9]|F1[0-9]|F20)"
      : "(0{0,2}0|0{0,2}[1-4]|0{0,1}3[1-4]|0{0,1}4[1-4]|F0[1-9]|F1[0-9]|F20)";
};
const resolveOutputOptionsFieldValidationMessage = (
  hardwareModel: PanelHardwareModel,
  houseCode: number
) => {
  const {
    isXtl,
    isXtlN,
    isXtlPlus,
    isXtlW,
    isTakeoverPanel,
    isXr150,
    isXr350,
    isXr550,
    isCellComEx,
    isXf6_500,
    isXf6_100,
    isTMS6,
  } = resolvePanelType(hardwareModel);

  if (isXtl || isXtlPlus || isXtlN || isXtlW) {
    return "Valid values are 0, 51-54, 61-64, F01-F20.";
  }
  if (isCellComEx) {
    return "Valid values are 0, 1.";
  }
  if (isTakeoverPanel) {
    return "Valid values are 0, 1-2, F01-F20.";
  }
  if (isTMS6) {
    return "Valid values are 0, 1-2.";
  }

  if (isXr150) {
    return "Valid values are 1-6, 450-474, 480-599, D01-D08, G01-G20, and F01-F20.";
  }
  if (isXr350) {
    return "Valid values are 1-6, 450-474, 480-799, D01-D16, G01-G20, and F01-F20.";
  }
  if (isXr550) {
    return "Valid values are 1-6, 450-474, 480-999, D01-D16, G01-G20, and F01-F20.";
  }
  if (isXf6_500) {
    return "Valid values are 1-6, 500-999, and G01-G20.";
  }
  if (isXf6_100) {
    return "Valid values are 1-6, 500-599, and G01-G20.";
  } else
    return houseCode === 0
      ? "Valid values are 0, 1-4, F01-F20."
      : "Valid values are 0, 1-4, 31-34, 41-44, F01-F20.";
};
const resolveOutputOptionsFieldInlineHelp = (
  hardwareModel: PanelHardwareModel,
  houseCode: number
) => {
  const {
    isXtl,
    isXtlN,
    isXtlPlus,
    isXtlW,
    isTakeoverPanel,
    isXr150,
    isXr350,
    isXr550,
    isCellComEx,
    isXf6_500,
    isXf6_100,
    isTMS6,
  } = resolvePanelType(hardwareModel);

  if (isXtl || isXtlPlus || isXtlN || isXtlW) {
    return "0, 51-54, 61-64, F01-F20";
  }
  if (isCellComEx) {
    return "0, 1";
  }
  if (isTakeoverPanel) {
    return "0, 1-2, F01-F20";
  }
  if (isTMS6) {
    return "0, 1-2";
  }

  if (isXr150) {
    return "1-6, 450-474, 480-599, D01-D08, G01-G20, F01-F20";
  }
  if (isXr350) {
    return "1-6, 450-474, 480-799, D01-D16, G01-G20, F01-F20";
  }
  if (isXr550) {
    return "1-6, 450-474, 480-999, D01-D16, G01-G20, F01-F20";
  }
  if (isXf6_500) {
    return "1-6, 500-999, G01-G20";
  }
  if (isXf6_100) {
    return "1-6, 500-599, G01-G20";
  } else
    return houseCode === 0
      ? "0, 1-4, F01-F20"
      : "0, 1-4, 31-34, 41-44, F01-F20";
};

export const formatOutputOnBlur = (output: string): string => {
  if (!isNaN(Number(output))) {
    return output.padStart(3, `0`);
  }
  const upperOutput = toUpper(output);
  if (["D", "G", "F"].includes(upperOutput[0])) {
    return upperOutput.length === 2
      ? upperOutput.replace(upperOutput[0], `${upperOutput[0]}0`)
      : upperOutput;
  }
  return "000";
};

export const resolveOutputOptionsFieldNumbers = (
  hardwareModel: PanelHardwareModel,
  houseCode: number = 0
) => {
  return {
    pattern: resolveOutputOptionsFieldPattern(hardwareModel, houseCode),
    inlineHelp: resolveOutputOptionsFieldInlineHelp(hardwareModel, houseCode),
    validationMessage: resolveOutputOptionsFieldValidationMessage(
      hardwareModel,
      houseCode
    ),
  };
};

const formatStringValue = (
  newValue: string,
  leadingLetter: string,
  numericRange: number[]
) => {
  const numbersOnly = newValue.substring(newValue.indexOf(leadingLetter) + 1);
  const closestNumber = closest(Number(numbersOnly), numericRange);
  const concatNumberToLetter = leadingLetter.concat(closestNumber.toString());
  return formatOutputOnBlur(concatNumberToLetter);
};

export const getClosestOutputValue = (
  newValue: string,
  originalValue: string,
  hardwareModel: PanelHardwareModel,
  validRange: number[]
) => {
  const { isXr150, isXr350, isXr550, isXf, isTMSentry } =
    resolvePanelType(hardwareModel);

  let leadingLetter = "";
  let numericRange = [0];
  let formattedValue = "";

  const canHaveDoorAndGroupOutputs = isXr550 || isXr150 || isXr350;

  const canHaveGroupOutputsOnly = isXf;

  const axDoorRange = [
    501, 505, 509, 513, 517, 521, 525, 529, 533, 537, 541, 545, 549, 553, 557,
    561, 601, 605, 609, 613, 617, 621, 625, 629, 633, 637, 641, 645, 649, 653,
    657, 661, 701, 705, 709, 713, 717, 721, 725, 729, 733, 737, 741, 745, 749,
    753, 757, 761, 801, 805, 809, 813, 817, 821, 825, 829, 833, 837, 841, 845,
    849, 853, 857, 861, 901, 905, 909, 913, 917, 921, 925, 929, 933, 937, 941,
    945, 949, 953, 957, 961,
  ];

  switch (newValue.toUpperCase().charAt(0)) {
    case "D":
      if (canHaveDoorAndGroupOutputs && !canHaveGroupOutputsOnly) {
        leadingLetter = "D";
        numericRange = isXr150
          ? range(1, 9)
          : isXr350
          ? range(1, 17)
          : concat(range(1, 17), axDoorRange);
      }
      break;
    case "F":
      if (!canHaveGroupOutputsOnly) {
        leadingLetter = "F";
        numericRange = range(1, 21);
      }
      break;
    case "G":
      if (canHaveDoorAndGroupOutputs || canHaveGroupOutputsOnly) {
        leadingLetter = "G";
        numericRange = range(1, 21);
      }
      break;
    default:
      break;
  }

  if (leadingLetter !== "") {
    formattedValue = formatStringValue(
      newValue.toUpperCase(),
      leadingLetter,
      numericRange
    );
  } else {
    const formattedNumber =
      newValue === ""
        ? "000"
        : isNaN(Number(newValue))
        ? originalValue
        : closest(Number(newValue), validRange).toString();
    formattedValue = formatOutputOnBlur(formattedNumber);
  }
  return `${formattedValue}`;
};
