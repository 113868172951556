/**
 * @generated SignedSource<<32aee120c61048ccc2a42a04603856e7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PanelContextUseBuiltIn1100WirelessEnabled_panel$data = {
  readonly systemOptions: {
    readonly useBuiltIn1100Wireless?: boolean;
  };
  readonly " $fragmentType": "PanelContextUseBuiltIn1100WirelessEnabled_panel";
};
export type PanelContextUseBuiltIn1100WirelessEnabled_panel$key = {
  readonly " $data"?: PanelContextUseBuiltIn1100WirelessEnabled_panel$data;
  readonly " $fragmentSpreads": FragmentRefs<"PanelContextUseBuiltIn1100WirelessEnabled_panel">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PanelContextUseBuiltIn1100WirelessEnabled_panel",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "systemOptions",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "useBuiltIn1100Wireless",
              "storageKey": null
            }
          ],
          "type": "XtSystemOptions",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Panel",
  "abstractKey": null
};

(node as any).hash = "775c78a1f12f0eb244e1b313136f14eb";

export default node;
