import graphql from "babel-plugin-relay/macro";
import { sleep } from "common/utils";
import { isNotNullOrUndefined } from "common/utils/universal/function";
import { GenericPageFallback } from "components/GenericPageFallback";
import * as React from "react";
import { fetchQuery, useFragment, useRefetchableFragment } from "react-relay";
import { RecordProxy } from "relay-runtime";
import RelayModernEnvironment from "relay-runtime/lib/store/RelayModernEnvironment";
import { asString, ControlSystem, ID } from "securecom-graphql/client";
import { Concept } from "../common/FullProgrammingForm";
import FullProgrammingVersionEntryPoint from "../common/FullProgrammingVersionEntryPoint";
import { panelVersionLTOE } from "../utils/panel";
import * as AreaInformation from "./XTAreaInformationProgrammingConceptForm";
import * as BellOptions from "./XTBellOptionsProgrammingConceptForm";
import * as Communication from "./XTCommunicationProgrammingConceptForm";
import * as DeviceSetup from "./XTDeviceSetupProgrammingConceptForm";
import * as Keyfobs from "./XTKeyfobProgrammingConceptForm";
import * as LockoutCode from "./XTLockoutCodeProgrammingConceptForm";
import * as MessagingSetup from "./XTMessagingSetupProgrammingConceptForm";
import * as NetworkOptions from "./XTNetworkOptionsProgrammingConceptForm";
import * as OutputInformation from "./XTOutputInformationProgrammingConceptForm";
import * as OutputOptions from "./XTOutputOptionsProgrammingConceptForm";
import * as RemoteOptions from "./XTRemoteOptionsProgrammingConceptForm";
import * as SecurityGrade from "./XTSecurityGradeProgrammingConceptForm";
import * as SystemOptions from "./XTSystemOptionsProgrammingConceptForm";
import * as SystemReports from "./XTSystemReportsProgrammingConceptForm";
import * as ZoneInformations from "./XTZoneInformationsProgrammingConceptForm";
import { XTFullProgrammingContainer_controlSystem$key } from "./__generated__/XTFullProgrammingContainer_controlSystem.graphql";
import { XTFullProgrammingContainer_templateStatus_controlSystem$key } from "./__generated__/XTFullProgrammingContainer_templateStatus_controlSystem.graphql";
import { XTFullProgrammingContainer_templateStatus_controlSystemQuery } from "./__generated__/XTFullProgrammingContainer_templateStatus_controlSystemQuery.graphql";
import { XTFullProgrammingTemplateQuery } from "./__generated__/XTFullProgrammingTemplateQuery.graphql";

export const createConcepts = ({
  softwareVersion,
  hasMessagingSetup,
}: {
  softwareVersion: number;
  hasMessagingSetup: boolean;
}) =>
  [
    Communication,
    NetworkOptions,
    hasMessagingSetup ? MessagingSetup : null,
    DeviceSetup,
    RemoteOptions,
    SystemReports,
    SystemOptions,
    BellOptions,
    OutputInformation,
    OutputOptions,
    AreaInformation,
    ZoneInformations,
    Keyfobs,
    LockoutCode,
    softwareVersion >= 620 ? SecurityGrade : null,
  ].filter(isNotNullOrUndefined) as Concept[];

export const xtFullProgrammingControlSystem = graphql`
  fragment XTFullProgramming_controlSystem on ControlSystem {
    id
    name
    ...FullProgrammingForm_controlSystem
    ...ProgrammingTemplateForm_controlSystem
    ...ControlSystemContext_controlSystem
    ...ControlSystemContextUseIsTakeoverPanelWithEcpOrDscEnabled_controlSystem
    ...XTCommunicationProgrammingConceptForm_controlSystem
    ...XTNetworkOptionsProgrammingConceptForm_controlSystem
    ...XTDeviceSetupProgrammingConceptForm_controlSystem
    ...XTDeviceSetupProgrammingConceptFormNavButton_controlSystem
    ...XTLockoutCodeProgrammingConceptForm_controlSystem
    ...XTRemoteOptionsProgrammingConceptForm_controlSystem
    ...XTMessagingSetupProgrammingConceptForm_controlSystem
    ...XTSystemReportsProgrammingConceptForm_controlSystem
    ...XTSystemOptionsProgrammingConceptForm_controlSystem
    ...XTOutputOptionsProgrammingConceptForm_controlSystem
    ...XTOutputInformationProgrammingConceptForm_controlSystem
    ...XTOutputInformationProgrammingConceptFormNavButton_controlSystem
    ...XTBellOptionsProgrammingConceptForm_controlSystem
    ...XTAreaInformationProgrammingConceptForm_controlSystem
    ...XTAreaInformationProgrammingConceptFormNavButton_controlSystem
    ...XTZoneInformationsProgrammingConceptForm_controlSystem
    ...XTZoneInformationsProgrammingConceptFormNavButton_controlSystem
    ...XTKeyfobProgrammingConceptForm_controlSystem
    ...XTKeyfobProgrammingConceptFormNavButton_controlSystem
    ...XTSecurityGradeProgrammingConceptForm_controlSystem
  }
`;

export const xtFullProgrammingInlineControlSystem = graphql`
  fragment XTFullProgrammingInline_controlSystem on ControlSystem {
    ...XTCommunicationProgrammingConceptFormInline_controlSystem
    ...XTNetworkOptionsProgrammingConceptFormInline_controlSystem
    ...XTDeviceSetupProgrammingConceptFormInline_controlSystem
    ...XTLockoutCodeProgrammingConceptFormInline_controlSystem
    ...XTRemoteOptionsProgrammingConceptFormInline_controlSystem
    ...XTMessagingSetupProgrammingConceptFormInline_controlSystem
    ...XTSystemReportsProgrammingConceptFormInline_controlSystem
    ...XTSystemOptionsProgrammingConceptFormInline_controlSystem
    ...XTOutputOptionsProgrammingConceptFormInline_controlSystem
    ...XTOutputInformationProgrammingConceptFormInline_controlSystem
    ...XTBellOptionsProgrammingConceptFormInline_controlSystem
    ...XTAreaInformationProgrammingConceptFormInline_controlSystem
    ...XTZoneInformationsProgrammingConceptFormInline_controlSystem
    ...XTKeyfobProgrammingConceptFormInline_controlSystem
    ...XTSecurityGradeProgrammingConceptFormInline_controlSystem
  }
`;

function XTFullProgrammingContainer(props: {
  dealerId: ID;
  systemId: string;
  controlSystem: XTFullProgrammingContainer_controlSystem$key;
}) {
  const controlSystemFragment = useFragment(
    graphql`
      fragment XTFullProgrammingContainer_controlSystem on ControlSystem {
        id
        name
        panel {
          softwareVersion
        }
        ...XTFullProgrammingContainer_templateStatus_controlSystem
      }
    `,
    props.controlSystem
  );

  const softwareVersion = Number(controlSystemFragment.panel?.softwareVersion);
  const hasMessagingSetup = panelVersionLTOE(192, softwareVersion);

  const concepts = React.useMemo(
    () =>
      createConcepts({
        softwareVersion,
        hasMessagingSetup,
      }) as Concept[],
    [hasMessagingSetup, softwareVersion]
  );

  const [{ templatePreProgrammingJobStatus }, refetchJobStatus] =
    useRefetchableFragment<
      XTFullProgrammingContainer_templateStatus_controlSystemQuery,
      XTFullProgrammingContainer_templateStatus_controlSystem$key
    >(
      graphql`
        fragment XTFullProgrammingContainer_templateStatus_controlSystem on ControlSystem
        @refetchable(
          queryName: "XTFullProgrammingContainer_templateStatus_controlSystemQuery"
        ) {
          templatePreProgrammingJobStatus
        }
      `,
      controlSystemFragment
    );

  const jobPending = [
    "new",
    "created",
    "running",
    "acquired",
    "started",
  ].includes(templatePreProgrammingJobStatus ?? "");

  React.useEffect(() => {
    type Timer = ReturnType<typeof setTimeout>;
    const timeOutIds: Array<Timer> = [];
    if (jobPending) {
      timeOutIds.push(
        setTimeout(function reloadData() {
          refetchJobStatus({}, { fetchPolicy: "store-and-network" });
          timeOutIds.push(setTimeout(reloadData, 5000));
        }, 5000)
      );
    }

    return () => {
      timeOutIds.forEach((id) => clearTimeout(id));
    };
  }, [refetchJobStatus, jobPending]);

  return jobPending ? (
    <GenericPageFallback message={"Applying Template Programming..."} />
  ) : (
    <FullProgrammingVersionEntryPoint
      systemName={controlSystemFragment.name}
      concepts={concepts}
      gqlQuery={graphql`
        query XTFullProgrammingQuery($dealerId: ID!, $systemId: ID!) {
          dealer: node(id: $dealerId) {
            ... on Dealer {
              id
              ...FullProgrammingVersionEntryPoint_dealer
            }
          }
          controlSystem: node(id: $systemId) {
            ... on ControlSystem {
              id
              name
              panel {
                softwareVersion
                hardwareModel
              }
              customer {
                id
              }
              ...NetworkOptionsDhcpEnabledField_controlSystem
              ...XTFullProgramming_controlSystem
              ...XTCommunicationProgrammingConceptFormInline_controlSystem
              ...XTAreaInformationProgrammingConceptFormOriginalAreas_controlSystem
              ...XTSystemOptionsProgrammingConceptFormInline_controlSystem
              ...NetworkOptionsDhcpEnabledField_controlSystem
              ...XTOutputInformationProgrammingConceptFormInline_controlSystem
              ...XTAreaInformationProgrammingConceptFormInline_controlSystem
              ...XTDeviceSetupProgrammingConceptFormInline_controlSystem
              ...XTKeyfobProgrammingConceptFormInline_controlSystem
              ...XTZoneInformationsProgrammingConceptFormInline_controlSystem
              ...XTNetworkOptionsProgrammingConceptFormInline_controlSystem
              ...XTRemoteOptionsProgrammingConceptFormInline_controlSystem
              ...XTSystemReportsProgrammingConceptFormInline_controlSystem
              ...XTOutputOptionsProgrammingConceptFormInline_controlSystem
              ...XTLockoutCodeProgrammingConceptFormInline_controlSystem
              ...XTMessagingSetupProgrammingConceptFormInline_controlSystem
              ...XTBellOptionsProgrammingConceptFormInline_controlSystem
            }
          }
          controlSystemInline: node(id: $systemId) {
            ... on ControlSystem {
              id
              ...XTFullProgrammingInline_controlSystem
            }
          }
        }
      `}
      gqlQueryVariables={{
        systemId: props.systemId,
        dealerId: asString(props.dealerId),
      }}
      gqlFormControlSystemFragment={xtFullProgrammingControlSystem}
      gqlFormControlSystemInlineFragment={xtFullProgrammingInlineControlSystem}
      applyTemplate={applyTemplate(props.systemId, concepts)}
    />
  );
}

export default XTFullProgrammingContainer;

const fetchTemplateData = (environment: RelayModernEnvironment, id: string) =>
  fetchQuery<XTFullProgrammingTemplateQuery>(
    environment,
    graphql`
      query XTFullProgrammingTemplateQuery($templateId: ID!) {
        programmingTemplate: node(id: $templateId) {
          id
          ... on ProgrammingTemplate {
            concepts {
              ... on XtProgrammingTemplateConcepts {
                systemOptions {
                  included
                  systemType {
                    included
                    data
                  }
                }
                ...XTCommunicationProgrammingConceptFormInline_xtProgrammingTemplateConcepts
                ...XTNetworkOptionsProgrammingConceptFormInline_xtProgrammingTemplateConcepts
                ...XTDeviceSetupProgrammingConceptFormInline_xrProgrammingTemplateConcepts
                ...XTRemoteOptionsProgrammingConceptFormInline_xtProgrammingTemplateConcepts
                ...XTSystemReportsProgrammingConceptFormInline_xtProgrammingTemplateConcepts
                ...XTSystemOptionsProgrammingConceptFormInline_xtProgrammingTemplateConcepts
                ...XTBellOptionsProgrammingConceptFormInline_xrProgrammingTemplateConcepts
                ...XTOutputInformationProgrammingConceptFormInline_xrProgrammingTemplateConcepts
                ...XTOutputOptionsProgrammingConceptFormInline_xtProgrammingTemplateConcepts
                ...XTAreaInformationProgrammingConceptFormInline_xtProgrammingTemplateConcepts
                ...XTZoneInformationsProgrammingConceptFormInline_xtProgrammingTemplateConcepts
                ...XTKeyfobProgrammingConceptFormInline_xrProgrammingTemplateConcepts
                ...XTLockoutCodeProgrammingConceptFormInline_xtProgrammingTemplateConcepts
              }
            }
          }
        }
      }
    `,
    { templateId: id },
    { fetchPolicy: "network-only" }
  )
    .toPromise()
    .then((response) => response?.programmingTemplate);

const applyTemplate =
  (systemId: string, concepts: Concept[]) =>
  async (environment: RelayModernEnvironment, templateId: string) => {
    const data = await fetchTemplateData(environment, templateId);
    if (data?.concepts) {
      if (
        data.concepts?.systemOptions?.included &&
        data.concepts?.systemOptions?.systemType?.included
      ) {
        environment.commitUpdate((store) => {
          const controlSystem =
            store.get<ControlSystem>(systemId) ??
            (store.create(
              systemId,
              "ControlSystem"
            ) as RecordProxy<ControlSystem>);
          if (
            controlSystem
              .getLinkedRecord("systemOptions")
              ?.getValue("systemType") !==
            data.concepts?.systemOptions?.systemType?.data
          ) {
            concepts.forEach((concept) => {
              if (concept.applyTemplateData) {
                if (concept.conceptId === "xt-system-options") {
                  concept.applyTemplateData(
                    data.concepts,
                    controlSystem,
                    store
                  );
                }
              }
            });
          }
        });
        await sleep(50);
      }
      environment.commitUpdate((store) => {
        const controlSystem =
          store.get<ControlSystem>(systemId) ??
          (store.create(
            systemId,
            "ControlSystem"
          ) as RecordProxy<ControlSystem>);
        concepts.forEach((concept) => {
          if (concept.applyTemplateData) {
            concept.applyTemplateData(data.concepts, controlSystem, store);
          }
        });
      });
    }
  };
