/**
 * @generated SignedSource<<e419e04f01c6fa3e472e61196c2b7515>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FullProgramming_controlSystem$data = {
  readonly id: string;
  readonly isTMSentry: boolean;
  readonly isTakeoverPanel: boolean;
  readonly isXf: boolean;
  readonly isXr: boolean;
  readonly isXt: boolean;
  readonly name: string;
  readonly " $fragmentSpreads": FragmentRefs<"TMSentryFullProgrammingContainer_controlSystem" | "TakeoverPanelFullProgrammingContainer_controlSystem" | "XFFullProgrammingContainer_controlSystem" | "XRFullProgrammingContainer_controlSystem" | "XTFullProgrammingContainer_controlSystem">;
  readonly " $fragmentType": "FullProgramming_controlSystem";
};
export type FullProgramming_controlSystem$key = {
  readonly " $data"?: FullProgramming_controlSystem$data;
  readonly " $fragmentSpreads": FragmentRefs<"FullProgramming_controlSystem">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FullProgramming_controlSystem",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isXr",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isXt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isTakeoverPanel",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isXf",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isTMSentry",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "XRFullProgrammingContainer_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "XFFullProgrammingContainer_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TakeoverPanelFullProgrammingContainer_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "XTFullProgrammingContainer_controlSystem"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TMSentryFullProgrammingContainer_controlSystem"
    }
  ],
  "type": "ControlSystem",
  "abstractKey": null
};

(node as any).hash = "d5bae18be31fe9a8fcc1774360153a95";

export default node;
