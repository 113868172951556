/**
 * @generated SignedSource<<dbd10784d1d8947561cb8fa1d2f2a52d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type SystemType = "ALL_PERIMETER" | "AREA" | "HOME_SLEEP_AWAY" | "HOME_SLEEP_AWAY_GUEST";
import { FragmentRefs } from "relay-runtime";
export type PanelContextUseSystemOptionsSystemType_panel$data = {
  readonly systemOptions: {
    readonly systemType?: SystemType;
  };
  readonly " $fragmentType": "PanelContextUseSystemOptionsSystemType_panel";
};
export type PanelContextUseSystemOptionsSystemType_panel$key = {
  readonly " $data"?: PanelContextUseSystemOptionsSystemType_panel$data;
  readonly " $fragmentSpreads": FragmentRefs<"PanelContextUseSystemOptionsSystemType_panel">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "systemType",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PanelContextUseSystemOptionsSystemType_panel",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "systemOptions",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": (v0/*: any*/),
          "type": "XrSystemOptions",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v0/*: any*/),
          "type": "XtSystemOptions",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Panel",
  "abstractKey": null
};
})();

(node as any).hash = "ab727efdcfc701075e9cf3ec4042624a";

export default node;
