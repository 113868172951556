import { between } from "utils";

App.controller("UserCodeEditCtrl", [
  "$q",
  "$rootScope",
  "$scope",
  "$http",
  "$modal",
  "PANEL_CONCEPTS",
  "XF6_MAX_USER_CODES",
  "user_number",
  "$state",
  "$filter",
  "$compile",
  "ControlSystemsService",
  "UserService",
  "UserCode",
  "Customer",
  "Panel",
  "MultiPanelService",
  "ClientEventsService",
  "PanelCapabilitiesService",
  "PanelDefRuleService",
  "UserCodeService",
  "CredentialsService",
  "GoogleAnalyticsService",
  "BatchImportCredentialsService",
  "BatchImportUsersService",
  "RemoteUpdateService",
  "DataTablesUtilsService",
  "DTColumnBuilder",
  "credential_id",
  "$timeout",
  "InitialConnectionService",
  function (
    $q,
    $rootScope,
    $scope,
    $http,
    $modal,
    PANEL_CONCEPTS,
    XF6_MAX_USER_CODES,
    user_number,
    $state,
    $filter,
    $compile,
    ControlSystemsService,
    UserService,
    UserCode,
    Customer,
    Panel,
    MultiPanelService,
    ClientEventsService,
    PanelCapabilitiesService,
    PanelDefRules,
    UserCodeService,
    CredentialsService,
    GoogleAnalyticsService,
    BatchImportCredentialsService,
    BatchImportUsersService,
    RemoteUpdateService,
    DataTablesUtilsService,
    DTColumnBuilder,
    credential_id,
    $timeout,
    InitialConnectionService
  ) {
    $scope.UserService = UserService;
    $scope.dealerData = UserService.dealerInfo;
    $scope.controlSystem = UserService.controlSystem;
    $scope._family =
      $scope.controlSystem.panels[
        $scope.controlSystem.panel_index
      ].hardware_family;
    $scope._model =
      $scope.controlSystem.panels[
        $scope.controlSystem.panel_index
      ].hardware_model;
    $scope._softwareVersion =
      $scope.controlSystem.panels[
        $scope.controlSystem.panel_index
      ].software_version;
    $scope.userCodeSupportsTempOption =
      PanelCapabilitiesService.userCodeSupportsTempOption(
        $scope.controlSystem.panels[$scope.controlSystem.panel_index]
      );
    $scope.datePickerOpen = {};
    $scope.initialConnectDone =
      ClientEventsService.initialConnection.hasBeenEstablished();
    $scope.BatchUserService = BatchImportUsersService;
    $scope.panel_version = parseInt($scope._softwareVersion);
    $scope.userCodeImportStatuses = BatchImportUsersService.importStatuses;
    $scope.userToEdit = {};
    $scope.panels = []; // Stores a list of all control systems the user has access to
    $scope.selectedPanels = []; // Stores a list of all selected systems from the multi-panel list
    $scope.pageType = "list";
    $scope.isBusy = true;
    $scope.multiBusy = false;
    $scope.scheduledJobs = []; // Updated in initPage()
    $scope.scheduledJobCount = 0; // Used to track changes in the amount of scheduled jobs
    $scope.userBackup = {}; // Used to store a backup copy of the user code in the event of the save failing
    $scope.nextManUpVar = {};
    $scope.nextManUpVar.useNextMan = true;
    $scope.tempUser = {};
    $scope.runningImport = true;
    $scope.finishedCheckingForImportJobs = false;
    $scope.pagin = {};
    $scope.pagin.currentPage = 1;
    $scope.pagin.filteredUserCodes = [];
    $scope.pagin.showIntUserCodes = false;
    $scope.panelHasGlobalProfiles = false;
    $scope.isShowingMultiSystems = false;
    $scope.userCodeMetaData = {};
    $scope.XF6_MAX_USER_CODES = XF6_MAX_USER_CODES.NUM;

    $scope.fileUploadTypes = [
      {
        id: 0,
        label: "CSV File",
      },
      {
        id: 1,
        label: "Remote Link User Code Export",
      },
    ];

    $scope.format = "MM-dd-yyyy";

    const isXR550Family = $scope._family === "XR550";

    $scope.isXF6Family = $scope._family === "XF6";

    var panel_model =
      $scope.controlSystem.panels[$scope.controlSystem.panel_index]
        .hardware_model;
    $scope.panel_model = panel_model;
    var profileLimit = 4; // The max number of profiles that can be selected

    $scope.multiPanelFilters = [];
    $scope.multiPanelFilters.push({
      field: "ITEM.software_version",
      operator: ">=",
      value: "100",
      action: "disable",
      displayTemplate:
        "Panel system version is less than {{ multiPanelFilterData.value }}.",
    }); // This is set dynamically on load and when twilight values change

    $scope.multiPanelFilters.push({
      field: "ITEM.hardware_family",
      operator: "==",
      value: $scope._family,
      action: "hide",
    });

    $scope.multiPanelOptions = {};
    // Based on panel family, determine the correct nested list concept to display
    var nestedProperty =
      $scope._family === "XT30" ? "area_informations" : "profiles";
    $scope.multiPanelOptions.nestedList = {
      property: nestedProperty,
      field: "number",
      selectionLimit: 4,
      displayTemplate: "{{number}} - {{name}}",
      conditions: [
        { field: "ITEM.templateSystem", operator: "!=", value: true },
      ],
    };

    $scope.codePart = null;
    $scope.eyeChecked = false;
    $scope.showPin = false;

    $scope.credentials = {
      assigned: {
        list: [],
      },
      unassigned: {
        itemsPerPage: 10,
        list: [],
        busy: true,
        searchResults: [],
      },
      userToEdit: {
        credential: {
          original: null,
          selected: null,
          canChange: false,
        },
        useCredential: false,
        credentialAssigned: false,
        selectedCredentialId: "",
      },
    };

    /**
     *    watch for updateIsBusy event
     *    used to update the busy state of the page
     *    through the SendAllUserCodesButton react component
     **/
    $scope.$on("$destroy", () => {
      // Cleanup
      window.removeEventListener("updateIsBusy", handleBusyUpdate);
    });

    const handleBusyUpdate = (event) => {
      // Update the busy state
      $scope.$apply(() => {
        $scope.isBusy = event.detail.isBusy;
      });
    };

    window.addEventListener("updateIsBusy", handleBusyUpdate); // Listen for busy updates

    $scope.userEditForm = null;
    $scope.scopeUserEditForm = function (form) {
      $scope.userEditForm = form;
    };

    $scope.changeCredential = function () {
      $scope.credentials.userToEdit.credential.canChange = true;
      $scope.$apply();
    };

    $scope.eyeClicked = function () {
      $scope.eyeChecked = !$scope.eyeChecked;
      if (UserService.canRevealUserCodes()) {
        var panelId = $scope.controlSystem.panels[0].id;
        UserCodeService.getPart(panelId, $scope.userToEdit.number)
          .then(
            function (data) {
              $scope.codePart = data.detail.part_num;
              if ($scope.eyeChecked) {
                $scope.userToEdit.code = $scope.codePart;
              } else {
                $scope.userToEdit.code = "";
              }
            },
            function (error) {
              $scope.codePart = null;
            }
          )
          .catch(function (error) {
            console.error(error);
          });
      }
    };

    $scope.toggleShowPin = function () {
      $scope.showPin = !$scope.showPin;
      if (UserService.canRevealUserCodes()) {
        var panelId = $scope.controlSystem.panels[0].id;
        UserCodeService.getPart(panelId, $scope.userToEdit.number)
          .then(
            function (data) {
              $scope.codePart = data.detail.model_num;
              if ($scope.showPin) {
                $scope.userToEdit.user_pin = $scope.codePart;
              } else {
                $scope.userToEdit.user_pin = "";
              }
            },
            function (error) {
              $scope.codePart = null;
            }
          )
          .catch(function (error) {
            console.error(error);
          });
      }
    };

    /**
     * @function getTenCodesFromAllUserCodes
     * @param user_codes - the user code array
     * @param pageNumber - page number from dirPaginate
     * @returns If the show user codes button is pressed, resolve the internal user code for the visible paginated items {array}
     */
    async function getTenCodesFromAllUserCodes(user_codes, pageNumber) {
      if (!$scope.pagin.showIntUserCodes) return;
      user_codes = await Promise.all(
        user_codes.map((user_code, index) =>
          (pageNumber - 1) * 10 <= index &&
          pageNumber * 10 > index &&
          !user_code.int_user_code
            ? $scope
                .getInternalUserCode(
                  $scope.controlSystem.panels[0].id,
                  user_code.number
                )
                .then((int_user_code) => {
                  user_code.int_user_code = int_user_code;
                })
            : Promise.resolve()
        )
      );
    }

    $scope.filterFunction = function (row) {
      // If one of the columns we're concerned about contains the search string
      if (
        !$scope.searchUser ||
        row.name.toLowerCase().includes($scope.searchUser) ||
        row.number.toLowerCase().includes($scope.searchUser)
      ) {
        return true;
      }
      // Handles searching over user codes. If the user code is undefined skip the check as not to bomb the function
      if (row.int_user_code && row.int_user_code.includes($scope.searchUser)) {
        return true;
      }
      return false;
    };

    $scope.showAllUserCodes = function () {
      // block the table with a loading spinner
      $scope.isBusy = true;
      // gets all of the user codes, this way the search bar can see them all.
      $scope.Panel.user_codes.forEach((user) => {
        $scope
          .getInternalUserCode($scope.controlSystem.panels[0].id, user.number)
          .then((int_user_code) => {
            user.int_user_code = int_user_code;
          });
      });
      // unblock the table
      $scope.isBusy = false;
    };

    $scope.setIsShowingMultiSystems = function () {
      $scope.isShowingMultiSystems = !$scope.isShowingMultiSystems;
      if ($scope.isShowingMultiSystems) {
        getMultiPanelInfo();
      }
    };

    /**
     * @function getFilteredInternalCodes()
     * @param page - the page of filtered items
     * @param filterStringIn - the search query
     * @description - adds a pause before returning filtered user codes
     */
    $scope.getFilteredInternalCodes = function (page, filterStringIn) {
      $scope.searchUser = filterStringIn;
      $timeout(getTenCodesFromFilteredUserCodes, 500);
    };
    /**
     * @function getTenCodesFromFilteredUserCodes
     * @param none
     * @returns {Promise<internal UserCode>}
     * @description Returns user codes for filtered results
     */
    async function getTenCodesFromFilteredUserCodes() {
      if (!$scope.pagin.showIntUserCodes) return;
      var user_codes = $scope.pagin.filteredUserCodes;
      user_codes = await Promise.all(
        user_codes.map((user_code, index) =>
          !user_code.int_user_code
            ? $scope
                .getInternalUserCode(
                  $scope.controlSystem.panels[0].id,
                  user_code.number
                )
                .then((int_user_code) => {
                  user_code.int_user_code = int_user_code;
                })
            : Promise.resolve()
        )
      );
    }

    /**
     * @function getInternalCodes()
     * @param page - page number from dirPaginate
     * @description - used to retrieve user codes when the  'Show User Codes' button is clicked
     */
    $scope.getInternalCodes = function (page) {
      getTenCodesFromAllUserCodes($scope.Panel.user_codes, page);
    };

    /**
     * @function: getInternalUserCode
     * @param panelId: The id number of target system
     * @param usrNumber: The user number of the user code to be retrieved
     * @returns {int} the user code !code returns 'NA'
     */
    $scope.getInternalUserCode = (panelId, usrNumber) =>
      //UserCode service call to retrieve user code part number
      UserCodeService.getPart(panelId, usrNumber)
        .then(
          (data) =>
            Number.isInteger(parseInt(data.detail.part_num))
              ? data.detail.part_num
              : (data.detail.part_num = "N/A"),
          (error) => null
        )
        .catch((error) => console.error(error));

    // Only restrict the nested list to area systems if the panel family is XT30
    if ($scope._family == "XT30") {
      $scope.multiPanelOptions.nestedList.conditions.push({
        field: "ITEM.arming_system",
        operator: "==",
        value: "AREA",
      });
    }
    /**
     * Function to get all the linked profiles for this customer and also set the panelHasGlobalProfiles variable if any are present.
     * @returns [Array] linkedProfiles an array of the profile numbers for profiles are part of a linked profile for this customer
     * @sets $scope.panelHasGlobalProfiles to true if there are any linked profiles for this customer
     */
    function getGlobalProfiles(profiles) {
      const profileNumbers = (profiles ?? [])
        .filter(({ linked_profile_id }) => !!linked_profile_id)
        .map(({ number }) => Number(number))
        .sort((a, b) => a - b);
      $scope.panelHasGlobalProfiles = !!profileNumbers.length;
      return profileNumbers;
    }

    $scope.nextManUpCheck = function () {
      if (!$scope.nextManUpVar.useNextMan) {
        var userToEditNumber = $filter("zpad")($scope.userToEdit.number, 4);
        $scope.multiPanelOptions.duplicateCheck = {
          displayTemplate:
            "User {{ duplicateCheckData.number }} already exists: {{ duplicateCheckData.name }}",
          conditions: [
            {
              field: "CONCEPTS.user_codes.number",
              operator: "==",
              value: userToEditNumber,
              storeMatchingValue: true,
            },
            { field: "ITEM.templateSystem", operator: "!=", value: true },
          ],
        };
      } else {
        $scope.userToEdit.number = $scope.initialUserNumber;
        $scope.multiPanelOptions.duplicateCheck = undefined;
      }
    };

    $scope.isECPSystem = getIsECPSystem();
    $scope.isDSCSystem = getIsDSCSystem();

    function initPage() {
      if (!$scope.controlSystem.panels[0].online) {
        InitialConnectionService.bypassForSCAPICorrection = true;
      }
      // Get the userCodeMetaData so we know how many user code digits are possible with the panels current programming.
      // This is to replace panel_def defining this as international panels have to account for both arming mode and security grade
      $scope.userCodeMetaData = PanelCapabilitiesService.getUserCodeMetaData(
        $scope.Panel
      );
      // Initialize the multi-panel service, clearing out any existing interval timers that are running.
      MultiPanelService.initialize();
      BatchImportUsersService.initialize($scope.controlSystem.panels[0].id);
      // Only proceed when the concept promise is resolved. Otherwise, we're waiting for all_concepts_retrieved to fire
      if ($scope.conceptPromise) {
        $scope.conceptPromise
          .then(function () {
            var rules = PanelDefRules.getRules(
              $scope.Panel,
              $scope.Panel.PDS.panel_def.CONCEPTS.user_codes.DISPLAY
            );
            if ($scope._family == "XR550") {
              $scope.globalProfiles = new Set(
                getGlobalProfiles($scope.Panel["profiles"])
              );
            }
            if (
              angular.isDefined(rules) &&
              DoesNestedPropertyExist(
                rules,
                "referenceGroups.system_options.arm_mode.overrideFieldRules"
              )
            ) {
              PanelDefRules.processRules(
                rules.referenceGroups.system_options.arm_mode
                  .overrideFieldRules,
                $scope.Panel
              );
            }
            if ($state.is("app.panel_programming.user_codes")) {
              $scope.initUserCodeList();
              $scope.getActiveUserCodeImportJobs();
              refreshCredentials();
              $scope.isBusy = false;
            } else if ($state.is("app.panel_programming.user_codes_new")) {
              $scope.initUserCodeNew();
              $scope.credentials.userToEdit.credential.canChange = true;
              refreshCredentials()
                .then(function () {
                  if (+credential_id) {
                    $scope.credentials.userToEdit.credential.selected =
                      $scope.credentials.unassigned.list.find(function (c) {
                        return c.id === +credential_id;
                      }) || null;
                    if ($scope.credentials.userToEdit.credential.selected) {
                      $scope.credentials.userToEdit.useCredential = true;
                    }
                    $scope.nextManUpVar.useNextMan = false;
                  }
                })
                .catch(function (error) {
                  console.error(error);
                });
              $scope.isBusy = false;
            } else if ($state.is("app.panel_programming.user_codes_edit")) {
              refreshCredentials();
              $scope
                .initUserCodeEdit()
                .then()
                .catch(function (error) {
                  console.error(error);
                })
                .finally(function () {
                  $scope.isBusy = false;
                });
            }
          })
          .catch(function (error) {
            console.error(error);
          });
      }
      $scope.getInternalCodes(1);
    }

    $scope.initUserCodeList = function () {
      var mpsJobs =
        $scope._family == "XR550"
          ? MultiPanelService.XR_USER_JOBS
          : MultiPanelService.XT_USER_JOBS;
      // Checking to see if there are scheduled user code jobs for this panel
      MultiPanelService.getActiveJobsForPanelConcept(mpsJobs)
        .then(function (jobs) {
          // If we have at least one pending job, start a watcher to get updates
          if (jobs.length > 0) {
            MultiPanelService.startMultiPanelWatcher(mpsJobs);
          }

          // Parse the RemoteJson returned from the job scheduler service for display
          angular.forEach(jobs, function (job) {
            if (job.RemoteJson !== null) {
              job.RemoteJson = angular.fromJson(job.RemoteJson);
            }
            job.RemoteJson === null
              ? (job.number = job.SelectedItem)
              : (job.number = job.RemoteJson.user_code.number);
          });
          $scope.scheduledJobs = jobs;
          $scope.scheduledJobCount = jobs.length;
        })
        .catch(function (error) {
          console.error(error);
        });
    };

    $scope.showActiveToggle = (family, version, model) => {
      if (model?.toUpperCase().includes("COM")) {
        return false;
      } else if (family === "XR550") {
        return (
          between(Number(version), 172, 599) ||
          between(Number(version), 672, Infinity)
        );
      } else if (family === "XT30") {
        return (
          between(Number(version), 213, 599) ||
          between(Number(version), 713, Infinity)
        );
      } else if (family === "TMSentry") {
        return true;
      } else {
        return false;
      }
    };

    $scope.initUserCodeNew = function () {
      // New User Code
      $scope.pageType = "new";
      $scope.isBusy = true;
      addNewUserCode()
        .then(
          function (userData) {
            $scope.isBusy = false;
            $scope.userToEdit = angular.extend(
              userData,
              new UserCode($scope._family)
            );
            if ($scope._model === "MiniCellCom") {
              $scope.userToEdit.areas = "1";
            }
            $scope.initialUserNumber = $scope.userToEdit.number;
            delete $scope.userToEdit.code; // User code comes back as a placeholder value. Remove it from the object
            delete $scope.userToEdit.user_pin; // User pin comes back as a placeholder value. Remove it from the object
            // Now that we have our current user code info, update our duplicate check conditions
            $scope.updateDuplicateCheck();

            initData();
          },
          function () {
            $scope.isBusy = false;
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    };

    $scope.initUserCodeEdit = function () {
      var deferred = $q.defer();
      // Editing User Code
      $scope.pageType = "edit";
      $scope.nextManUpVar.useNextMan = false;
      // Get the specified user code
      var userData = $filter("filter")(
        $scope.Panel["user_codes"],
        { number: user_number },
        true
      )[0];
      $scope.userToEdit = angular.extend(
        userData,
        new UserCode($scope._family)
      );

      delete $scope.userToEdit.code; // User code comes back as a placeholder value. Remove it from the object
      delete $scope.userToEdit.user_pin; // User pin comes back as a placeholder value. Remove it from the object
      // Set the credential to that of the user
      CredentialsService.get([$scope.userToEdit.credential_id])
        .then(
          function (credentials) {
            console.debug(
              "UserCodeEditCtrl->initUserCodeEdit() got credentials: " +
                angular.toJson(credentials)
            );
            $scope.credentials.userToEdit.credential.original = credentials[0];
            if (isAssignedSystemCredential(credentials[0])) {
              $scope.credentials.userToEdit.credentialAssigned =
                $scope.credentials.userToEdit.useCredential = true;
              $scope.credentials.userToEdit.credential.selected = angular.copy(
                credentials[0]
              );
            }
            // Now that we have our current user code info, update our duplicate check conditions
            if ($scope.multiPanelOptions.duplicateCheck)
              $scope.multiPanelOptions.duplicateCheck.conditions[0].value =
                $scope.userToEdit.number;
            $scope.initialUserNumber = $scope.userToEdit.number;
            // Get the list of user systems and their required concepts for the multi-panel list
            getMultiPanelInfo();
            initData();
            deferred.resolve();
          },
          function (error) {
            $rootScope.alerts.push({
              type: "error",
              text: "error retrieving credential for user code",
              json: error,
            });
            deferred.reject();
          }
        )
        .catch(function (error) {
          console.error(error);
        });
      return deferred.promise;
    };

    $scope.updateDuplicateCheck = function () {
      if (!$scope.canSendToLocks()) {
        // make sure new user code can send locks
        $scope.userToEdit.snd_to_lks = "N";
        $scope.multiPanelFilters[0].value = "100";
      }

      //$scope.initialUserNumber = $scope.userToEdit.number;
      if (angular.isDefined($scope.multiPanelOptions.duplicateCheck))
        $scope.multiPanelOptions.duplicateCheck.conditions[0].value = $filter(
          "zpad"
        )($scope.userToEdit.number, 4);
    };
    /**
     * Enable or Disable Arm Only and Temporary options based on if the Master role is selected
     */
    $scope.updateUserLevel = function () {
      if ($scope.userToEdit.user_level == "9") {
        $scope.userToEdit.arm_only = false;
        $scope.userToEdit.temp = false;
      }
    };

    $scope.checkValidDateRange = function () {
      if ($scope.userToEdit.start_date > $scope.userToEdit.temp_date) {
        $scope.userEditForm.start_temp.$setValidity("startDate", false);
      } else {
        $scope.userEditForm.start_temp.$setValidity("startDate", true);
      }
    };

    /**
     * @desc Update our multi-panel-list filters
     */
    $scope.updateMultiPanelFilters = function () {
      $scope.userToEdit.snd_to_lks == "Y"
        ? ($scope.multiPanelFilters[0].value = "171")
        : ($scope.multiPanelFilters[0].value = "100");
    };

    function initData() {
      if (!$scope.isXF6Family) {
        if ($scope._family === "XR550")
          $scope.userToEdit.profiles = $scope.Panel["profiles"];
        else $scope.userToEdit.areaList = $scope.Panel["area_informations"];
        // Must initialize ONLY after adding profiles or areas
        $scope.userToEdit.init();
      }

      $scope.isBusy = false;
    }

    function getIsECPSystem() {
      if (angular.isUndefined($scope.Panel.system_options)) {
        return false;
      } // Return false if system_options isn't loaded
      if (
        $scope._family == "XT30" &&
        (panel_model == "CellComSL" ||
          panel_model == "CellComEX" ||
          panel_model == "iComSL")
      ) {
        return $scope.Panel.system_options.kpad_input == "E";
      }
    }

    function getIsDSCSystem() {
      if (angular.isUndefined($scope.Panel.system_options)) {
        return false;
      }
      if (
        $scope._family == "XT30" &&
        (panel_model == "CellComSl" || panel_model == "iComSL")
      ) {
        return $scope.Panel.system_options.kpad_input == "D";
      }
    }

    $scope.goToNewUserCode = function () {
      $state.go("^.user_codes_new", { credential_id: 0 }, { reload: true });
    };

    function addNewUserCode() {
      var deferred = $q.defer();
      // Get a new UserCode
      $scope.Panel.newItem("user_codes")
        .then(
          function (data) {
            // Make sure we have a valid fill code
            data.code_fill = "F";

            if ($scope.isXF6Family) {
              // profile1 needs to be padded to the correct format
              data.profile1 = data.profile1.padStart(3, 0);
            } else if (data.profile1) {
              // By default, reset profile1 to empty string (so a Global Profile 1 doesn't lock editing of new user)
              data.profile1 = "";
            }
            // Set the default areas for a non-Area (Area system is N) system user_code
            if ($scope.Panel.system_options.arm_mode != "N") {
              data.areas = "1-6";
            }
            deferred.resolve(data);
          },
          function (error) {
            $rootScope.alerts.push({
              type: "error",
              text: "Error occurred when attempting to create new user code template.",
              json: error,
            });
          }
        )
        .catch(function (error) {
          console.error(error);
        });
      GoogleAnalyticsService.trackEvent(
        "usercode",
        "create",
        "User Code Add button used"
      );
      return deferred.promise;
    }

    function goToUserCodeList() {
      $state.go(
        "^.user_codes",
        {
          customer_id: UserService.customer_id,
          control_system_id: UserService.control_system_id,
          panel_id: UserService.panel_id,
        },
        { reload: true }
      );
    }

    $scope.retrieveAllData = function () {
      $scope.isBusy = true;
      $scope.Panel.refresh([], false)
        .then(
          function () {
            $rootScope.alerts.push({
              type: "success",
              text: "User Codes are being retrieved",
            });
            $scope.isBusy = false;
            initPage();
          },
          function (error) {
            $scope.isBusy = false;
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    };

    $scope.getAllData = function () {
      $scope.Panel.get("user_codes");
    };

    /**
     * @ngdoc
     * @name SchedulesEditCtrl:sendUserCodeChanges
     *
     * @description
     * Save all changes that the user has made to panel programming then calls goToUserCodesList on success
     */
    $scope.sendUserCodeChanges = function () {
      // Zero pad the entered user number
      $scope.userToEdit.number = $filter("zpad")($scope.userToEdit.number, 4);

      // Create a copy of the user code in case we need to restore the deleted properties
      $scope.userBackup = angular.copy($scope.userToEdit);

      // If the user code is blank and we're editing, remove the user code property
      if ($scope.pageType == "edit" && $scope.userToEdit.code == "") {
        delete $scope.userToEdit.code;
      }
      if ($scope.pageType == "edit" && $scope.userToEdit.user_pin == "") {
        delete $scope.userToEdit.user_pin;
      }

      // If we're on an XR550 series panel and don't have a temp profile selected, make sure we don't send back a temp_date
      if (
        $scope._family == "XR550" &&
        !$scope.userToEdit.isTempProfileSelected() &&
        !$scope.userCodeSupportsTempOption
      ) {
        $scope.userToEdit.temp_date = "";
        $scope.userToEdit.expiretime = "";
      }

      if ($scope.userToEdit.temp_user === "N") {
        $scope.userToEdit.temp_date = "";
        $scope.userToEdit.start_date = "";
      }

      // Remove any worker properties from the user code object
      if (angular.isDefined($scope.userToEdit.profiles)) {
        delete $scope.userToEdit.profiles;
      }
      if (angular.isDefined($scope.userToEdit.areaList)) {
        delete $scope.userToEdit.areaList;
      }

      let selectedSystemsCount;

      // oData doesn't return any list data for Parent dealers, so if the user is a parent dealer we need to assume they're only sending the usercode to the panel they're currently on.
      if (UserService.isParentDealerLogin()) {
        selectedSystemsCount = 1;
      } else {
        selectedSystemsCount = $filter("filter")(
          $scope.panels,
          { selected: true },
          true
        ).length;
      }

      if (
        !$scope.isShowingMultiSystems ||
        selectedSystemsCount === 1 ||
        $scope.credentials.userToEdit.useCredential
      ) {
        if ($scope.credentials.userToEdit.useCredential) {
          $scope.userToEdit.credential_id =
            $scope.credentials.userToEdit.credential.selected.id;
          // Attach a property to be picked up by the panel-service
          $scope.userToEdit.setCredentialQueryFlag = true;
        }
        delete $scope.userToEdit.credential;
        saveSinglePanel($scope.userToEdit);
      } else {
        delete $scope.userToEdit.credential;
        saveMultiPanel($scope.userToEdit);
      }
    };

    function saveSinglePanel(userCode) {
      $scope.isBusy = true;
      var orphanedCredentialId = Boolean(
        $scope.credentials.userToEdit.useCredential &&
          DoesNestedPropertyExist(
            $scope.credentials.userToEdit.credential.original,
            "id"
          ) &&
          DoesNestedPropertyExist(
            $scope.credentials.userToEdit.credential.selected,
            "id"
          ) &&
          $scope.credentials.userToEdit.credential.original.id !==
            $scope.credentials.userToEdit.credential.selected.id
      )
        ? $scope.credentials.userToEdit.credential.original.id
        : null;

      $scope.Panel.sendProgramming("user_codes", false, false, userCode.number)
        .then(
          function () {
            if (orphanedCredentialId) {
              CredentialsService.destroy(orphanedCredentialId)
                .then()
                .catch(function (error) {
                  console.error(error);
                })
                .finally(function () {
                  $scope.isBusy = false;
                  $rootScope.alerts.push({
                    type: "success",
                    text: "Successfully saved user code",
                  });
                  goToUserCodeList();
                });
            } else {
              $scope.isBusy = false;
              $rootScope.alerts.push({
                type: "success",
                text: "Successfully saved user code",
              });
              goToUserCodeList();
            }
          },
          function (error) {
            $scope.isBusy = false;
            $rootScope.alerts.push({
              type: "error",
              text: "Error occurred when attempting to Send the data to the system.",
              json: error,
            });
            // Restore our user code object from the backup we made earlier
            $scope.userToEdit = angular.extend(
              $scope.userToEdit,
              $scope.userBackup
            );
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    }

    function saveMultiPanel(userCode) {
      $scope.isBusy = true;
      var jobData = [];
      var jobType = "";
      // Are we saving changes to an XR550 or XT30 family
      //TODO: Is this necessary??
      if ($scope._family == "XR550") {
        jobType =
          $scope.pageType == "edit" ? "UPDATE_XR_USER_V2" : "ADD_XR_USER_V2";
      } else {
        jobType =
          $scope.pageType == "edit" ? "UPDATE_XT_USER_V2" : "ADD_XT_USER_V2";
      }

      // Loop through all panels in the multi-panel list and build our jobData objects to send to the multi-panel service
      for (var i = 0; i < $scope.selectedPanels.length; i++) {
        var pnl = $scope.selectedPanels[i]; // Get a reference to the current panel in our selectedPanels array
        var curUser = angular.copy(userCode); // Make a copy of the current user code to send to each selected panel

        // if Next Man Up checked
        if ($scope.nextManUpVar.useNextMan) {
          delete curUser.number;
        }

        // Update the profiles list for each selected panel, except for the current system, it's profiles are already set
        if (
          pnl.panel_id != $scope.Panel.panel_id &&
          $scope._family == "XR550"
        ) {
          updateProfiles(pnl, curUser);
        }
        if (pnl.panel_id != $scope.Panel.panel_id && $scope._family == "XT30") {
          updateAreas(pnl, curUser);
        }

        // Prepare the remote json to send to the job scheduler
        if (angular.isDefined(curUser.profiles)) {
          delete curUser.profiles;
        }
        if (angular.isDefined(curUser.areaList)) {
          delete curUser.areaList;
        }
        jobData.push({ user_code: curUser });
      }

      // Create our SchedulerJobs and JobGroup and schedule the group to run
      MultiPanelService.createMultiPanelJob(
        jobType,
        $scope.selectedPanels,
        jobData,
        $scope.userToEdit.number
      )
        .then(
          function () {
            $scope.isBusy = false;
            $rootScope.alerts.push({
              type: "success",
              text: "User Code changes have been queued up.",
            });
            $scope.Panel.cancelEdit("user_codes"); // Since we don't want to show changes until the scheduled job finishes, revert back to the pristine concept
            goToUserCodeList();
          },
          function (error) {
            $scope.isBusy = false;
            $rootScope.alerts.push({
              type: "error",
              text: "Error occurred when attempting to schedule the multi-panel action.",
              json: error,
            });
            // Restore our user code object from the backup we made earlier
            $scope.userToEdit = angular.extend(
              $scope.userToEdit,
              $scope.userBackup
            );
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    }

    $scope.sendToLocksChanged = function () {
      return $scope.userEditForm.send_to_locks.$dirty;
    };

    /**
     * @ngdoc
     * @name SchedulesEditCtrl:deleteSchedule
     *
     * @description
     * Removes the specified schedule from the panel then calls goToUserCodeList
     *
     * @param {user_code} userCodeToDelete Optional The user code to remove from the panel. If not specified the current user code will be deleted
     */
    $scope.deleteUserCode = function (userCodeToDelete) {
      $scope.pagin.showIntUserCodes = false;
      // To delete a user code, you'll need the credential information as well
      getDeleteUserCodeData(userCodeToDelete)
        .then(
          function (deleteData) {
            var selectedSystemsCount = $filter("filter")(
              $scope.panels,
              { selected: true },
              true
            ).length;
            if (selectedSystemsCount <= 1 || deleteData.credentialAssigned) {
              deleteSinglePanel(deleteData.userCode)
                .then(function () {
                  // If the credential only had the deleted user code assigned to it, it is now unassigned and should be
                  // deleted.  Otherwise, it has another user code assigned to it; is attached to another system.
                  if (
                    deleteData.credentialAssigned &&
                    deleteData.credential.user_codes.length === 1 &&
                    Number(deleteData.credential.user_codes[0].number) ===
                      Number(deleteData.userCode.number) &&
                    Number(deleteData.credential.user_codes[0].panel_id) ===
                      Number($scope.Panel.panel_id)
                  ) {
                    CredentialsService.destroy(deleteData.credential.id)

                      .then(
                        console.warn(
                          "UserCodeEditCtrl->deleteUserCode() -> credential was deleted: " +
                            angular.toJson(deleteData.credential)
                        )
                      )
                      .catch(function (error) {
                        console.error(error);
                      })
                      .finally(function () {
                        if (
                          $state.is("app.panel_programming.user_codes_edit")
                        ) {
                          goToUserCodeList();
                        }
                      });
                  } else {
                    console.warn(
                      "UserCodeEditCtrl->deleteUserCode() -> credential doesn't meet requirements to be deleted: " +
                        angular.toJson(deleteData.credential)
                    );
                    if ($state.is("app.panel_programming.user_codes_edit")) {
                      goToUserCodeList();
                    }
                  }
                })
                .catch(function (error) {
                  console.error(error);
                });
            } else {
              deleteMultiPanel(deleteData.userCode);
            }
          },
          function (error) {
            $rootScope.alerts.push({
              type: "error",
              text: "error getting credentials",
              json: error,
            });
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    };

    /**
     * Gather any information that may be needed to delete the user, including credentials
     * @param userCodeToDelete
     * @returns {*}
     */
    function getDeleteUserCodeData(userCodeToDelete) {
      var deferred = $q.defer();
      var deleteData = {
        userCode: null,
        credential: null,
        credentialAssigned: null,
      };
      if (userCodeToDelete) {
        deleteData.userCode = userCodeToDelete;
        CredentialsService.get([userCodeToDelete.credential_id])
          .then(
            function (credentials) {
              deleteData.credential = credentials[0];
              deleteData.credentialAssigned = true;

              deferred.resolve(deleteData);
            },
            function (error) {
              deferred.reject(error);
            }
          )
          .catch(function (error) {
            console.error(error);
          });
      } else {
        deleteData.userCode = $scope.userToEdit;
        deleteData.credential =
          $scope.credentials.userToEdit.credential.original;
        deleteData.credentialAssigned =
          $scope.credentials.userToEdit.credentialAssigned;
        deferred.resolve(deleteData);
      }
      return deferred.promise;
    }

    function deleteSinglePanel(userCodeToDelete) {
      var deferred = $q.defer();
      $scope.isBusy = true;
      $scope.Panel.deleteItem("user_codes", userCodeToDelete)
        .then(
          function () {
            setUserCodesCredentials();
            deferred.resolve();
          },
          function (error) {
            $rootScope.alerts.push({
              type: "error",
              text: "error deleting user code.",
              json: error,
            });
            deferred.reject(error);
          }
        )
        .catch(function (error) {
          console.error(error);
        })
        .finally(function () {
          $scope.isBusy = false;
        });
      return deferred.promise;
    }

    function deleteMultiPanel(userCodeToDelete) {
      var queuedJobs = [];
      var jobType =
        $scope._family == "XR550" ? "DELETE_XR_USER_V2" : "DELETE_XT_USER_V2";

      // Create our SchedulerJobs and JobGroup and schedule the group to run
      MultiPanelService.createMultiPanelJob(
        jobType,
        $scope.selectedPanels,
        null,
        $scope.userToEdit.number
      )
        .then(
          function () {
            $scope.isBusy = false;
            $rootScope.alerts.push({
              type: "success",
              text: "User Code changes have been queued up.",
            });
            $scope.Panel.cancelEdit("user_codes"); // Since we don't want to show changes until the scheduled job finishes, revert back to the pristine concept
            goToUserCodeList();
          },
          function (error) {
            $scope.isBusy = false;
            $rootScope.alerts.push({
              type: "error",
              text: "Error occurred when attempting to schedule the multi-panel action.",
              json: error,
            });
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    }

    $scope.cancelEdit = function () {
      $scope.Panel.cancelEdit("user_codes");
      goToUserCodeList();
    };

    /**
     * Check to see if a pending job exists for the given item
     */
    $scope.pendingJobExists = function (number) {
      var result = $filter("filter")(
        $scope.scheduledJobs,
        { number: number },
        true
      );
      if (result.length > 0) {
        return result[0];
      }
      return false;
    };

    $scope.filterAddJobs = function (job) {
      return job.JobDesc.includes("Add");
    };

    /**
     * Check to see if the current user code exists for the specified panel
     * Returns: The name of the existing user code or an empty string
     */
    $scope.userCodeExists = function (panel) {
      var intUserNumber = parseInt($scope.userToEdit.number, 10);
      for (var i = 0; i < panel.user_codes.length; i++) {
        if (parseInt(panel.user_codes[i].number, 10) == intUserNumber) {
          return panel.user_codes[i].name;
        }
      }
    };

    /**
     * Check a user to see if any of their profiles is an admin profile
     * Returns: true if any of the profiles assigned to a user is global
     * Function is memoized to speed up returns
     */

    $scope.userHasGlobalProfile = (() => {
      let cache = {};
      return (user) => {
        if ($scope.globalProfiles) {
          const n = parseInt(user.number);
          if (n in cache) {
            return cache[n];
          } else {
            let result =
              $scope.globalProfiles.has(parseInt(user.profile1, 10)) ||
              $scope.globalProfiles.has(parseInt(user.profile2, 10)) ||
              $scope.globalProfiles.has(parseInt(user.profile3, 10)) ||
              $scope.globalProfiles.has(parseInt(user.profile4, 10));
            cache[n] = result;
            return result;
          }
        } else {
          return false;
        }
      };
    })();
    $scope.hasMaxProfiles = function (profiles, selected) {
      var profileLimitReached;
      var selectedProfiles = $filter("filter")(profiles, { selected: true });
      selectedProfiles.length == profileLimit
        ? (profileLimitReached = true)
        : (profileLimitReached = false);
      if (!selected && profileLimitReached) {
        return true;
      } else {
        return false;
      }
    };

    $scope.install_date_open = function ($event) {
      $event.preventDefault();
      $event.stopPropagation();

      $scope.datePickerOpen.installDateOpened = true;
    };

    $scope.install_date_close = function ($event) {
      $event.preventDefault();
      $event.stopPropagation();

      $scope.datePickerOpen.installDateClosed = true;
    };

    /**
     * Function used to show/hide the send to locks checkbox
     */
    $scope.canSendToLocks = function () {
      return PanelCapabilitiesService.canSendCodeToLocks(
        $scope.Panel,
        $scope.userToEdit
      );
    };

    /**
     * Function to convert selected profiles to user_code profile attributes. Used with multi-panel user code creation
     */
    function updateProfiles(panel, user_code) {
      // If the nested list doesn't exist, there's nothing to check. Exit
      if (!angular.isDefined(panel.nestedList)) {
        return;
      }

      var selectedProfiles = $filter("filter")(panel.nestedList, {
        selected: true,
      });
      // If there were no profiles selected return. We already have a copy of the template system profiles
      if (selectedProfiles.length == 0) {
        return;
      }

      var profileNumber = 0;
      var currentIteration = 0;
      for (var i = 0; i < selectedProfiles.length; i++) {
        currentIteration = i + 1;
        profileNumber = "profile" + (i + 1);
        user_code[profileNumber] = selectedProfiles[i].number;
      }

      if (currentIteration < profileLimit) {
        currentIteration++; // Need to go to the NEXT profile, don't want to delete what we just added.
        for (i = currentIteration; i <= profileLimit; i++) {
          profileNumber = "profile" + i;
          user_code[profileNumber] = "";
        }
      }
    }

    /**
     * Determine whether or not to show the pin field: Only show it if any of the profiles in the list of selected profiles has card plus pin selected
     */
    $scope.displayPinField = function () {
      if (
        $scope.userToEdit &&
        $scope.userToEdit.profiles &&
        $scope.userToEdit.profiles.length > 0
      ) {
        return (
          $filter("filter")($scope.userToEdit.profiles, {
            selected: true,
            card_p_pin: true,
          }).length > 0
        );
      }
    };

    /**
     * Function to convert selected areas to a user_code area csv list. Used with multi-panel user code creation
     */
    function updateAreas(panel, user_code) {
      // If the nested list doesn't exist, there's nothing to check. Exit
      if (!angular.isDefined(panel.nestedList)) {
        return;
      }

      var selectedAreas = $filter("filter")(panel.nestedList, {
        selected: true,
      });

      // If there were no areas selected return. We already have a copy of the template system areas
      if (selectedAreas.length == 0) {
        return;
      }

      //Convert the array to a csv string
      var areaNumbers = [];
      for (var i = 0; i < selectedAreas.length; i++) {
        areaNumbers.push(selectedAreas[i].record_number);
      }
      user_code.areas = areaNumbers.toString();
    }

    /**
     * Get a list of all supported panels for the multi-panel list. Also get the appropriate concepts for each panel
     */
    function getMultiPanelInfo() {
      // Retrieve control systems for the current customer
      $scope.multiBusy = true;

      MultiPanelService.getPanelsWithOdata(
        $state.params.customer_id,
        $scope.Panel.panel_id,
        getHardwareFamilyFromHardwareModel($scope.Panel.panel_model) === "XR550"
      )
        .then(
          function (systems) {
            $scope.multiBusy = false;
            const currentPanelHardwareFamily =
              getHardwareFamilyFromHardwareModel($scope.Panel.panel_model);
            $scope.panels = systems.filter(
              (system) => system.hardware_family === currentPanelHardwareFamily
            );
          },
          function (error) {
            $scope.multiBusy = false;
            $rootScope.alerts.push({
              type: "error",
              text: "An error occurred while retrieving the multi-panel list.",
              json: error,
            });
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    }

    /**
     * Callback for multi-panel-list directive selectionChanged event
     * @param selectedPanels {array} An array of currently selected panels in the multi-panel list
     */
    $scope.selectionChanged = function () {
      $scope.selectedPanels = $filter("filter")($scope.panels, {
        selected: true,
      });
    };

    $scope.batchSelectPanels = (panels, status) => {
      for (let panel of panels) {
        if (panel.id !== $scope.controlSystem.id) panel.selected = status;
      }
      $scope.selectionChanged();
    };

    /**
     * Listen for the multiPanelStatusUpdate event, to handle any pending multi-panel jobs
     */
    $scope.$on("event-multiPanelStatusUpdate", function (evt, data) {
      $scope.scheduledJobs = data; // Update our scheduled jobs list

      // Parse the RemoteJson returned from the job scheduler service for display
      angular.forEach(data, function (job) {
        if (job.RemoteJson !== null) {
          job.RemoteJson = angular.fromJson(job.RemoteJson);
        }
        job.RemoteJson === null
          ? (job.number = job.SelectedItem)
          : (job.number = job.RemoteJson.user_code.number);
      });

      // If there are no more multi-panel jobs pending, or if we've recently completed a job, refresh the page
      if (data.length == 0 || $scope.scheduledJobCount > data.length) {
        $scope.getAllData();
      }

      $scope.scheduledJobCount = data.length; // Update our scheduled job count with the latest number
    });

    /**
     * Listen for the all_concepts_retrieved event, which means the progrouter controller has successfully retrieved the concepts
     */
    $scope.$on("all_concepts_retrieved", function () {
      initPage();
    });

    /**
     * Cancel any pending changes when the controller is destroyed.
     * This prevents the list page from displaying incorrect data if the user presses the back button
     */
    $scope.$on("$destroy", function () {
      $scope.Panel.cancelEdit("user_codes");
      InitialConnectionService.bypassForSCAPICorrection = false;
    });

    $scope.deleteCredential = function (credential) {
      CredentialsService.destroy(credential.id)
        .then(
          function () {
            $rootScope.alerts.push({
              type: "success",
              text: "successfully deleted credential",
            });
            refreshCredentials();
          },
          function (error) {
            $rootScope.alerts.push({
              type: "error",
              text: "error deleting credential",
              json: error,
            });
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    };

    $scope.deleteAllUnusedCredentials = function () {
      $scope.credentials.unassigned.busy = true;
      var promises = [];
      angular.forEach(
        $scope.credentials.unassigned.list,
        function (credential) {
          promises.push(CredentialsService.destroy(credential.id));
        }
      );
      $q.all(promises)
        .then(
          function () {
            $rootScope.alerts.push({
              type: "success",
              text: "Successfully deleted cards",
            });
            $scope.credentials.unassigned.list = [];
            $scope.credentials.unassigned.busy = false;
          },
          function (error) {
            console.error(
              "UserCodeEditCtrl->deleteAllUnusedCredentials - error: " +
                angular.toJson(error)
            );
            $rootScope.alerts.push({
              type: "error",
              text: "Error deleting cards",
              json: error,
            });
            refreshCredentials();
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    };

    $scope.codeTypeRadioClick = function () {
      if ($scope.credentials.userToEdit.useCredential) {
        angular.forEach($scope.panels, function (p) {
          if (p.panel_id !== $scope.Panel.panel_id) {
            p.selected = false;
          }
        });
        $scope.nextManUpVar.useNextMan = false;
      }
      delete $scope.userToEdit.code;
      if (
        $scope.userEditForm &&
        $scope.userEditForm.hasOwnProperty("user_code_input")
      ) {
        $scope.userEditForm.user_code_input.$setUntouched();
        $scope.userEditForm.user_code_input.$setPristine();
      }
    };

    function refreshCredentials() {
      var deferred = $q.defer();
      $scope.credentials.unassigned.busy = true;
      $scope.credentials.unassigned.list = [];
      CredentialsService.getForControlSystem(
        ControlSystemsService.currentControlSystem.control_system_id,
        "all"
      )
        .then(
          function (credentials) {
            console.debug(
              "UserCodeEditCtrl->refreshCredentials() - retrieved credentials:" +
                angular.toJson(credentials)
            );
            var validUnassignedSystemCredentials = [];
            var assignedCredentials = [];
            angular.forEach(credentials, function (c) {
              if (isUnassignedSystemCredential(c)) {
                validUnassignedSystemCredentials.push(c);
              } else if (isAssignedSystemCredential(c)) {
                assignedCredentials.push(c);
              }
            });
            $scope.credentials.unassigned.list =
              validUnassignedSystemCredentials;
            $scope.credentials.assigned.list = assignedCredentials;
            if ($scope.credentials.assigned.list.length) {
              setUserCodesCredentials();
            }
            deferred.resolve($scope.credentials.unassigned.list);
          },
          function (error) {
            console.error(
              "UserCodeEditCtrl->refreshCredentials() - error retrieving unassigned credentials:" +
                angular.toJson(error)
            );
            $rootScope.alerts.push({
              type: "error",
              text: "error retrieving unassigned cards",
              error: error,
            });
          }
        )
        .catch(function (error) {
          console.error(error);
        })
        .finally(function () {
          $scope.credentials.unassigned.busy = false;
        });
      return deferred.promise;
    }

    function setUserCodesCredentials() {
      if ($scope.Panel.user_codes) {
        angular.forEach(
          $scope.credentials.assigned.list,
          function (credential) {
            angular.forEach(credential.user_codes, function (uc) {
              var uCode = $scope.Panel.user_codes.find(function (u) {
                return +u.number === uc.number;
              });
              if (uCode) {
                // Create a credential property
                uCode["credential"] = credential;
              }
            });
          }
        );
      } else {
        console.warn("setUserCodesCredentials() - NO $scope.Panel.user_codes!");
      }
    }

    /**
     * Returns true if the credential has the properties needed by this controller
     * @param credential
     * @returns {boolean}
     */
    function isValidBadgeCredential(credential) {
      return Boolean(
        propertyExistsAndEquals(credential, "credential_type", "badge") &&
          DoesNestedPropertyExist(credential, "card_number.length") &&
          credential.card_number.length > 0
      );
    }

    /**
     * Returns true if the credential is of the control system in question and has no associated user codes
     * @param credential
     * @returns {boolean}
     */
    function isUnassignedSystemCredential(credential) {
      return Boolean(
        isValidBadgeCredential(credential) &&
          propertyExistsAndEquals(
            credential,
            "parent_entity.type",
            "control_systems"
          ) &&
          propertyExistsAndEquals(credential, "user_codes.length", 0)
      );
    }

    /**
     * Returns true if the credential of the control system and is associated to at least 1 user code
     * @param credential
     * @returns {boolean}
     */
    function isAssignedSystemCredential(credential) {
      return Boolean(
        isValidBadgeCredential(credential) &&
          propertyExistsAndEquals(
            credential,
            "parent_entity.type",
            "control_systems"
          ) &&
          credential.user_codes.length
      );
    }

    /**
     * Listen for the credentialImportComplete event, then reload the credentials so the list is up to date.
     */
    $scope.$on("event-credentialImportComplete", function () {
      refreshCredentials();
    });

    $scope.refreshCodesSearchResults = function (searchText) {
      $scope.credentials.unassigned.searchResults = [];
      for (var i = 0; i < $scope.credentials.unassigned.list.length; i++) {
        var c = $scope.credentials.unassigned.list[i];
        if (c.card_number.startsWith(searchText)) {
          $scope.credentials.unassigned.searchResults.push(c);
        }
        if ($scope.credentials.unassigned.searchResults.length === 10) {
          break;
        }
      }
      var element = angular.element("user_code_ui_select");
      $compile(element.contents())($scope);
    };

    $scope.openFileBrowser = function () {
      document.querySelector("#file-upload-chooser").click();
    };

    $scope.openUserFileBrowser = function () {
      document.querySelector("#user-file-upload-chooser").click();
    };

    $scope.importCards = function (file) {
      BatchImportCredentialsService.initialize(UserService.panel_id);
      $scope.BatchService = BatchImportCredentialsService;
      var deferred = $q.defer();
      var reader = new FileReader();
      reader.readAsDataURL(file);
      var credential = {};
      $scope.myFileName = file.name;
      credential.fileName = file.name;
      reader.onload = function () {
        $scope.openCardImportModal();
        credential.data = angular.copy(reader.result.split(",")[1]);
        BatchImportCredentialsService.import(
          credential.data,
          credential.fileName
        )
          .then(
            function (data) {
              deferred.resolve(data);
            },
            function (error) {
              deferred.reject(error);
            }
          )
          .catch(function (error) {
            console.error(error);
          });
      };
      return deferred.promise;
    };

    $scope.openCardImportModal = function () {
      var importCardsModal = $modal.open({
        templateUrl: "importCardsModal.html",
        windowClass: "",
        size: "md",
        backdrop: "static",
        keyboard: false,
        scope: $scope,
        controller: function ($scope, $modalInstance) {
          $scope.ok = function (e) {
            $modalInstance.close();
          };
          $scope.closeImportModal = function (e) {
            $modalInstance.dismiss();
          };
        },
      });
    };

    const updateNestedListItems = (panel) => {
      panel.nestedList = isXR550Family
        ? panel.profiles
        : panel.area_informations;
    };

    $scope.showDuplicatePanelWarning = (panel) =>
      !$scope.nextManUpVar.useNextMan && panel.user_codes
        ? panel.user_codes.some(
            (code) => Number(code.number) === Number($scope.userToEdit.number)
          )
        : false;

    $scope.getDuplicateCodeName = (panel, codeNumber) =>
      panel.user_codes.find(
        (code) => Number(code.number) === Number(codeNumber)
      ).name;

    $scope.maxItemsInNestedList = (list, max) =>
      list.filter((item) => item.selected).length === max;

    $scope.getNestedListItems = (curPanel, forceOpenToggle = false) => {
      if (forceOpenToggle && curPanel.selected) curPanel.isToggled = true;

      let conceptPromises = [];

      conceptPromises.push(
        curPanel.fetch("user_codes", { panel_id: curPanel.id })
      );

      conceptPromises.push(
        curPanel.fetch("capabilities", { panel_id: curPanel.id })
      );

      isXR550Family
        ? conceptPromises.push(
            curPanel.fetch("profiles", { panel_id: curPanel.id })
          )
        : conceptPromises.push(
            curPanel.fetch("area_informations", { panel_id: curPanel.id })
          );

      $q.all(conceptPromises).then(
        (success) => {
          updateNestedListItems(curPanel);
        },
        (fail) => {
          $rootScope.alerts.push({
            type: "error",
            text: "Error getting programming info",
          });
        }
      );
    };

    $scope.getActiveUserCodeImportJobs = function () {
      BatchImportUsersService.getRunningImportsForPanel()
        .then(
          function (data) {
            if (data.length > 0) {
              BatchImportUsersService.setStatus(
                $scope.userCodeImportStatuses.IMPORT_IN_PROGRESS
              );
              // Start the Update Watcher, to keep checking the status of the update
              BatchImportUsersService.startImportWatcher(data[0].Id);
            } else {
              $scope.runningImport = false;
            }
            $scope.finishedCheckingForImportJobs = true;
          },
          function (error) {}
        )
        .catch(function (error) {
          console.error(error);
        });
    };

    $scope.$on("$destroy", function () {
      BatchImportUsersService.stopImportWatcher();
    });

    $rootScope.$on("event-userCodeImportComplete", function () {
      $scope.getAllData();
      $scope.runningImport = false;
    });

    $scope.importUsers = function (file, importFileType) {
      BatchImportUsersService.initialize(UserService.panel_id);
      $scope.runningImport = true;
      var deferred = $q.defer();
      var reader = new FileReader();
      reader.readAsDataURL(file);
      var userCodes = {};
      $scope.myFileName = file.name;
      userCodes.fileName = file.name;
      reader.onload = function () {
        $scope.openUserImportModal();
        userCodes.data = angular.copy(reader.result.split(",")[1]);
        BatchImportUsersService.import(
          userCodes.data,
          userCodes.fileName,
          importFileType
        )
          .then(
            function (data) {
              deferred.resolve(data);
            },
            function (error) {
              deferred.reject(error);
            }
          )
          .catch(function (error) {
            console.error(error);
          });
      };
      GoogleAnalyticsService.trackEvent(
        "usercode",
        "usercode-import",
        $scope.fileUploadTypes[importFileType]
      );
      return deferred.promise;
    };

    const reloadPage = function () {
      $state.forceReload();
    };

    $scope.openUserImportModal = function () {
      var importUsersModal = $modal.open({
        templateUrl: "importUsersModal.html",
        windowClass: "",
        size: "md",
        backdrop: "static",
        keyboard: false,
        scope: $scope,
        controller: function ($scope, $modalInstance) {
          $scope.ok = function (e) {
            $modalInstance.close();
          };
          $scope.closeImportModal = function (e) {
            $modalInstance.dismiss();
          };
        },
      });
    };

    $scope.openInitialUserImportModal = function () {
      const initialUserImportModal = $modal.open({
        templateUrl:
          "app/programming/user_codes/modal--initial-user-input.html",
        windowClass: "",
        size: "md",
        backdrop: "static",
        keyboard: false,
        scope: $scope,
        controller: function ($scope, $modalInstance) {
          $scope.ok = function (e) {
            $modalInstance.close();
          };
          $scope.closeImportModal = function (e) {
            $modalInstance.dismiss();
          };
        },
      });
    };

    // initPage();
  },
]);
