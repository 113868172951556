import { RemountOnUpdateContainer } from "components/FullProgramming/common/LastUpdatedContext";
import { useTemplateContext } from "components/FullProgramming/common/TemplateContext";
import { resolvePanelType } from "components/FullProgramming/utils/panel";
import * as React from "react";
import { ID } from "securecom-graphql/client";
import { TakeoverTemplateContainer } from "./TakeoverTemplate";
import { TMSentryTemplateContainer } from "./TMSentryTemplate";
import { XFTemplateContainer } from "./XFTemplate";
import { XRTemplateContainer } from "./XRTemplate";
import { XTTemplateContainer } from "./XTTemplate";

type Props = { dealerId: ID };
export const FULL_PROGRAMMING_TEMPLATE_ID = "full-programming-template";

export const Templates: React.FC<Props> = ({ dealerId }) => {
  const { hardwareModel } = useTemplateContext();
  const { isXr, isTakeoverPanel, isXt, isXf, isTMSentry } =
    resolvePanelType(hardwareModel);
  return (
    <RemountOnUpdateContainer nodeId={FULL_PROGRAMMING_TEMPLATE_ID}>
      {isXt ? (
        <XTTemplateContainer
          dealerId={`${dealerId}`}
          hardwareModel={hardwareModel}
          softwareVersion="599"
        />
      ) : isXr ? (
        <XRTemplateContainer
          dealerId={`${dealerId}`}
          hardwareModel={hardwareModel}
          softwareVersion="599"
        />
      ) : isTakeoverPanel ? (
        <TakeoverTemplateContainer
          dealerId={`${dealerId}`}
          hardwareModel={hardwareModel}
          softwareVersion="599"
        />
      ) : isXf ? (
        <XFTemplateContainer
          dealerId={`${dealerId}`}
          hardwareModel={hardwareModel}
          softwareVersion="599"
        />
      ) : isTMSentry ? (
        <TMSentryTemplateContainer
          dealerId={`${dealerId}`}
          hardwareModel={hardwareModel}
          softwareVersion="599"
        />
      ) : null}
    </RemountOnUpdateContainer>
  );
};
