/**
 * @generated SignedSource<<60c507912d1af637cb9d40c64d9ebd02>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type SystemType = "ALL_PERIMETER" | "AREA" | "HOME_SLEEP_AWAY" | "HOME_SLEEP_AWAY_GUEST";
import { FragmentRefs } from "relay-runtime";
export type SystemOptionsContextIsAreaSystem_systemOptions$data = {
  readonly systemType?: SystemType;
  readonly " $fragmentType": "SystemOptionsContextIsAreaSystem_systemOptions";
};
export type SystemOptionsContextIsAreaSystem_systemOptions$key = {
  readonly " $data"?: SystemOptionsContextIsAreaSystem_systemOptions$data;
  readonly " $fragmentSpreads": FragmentRefs<"SystemOptionsContextIsAreaSystem_systemOptions">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "systemType",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SystemOptionsContextIsAreaSystem_systemOptions",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "XrSystemOptions",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "XtSystemOptions",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "TMSentrySystemOptions",
      "abstractKey": null
    }
  ],
  "type": "SystemOptions",
  "abstractKey": "__isSystemOptions"
};
})();

(node as any).hash = "5e74cb1a5e91a1d754b562b3f1398a48";

export default node;
