import graphql from "babel-plugin-relay/macro";
import Select from "components/FullProgramming/common/Select";
import * as React from "react";
import { useRelayEnvironment } from "react-relay";
import {
  Device734,
  Device734NoCommWithPanelRelayAction,
  DeviceInformation,
  DeviceInformationType,
} from "securecom-graphql/client";
import ProgrammingConceptForm from "../../ProgrammingConceptForm";
import { useDeviceInformationFragment } from "../DeviceInformationContext";
import { DeviceInformationNoCommWithPanelField_deviceInformation$key } from "./__generated__/DeviceInformationNoCommWithPanelField_deviceInformation.graphql";

export const deviceInformationNoCommWithPanelFieldId = (number: string) =>
  `device-information-no-comm-with-panel-${number}`;

function DeviceInformationNoCommWithPanelField() {
  const [{ id, number, device734, deviceType, remoteProgram734 }] =
    useDeviceInformationFragment<DeviceInformationNoCommWithPanelField_deviceInformation$key>(
      graphql`
        fragment DeviceInformationNoCommWithPanelField_deviceInformation on DeviceInformation {
          id
          number
          ... on XrDeviceInformation {
            remoteProgram734
            deviceType
            device734 {
              ... on Device734 {
                noCommWithPanelRelayAction
              }
            }
          }
        }
      `
    );

  const fieldId = deviceInformationNoCommWithPanelFieldId(String(number));
  const relayEnv = useRelayEnvironment();
  const disabled =
    deviceType !== DeviceInformationType.DOOR || !remoteProgram734;

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="No Communication with Panel"
      tooltip="Define relay action when communication from the panel has not occurred for approximately 10 seconds. See programming guide for details."
      disabled={disabled}
    >
      <Select
        id={fieldId}
        disabled={disabled}
        value={
          device734?.noCommWithPanelRelayAction ??
          Device734NoCommWithPanelRelayAction.OFF
        }
        onChange={({ target }) => {
          relayEnv.commitUpdate((store) => {
            const device734Options = store
              .get<DeviceInformation>(id)
              ?.getLinkedRecord<Device734>("device734");

            if (device734Options) {
              device734Options.setValue(
                target.value,
                "noCommWithPanelRelayAction"
              );
            }
          });
        }}
      >
        <Select.Option value={Device734NoCommWithPanelRelayAction.OFF}>
          Off
        </Select.Option>
        <Select.Option value={Device734NoCommWithPanelRelayAction.SITE}>
          Site
        </Select.Option>
        <Select.Option value={Device734NoCommWithPanelRelayAction.ANY}>
          Any
        </Select.Option>
        <Select.Option value={Device734NoCommWithPanelRelayAction.ON}>
          On
        </Select.Option>
        <Select.Option value={Device734NoCommWithPanelRelayAction.LAST}>
          Last
        </Select.Option>
      </Select>
    </ProgrammingConceptForm.Field>
  );
}

export default DeviceInformationNoCommWithPanelField;
