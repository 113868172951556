/**
 * @generated SignedSource<<41368dd5f880e5010759940867731c40>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AreaOpenClosingReportsField_xrArea$data = {
  readonly number: string;
  readonly openClosingReports: boolean;
  readonly " $fragmentType": "AreaOpenClosingReportsField_xrArea";
};
export type AreaOpenClosingReportsField_xrArea$key = {
  readonly " $data"?: AreaOpenClosingReportsField_xrArea$data;
  readonly " $fragmentSpreads": FragmentRefs<"AreaOpenClosingReportsField_xrArea">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AreaOpenClosingReportsField_xrArea",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "number",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "openClosingReports",
      "storageKey": null
    }
  ],
  "type": "XrArea",
  "abstractKey": null
};

(node as any).hash = "922225117746d245ca3ba032ce718945";

export default node;
