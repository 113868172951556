import { angular, jQuery } from "../globals";

export function pad(n, width, z) {
  z = z || "0";
  n = n + "";
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}

/**
 * @description
 *   Returns the next available "slot", given an array of numbers
 * @link http://stackoverflow.com/questions/14958906/algorithm-to-find-next-number-in-sequence
 * @param {Array} array
 * @param {int} until
 * @returns {_L5.nextManUp} itself
 */
export function nextManUp(array, until, startAt) {
  // Set the default for refresh...clunky but standard
  startAt = typeof startAt !== "undefined" ? startAt : 1;
  // This will pre-fill the values before startAt
  if (startAt > 1) {
    // Loop through all values before startAt
    for (var i = 1; i < startAt; i++) {
      // If the current number is missing from the array
      if (jQuery.inArray(i, array) < 0) {
        // then add it.
        array.push(i);
      }
    }
  }

  // Sort the array, numeric ascending
  array.sort(function (a, b) {
    return a - b;
  });
  this.array = array;
  this.lastIndex = 0;
  this.lastItem = 0;
  this.until = until;
  this.loopFinished = false;
  this.done = false;
  this.reset = function () {
    this.lastIndex = 0;
    this.lastItem = 0;
    this.done = false;
    this.loopFinished = false;
  };
  this.getNext = function () {
    if (this.done) {
      return null;
    }
    if (!this.loopFinished) {
      for (let loop = this.lastIndex; loop < this.array.length; loop++) {
        if (this.array[loop] !== this.lastItem + 1) {
          this.lastItem++;
          this.lastIndex = loop;
          if (this.lastItem > until) break;
          return this.lastItem;
        } else {
          this.lastItem = this.array[loop];
        }
      }
    }
    this.loopFinished = true;
    if (this.lastItem < this.until) {
      return ++this.lastItem;
    }
    this.done = true;
    return null;
  };
}

/**
 * @description
 *   Returns the next available "slot", given an array of numbers and a mask of valid numbers
 * @link http://stackoverflow.com/questions/14958906/algorithm-to-find-next-number-in-sequence
 * @param {Array} array
 * @param {Array} mask
 * @returns {nextManUpMask} itself
 */
export function nextManUpMask(array, mask) {
  // Sort the array, numeric ascending
  array.sort(function (a, b) {
    return a - b;
  });
  mask.sort(function (a, b) {
    return a - b;
  });

  for (let i = 0; i < mask.length; i++) {
    if (array.indexOf(mask[i]) < 0) {
      return mask[i].toString();
    }
  }
  return null;
}

/**
 * @description
 *  Checks to see whether theNumber is within distance of the closest number in theArray
 * @param theNumber the number that is being tested
 * @param theArray the Array of existing numbers
 * @param distance the allowed maximum distance
 */
export function isNumberWithinRange(theNumber, theArray, distance) {
  theNumber = parseInt(theNumber, 10);
  theArray.sort(function (a, b) {
    return a - b;
  });
  theArray = theArray.map(Number);
  var newDistance = distance - 1;
  return theArray.every(function (num) {
    return num + newDistance === theNumber || num - newDistance === theNumber;
  });
}

/**
 * Function to convert mixed range and csv string of numbers to a pure CSV number list
 * For example: "1,3-5,8" -> "1,3,4,5,8"
 *
 * lifted from http://codereview.stackexchange.com/questions/26125/getting-all-number-from-a-string-like-this-1-2-5-9
 */
export const numberRangeToCSV = (function () {
  //we create a closure so as not to expose some of our utility functions

  function isNumber(n) {
    //we check isFinite first since it will weed out most of the non-numbers
    //like mixed numbers and strings, which parseFloat happily accepts
    return isFinite(n) && !isNaN(parseFloat(n));
  }

  //let's get this one out as well
  //the simple sort() wouldn't work so instead we provide a sorter
  function sorterFunction(a, b) {
    return a - b;
  }

  //numberRangeToCSV should return this function
  return function (stringNumbers) {
    //variable declaration format is personal preference
    //but I prefer having declarations with assignments have individual vars
    //while those that have no assignments as comma separated
    var i, range, low, high, entry;

    //an added bonus, " and ' are the same in JS, but order still applies
    //I prefer to use ' since it's cleaner
    var entries = stringNumbers.split(",");
    var length = entries.length;
    var nums = [];

    for (i = 0; i < length; ++i) {
      entry = entries[i];

      if (isNumber(entry)) {
        //we check if the entry itself is a number. If it is, then we push it directly.
        //an additional advantage is that negative numbers are valid
        nums.push(+entry);
      } else {
        //if not a number, probably it had the - and not being a negative number
        //only here do we split after we determined that the entry isn't a number
        range = entry.split("-");

        //check if what we split are both numbers, else skip
        if (!isNumber(range[0]) || !isNumber(range[1])) continue;

        //force both to be numbers
        low = +range[0];
        high = +range[1];

        //since we are dealing with numbers, we could do an XOR swap
        //which is a swap that doesn't need a third variable
        //http://en.wikipedia.org/wiki/XOR_swap_algorithm
        if (high < low) {
          low = low ^ high;
          high = low ^ high;
          low = low ^ high;
        }

        //from low, we push up to high
        while (low <= high) {
          nums.push(low++);
        }
      }
    }
    return nums.sort(sorterFunction);
  };
})();

export function isNumber(n) {
  //we check isFinite first since it will weed out most of the non-numbers
  //like mixed numbers and strings, which parseFloat happily accepts
  return isFinite(n) && !isNaN(parseFloat(n));
}

/**
 * Function to convert a date formatted string to a fully formatted ISO date string.
 * For example, you can pass in "12/15/14" and it will convert it to "2014-12-15T00:00:00.000Z"
 * @param {String} strDate a date formatted string (almost any format), to convert to ISO format
 * @returns {String}
 */
export function dateTimeStringToISOString(strDate) {
  if (!strDate || strDate === "") {
    return "";
  }
  var date = new Date(strDate);
  var _userOffset = date.getTimezoneOffset() * 60 * 1000; // user's offset time, in minutes
  date = new Date(date.getTime() - _userOffset);
  return date.toISOString();
}

/**
 * Converts a loosely formatted date string into an ISO 8601 valid date string
 * @param strDate
 */
export function cleanUpDateString(strDate) {
  let suffix = null;
  let prefix = null;
  switch (true) {
    // 8 -> 8:00 OR 11 -> 11:00 OR 08 -> 08:00
    case /^[0-2]?[0-9]$/.test(strDate):
      return strDate + ":00";

    // 800 -> 8:00 or 0800 -> 08:00
    case /^[0-2]?[0-9][0-9][0-9]$/.test(strDate):
      return [
        strDate.slice(0, strDate.length - 2),
        ":",
        strDate.slice(-2),
      ].join("");

    // 8.00 or 08.00 or 8;00 or 08;00
    case /^[0-2]?[0-9](;|\.|,)[0-9][0-9]$/.test(strDate):
      return [
        strDate.slice(0, strDate.length - 3),
        ":",
        strDate.slice(-2),
      ].join("");

    // 8p or 8 p or 8pm or 8 pm
    case /^[0-1]?[0-9][ ]?([APap][mM]?)$/.test(strDate):
      // Get the a, p, am, pm, A, P, AM, PM, etc
      suffix = strDate.match(/([APap][mM]?)/)[0];
      // Strip off the suffix, and the rest is the prefix
      prefix = strDate.replace(suffix, "").trim();
      // Reformat a - AM, am - AM, p - PM, pm - PM, etc.
      suffix = suffix.toUpperCase().indexOf("P") > -1 ? "PM" : "AM";
      return [prefix, ":00", suffix].join("");

    // 8:00pm or 8:00 PM, etc.
    case /^[0-1]?[0-9](:|;|\.|,)?[0-9][0-9][ ]?([APap][mM]?)$/.test(strDate):
      // Get the a, p, am, pm, A, P, AM, PM, etc
      suffix = strDate.match(/([APap][mM]?)/)[0];
      // Strip off the suffix, then trim it, and the rest is the prefix
      prefix = strDate.replace(suffix, "").trim();
      // Reformat a - AM, am - AM, p - PM, pm - PM, etc.
      suffix = suffix.toUpperCase().indexOf("P") > -1 ? "PM" : "AM";
      if (prefix.match(/(:|;|\.|,)/)) {
        // 8;00 to 8:00, 12,00 to 12:00, etc.
        prefix = [
          prefix.slice(0, prefix.length - 3),
          ":",
          prefix.slice(-2),
        ].join("");
      } else {
        prefix = [
          prefix.slice(0, prefix.length - 2),
          ":",
          prefix.slice(-2),
        ].join("");
      }
      return [prefix, " ", suffix].join("");

    default:
      return strDate.trim();
  }
}

/**
 * Function to adjust a Date object so local time is actually UTC time. For example,
 * "1/15/2014 12:00 PM", if called from a browser in UTC-6, would normally be displayed as "1/15/2014 6:00 AM".
 * This function would forward the UTC date by 6 hours, so that the browser would display "1/15/2014 12:00 PM".
 * WARNING:  IF you are just passing in a date positive time zones (BELGIUM  UTC +1) will return the day before, to prevent this pass in the date + " 12:00"
 * FIX: DA-3652 This is to prevent positive time zone offsets (BELGIUM) from showing the wrong day on schedules.
 * @param {Date} inDate a date object
 * @returns {Date}
 */
export function dateTimeForceUTC(inDate) {
  var _userOffset = inDate.getTimezoneOffset() * 60 * 1000; // user's offset time, in minutes
  var date = new Date(inDate.getTime() + _userOffset);
  return date;
}

/**
 * Function to get the number of minutes between two date times objects.
 * This returns an absolute value so the result will always be a positive number
 * @param startDate
 * @param endDate
 * @returns {number}
 */
export function dateTimeMinutesBetween(startDate, endDate) {
  var diff = Math.abs(startDate - endDate); // Returns difference in milliseconds
  return Math.floor(diff / 1000 / 60); // Divide by 1000 to get seconds, then by 60 to get minutes
}
/**
 * @description
 * Simple XOR encryption to scramble visible parameters
 * @param s Content to be encrypted
 * @param pw Passkey for encryption
 * @returns {string} Encrypted Value (always numbers i.e. 010293923)
 */
export function encrypt(s, pw) {
  var a = 0;
  var myString = "";
  var textLen = s.length;
  var pwLen = pw.length;

  for (let i = 0; i < textLen; i++) {
    a = parseInt(s.charCodeAt(i));

    a = a ^ pw.charCodeAt(i % pwLen);
    a = a + "";
    while (a.length < 3) a = "0" + a;

    myString += a;
  }
  return myString;
}
/**
 * @description
 * Simple XOR decryption
 * @param s Content to be decrypted
 * @param pw Passkey for decryption
 * @returns {string} Decrypted Value
 */
export function decrypt(s, pw) {
  var myString = "";
  var a = 0;
  var pwLen = pw.length;
  var i = 0;
  var myHolder = "";
  while (i < s.length - 2) {
    myHolder = s.charAt(i) + s.charAt(i + 1) + s.charAt(i + 2);
    if (s.charAt(i) === "0") {
      myHolder = s.charAt(i + 1) + s.charAt(i + 2);
    }
    if (s.charAt(i) === "0" && s.charAt(i + 1) === "0") {
      myHolder = s.charAt(i + 2);
    }
    a = parseInt(myHolder);
    a = a ^ pw.charCodeAt((i / 3) % pwLen);
    myString += String.fromCharCode(a);
    i += 3;
  } //end of while i
  return myString;
}

/**
 * Function to parse a date in mm-dd-yy format, as we get dates from the panels
 * @param input
 * @returns {Date}
 */
export function parsePanelDate(input) {
  var parts = input.match(/(\d+)/g);
  // note parts[1]-1
  var date = new Date();
  date.setYear("20" + parts[2]);
  date.setMonth(parts[0] - 1);
  date.setDate(parts[1]);
  return dateTimeForceUTC(date);
}

export function endsWith(str, suffix) {
  return str.indexOf(suffix, str.length - suffix.length) !== -1;
}

/**
 * @ngdoc object
 * @name method:ScheduleTimeFromUTC
 *
 * @description
 * Take the time that the user entered and break it into hour, minute, and AM/PM
 *
 * @returns
 * result[0] = Hours
 * result[1] = Minutes
 * result[2] = AM/PM
 */
export function ScheduleTimeFromUTC(input) {
  var result = [];

  // Force the Javascript Date object to use UTC time instead of the local timezone
  var time = dateTimeForceUTC(new Date(input));
  // Break up the newly created time into it's elements
  result[0] = time.getHours();
  result[1] = time.getMinutes();

  // Convert 24hr time to 12hr time
  if (result[0] === 0) {
    result[0] = 12;
    result[2] = "AM";
  } // 00 hours
  else if (result[0] < 12) {
    result[2] = "AM";
  } // < 12 hours
  else if (result[0] === 12) {
    result[2] = "PM";
  } // 12 hours
  else if (result[0] > 12) {
    result[0] = result[0] - 12;
    result[2] = "PM";
  } // > 12 hours

  return result;
}

/**
 * @ngdoc object
 * @name method:UTCFromScheduleTime
 *
 * @description
 * Take a schedules time and converts it to a UTC time
 *
 * @returns {Date} UTC formatted time
 */
export function UTCFromScheduleTime(hour, minute, am_pm) {
  var dt = new Date();
  if (hour === 12) {
    hour = 0;
  }
  if (am_pm === "PM") {
    hour = hour + 12;
  }
  dt.setHours(hour);
  dt.setMinutes(minute);
  return new Date(dateTimeStringToISOString(dt.toString()));
}

/**
 * Checks to see if a nested property exists on the passed in object
 * @param baseObject The base object used to check for the existence of the property string
 * @param pathString The '.' separated path string to the property that you're checking for
 * @returns {boolean} False if any of the properties don't exist, else true
 * @constructor
 */
export function DoesNestedPropertyExist(baseObject, pathString) {
  var props = pathString.split(".");
  var currentProp = baseObject;

  // Loop through the props array, checking to see if each property exists
  for (var i = 0; i < props.length; i++) {
    if (!currentProp || !currentProp.hasOwnProperty(props[i])) {
      // Property doesn't exist
      return false;
    }

    // Set the current prop object to the last property we tested
    currentProp = currentProp[props[i]];
  }

  // All properties exist
  return true;
}

export function isUndefinedOrNull(val) {
  return angular.isUndefined(val) || val === null;
}

export function hexToDecimal(hex) {
  return parseInt(hex, 16);
}

/**
 * Find the index of the first object in an array of objects with a given property value
 * @param {array} objectArray - an array of objects with properties to search
 * @param {string} propertyKey - the name of the property of the object within the array to check
 * @param {string|number|boolean} value - the value to compare to the object property
 * @returns {number} - The index of the object if found. Returns -1 if no matching object is found
 */
export function indexOfByPropertyValue(objectArray, propertyKey, value) {
  var index = -1;
  for (var i = 0; i < objectArray.length; i++) {
    if (
      objectArray[i].hasOwnProperty(propertyKey) &&
      objectArray[i][propertyKey] === value
    ) {
      index = i;
      break;
    }
  }
  return index;
}

/**
 * Given a base object, checks if the property exists on the object and is equal to the value provided
 * @param {Object} baseObject - an object with properties to check
 * @param {string} pathString - the . separated string of properties to check
 * @param {*} value - the value to check
 * @returns {boolean} - true if the property exists and the value is the same type and value as expected
 */
export function propertyExistsAndEquals(baseObject, pathString, value) {
  if (
    angular.isUndefined(baseObject) ||
    angular.isUndefined(pathString) ||
    angular.isUndefined(value)
  ) {
    return false;
  }
  var object = baseObject;
  var properties = pathString.split(".");
  for (var i = 0; i < properties.length; i++) {
    if (isUndefinedOrNull(object) || !object.hasOwnProperty(properties[i])) {
      return false;
    }
    object = object[properties[i]];
  }
  return object === value;
}

/**
 * Get a random integer between the min and max value, inclusive
 * @param min
 * @param max
 * @returns {*}
 */
export function getRandomIntInclusive(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min; //The maximum is inclusive and the minimum is inclusive
}

/**
 * Given address information, format a postal address
 * @param {string} address_1
 * @param {string} address_2 - ex: STE 100
 * @param {string} city
 * @param {string} state
 * @param {int|string} postal_code
 * @returns {{topLine: string, bottomLine: string}}
 */
export function formatPostalAddress(
  address_1,
  address_2,
  city,
  state,
  postal_code
) {
  var postalAddress = {
    topLine: "",
    bottomLine: "",
  };

  postalAddress.topLine = address_1 ? address_1 : "";
  postalAddress.topLine += address_1 && address_2 ? " " : "";
  postalAddress.topLine += address_2 ? address_2 : "";

  postalAddress.bottomLine = city ? city : "";
  postalAddress.bottomLine += city && state ? "," : "";
  postalAddress.bottomLine += city && (state || postal_code) ? " " : "";
  postalAddress.bottomLine += state ? state : "";
  postalAddress.bottomLine += city && state && postal_code ? " " : "";
  postalAddress.bottomLine += postal_code ? postal_code : "";

  return postalAddress;
}

/**
 * Return true if the current time is within the role schedule
 * @param {string} startTime - server DateTimeOffset
 * @param {string} endTime - server DateTimeOffset
 * @returns {boolean}
 */
export function nowInSchedule(startTime, endTime) {
  if (startTime && endTime) {
    var Now = new Date();
    var Start = normalizeServerDate(startTime);
    var End = normalizeServerDate(endTime);
    return Now > Start && Now < End;
  }
  return false;

  // Create a new (today) date and normalize the HMS from the server time
  function normalizeServerDate(dateString) {
    var date = new Date(dateString);
    var newDate = new Date();
    newDate.setHours(date.getUTCHours());
    newDate.setMinutes(date.getUTCMinutes());
    newDate.setSeconds(date.getUTCSeconds());
    return newDate;
  }
}

export function makeNumberRange(start, end, step) {
  step = step || 1;
  let range = [];
  for (let n = +start; n <= +end; n += +step) {
    range.push(n);
  }
  return range;
}

export function between(num, min = -Infinity, max = Infinity) {
  return num >= min && num <= max;
}

export function getTimePlusDuration(startTime, duration) {
  return new Date(new Date(startTime).getTime() + duration);
}
export const getHardwareFamilyFromHardwareModel = (model) => {
  switch (model) {
    case "XF6_100":
    case "XF6_500":
      return "XF6";
    case "XR100":
    case "XR500":
      return "XR500";
    case "XR150":
    case "XR350":
    case "XR550":
      return "XR550";
    case "XT30":
    case "XT50":
    case "XTLP":
    case "XTL":
    case "CellComSL":
    case "CellComEX":
    case "iComSL":
    case "DualCom":
    case "MiniCellCom":
    case "iComLNC":
      return "XT30";
    case "EM20D":
      return "EM20";
    case "Video Only":
      return "Video Only";
    default:
      return "";
  }
};
