App.factory("UserService", [
  "$sessionStorage",
  "$q",
  "PROPS",
  "$window",
  "$filter",
  "REMOTE_UPDATE_VERSIONS",
  "PANEL_TEST_VERSIONS",
  "NEWS_ITEMS",
  "NO_NEWS",
  "GoogleAnalyticsService",
  "KEYPAD_BANNER_SEND",
  "ACCOUNTING_REPORTS",
  "SPECIAL_DEALERS",
  "BETA",
  "$rootScope",
  function (
    $sessionStorage,
    $q,
    PROPS,
    $window,
    $filter,
    REMOTE_UPDATE_VERSIONS,
    PANEL_TEST_VERSIONS,
    NEWS_ITEMS,
    NO_NEWS,
    GoogleAnalyticsService,
    KEYPAD_BANNER_SEND,
    ACCOUNTING_REPORTS,
    SPECIAL_DEALERS,
    BETA,
    $rootScope
  ) {
    let userDeferred = $q.defer();
    let dealerInfoDeferred = $q.defer();

    var thisService = {
      waitForUser: () => userDeferred.promise,
      waitForDealerInfo: () => dealerInfoDeferred.promise,
      //this needs to be backwards compatible and use the new bearer_token.
      //TODO: Clean this up to handle both tokens correctly
      login: function (email, auth_token, refresh_token) {
        this.email = email;
        this.auth_token = auth_token;
        this.refresh_token = refresh_token;
        $sessionStorage.auth_token = auth_token;
        $sessionStorage.email = email;
        $sessionStorage.refresh_token = refresh_token;
      },
      /**UserService.logout() function should not be used directly use RelayService.logout() instead. 
       This will allow the relay cache to be cleared properly on logout**/
      logout: function () {
        $sessionStorage.$reset();
        this.initialize();
      },
      initialize: function () {
        delete this.email;
        delete this.auth_token;
        delete this.dealerInfo;
        delete this.store_user_codes;
        delete this.logoUrl;
        delete this.systems;
        delete this.customerInfo;
        delete this.user;
        delete this.controlSystem;
        delete this.panelConnected;
        delete this.retrievedDealerInitKeys;
        delete this.customer_id;
        delete this.control_system_id;
        delete this.customerListCached;
        delete this.appUserListCached;
        delete this.selectedDealer;
        this.isBusy = false;
        this.dealer_id = null;
        this.canProgramFast = false;
        this.canProgramFull = false;
        // Should dealer action items be shown: sidebar, etc.
        this.displayDealerOptions = false;
        // Dealers can own dealers. This is storage for that information.
        initParentDealerInfo(this);
        $rootScope.refreshPromise = null;

        userDeferred = $q.defer();
        dealerInfoDeferred = $q.defer();
      },
      /**
       * Login is valid if auth_token and email are either in sessionStorage, or already populated in this service
       * @returns {*}
       */
      isAuthenticated: function () {
        return (this.email && this.auth_token) || this.loadAuthFromCache();
      },
      loadAuthFromCache: function () {
        if ($sessionStorage.auth_token && $sessionStorage.email) {
          this.email = $sessionStorage.email;
          this.auth_token = $sessionStorage.auth_token;
          if ($sessionStorage.refresh_token) {
            this.refresh_token = $sessionStorage.refresh_token;
          }
          return true;
        } else {
          return false;
        }
      },

      setDealerDashboardData: function (dashboardData) {
        var _this = this;
        var deferred = $q.defer();

        _this.dashboardData = dashboardData;
        _this.dashboardData.dataAge = Date.now();

        deferred.resolve(_this.dashboardData);

        return deferred.promise;
      },
      /**
       * Set dealer info of this object to the passed-in DealerSettings and retrieve
       * @param {object} dealerInfo
       */
      setDealerInfo: function (dealerInfo) {
        if (angular.isUndefined(dealerInfo)) {
          return $q.reject("No dealer info");
        }
        var _this = this;
        var deferred = $q.defer();
        // dealerInfo (DealerService) can be initialized with a dealer_id or customer_id
        var dealerInitKey = DoesNestedPropertyExist(
          dealerInfo,
          "initKeys.dealer_id"
        )
          ? dealerInfo.initKeys.dealer_id
          : null;
        // Retrieve dealer if a dealer has not been successfully retrieved or you don't have a dealer key or you
        // have a dealer key for a different dealer
        if (
          !_this.dealer_id ||
          !dealerInitKey ||
          +_this.dealer_id !== +dealerInitKey
        ) {
          // a dealer is initialized without an id so _this.dealerInfo is essentially a buffer until
          // the get function resolves, at which point an id is attached to _this.dealerInfo
          _this.dealerInfo = dealerInfo;
          _this.dealerInfo
            .get()
            .then(
              function (data) {
                // Get a fresh customer count
                _this.dealerInfo.getCustomerCount();
                // Get a fresh referrals count
                _this.dealerInfo.getReferralsCount();
                // Get fresh billing information
                _this.dealerInfo.getHasValidBillingInformation();
                // If you've retrieved a different dealer
                if (_this.dealer_id !== data.dealer.id) {
                  // Attaching the dealer_id indicates the dealer has been successfully retrieved. Used throughout html.
                  _this.dealer_id = data.dealer.id;
                  _this.retrievedDealerInitKeys = dealerInfo.initKeys;
                  _this.logoUrl = data.dealer.website_logo;
                  _this.dealerInfo.store_user_codes =
                    data.dealer.store_user_codes;
                  deferred.resolve(data.dealer);
                  dealerInfoDeferred.resolve(data.dealer);
                } else {
                  deferred.resolve(data.dealer);
                  dealerInfoDeferred.resolve(data.dealer);
                }
              },
              function (error) {
                console.error(
                  "UserService->setDealerInfo - error getting dealerInfo: " +
                    angular.toJson(error)
                );
                delete _this.dealerInfo;
                deferred.reject(error);
              }
            )
            .catch(function (error) {
              console.error(error);
            });
        } else {
          deferred.resolve(_this.dealerInfo);
        }
        return deferred.promise;
      },
      setLogoUrl: function (logoUrl) {
        this.logoUrl = logoUrl;
      },
      setSystems: function (systems) {
        //store response JSON data
        this.systems = systems;
      },
      getSystems: function () {
        return this.systems;
      },

      setCustomerInfo: function (customerInfo) {
        this.customerInfo = customerInfo;
      },
      setProgramming: function (panel) {
        this.canProgramFast = false;
        this.canProgramFull = false;
        if (
          panel.programming_type == "XT30" ||
          panel.programming_type == "XT50" ||
          panel.programming_type == "XR150" ||
          panel.programming_type == "XR350" ||
          panel.programming_type == "XR550" ||
          panel.programming_type == "XF6" ||
          panel.programming_type == "TMS6"
        ) {
          this.canProgramFull = true;
        } else if (
          panel.comm_type == "cell" &&
          panel.programming_type == "MiniCellCom"
        ) {
          this.canProgramFast = true;
        } else if (
          panel.programming_type == "CellComSL" ||
          panel.programming_type == "CellComEX"
        ) {
          this.canProgramFast = true;
          this.canProgramFull = true;
        } else if (
          (panel.comm_type == "network" || panel.comm_type == "persistent") &&
          panel.programming_type == "iComSL"
        ) {
          this.canProgramFast = true;
          this.canProgramFull = true;
        } else if (panel.programming_type == "XTL") {
          this.canProgramFast = true;
        } else if (panel.programming_type == "iComLNC") {
          this.canProgramFast = true;
        } else if (panel.programming_type == "DualCom") {
          this.canProgramFast = true;
          this.canProgramFull = true;
        } else if (panel.programming_type == "XTLP") {
          this.canProgramFast = true;
          this.canProgramFull = true;
        } else {
          this.canProgramFast = false;
          this.canProgramFull = false;
        }
      },
      setControlSystem: function (controlSystem) {
        this.controlSystem = controlSystem;
      },
      setSite: function (site) {
        this.site = site;
      },

      /**
       * Attach a user to this service and set appropriate properties
       * @param {object} user
       * @returns {boolean} - True if user object has valid format / required information
       */
      setUser: function (user) {
        var _this = this;
        if (isValidUserObject(user)) {
          _this.user = user;
          userDeferred.resolve(user);
          if (user.hasOwnProperty("id")) {
            GoogleAnalyticsService.setUserId(user.id);
          }
          _this.email = user.email;
          _this.setDisplayDealerOptions();
          return true;
        } else {
          delete _this.user;
          userDeferred.reject(new Error("invalid user object"));
          return false;
        }
      },

      /**
       * Dealer user logins may have roles and/or be a login of a parent dealer (a dealer who owns other dealers).
       * This function attaches those properties to this service when needed
       * @param {object} dealerInfo
       * @param {object} user
       * @param {object} [role]
       */
      prepForDealerUser: function (dealerInfo, user, role) {
        var _this = this;
        var deferred = $q.defer();
        // Validate objects
        if (
          DoesNestedPropertyExist(dealerInfo, "initKeys") &&
          isValidUserObject(user) &&
          user.accessible_type.toLowerCase() === "dealer"
        ) {
          // To get parent dealer information, the dealerInfo must be set
          _this
            .setDealerInfo(dealerInfo)
            .then(
              function () {
                if (_this.setUser(user)) {
                  if (_this.isDealerAccessible()) {
                    // If a role was sent, attach it
                    if (angular.isDefined(role) && role !== null) {
                      _this.user.customRole = role;
                    }
                    // A dealer may own other dealers. For dealer log-ins, we must retrieve dealers for which they have
                    // access to determine if they are a user for a parent dealer
                    _this.dealerInfo
                      .getDealersForUser()
                      .then(
                        function (data) {
                          initParentDealerInfo(_this);
                          angular.forEach(data, function (record) {
                            var dealer = record.dealer;
                            // The response will be an array, usually of 1. If any dealer has a parent dealer...
                            if (
                              dealer.hasOwnProperty("parent_id") &&
                              dealer.parent_id !== null
                            ) {
                              // If the parent dealer is the current dealer, add it to a list of dealers of the current dealer
                              if (dealer.parent_id === user.accessible_id) {
                                _this.parentDealerInfo.dealers.push(dealer);
                              }
                            }
                          });
                          if (_this.parentDealerInfo.dealers.length > 0) {
                            var parentDealer = data.find(function (record) {
                              return record.dealer.id === user.accessible_id;
                            });
                            if (angular.isDefined(parentDealer)) {
                              _this.parentDealerInfo.parent =
                                parentDealer.dealer;
                            }
                          }
                          deferred.resolve();
                        },
                        function (error) {
                          console.error(
                            "UserService->prepForDealerUser() - Error getting dealers for dealer user " +
                              angular.toJson(error)
                          );
                          deferred.reject(error);
                        }
                      )
                      .catch(function (error) {
                        console.error(error);
                      });
                  } else {
                    // If a dealer role that doesn't need custom role or parent dealer info, you're done
                    deferred.resolve();
                  }
                } else {
                  deferred.reject("bad request");
                }
              },
              function (error) {
                deferred.reject(error);
              }
            )
            .catch(function (error) {
              console.error(error);
            });
        } else {
          deferred.reject("bad request");
        }
        return deferred.promise;
      },

      isDealerAdmin: function (user) {
        if (angular.isUndefined(user)) {
          return (
            this.user &&
            this.user.accessible_type === "Dealer" &&
            this.user.role === "admin"
          );
        } else {
          return user.accessible_type === "Dealer" && user.role === "admin";
        }
      },
      isDealerOperator: function (user) {
        if (angular.isUndefined(user)) {
          return (
            this.user &&
            this.user.accessible_type === "Dealer" &&
            this.user.role === "operator"
          );
        } else {
          return user.accessible_type === "Dealer" && user.role === "operator";
        }
      },
      isDealerTechnician: function (user) {
        if (angular.isUndefined(user)) {
          return (
            this.user &&
            this.user.accessible_type === "Dealer" &&
            this.user.role === "technician"
          );
        } else {
          return (
            user.accessible_type === "Dealer" && user.role === "technician"
          );
        }
      },
      isCustomer: function () {
        return this.user && this.user.accessible_type === "Customer";
      },
      isAdmin: function () {
        return (
          this.user &&
          this.user.accessible_type === "Supervisor" &&
          this.user.role === "admin"
        );
      },
      isTechSupport: function () {
        return (
          this.user &&
          this.user.accessible_type === "Supervisor" &&
          this.user.role === "technician"
        );
      },
      // aka DMP customer service.
      // historical note: These were all changed to technician roles because the permissions were not meeting needs
      isCustomerSupport: function () {
        return (
          this.user &&
          this.user.accessible_type === "Supervisor" &&
          this.user.role === "support"
        );
      },
      isVideoVerifier: function (user) {
        if (angular.isUndefined(user)) {
          return (
            this.user &&
            this.user.accessible_type === "Dealer" &&
            this.user.role === "video_verifier"
          );
        } else {
          return (
            user.accessible_type === "Dealer" && user.role === "video_verifier"
          );
        }
      },
      isSalesManager: function () {
        return (
          this.user &&
          this.user.accessible_type === "Dealer" &&
          this.user.role === "sales_manager"
        );
      },
      // An employee of DMP that provides support for dealers (not all DMP roles)
      isSupervisorAccessible: function () {
        return (
          this.isAdmin() || this.isTechSupport() || this.isCustomerSupport()
        );
      },
      isDealerAccessible: function (user) {
        if (angular.isUndefined(user)) {
          return (
            this.isDealerAdmin() ||
            this.isDealerOperator() ||
            this.isDealerTechnician()
          );
        } else {
          return (
            this.isDealerAdmin(user) ||
            this.isDealerOperator(user) ||
            this.isDealerTechnician(user)
          );
        }
      },
      isAccountant: function () {
        return (
          this.user &&
          this.user.accessible_type === "Dealer" &&
          this.user.role === "accountant"
        );
      },

      /**
       * The Dealer Admin can collection
       */
      canCreateTempDealerCustomerUser: function () {
        return (
          (this.isDealerAdmin() || this.parentDealerOnChild()) &&
          customRoleAllows(this, "temp_customer_user_view") &&
          this.enabledLoginAsCustomer()
        );
      },
      canCreateTempDealerCustomerUserViaPermission: function () {
        return (
          // tech support can create temp dealer customer users
          this.isDealerTechnician
        );
      },

      // Used to determine if a dealer
      // can give permission to a tech support agent
      // to create log into a customer account through VKB
      canAuthorizeLoginAsCustomer: function () {
        return (
          this.isDealerAdmin() ||
          this.parentDealerOnChild() ||
          this.isDealerTechnician()
        );
      },

      // Used to determine if a dealer can create a temp dealer
      //customer user via permission from a dealer

      canViewSensorReset: function () {
        return (
          this.isDealerAdmin() && customRoleAllows(this, "sensor_reset_view")
        );
      },
      canViewMarketingCentral: function () {
        return (
          (this.isSupervisorAccessible() || this.isDealerAccessible()) &&
          customRoleAllows(this, "marketing_central_view")
        );
      },
      canViewMobileCredentials: function () {
        return (
          (this.isAdmin() ||
            (this.isDealerAdmin() &&
              customRoleAllows(this, "mobile_credentials_view"))) &&
          !this.parentDealerOnChild()
        );
      },
      canIssueMobileCredentials: function () {
        return (
          this.isDealerAdmin() &&
          customRoleAllows(this, "mobile_credentials_allocate") &&
          customRoleAllows(this, "mobile_credentials_purchase")
        );
      },
      canViewBulkUpdate: function () {
        return (
          this.notParentDealerNotOnSelf() &&
          !this.isTechSupport() &&
          customRoleAllows(this, "bulk_update_view")
        );
      },
      canViewTechLocator: function () {
        return (
          (this.isDealerAdmin() || this.isAdmin()) &&
          this.dealerInfo.tech_locations
        );
      },
      canViewRemoteUpdateDashboard: function () {
        return (
          this.notParentDealerNotOnSelf() &&
          customRoleAllows(this, "remote_update_dashboard_view")
        );
      },
      canViewMassProgramming: function () {
        return (
          (this.isDealerAdmin() || this.isAdmin()) &&
          customRoleAllows(this, "mass_programming_view")
        );
      },
      canArmSystem: function () {
        return this.isDealerAdmin() && customRoleAllows(this, "arming");
      },
      canViewPrintProgramming: function () {
        return (
          (this.isDealerAdmin() || this.isAdmin() || this.isTechSupport()) &&
          customRoleAllows(this, "print_programming_view")
        );
      },
      canViewServiceRequests: function () {
        return (
          (this.isDealerAdmin() || this.isAdmin()) &&
          customRoleAllows(this, "service_request_dashboard_view")
        );
      },
      canViewDefaultProgramming: function () {
        return (
          this.notParentDealerOnSelf() &&
          this.canEditDealerSettings() &&
          customRoleAllows(this, "default_programming_view")
        );
      },
      canViewRemoteUpdate: function () {
        return !this.isBusy && customRoleAllows(this, "remote_update_view");
      },
      canViewReceivers: function () {
        return (
          this.canEditDealerSettings() &&
          customRoleAllows(this, "receivers_view")
        );
      },
      canViewSystemAnalytics: function () {
        return customRoleAllows(this, "system_analytics_view");
      },
      canViewReportingAnalytics: function () {
        return customRoleAllows(this, "reporting_analytics_view");
      },
      canViewCustomers: function () {
        return (
          (this.isAdmin() ||
            this.isDealerAdmin() ||
            this.isDealerOperator() ||
            this.isDealerTechnician() ||
            this.isTechSupport()) &&
          customRoleAllows(this, "customers_view")
        );
      },
      canReadMonitoringReceiverSettingsOnly: function () {
        return this.isTechSupport() || this.isAdmin();
      },
      canFormatSdCard: function () {
        return (
          //needs to be a supervisor or a dealer technician
          this.isSupervisorAccessible() || this.isDealerTechnician()
        );
      },

      canEditCustomers: function () {
        return (
          (this.isAdmin() ||
            this.isDealerAdmin() ||
            this.isDealerOperator() ||
            this.isDealerTechnician()) &&
          customRoleAllows(this, "customers_edit")
        );
      },
      canAddCustomers: function () {
        return (
          (this.isAdmin() ||
            this.isDealerAdmin() ||
            this.isDealerOperator() ||
            this.isDealerTechnician()) &&
          customRoleAllows(this, "customers_add")
        );
      },
      canDeleteCustomers: function () {
        return (
          (this.isAdmin() || this.isDealerAdmin()) &&
          customRoleAllows(this, "customers_delete")
        );
      },
      canViewCustomersList: function () {
        return (
          customRoleAllows(this, "customers_list_view") &&
          customRoleAllows(this, "customers_view")
        );
      },
      canViewSystemStatus: function () {
        return (
          !this.isSupervisorAccessible() &&
          this.notParentDealerNotOnSelf() &&
          !this.isDealerTechnician() &&
          customRoleAllows(this, "arming")
        );
      },
      canViewSystems: function () {
        return (
          (this.isAdmin() ||
            this.isDealerAdmin() ||
            this.isDealerOperator() ||
            this.isDealerTechnician() ||
            this.isTechSupport()) &&
          customRoleAllows(this, "systems_view")
        );
      },
      canEditSystems: function () {
        return (
          (this.isAdmin() ||
            this.isDealerAdmin() ||
            this.isDealerOperator() ||
            this.isDealerTechnician()) &&
          customRoleAllows(this, "systems_edit")
        );
      },
      canAddSystems: function () {
        return (
          (this.isAdmin() ||
            this.isDealerAdmin() ||
            this.isDealerOperator() ||
            this.isDealerTechnician()) &&
          customRoleAllows(this, "systems_add")
        );
      },
      canDeleteSystems: function () {
        return (
          (this.isAdmin() || this.isDealerAdmin()) &&
          customRoleAllows(this, "systems_delete")
        );
      },
      canViewAutomation: function () {
        return customRoleAllows(this, "automation_view");
      },
      canViewIncludedFeatures: function () {
        return customRoleAllows(this, "included_features_view");
      },
      canEditIncludedFeatures: function () {
        return customRoleAllows(this, "included_features_edit");
      },
      canViewAddOnFeatures: function () {
        return customRoleAllows(this, "add_on_features_view");
      },
      canEditAddOnFeatures: function () {
        return customRoleAllows(this, "add_on_features_edit");
      },
      canViewCellular: function () {
        return customRoleAllows(this, "cellular_view");
      },
      canActivateCellular: function () {
        return customRoleAllows(this, "cellular_activate");
      },
      canDeactivateCellular: function () {
        return customRoleAllows(this, "cellular_deactivate");
      },
      canViewUsers: function () {
        return (
          (this.isAdmin() ||
            this.isDealerAdmin() ||
            this.isDealerOperator() ||
            this.isDealerTechnician() ||
            this.isTechSupport()) &&
          customRoleAllows(this, "app_users_view")
        );
      },
      canCreateUsers: function () {
        return (
          (this.isAdmin() ||
            this.isDealerAdmin() ||
            this.isDealerOperator() ||
            this.isDealerTechnician()) &&
          customRoleAllows(this, "app_users_add")
        );
      },
      canEditUsers: function () {
        return (
          (this.isAdmin() ||
            this.isDealerAdmin() ||
            this.isDealerTechnician()) &&
          customRoleAllows(this, "app_users_edit")
        );
      },
      canDeleteUsers: function () {
        return (
          (this.isAdmin() ||
            this.isDealerAdmin() ||
            this.isDealerTechnician()) &&
          customRoleAllows(this, "app_users_delete")
        );
      },
      canMergeUsers: function () {
        return this.isDealerAdmin();
      },
      canResetAccess: function () {
        return (
          this.isAdmin() ||
          this.isDealerAdmin() ||
          this.isDealerOperator() ||
          this.isDealerTechnician() ||
          this.isTechSupport()
        );
      },
      canCreatePersonnel: function () {
        return (
          (this.isAdmin() ||
            this.isDealerAdmin() ||
            this.isTechSupport() ||
            this.isCustomerSupport()) &&
          customRoleAllows(this, "personnel_edit")
        );
      },
      canViewPersonnel: function () {
        return (
          (this.isAdmin() ||
            this.isDealerAdmin() ||
            this.isTechSupport() ||
            this.isCustomerSupport()) &&
          customRoleAllows(this, "personnel_view")
        );
      },
      canEditPersonnel: function () {
        return (
          (this.isAdmin() ||
            this.isDealerAdmin() ||
            this.isTechSupport() ||
            this.isCustomerSupport()) &&
          customRoleAllows(this, "personnel_edit")
        );
      },
      canDeletePersonnel: function () {
        return (
          (this.isAdmin() ||
            this.isDealerAdmin() ||
            this.isTechSupport() ||
            this.isCustomerSupport()) &&
          customRoleAllows(this, "personnel_delete")
        );
      },
      canViewVideoDevice: function () {
        return (
          (this.isAdmin() ||
            this.isDealerAdmin() ||
            this.isDealerOperator() ||
            this.isDealerTechnician() ||
            this.isTechSupport()) &&
          customRoleAllows(this, "systems_view")
        );
      },
      // For custom roles, System permissions apply to Video Devices
      canCreateVideoDevice: function () {
        return (
          (this.isAdmin() ||
            this.isDealerAdmin() ||
            this.isDealerOperator() ||
            this.isDealerTechnician() ||
            this.isTechSupport()) &&
          customRoleAllows(this, "systems_add")
        );
      },
      canEditVideoDevice: function () {
        return (
          (this.isAdmin() ||
            this.isDealerAdmin() ||
            this.isDealerOperator() ||
            this.isDealerTechnician()) &&
          customRoleAllows(this, "systems_edit")
        );
      },
      canDeleteVideoDevice: function () {
        return (
          (this.isAdmin() || this.isDealerAdmin()) &&
          customRoleAllows(this, "systems_delete")
        );
      },
      canUpdateHikDoorbellFirmware: function () {
        // Don't allow supervisor to update hik doorbell firmware; make dealer be responsible for it
        // (if power is lost to doorbell during update, it is bricked)
        return (
          (this.isDealerAdmin() ||
            this.isDealerOperator() ||
            this.isDealerTechnician()) &&
          customRoleAllows(this, "systems_edit")
        );
      },
      canAddCameras: function () {
        return (
          this.isAdmin() ||
          this.isDealerAdmin() ||
          this.isDealerOperator() ||
          this.isDealerTechnician()
        );
      },
      canTestCameras: function () {
        return (
          this.isAdmin() ||
          this.isDealerAdmin() ||
          this.isDealerOperator() ||
          this.isDealerTechnician() ||
          this.isTechSupport()
        );
      },
      canEditTags: function () {
        return this.userHasCustomRole()
          ? this.user.customRole.manage_tags
          : this.isAdmin() || this.isDealerAdmin() || this.isDealerTechnician();
      },

      canViewCustomBranding: function () {
        return PROPS.CUSTOM_BRANDING_USERS.has(this.email);
      },

      tagsEnabled: function () {
        return true; // This feature is always enabled now for all dealers, but could be disabled in the future
      },

      canViewSendAllUsersButton: function () {
        return PROPS.AUTHORIZED_LIST_SEND_ALL_USERS.has(this.email);
      },

      // Can get to fast programming
      canFastProgramPanel: function () {
        return (
          (this.isAdmin() ||
            this.isDealerAdmin() ||
            this.isDealerOperator() ||
            this.isDealerTechnician() ||
            this.isTechSupport()) &&
          this.canProgramFast &&
          customRoleAllows(this, "programming_view")
        );
      },
      // Can get to full programming
      canProgramFullPanel: function () {
        return (
          (this.isAdmin() ||
            this.isDealerAdmin() ||
            this.isDealerOperator() ||
            this.isDealerTechnician() ||
            this.isTechSupport()) &&
          this.canProgramFull &&
          customRoleAllows(this, "programming_view")
        );
      },
      // Program panel entities
      canViewProgramming: function () {
        return customRoleAllows(this, "programming_view");
      },
      canEditProgramming: function () {
        return customRoleAllows(this, "programming_edit");
      },
      canAddProgramming: function () {
        return customRoleAllows(this, "programming_add");
      },
      canDeleteProgramming: function () {
        return customRoleAllows(this, "programming_delete");
      },
      canUtilizeTechTools: function () {
        return customRoleAllows(this, "tech_tools");
      },
      canEditXTSchedules: function () {
        return (
          (this.isAdmin() ||
            this.isDealerAdmin() ||
            this.isDealerOperator() ||
            this.isDealerTechnician() ||
            this.isTechSupport()) &&
          customRoleAllows(this, "schedules_edit") &&
          [
            "CellComEX",
            "CellComSL",
            "DualCom",
            "iComLNC",
            "iComSL",
            "MiniCellCom",
            "TMS6",
            "XT30",
            "XT50",
            "XTL",
            "XTLN",
            "XTLP",
            "XTLW",
          ].includes(
            this.controlSystem.panels[this.controlSystem.panel_index]
              .hardware_model
          )
        );
      },
      canEditXRSchedules: function () {
        return (
          (this.isAdmin() ||
            this.isDealerAdmin() ||
            this.isDealerOperator() ||
            this.isDealerTechnician()) &&
          customRoleAllows(this, "schedules_edit") &&
          ["XR150", "XR350", "XR550"].includes(
            this.controlSystem.panels[this.controlSystem.panel_index]
              .hardware_model
          )
        );
      },
      canViewXTSchedules: function () {
        return (
          (this.isAdmin() ||
            this.isDealerAdmin() ||
            this.isDealerOperator() ||
            this.isDealerTechnician() ||
            this.isTechSupport()) &&
          customRoleAllows(this, "schedules_view") &&
          [
            "CellComEX",
            "CellComSL",
            "DualCom",
            "iComLNC",
            "iComSL",
            "MiniCellCom",
            "TMS6",
            "XT30",
            "XT50",
            "XTL",
            "XTLN",
            "XTLP",
            "XTLW",
          ].includes(
            this.controlSystem.panels[this.controlSystem.panel_index]
              .hardware_model
          )
        );
      },
      canViewXRSchedules: function () {
        return (
          (this.isAdmin() ||
            this.isDealerAdmin() ||
            this.isDealerOperator() ||
            this.isDealerTechnician() ||
            this.isTechSupport()) &&
          customRoleAllows(this, "schedules_view") &&
          ["XR150", "XR350", "XR550"].includes(
            this.controlSystem.panels[this.controlSystem.panel_index]
              .hardware_model
          )
        );
      },
      canViewUserCodes: function () {
        return (
          (this.isAdmin() ||
            this.isDealerAdmin() ||
            this.isDealerOperator() ||
            this.isDealerTechnician() ||
            this.isTechSupport()) &&
          customRoleAllows(this, "user_codes_view") &&
          [
            "CellComEX",
            "CellComSL",
            "DualCom",
            "iComLNC",
            "iComSL",
            "MiniCellCom",
            "TMS6",
            "XF6_100",
            "XF6_500",
            "XR150",
            "XR350",
            "XR550",
            "XT30",
            "XT50",
            "XTL",
            "XTLN",
            "XTLP",
            "XTLW",
          ].includes(
            this.controlSystem.panels[this.controlSystem.panel_index]
              .hardware_model
          )
        );
      },
      canEditUserCodes: function () {
        return (
          (this.isAdmin() ||
            this.isDealerAdmin() ||
            this.isDealerOperator() ||
            this.isDealerTechnician()) &&
          customRoleAllows(this, "user_codes_edit") &&
          [
            "CellComEX",
            "CellComSL",
            "DualCom",
            "iComLNC",
            "iComSL",
            "MiniCellCom",
            "TMS6",
            "XF6_100",
            "XF6_500",
            "XR150",
            "XR350",
            "XR550",
            "XT30",
            "XT50",
            "XTL",
            "XTLN",
            "XTLP",
            "XTLW",
          ].includes(
            this.controlSystem.panels[this.controlSystem.panel_index]
              .hardware_model
          )
        );
      },
      canRevealUserCodes: function () {
        return (
          this.isDealerAdmin() &&
          customRoleAllows(this, "user_codes_reveal") &&
          this.dealerInfo.store_user_codes &&
          this.controlSystem.services_manager.store_user_codes
        );
      },
      canRunPanelTests: function () {
        return (
          (this.isAdmin() ||
            this.isDealerAdmin() ||
            this.isDealerOperator() ||
            this.isDealerTechnician() ||
            this.isTechSupport()) &&
          (["XTLP", "XT30", "XT50", "XF6_500", "XF6_100", "TMS6"].includes(
            this.controlSystem.panels[this.controlSystem.panel_index]
              .hardware_model
          ) ||
            (["XR150", "XR350", "XR550"].includes(
              this.controlSystem.panels[this.controlSystem.panel_index]
                .hardware_model
            ) &&
              ((599 >=
                this.controlSystem.panels[this.controlSystem.panel_index]
                  .software_version &&
                this.controlSystem.panels[this.controlSystem.panel_index]
                  .software_version >= 182) ||
                this.controlSystem.panels[this.controlSystem.panel_index]
                  .software_version >= 682)) ||
            ([
              "CellComSL",
              "CellComEX",
              "DualCom",
              "MiniCellCom",
              "iComSL",
              "iComLNC",
            ].includes(
              this.controlSystem.panels[this.controlSystem.panel_index]
                .hardware_model
            ) &&
              this.controlSystem.panels[this.controlSystem.panel_index]
                .software_version >= 125))
        );
      },
      canMoveSystems: function () {
        return (
          (this.isAdmin() || this.isDealerAdmin()) &&
          customRoleAllows(this, "move_systems")
        );
      },
      canEditProfiles: function () {
        return (
          (this.isAdmin() ||
            this.isDealerAdmin() ||
            this.isDealerOperator() ||
            this.isDealerTechnician() ||
            this.isTechSupport) &&
          customRoleAllows(this, "profiles_edit") &&
          ["XR150", "XR350", "XR550"].indexOf(
            this.controlSystem.panels[this.controlSystem.panel_index]
              .hardware_model
          ) > -1
        );
      },
      canViewProfiles: function () {
        return (
          (this.isAdmin() ||
            this.isDealerAdmin() ||
            this.isDealerOperator() ||
            this.isDealerTechnician() ||
            this.isTechSupport()) &&
          customRoleAllows(this, "profiles_view") &&
          ["XR150", "XR350", "XR550"].indexOf(
            this.controlSystem.panels[this.controlSystem.panel_index]
              .hardware_model
          ) > -1
        );
      },
      canReport: function () {
        return (
          this.isDealerAdmin() ||
          this.isDealerOperator() ||
          this.isDealerTechnician() ||
          this.isTechSupport()
        );
      },
      canUpdatePanel: function () {
        return (
          (this.isAdmin() ||
            this.isDealerAdmin() ||
            this.isDealerOperator() ||
            this.isDealerTechnician()) &&
          this.activePanelSupportsUpdate()
        );
      },
      canMonitorOnDemand: function () {
        return (
          this.isAdmin() ||
          this.isDealerAdmin() ||
          this.isDealerOperator() ||
          (this.isDealerTechnician() && this.supportMonitorOnDemand())
        );
      },
      canEditNewsItems: function () {
        // Make sure the user is a DMP Employee and their user email is in the News Items white list
        return (
          (this.isAdmin() ||
            this.isTechSupport() ||
            this.isCustomerSupport()) &&
          NEWS_ITEMS.NewsItemsUsers.indexOf(this.email) !== -1
        );
      },
      canReceiveNewsItems: function () {
        // Block News Items for the following users
        return !NO_NEWS.NoNewsUsers.includes(this.email);
      },
      canViewDealerStore: function () {
        return (
          (this.isAdmin() ||
            this.isTechSupport() ||
            this.isCustomerSupport() ||
            this.isDealerAdmin()) &&
          this.enabledCompanyStore()
        );
      },
      canEditDealerStore: function () {
        return (
          (this.isAdmin() ||
            this.isTechSupport() ||
            this.isCustomerSupport() ||
            this.isDealerAdmin()) &&
          this.enabledCompanyStore()
        );
      },
      canDeleteDealerStore: function () {
        return (
          (this.isAdmin() ||
            this.isTechSupport() ||
            this.isCustomerSupport() ||
            this.isDealerAdmin()) &&
          this.enabledCompanyStore()
        );
      },
      canViewDealerSettings: function () {
        return (
          (this.isAdmin() ||
            this.isTechSupport() ||
            this.isCustomerSupport() ||
            this.isDealerAdmin() ||
            this.isDealerOperator() ||
            this.isDealerTechnician()) &&
          customRoleAllows(this, "dealer_settings_view")
        );
      },
      canEditDealerSettings: function () {
        return (
          this.isAdmin() ||
          this.isTechSupport() ||
          this.isCustomerSupport() ||
          this.isDealerAdmin()
        );
      },
      canDeleteDealerSettings: function () {
        return (
          this.isAdmin() ||
          this.isTechSupport() ||
          this.isCustomerSupport() ||
          this.isDealerAdmin()
        );
      },
      canViewSalesApp: function () {
        return (
          (this.isAdmin() ||
            this.isTechSupport() ||
            this.isCustomerSupport() ||
            this.isDealerAdmin()) &&
          this.enabledSalesApp()
        );
      },
      canEditSalesApp: function () {
        return (
          (this.isAdmin() ||
            this.isTechSupport() ||
            this.isCustomerSupport() ||
            this.isDealerAdmin()) &&
          this.enabledSalesApp()
        );
      },
      canDeleteSalesApp: function () {
        return (
          (this.isAdmin() ||
            this.isTechSupport() ||
            this.isCustomerSupport() ||
            this.isDealerAdmin()) &&
          this.enabledSalesApp()
        );
      },
      canEditOnDemandPricing: function () {
        return (
          this.isAdmin() ||
          this.isTechSupport() ||
          this.isCustomerSupport() ||
          this.isDealerAdmin()
        );
      },
      canViewOnDemandPricing: function () {
        return (
          this.isAdmin() ||
          this.isTechSupport() ||
          this.isCustomerSupport() ||
          this.isDealerAdmin()
        );
      },
      canViewDealerInvoices: function () {
        return (
          (this.isDealerAdmin() ||
            this.isSupervisorAccessible() ||
            this.isAccountant()) &&
          customRoleAllows(this, "billing_view") &&
          (this.dealerInfo.allow_billing ||
            this.parentDealerOnChildAndCanViewInvoices())
        );
      },
      canViewSecureComStats: function () {
        return this.isSupervisorAccessible() && this.user.role === "admin";
      },
      canViewSecureComPricing: function () {
        return this.canViewDealerInvoices();
      },
      canMakeDealerPayments: function () {
        return (
          this.isDealerAdmin() && customRoleAllows(this, "create_payments")
        );
      },
      canViewUserSettingsPage: function () {
        return !this.isAccountant();
      },
      canViewCellularSunset: function () {
        return (
          (this.isSupervisorAccessible() ||
            this.isDealerAdmin() ||
            this.isDealerOperator() ||
            this.isDealerTechnician()) &&
          customRoleAllows(this, "cellular_sunset_view") &&
          (this.dealerInfo.allow_billing ||
            this.parentDealerOnChildAndCanViewInvoices())
        );
      },
      canViewCustomerReferralTab: function () {
        return (
          this.enabledCustomerReferral() &&
          (this.isSupervisorAccessible() || this.isDealerAdmin())
        );
      },
      canViewCustomerReferralDashboard: function () {
        return this.isSupervisorAccessible() || this.isDealerAdmin();
      },
      canViewCustomerCampaignEmailTab: function () {
        return this.isSupervisorAccessible() || this.isDealerAdmin();
      },
      enforceIpWhitelist: function () {
        return this.isDealerAdmin() && mustFollowWhitelist(this);
      },
      enabledIpWhitelist: function () {
        return this.dealerInfo && this.dealerInfo?.ip_whitelist_enabled;
      },
      enabledDistributionSubscriber: function () {
        return this.dealerInfo?.distribution_subscriber;
      },
      enforceDistributionSubscriberValidPaymentMethod: function () {
        return (
          !this.isSupervisorAccessible() &&
          this.enabledDistributionSubscriber() &&
          !this.dealerInfo?.hasValidBillingInformation &&
          !this.dealerInfo?.valid_payment_source
        );
      },
      enabledCompanyStore: function () {
        return this.dealerInfo && this.dealerInfo.company_store;
      },
      enabledSalesApp: function () {
        return this.dealerInfo && this.dealerInfo.sales_app_allowed;
      },
      enabledOnDemand: function () {
        return this.dealerInfo && this.dealerInfo.on_demand_allowed;
      },
      enabledCustomApp: function () {
        return this.dealerInfo && this.dealerInfo.custom_app;
      },
      enabledIntegrations: function () {
        return this.dealerInfo && this.dealerInfo.integrationsEnabled;
      },
      enabledLoginAsCustomer: function () {
        return this.dealerInfo && this.dealerInfo.allow_log_in_as_customer;
      },
      enabledAllowTechSupportLogInAsCustomer: function () {
        return this.dealerInfo && this.dealerInfo.allow_tech_support_log_in_as;
      },
      enabledCustomerReferral: function () {
        return this.dealerInfo && this.dealerInfo.customer_referral;
      },
      canViewIntegrations: function () {
        return (
          this.canEditDealerSettings() &&
          (this.enabledIntegrations() || this.isAdmin())
        );
      },
      canViewOrderDetails: function () {
        return (
          (this.isAdmin() ||
            this.isTechSupport() ||
            this.isCustomerSupport() ||
            this.isDealerAdmin() ||
            this.isSalesManager() ||
            this.isDealerOperator()) &&
          (this.enabledCompanyStore() || this.enabledSalesApp())
        );
      },
      canApproveOrders: function () {
        return (
          (this.isDealerAdmin() ||
            this.isSalesManager() ||
            this.isDealerOperator()) &&
          (this.enabledCompanyStore() || this.enabledSalesApp())
        );
      },
      canChangeOwnPassword: function () {
        return this.isAdmin() || this.isTechSupport() || this.isDealerAdmin();
      },
      canUpdateModemFirmware: function () {
        return this.isAdmin();
      },
      canViewSystemNotesImages: function () {
        return (
          (this.isDealerAdmin() ||
            this.isDealerOperator() ||
            this.isDealerTechnician()) &&
          customRoleAllows(this, "systems_view")
        );
      },
      canEditSystemNotesImages: function () {
        return (
          (this.isDealerAdmin() ||
            this.isDealerOperator() ||
            this.isDealerTechnician()) &&
          customRoleAllows(this, "systems_edit")
        );
      },
      canSendClearBayAlarmBanner: function () {
        return (
          (this.isDealerOperator() || this.isDealerAdmin()) &&
          KEYPAD_BANNER_SEND.DEALERS.includes(this.dealer_id)
        );
      },
      canViewBusinessAnalytics: function () {
        return (
          (this.isDealerAdmin() || this.isSupervisorAccessible()) &&
          customRoleAllows(this, "business_analytics_view")
        );
      },
      canManageBusinessAnalytics: function () {
        return this.isDealerAdmin() || this.isSupervisorAccessible();
      },
      canLoginToDealerAdmin: function () {
        return customRoleAllows(this, "dealer_admin");
      },
      canViewAccountingReports: function () {
        return (
          this.isSupervisorAccessible() &&
          ACCOUNTING_REPORTS.AccountingReportsUsers.indexOf(this.email) !== -1
        );
      },

      activePanelSupportsUpdate: function () {
        var activePanelVersion =
          this.controlSystem.panels[this.controlSystem.panel_index]
            .software_version;
        var activePanelDateCode =
          this.controlSystem.panels[this.controlSystem.panel_index]
            .software_date;
        var activePanelFamily =
          this.controlSystem.panels[this.controlSystem.panel_index]
            .hardware_family;
        var activePanelHardwareModel =
          this.controlSystem.panels[this.controlSystem.panel_index]
            .hardware_model;
        var activePanelSerialNumber =
          this.controlSystem.panels[this.controlSystem.panel_index]
            .serial_number;
        switch (activePanelFamily) {
          case "TMSentry":
            return true;
          case "XF6":
            return true;
          case "XT30":
            // Remote Update is supported for XTLP
            if (/XTLP.*/.test(activePanelHardwareModel)) {
              return true;
            }
            // ALL models of CellComEX support remote update
            if (/CellComEX/.test(activePanelHardwareModel)) {
              return true;
            }
            // ALL models of iComSL support remote update
            if (/iComSL/.test(activePanelHardwareModel)) {
              return true;
            }
            if (/iComLNC/.test(activePanelHardwareModel)) {
              return true;
            }
            // Remote Update is currently NOT supported for XTL models
            if (/XTL.*/.test(activePanelHardwareModel)) {
              return false;
            }
            // Otherwise, let the version determine if Remote Update is supported
            return (
              (activePanelVersion > REMOTE_UPDATE_VERSIONS.XT_MIN_VERSION &&
                parseInt(activePanelSerialNumber, 16) >
                  parseInt(REMOTE_UPDATE_VERSIONS.XT_MIN_SERIAL, 16)) ||
              (activePanelVersion === REMOTE_UPDATE_VERSIONS.XT_MIN_VERSION &&
                activePanelDateCode >= REMOTE_UPDATE_VERSIONS.XT_MIN_DATECODE &&
                parseInt(activePanelSerialNumber, 16) >=
                  parseInt(REMOTE_UPDATE_VERSIONS.XT_MIN_SERIAL, 16)) ||
              activePanelVersion >= REMOTE_UPDATE_VERSIONS.XT_INT_VERSION
            );

          case "XR550":
            return (
              activePanelVersion > REMOTE_UPDATE_VERSIONS.XR_MIN_VERSION ||
              (activePanelVersion == REMOTE_UPDATE_VERSIONS.XR_MIN_VERSION &&
                activePanelDateCode > REMOTE_UPDATE_VERSIONS.XR_MIN_DATECODE)
            );
          default:
            return false;
        }
      },

      activePanelSupportsPanelTests: function () {
        var activePanelVersion =
          this.controlSystem.panels[this.controlSystem.panel_index]
            .software_version;
        var activePanelDateCode =
          this.controlSystem.panels[this.controlSystem.panel_index]
            .software_date;
        var activePanelFamily =
          this.controlSystem.panels[this.controlSystem.panel_index]
            .hardware_family;
        var activePanelHardwareModel =
          this.controlSystem.panels[this.controlSystem.panel_index]
            .hardware_model;
        switch (activePanelFamily) {
          case "XT30":
            // Let the version determine if panel tests are supported
            return activePanelVersion >= PANEL_TEST_VERSIONS.XT_MIN_VERSION;
          case "XR550":
            // Let the version determine if panel tests are supported
            return activePanelVersion >= PANEL_TEST_VERSIONS.XR_MIN_VERSION;
          default:
            return false;
        }
      },

      supportMonitorOnDemand: function () {
        return true;
      },

      /**
       * For DMP log-ins, remove the selected dealer
       * @param dealerService - the dealer service for the logged in user
       */
      unsetSelectedDealer: function (dealerService) {
        var deferred = $q.defer();
        if (this.isSupervisorAccessible()) {
          if (angular.isDefined(dealerService)) {
            var _this = this;
            this.setDealerInfo(dealerService)
              .then(
                function () {
                  delete _this.selectedDealer;
                  _this.setDisplayDealerOptions();
                  deferred.resolve();
                },
                function (error) {
                  deferred.reject(error);
                }
              )
              .catch(function (error) {
                console.error(error);
              });
          } else {
            deferred.reject("No dealer service");
          }
        } else {
          deferred.resolve();
        }
        return deferred.promise;
      },

      /**
       * For DMP log-ins, set the dealer that has been selected
       * @param dealerService - the dealer service for the dealer that has been selected
       */
      setSelectedDealer: function (dealerService) {
        var _this = this;
        var deferred = $q.defer();
        if (_this.isSupervisorAccessible()) {
          if (angular.isDefined(dealerService)) {
            _this
              .setDealerInfo(dealerService)
              .then(
                function (data) {
                  if (data) {
                    _this.selectedDealer = data.name;
                    _this.setDisplayDealerOptions();
                  }
                  deferred.resolve();
                },
                function (error) {
                  deferred.reject(error);
                }
              )
              .catch(function (error) {
                console.error(error);
              });
          } else {
            deferred.reject("No dealer service");
          }
        } else {
          deferred.resolve();
        }
        return deferred.promise;
      },

      // updateCustomerCount: function() {
      //   DashboardDataService.getNumberOfCustomersForDealer(UserService.dealer_id).then(
      //     function(data){
      //       _this.dealerInfo.numberOfCustomers = data;
      //     },
      //     function(){

      //     }
      //   )
      // },

      /**
       * Based on the user on this object, display the dealer specific type buttons / links in the sidebar, etc.
       */
      setDisplayDealerOptions: function () {
        if (this.hasOwnProperty("user")) {
          this.displayDealerOptions = !(
            this.isSupervisorAccessible() &&
            angular.isUndefined(this.selectedDealer)
          );
        } else {
          console.warn(
            "UserService - user not loaded. Unable to set displayDealerOptions."
          );
        }
      },

      /**
       * True if the logged in user is that of a parent dealer; dealer that has dealers
       * @returns {*|boolean}
       */
      isParentDealerLogin: function () {
        return (
          this.isDealerAccessible() &&
          this.parentDealerInfo.parent.hasOwnProperty("id")
        );
      },

      /**
       * True if the logged in user is that of a parent dealer and the parent dealer is currently selected
       * @returns {boolean}
       */
      onParentDealer: function () {
        return (
          angular.isDefined(this.dealer_id) &&
          this.parentDealerInfo.parent.hasOwnProperty("id") &&
          this.parentDealerInfo.parent.id === this.dealer_id
        );
      },

      /**
       * True if the logged in user is not that of a parent dealer or is and the parent dealer is currently selected
       * @returns {boolean}
       */
      notParentDealerOnSelf: function () {
        if (this.isParentDealerLogin()) {
          return !this.onParentDealer();
        } else {
          return true;
        }
      },

      /**
       * True if the logged in user is not that of a parent dealer or is and one of the child dealers is selected
       * @returns {boolean}
       */
      notParentDealerNotOnSelf: function () {
        if (this.isParentDealerLogin()) {
          return this.onParentDealer();
        } else {
          return true;
        }
      },

      userHasCustomRole: function () {
        return this.user && angular.isDefined(this.user.customRole);
      },

      userHasSystemCheckInRestriction: function () {
        return this.userHasCustomRole() && this.user.customRole.limit_entity;
      },

      parentDealerOnChild: function () {
        return this.isParentDealerLogin() && !this.onParentDealer();
      },

      parentDealerOnChildAndCanViewInvoices: function () {
        return (
          this.isParentDealerLogin() &&
          !this.onParentDealer() &&
          this.parentDealerInfo.parent.allow_billing
        );
      },

      isBayAlarm: function () {
        return this.dealer_id == SPECIAL_DEALERS.BayAlarm;
      },

      /**
       * Return true if the user has no role or has a role that is within schedule
       * @returns {boolean|*}
       */
      roleInSchedule: function () {
        // The user is in schedule if they don't have a role...
        return (
          !this.userHasCustomRole() ||
          // or they do and current time is within the start / end times;
          (this.userHasCustomRole() &&
            roleEnabledToday(this.user.customRole) &&
            nowInSchedule(
              this.user.customRole.start_time,
              this.user.customRole.end_time
            ))
        );
      },
    };

    function roleEnabledToday(role) {
      var Now = new Date();
      switch (Now.getDay()) {
        case 0:
          return role.sunday;
        case 1:
          return role.monday;
        case 2:
          return role.tuesday;
        case 3:
          return role.wednesday;
        case 4:
          return role.thursday;
        case 5:
          return role.friday;
        case 6:
          return role.saturday;
      }
    }

    // Private functions
    /**
     * Clear the parent dealer information
     * @param userService
     */
    function initParentDealerInfo(userService) {
      userService.parentDealerInfo = {
        // The parent dealer
        parent: {},
        // Dealers of the parent dealer
        dealers: [],
      };
    }

    /**
     * Fields that must be defined and not null for a valid user
     * @type {string[]}
     */
    var requiredUserFields = ["email", "accessible_type", "accessible_id"];

    /**
     * Check that the user has values for all the necessary fields
     * @param {object} user
     * @returns {boolean}
     */
    function isValidUserObject(user) {
      if (angular.isUndefined(user)) {
        return false;
      }
      var valid = true;
      for (var i = 0; i < requiredUserFields.length; i++) {
        if (isUndefinedOrNull(user[requiredUserFields[i]])) {
          valid = false;
          break;
        }
      }
      return valid;
    }

    /**
     * Verifies a custom role authorizes a given action
     * @param {*} userService
     * @param {string} field
     * @returns {boolean} True if user is set and the IP Whitelist role is assigned
     */
    function mustFollowWhitelist(userService) {
      if (userService.userHasCustomRole()) {
        var permission =
          userService.user.customRole.user_auth_permissions
            .enforce_ip_whitelist;
        return permission;
      } else {
        // There is no role assigned to the user or there is no user set so the role doesn't prevent the action
        return false;
      }
    }

    /**
     * Verifies a custom role authorizes a given action
     * @param {*} userService
     * @param {string} field
     * @returns {boolean} True if user is set and a role is assigned and allows the action or there is no role assigned
     */
    function customRoleAllows(userService, field) {
      if (userService.userHasCustomRole()) {
        var permission =
          userService.user.customRole.user_auth_permissions[field];
        return permission && userService.roleInSchedule();
      } else {
        // There is no role assigned to the user or there is no user set so the role doesn't prevent the action
        return angular.isDefined(userService.user);
      }
    }

    // Initialize the service on load
    thisService.initialize();

    // Then return the service
    return thisService;
  },
]);
