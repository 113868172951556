import { defaultTheme } from "app/common/DaColors";
import graphql from "babel-plugin-relay/macro";
import { addClassesIf } from "common/utils/web/css";
import ConfirmingDeleteTooltip from "components/ConfirmingDeleteTooltip";
import {
  Clickable,
  Input,
  Panel,
  Table,
  TableFooter,
  TableFooterReducedPadding,
  TableHeader,
} from "components/DaStyledElements";
import * as Paginate from "components/DaStyledElements/Paginator";
import usePaginationState, {
  ActionType,
  SortOrder,
} from "components/DaStyledElements/Paginator/usePaginationState";
import { GenericPageFallback } from "components/GenericPageFallback";
import Icon from "components/Icon";
import { TableHeaderSorting } from "components/MobileCredentials/TableStyledComponents";
import { AlertsContextProvider, useShowAlert } from "contexts/AlertsContext";
import { ascend, descend, isEmpty, sort, splitEvery } from "ramda";
import React, { Suspense } from "react";
import {
  RelayEnvironmentProvider,
  useLazyLoadQuery,
  useMutation,
  useRefetchableFragment,
} from "react-relay/hooks";
import { react2angular } from "react2angular";
import { vagueSearchUsingGetters } from "search-filterer";
import {
  asID,
  fromProgrammingTemplateId,
  idAsString,
  PanelHardwareModel,
  toDealerId,
} from "securecom-graphql/client";
import styled, { ThemeProvider } from "styled-components";
import { TemplatesListDeleteMutation } from "./__generated__/TemplatesListDeleteMutation.graphql";
import { TemplatesListQuery } from "./__generated__/TemplatesListQuery.graphql";
import { TemplatesList_dealer$key } from "./__generated__/TemplatesList_dealer.graphql";

enum SortKey {
  TemplateName,
  CustomerName,
  SystemType,
  CommunicationType,
}

const FixedWidthTableHeaderSorting = styled(TableHeaderSorting)`
  width: 25%;
`;

const MinHeightPanelTable = styled(Panel.Table)`
  min-height: 8rem;
  border: 0;
`;

const SystemTypeAndDelete = styled.td`
  display: flex;
  justify-content: space-between;
`;

const ListItemGridEdit = styled.div`
  grid-area: edit;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  @media (min-width: 40rem) {
    justify-content: flex-end;
  }
`;

const formatSystemType = (systemType: string) => {
  const system = systemType as PanelHardwareModel;
  switch (system) {
    case PanelHardwareModel.XTLP:
      return "XTLplus/XTLtouch";
    case PanelHardwareModel.CELLCOM_EX:
      return "CellComEX";
    case PanelHardwareModel.DUALCOM:
      return "DualCom";
    case PanelHardwareModel.CELLCOM_SL:
      return "CellComSL";
    case PanelHardwareModel.XF6_100:
    case PanelHardwareModel.XF6_500:
      return system.replace("_", "-");
    case PanelHardwareModel.TMS6:
      return "TMSentry";
    default:
      return systemType;
  }
};

const TemplatesList = ({
  editTemplate,
  dealer,
}: {
  editTemplate: (templateId: string, hardwareModel: string) => void;
  dealer: TemplatesList_dealer$key;
}) => {
  const [data, refetch] = useRefetchableFragment(
    graphql`
      fragment TemplatesList_dealer on Dealer
      @refetchable(queryName: "TemplateListRefetchQuery") {
        programmingTemplates {
          totalCount
          edges {
            node {
              id
              name
              hardwareModel
              customer {
                id
                name
              }
            }
          }
        }
      }
    `,
    dealer
  );

  const [paginationState, dispatch] = usePaginationState({
    sortOrder: SortOrder.Ascending,
    sortKey: SortKey.TemplateName,
    pageSize: 10,
  });

  const showAlert = useShowAlert();

  const searchedTemplates = React.useMemo(
    () =>
      vagueSearchUsingGetters(
        paginationState.search,
        [
          (edge) => (edge.node?.name ?? "").toUpperCase(),
          (edge) => (edge.node?.customer?.name ?? "").toUpperCase(),
          (edge) =>
            formatSystemType(edge.node?.hardwareModel ?? "").toUpperCase(),
        ],
        data ? data.programmingTemplates.edges : []
      ),
    [data, paginationState.search]
  );

  const sortedTemplates = React.useMemo(
    () =>
      sort(
        (paginationState.sortOrder === SortOrder.Ascending ? ascend : descend)(
          (template) => {
            switch (paginationState.sortKey) {
              case SortKey.CustomerName:
                return (template?.node?.customer?.name ?? "").toUpperCase();
              case SortKey.TemplateName:
                return (template.node?.name ?? "").toUpperCase();
              case SortKey.SystemType:
                return formatSystemType(
                  template.node?.hardwareModel ?? ""
                ).toUpperCase();
              default:
                return (template.node?.name ?? "").toUpperCase();
            }
          }
        ),
        searchedTemplates
      ),
    [paginationState.sortKey, paginationState.sortOrder, searchedTemplates]
  );

  const pagedTemplates = React.useMemo(
    () =>
      splitEvery(paginationState.pageSize, sortedTemplates)[
        paginationState.currentPage - 1
      ] ?? [],
    [paginationState.currentPage, paginationState.pageSize, sortedTemplates]
  );

  const maxPage = Math.ceil(sortedTemplates.length / paginationState.pageSize);

  const deleteMutation = graphql`
    mutation TemplatesListDeleteMutation($id: ID!) {
      deleteProgrammingTemplate(templateId: $id) {
        ... on DeleteProgrammingTemplateSuccessResult {
          deletedTemplateId
        }
        ... on DeleteProgrammingTemplateErrorResult {
          error
          messages
        }
      }
    }
  `;

  const [deleteTemplate] =
    useMutation<TemplatesListDeleteMutation>(deleteMutation);

  const removeTemplate = (templateId: string) => {
    if (templateId) {
      deleteTemplate({
        variables: { id: templateId },
        updater: (_, response) => {
          if (response?.deleteProgrammingTemplate?.deletedTemplateId) {
            showAlert({
              type: "success",
              text: "Template deleted successfully",
            });

            refetch({}, { fetchPolicy: "store-and-network" });
          } else if (response?.deleteProgrammingTemplate?.error) {
            showAlert({
              type: "error",
              text: `Unable to Delete Template: ${response.deleteProgrammingTemplate?.messages?.join(
                ",\r\n"
              )}`,
            });
          }
        },
      });
    }
  };

  return (
    <>
      <MinHeightPanelTable>
        <TableHeader>
          <TableHeader.Left>
            <label className="sr-only" htmlFor="search-input">
              Search
            </label>
            <Input.TableSearch
              id="search-input"
              value={paginationState.search}
              onChange={(search) => {
                dispatch({
                  type: ActionType.SetSearch,
                  search,
                });
              }}
            />
          </TableHeader.Left>
        </TableHeader>
        <Table>
          <thead>
            <tr>
              <FixedWidthTableHeaderSorting
                className={addClassesIf(
                  [
                    [
                      paginationState.sortKey === SortKey.TemplateName,
                      "is-active",
                    ],
                    [
                      paginationState.sortOrder === SortOrder.Descending,
                      "tablesort-desc",
                    ],
                    [
                      paginationState.sortOrder === SortOrder.Ascending,
                      "tablesort-asc",
                    ],
                  ],
                  "tablesort-sortable"
                )}
                onClick={() => {
                  if (paginationState.sortKey === SortKey.TemplateName) {
                    dispatch({
                      type: ActionType.SetSortOrder,
                      sortOrder:
                        paginationState.sortOrder === SortOrder.Ascending
                          ? SortOrder.Descending
                          : SortOrder.Ascending,
                    });
                  } else {
                    dispatch({
                      type: ActionType.SetSortKey,
                      sortKey: SortKey.TemplateName,
                    });
                  }
                }}
              >
                Template Name
              </FixedWidthTableHeaderSorting>

              <FixedWidthTableHeaderSorting
                className={addClassesIf(
                  [
                    [
                      paginationState.sortKey === SortKey.CustomerName,
                      "is-active",
                    ],
                    [
                      paginationState.sortOrder === SortOrder.Descending,
                      "tablesort-desc",
                    ],
                    [
                      paginationState.sortOrder === SortOrder.Ascending,
                      "tablesort-asc",
                    ],
                  ],
                  "tablesort-sortable"
                )}
                onClick={() => {
                  if (paginationState.sortKey === SortKey.CustomerName) {
                    dispatch({
                      type: ActionType.SetSortOrder,
                      sortOrder:
                        paginationState.sortOrder === SortOrder.Ascending
                          ? SortOrder.Descending
                          : SortOrder.Ascending,
                    });
                  } else {
                    dispatch({
                      type: ActionType.SetSortKey,
                      sortKey: SortKey.CustomerName,
                    });
                  }
                }}
              >
                Customer
              </FixedWidthTableHeaderSorting>

              <FixedWidthTableHeaderSorting
                className={addClassesIf(
                  [
                    [
                      paginationState.sortKey === SortKey.SystemType,
                      "is-active",
                    ],
                    [
                      paginationState.sortOrder === SortOrder.Descending,
                      "tablesort-desc",
                    ],
                    [
                      paginationState.sortOrder === SortOrder.Ascending,
                      "tablesort-asc",
                    ],
                  ],
                  "tablesort-sortable"
                )}
                onClick={() => {
                  if (paginationState.sortKey === SortKey.SystemType) {
                    dispatch({
                      type: ActionType.SetSortOrder,
                      sortOrder:
                        paginationState.sortOrder === SortOrder.Ascending
                          ? SortOrder.Descending
                          : SortOrder.Ascending,
                    });
                  } else {
                    dispatch({
                      type: ActionType.SetSortKey,
                      sortKey: SortKey.SystemType,
                    });
                  }
                }}
              >
                System Type
              </FixedWidthTableHeaderSorting>
            </tr>
          </thead>
          <tbody>
            {!isEmpty(pagedTemplates) ? (
              pagedTemplates.map((template) => (
                <tr key={template.node?.id}>
                  <td className="text-left">{template.node?.name} </td>
                  <td className="text-left">
                    {template?.node?.customer?.name}
                  </td>
                  <SystemTypeAndDelete className="text-left">
                    <>
                      {formatSystemType(template.node?.hardwareModel ?? "")}

                      <ListItemGridEdit>
                        <Clickable.IconButton
                          className="mar-r-12"
                          icon={
                            <Icon
                              name="settings"
                              size="2.4rem"
                              color="var(--color-primary)"
                            />
                          }
                          onClick={() => {
                            if (template.node)
                              editTemplate(
                                template.node.id,
                                template.node.hardwareModel
                              );
                          }}
                        />
                        <ConfirmingDeleteTooltip
                          iconSize="2.4rem"
                          onConfirm={() => {
                            if (template.node) removeTemplate(template.node.id);
                          }}
                          message={`Deleting Template "${template.node?.name}".  Are you sure?`}
                        />
                      </ListItemGridEdit>
                    </>
                  </SystemTypeAndDelete>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={4}>No data</td>
              </tr>
            )}
          </tbody>
        </Table>
        <TableFooterReducedPadding>
          <TableFooter.Left>
            <Paginate.ItemsOfItemCount
              items={sortedTemplates.length}
              itemCount={data.programmingTemplates.totalCount}
            />
          </TableFooter.Left>
          <TableFooter.Center>
            <Paginate.Paginator
              state={paginationState}
              dispatch={dispatch}
              maxPage={maxPage}
            />
          </TableFooter.Center>
          <TableFooter.Right>
            <Paginate.ItemsPerPage
              state={paginationState}
              dispatch={dispatch}
            />
          </TableFooter.Right>
        </TableFooterReducedPadding>
      </MinHeightPanelTable>
    </>
  );
};

const TemplatesListRoot = ({ UserService, $state }: any) => {
  const editTemplate = (templateId: string, hardwareModel: string) => {
    $state.go("app.dealer.template_edit", {
      dealer_id: UserService.dealer_id,
      id: fromProgrammingTemplateId(asID(templateId)).id,
      hardware_model: hardwareModel,
    });
  };
  const data = useLazyLoadQuery<TemplatesListQuery>(
    graphql`
      query TemplatesListQuery($dealerId: ID!) {
        dealer(id: $dealerId) {
          id
          programmingTemplates {
            totalCount
          }
          ...TemplatesList_dealer
        }
      }
    `,
    { dealerId: idAsString(toDealerId(UserService.dealer_id)) },
    { fetchPolicy: "network-only" }
  );

  return data.dealer ? (
    <TemplatesList editTemplate={editTemplate} dealer={data.dealer} />
  ) : null;
};

const TemplatesListAngularEntryPoint = ({
  RelayService,
  UserService,
  $state,
  $rootScope,
}: any) => {
  const environment = React.useMemo(
    () => RelayService.getEnvironment(),
    [RelayService]
  );
  return (
    <RelayEnvironmentProvider environment={environment as any}>
      <ThemeProvider theme={defaultTheme}>
        <Suspense fallback={<GenericPageFallback />}>
          <AlertsContextProvider $rootScope={$rootScope}>
            <TemplatesListRoot UserService={UserService} $state={$state} />
          </AlertsContextProvider>
        </Suspense>
      </ThemeProvider>
    </RelayEnvironmentProvider>
  );
};

export const dangerouslyAddToApp = () => {
  App.component(
    "templatesList",
    react2angular(
      TemplatesListAngularEntryPoint,
      [],
      ["RelayService", "UserService", "$state", "$rootScope", "$stateParams"]
    )
  );
};
