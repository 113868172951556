import graphql from "babel-plugin-relay/macro";
import Select from "components/FullProgramming/common/Select";
import TextInput from "components/FullProgramming/common/TextInput";
import { formatListOfNumbers } from "components/FullProgramming/utils/format";
import { resolvePanelType } from "components/FullProgramming/utils/panel";
import { curry } from "ramda";
import * as React from "react";
import { KeyfobButtonType, SystemType } from "securecom-graphql/client";
import { useHardwareModel, useSystemOptionsSystemType } from "../PanelContext";
import ProgrammingConceptForm from "../ProgrammingConceptForm";
import { useKeyfobFragment } from "./KeyfobContext";
import { KeyfobButtonNumberType } from "./utils";
import { KeyfobButtonAreasField_keyfob$key } from "./__generated__/KeyfobButtonAreasField_keyfob.graphql";

export const keyfobButtonAreasFieldId = curry(
  (buttonNumber: KeyfobButtonNumberType, number: string) =>
    `keyfob-button-areas-button-number-${buttonNumber.toLowerCase()}-${number}`
);

function KeyfobButtonAreasField(props: {
  buttonNumber: KeyfobButtonNumberType;
}) {
  const [
    {
      id,
      number,
      buttonOneAction,
      buttonTwoAction,
      buttonThreeAction,
      buttonFourAction,
      buttonOneAreas,
      buttonTwoAreas,
      buttonThreeAreas,
      buttonFourAreas,
    },
    updateKeyfob,
  ] = useKeyfobFragment<KeyfobButtonAreasField_keyfob$key>(
    graphql`
      fragment KeyfobButtonAreasField_keyfob on Keyfob {
        id
        number
        buttonOneAction
        buttonTwoAction
        buttonThreeAction
        buttonFourAction
        buttonOneAreas
        buttonTwoAreas
        buttonThreeAreas
        buttonFourAreas
      }
    `
  );

  const hardwareModel = useHardwareModel();
  const { isXr150, isXt } = resolvePanelType(hardwareModel);

  const { buttonNumber } = props;
  const fieldId = keyfobButtonAreasFieldId(buttonNumber, String(number));
  const armingMode = useSystemOptionsSystemType();
  const enabledButtonTypes = [
    KeyfobButtonType.ARMING,
    KeyfobButtonType.TOGGLE_ARM_DISARM,
    KeyfobButtonType.DISARMING,
  ];

  const disabled =
    (!enabledButtonTypes.includes(buttonOneAction as KeyfobButtonType) &&
      buttonNumber === "One") ||
    (!enabledButtonTypes.includes(buttonTwoAction as KeyfobButtonType) &&
      buttonNumber === "Two") ||
    (!enabledButtonTypes.includes(buttonThreeAction as KeyfobButtonType) &&
      buttonNumber === "Three") ||
    (!enabledButtonTypes.includes(buttonFourAction as KeyfobButtonType) &&
      buttonNumber === "Four");

  return (
    <ProgrammingConceptForm.Field
      fieldId={fieldId}
      label="Areas"
      disabled={disabled}
      tooltip="Select which Areas will Arm/Disarm when this button is activated."
    >
      {armingMode === SystemType.AREA && (
        <TextInput
          id={fieldId}
          disabled={disabled}
          value={
            buttonNumber === "Two"
              ? buttonTwoAreas ?? 0
              : buttonNumber === "Three"
              ? buttonThreeAreas ?? 0
              : buttonNumber === "Four"
              ? buttonFourAreas ?? 0
              : buttonOneAreas ?? 0
          }
          pattern={
            isXr150
              ? "([1-8]([\\,\\-][1-8]){0,7})"
              : isXt
              ? "([1-6]([\\,\\-][1-6]){0,5})"
              : "([1-9]|[12][0-9]|3[0-2])(([\\,\\-])([1-9]|[12][0-9]|3[0-2])){0,31}"
          }
          validationMessage={
            isXr150
              ? "Valid values are any combination of 1-8."
              : isXt
              ? "Valid values are any combination of 1-6."
              : "Valid values are any combination of 1-32."
          }
          required
          onChange={({ target }) => {
            updateKeyfob((recordProxy) => {
              recordProxy.setValue(target.value, `button${buttonNumber}Areas`);
            });
          }}
          onBlur={({ target }) => {
            updateKeyfob((recordProxy) => {
              recordProxy.setValue(
                formatListOfNumbers(target.value),
                `button${buttonNumber}Areas`
              );
            });
          }}
        />
      )}
      {armingMode !== SystemType.AREA && (
        <Select
          id={id}
          name={id}
          disabled={disabled}
          value={
            buttonNumber === "Two"
              ? buttonTwoAreas
              : buttonNumber === "Three"
              ? buttonThreeAreas
              : buttonNumber === "Four"
              ? buttonFourAreas
              : buttonOneAreas
          }
          required
          onChange={({ target }) => {
            updateKeyfob((recordProxy) => {
              recordProxy.setValue(target.value, `button${buttonNumber}Areas`);
            });
          }}
        >
          {armingMode === SystemType.HOME_SLEEP_AWAY && (
            <>
              <Select.Option value={"1,2,3"}>Away</Select.Option>
              <Select.Option value={"1,2"}>Sleep</Select.Option>
              <Select.Option value={"1"}>Home</Select.Option>
            </>
          )}
          {armingMode === SystemType.ALL_PERIMETER && (
            <>
              <Select.Option value={"1,2"}>All</Select.Option>
              <Select.Option value={"1"}>Perimeter</Select.Option>
            </>
          )}
        </Select>
      )}
    </ProgrammingConceptForm.Field>
  );
}

export default KeyfobButtonAreasField;
