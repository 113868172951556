/**
 * @generated SignedSource<<8ae5365973de3e01d9fa59e97f980596>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type KeypadArmedLed = "ALL" | "ANY";
export type SystemType = "ALL_PERIMETER" | "AREA" | "HOME_SLEEP_AWAY" | "HOME_SLEEP_AWAY_GUEST";
import { FragmentRefs } from "relay-runtime";
export type SystemOptionsKeypadArmedLedField_systemOptions$data = {
  readonly keypadArmedLed?: KeypadArmedLed;
  readonly systemType?: SystemType;
  readonly " $fragmentType": "SystemOptionsKeypadArmedLedField_systemOptions";
};
export type SystemOptionsKeypadArmedLedField_systemOptions$key = {
  readonly " $data"?: SystemOptionsKeypadArmedLedField_systemOptions$data;
  readonly " $fragmentSpreads": FragmentRefs<"SystemOptionsKeypadArmedLedField_systemOptions">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SystemOptionsKeypadArmedLedField_systemOptions",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "systemType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "keypadArmedLed",
          "storageKey": null
        }
      ],
      "type": "XrSystemOptions",
      "abstractKey": null
    }
  ],
  "type": "SystemOptions",
  "abstractKey": "__isSystemOptions"
};

(node as any).hash = "1517437f1b612fd1d53a0831420d93e2";

export default node;
